@charset "UTF-8";
/*!
 * Jobdigger (https://jobdigger.nl/)
 */
@import url("https://use.typekit.net/yfa2xjr.css");
.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
/* Scale, Type, Logo, Color, Gradients, Components, Icons, Shadows, Export, ... */
/* --------------------------------------------------------- */
/* UI[X] --------------------------------------------------- */
/* --------------------------------------------------------- */
/* 16px = 100% */
/* Focus ...? */
/* Radius for common elements .i.e. cards, tooltips, ... */
/* --------------------------------------------------------- */
/* [T]YPE -------------------------------------------------- */
/* --------------------------------------------------------- */
/* Weights ------------------------------------------------- */
/* H1, H2, H3? */
/* Sizes --------------------------------------------------- */
/* Lead, h6 */
/* H5 */
/* H4 */
/* H3 */
/* H2 */
/* H1 */
/* --------------------------------------------------------- */
/* [C]OLORS ------------------------------------------------ */
/* --------------------------------------------------------- */
/* Accents ------------------------------------------------- */
/* Base ---------------------------------------------------- */
/* Shadow -------------------------------------------------- */
/* --------------------------------------------------------- */
/* [A]NIMATION---------------------------------------------- */
/* --------------------------------------------------------- */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 400;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover:focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  display: list-item;
  cursor: pointer;
}

/* --------------------------------------------------------- */
/* CUSTOM -------------------------------------------------- */
/* --------------------------------------------------------- */
body {
  text-align: left;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

em {
  font-style: normal;
}

a,
button {
  cursor: pointer;
}
a:focus,
button:focus {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover, a:focus, a:hover:focus {
  text-decoration: none;
}

p,
ul,
ol {
  margin-bottom: 1.5rem;
}

input:focus {
  outline: none;
}
input[type=text]:focus,
textarea:focus {
  -webkit-text-size-adjust: 100%;
}

::selection {
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 0.25rem;
  background-color: transparent !important;
}

::-webkit-scrollbar:horizontal {
  height: 0.125rem;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 0rem;
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar:horizontal {
    height: 0.25rem;
  }
  .windows.webkit ::-webkit-scrollbar {
    width: 0.75rem;
  }
}
.mac.touch.webkit div {
  -webkit-overflow-scrolling: touch;
}

.windows.ie div {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

h1,
.h1 {
  font-size: 1.75rem;
  line-height: 2.5rem;
}

h2,
.h2 {
  font-size: 1.5rem;
  line-height: 2.125rem;
}

h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

body,
h4, h5, h6,
.h4, .h5, .h6 {
  font-size: 1rem;
  line-height: 1.75rem;
}

.navbar_menu--items-modal, .navbar_menu--backdrop, .dashboard-funnel-container .pane-section--inset .funnel-step:hover,
.portfolio-funnel-container .pane-section--inset .funnel-step:hover, .indicator::before, .vacancy-link, .navigation-toggle span, .navigation-toggle:before, .navigation-toggle:after, .button, .btn {
  transition: all 150ms ease;
}

.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button, .menu-item, .modal {
  transition: background 150ms ease;
}

.funnel-title, .menu-item-label,
.menu-item-title, .user-name,
.user-name-via,
.user-name-location,
.user-name-credits,
.user-avatar:after, a {
  transition: color 150ms ease;
}

.cabinet-card {
  transition: box-shadow 150ms ease;
}

.cabinet-card:after, .menu--underline-hover .menu-item:after {
  transition: width 150ms ease;
}

.cabinet-labels, .no-touch .report-list-item .organisation-actions, .pane-v2-header-options.pane-v2-header-options--cabinet, .pane-v2-content, .pane-v2-header,
.pane-v2-bar,
.pane-v2-crumbs,
.pane-v2-subheader,
.pane-v2-tabs,
.pane-v2-actions,
.pane-v2-footer, .pane-v2-backdrop, .application.application-v2--webfontloader-enabled,
.application-v2.application-v2--webfontloader-enabled {
  transition: opacity 150ms ease;
}

.organisation-name[data-toggle] {
  cursor: pointer;
}

.module-career .pane-filter--search .filter-reset,
.application-v2--module-career .pane-filter--search .filter-reset, .menu-item-suffix, .focus-meta-toggle, .filters .filter-label, .pane-locations-mode, .button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
  appearance: none;
  user-select: none;
  cursor: pointer;
  background: transparent;
  touch-action: manipulation;
  text-transform: none;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
.module-career .pane-filter--search .filter-reset:hover,
.application-v2--module-career .pane-filter--search .filter-reset:hover, .menu-item-suffix:hover, .focus-meta-toggle:hover, .filters .filter-label:hover, .pane-locations-mode:hover, .button:hover, .module-career .pane-filter--search .filter-reset:focus,
.application-v2--module-career .pane-filter--search .filter-reset:focus, .menu-item-suffix:focus, .focus-meta-toggle:focus, .filters .filter-label:focus, .pane-locations-mode:focus, .button:focus {
  cursor: pointer;
}

.menu-item-link, .menu-item.menu-item--type-heading, .menu-item.menu-item--type-label, .pane-section.pane-section--inset, .dashboard-credits,
.dashboard-qa, .dashboard-intro {
  padding-left: 2rem;
  padding-right: 2rem;
}

.dashboard-qa-heading:first-child, .dashboard-credits,
.dashboard-qa, .dashboard-intro, .dashboard {
  padding-top: 2rem;
}

.pane-section--inset .strip .strip-item:last-child, .menu-item-suffix {
  padding-right: 2rem;
}

.dashboard-qa-item, .dashboard-credits,
.dashboard-qa, .dashboard-intro {
  padding-bottom: 2rem;
}

.pane-section--inset .strip .strip-item:first-child {
  padding-left: 2rem;
}

.pane-section--inset .strip, .pane-section--inset .grid-view, .card-content > .menu, .pane-section--inset > .menu {
  margin-left: -2rem;
  margin-right: -2rem;
}

@media (min-width: 576px) {
  .menu--expand .menu-item--type-heading,
  .menu--expand .menu-item--type-label,
  .menu--expand .menu-item-link, .pane-section.pane-section--expand, .dashboard-credits,
  .dashboard-qa, .dashboard-intro {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pane-section--expand .strip .strip-item:last-child, .menu--expand .menu-item-suffix {
    padding-right: 3rem;
  }
  .pane-section--expand .strip .strip-item:first-child {
    padding-left: 3rem;
  }
  .pane-section--expand .strip, .pane-section--expand .grid-view, .pane-section--expand > .menu {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
.step-title {
  font-size: 1rem;
  line-height: 1.75rem;
}

html.state--client .view-results .pane-focus .actions-header .focus-save, .pane-close,
.pane-back,
.pane-dropdown, template, input::-ms-clear, select::-ms-expand, [x-cloak] {
  display: none;
}

html.state--client .view-results .pane-results .results-modes, html.state--client .view-account .pane-v2--search,
html.state--client .view-account .action--search,
html.state--client .view-vacancies .pane-v2--search,
html.state--client .view-vacancies .action--search, .view-professions .layout-pane--tertiary .pane-header .pane-close, .view-dashboard .menu-item--icon-home, .pane-header--steps .steps .step:first-child .step-link:before, .pane-header--steps .steps .step:last-child .step-link:after,
.pane-v2-banner .steps .step:first-child .step-link:before,
.pane-v2-banner .steps .step:last-child .step-link:after, .kv-grid-loading .kv-loader-overlay, .modal-backdrop, .user.user--compact .user-name,
.user.user--compact .user-name-via,
.user.user--compact .user-name-location, [hidden], .hide,
.export--only {
  display: none !important;
}

html.state--client .view-results .pane-focus .btn.focus-save, html.state--client .view-results .pane-focus .focus-cover-actions, .pane-header--steps .steps .step.step--active,
.pane-v2-banner .steps .step.step--active, .step-link, .step, .user-modal.modal .pane-dropdown, .pane-dropdown, article, aside, figcaption, figure, footer, header, hgroup, main, nav, section, legend {
  display: block;
}

label, output {
  display: inline-block;
}

html, body, button, input, optgroup, select, textarea {
  font-family: "neuzeit-grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

::selection {
  background: #1E025F;
  color: #ffffff;
}

body {
  color: #443469;
  font-weight: 400;
}

th,
b,
strong {
  font-weight: 700;
}

.lead {
  font-weight: 400;
}
.lead em {
  color: #443469;
}

::-webkit-scrollbar {
  background-color: #F4F6Fa;
}

::-webkit-scrollbar-thumb {
  background-color: #d5d5e1;
}

.pane-content::-webkit-scrollbar,
.grid-view::-webkit-scrollbar {
  background-color: #ffffff;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #1E025F;
  /* Temporarily set default brand to bold headings */
  font-weight: 700;
}

a:hover, a:focus, a:hover:focus {
  color: #40BC8A;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #9F99B1;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #9F99B1;
  opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #9F99B1;
  opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9F99B1;
}

.application,
.application-v2 {
  background: #F4F6Fa;
}

.btn {
  color: #443469;
  background-color: #ffffff;
  border-color: #ffffff;
  font-weight: 700;
}
.btn:hover {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn:focus, .btn.focus {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn:active, .btn.active, .open > .btn.dropdown-toggle {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  background-image: none;
}
.btn:active:hover, .btn:active:focus, .btn:active.focus, .btn.active:hover, .btn.active:focus, .btn.active.focus, .open > .btn.dropdown-toggle:hover, .open > .btn.dropdown-toggle:focus, .open > .btn.dropdown-toggle.focus {
  color: #443469;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}
.btn.disabled:focus, .btn.disabled.focus, .btn:disabled:focus, .btn:disabled.focus {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn.disabled:hover, .btn:disabled:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn:focus, .btn:active, .btn:hover {
  background-color: #ffffff;
  border-color: #E4E4EC;
}

.btn-default {
  color: #443469;
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-default:hover {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #c0c0d3;
}
.btn-default:focus, .btn-default.focus {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #c0c0d3;
}
.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #c0c0d3;
  background-image: none;
}
.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
  color: #443469;
  background-color: #d4d4d4;
  border-color: #9999b7;
}
.btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default:disabled:focus, .btn-default:disabled.focus {
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-default.disabled:hover, .btn-default:disabled:hover {
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-default:focus, .btn-default:active, .btn-default:hover {
  background-color: #ffffff;
  border-color: #9F99B1;
}

.btn-primary {
  color: #ffffff;
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #33966e;
  border-color: #308e68;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #33966e;
  border-color: #308e68;
}
.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #33966e;
  border-color: #308e68;
  background-image: none;
}
.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #2a7b5b;
  border-color: #205d44;
}
.btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary:disabled:focus, .btn-primary:disabled.focus {
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-primary.disabled:hover, .btn-primary:disabled:hover {
  background-color: #40BC8A;
  border-color: #40BC8A;
}

.btn-secondary {
  color: #ffffff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #0e012d;
  border-color: #0b0123;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #0e012d;
  border-color: #0b0123;
}
.btn-secondary:active, .btn-secondary.active, .open > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #0e012d;
  border-color: #0b0123;
  background-image: none;
}
.btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus, .open > .btn-secondary.dropdown-toggle:hover, .open > .btn-secondary.dropdown-toggle:focus, .open > .btn-secondary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #03000a;
  border-color: black;
}
.btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary:disabled:focus, .btn-secondary:disabled.focus {
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary.disabled:hover, .btn-secondary:disabled:hover {
  background-color: #1E025F;
  border-color: #1E025F;
}

.btn-tertiary {
  color: #ffffff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary:hover {
  color: #ffffff;
  background-color: #f62b3d;
  border-color: #f52134;
}
.btn-tertiary:focus, .btn-tertiary.focus {
  color: #ffffff;
  background-color: #f62b3d;
  border-color: #f52134;
}
.btn-tertiary:active, .btn-tertiary.active, .open > .btn-tertiary.dropdown-toggle {
  color: #ffffff;
  background-color: #f62b3d;
  border-color: #f52134;
  background-image: none;
}
.btn-tertiary:active:hover, .btn-tertiary:active:focus, .btn-tertiary:active.focus, .btn-tertiary.active:hover, .btn-tertiary.active:focus, .btn-tertiary.active.focus, .open > .btn-tertiary.dropdown-toggle:hover, .open > .btn-tertiary.dropdown-toggle:focus, .open > .btn-tertiary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #f30a1f;
  border-color: #cc091a;
}
.btn-tertiary.disabled:focus, .btn-tertiary.disabled.focus, .btn-tertiary:disabled:focus, .btn-tertiary:disabled.focus {
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary.disabled:hover, .btn-tertiary:disabled:hover {
  background-color: #F85C6A;
  border-color: #F85C6A;
}

.btn-quaternary {
  color: #ffffff;
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-quaternary:hover {
  color: #ffffff;
  background-color: #847c9b;
  border-color: #7e7696;
}
.btn-quaternary:focus, .btn-quaternary.focus {
  color: #ffffff;
  background-color: #847c9b;
  border-color: #7e7696;
}
.btn-quaternary:active, .btn-quaternary.active, .open > .btn-quaternary.dropdown-toggle {
  color: #ffffff;
  background-color: #847c9b;
  border-color: #7e7696;
  background-image: none;
}
.btn-quaternary:active:hover, .btn-quaternary:active:focus, .btn-quaternary:active.focus, .btn-quaternary.active:hover, .btn-quaternary.active:focus, .btn-quaternary.active.focus, .open > .btn-quaternary.dropdown-toggle:hover, .open > .btn-quaternary.dropdown-toggle:focus, .open > .btn-quaternary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #72698a;
  border-color: #5f5873;
}
.btn-quaternary.disabled:focus, .btn-quaternary.disabled.focus, .btn-quaternary:disabled:focus, .btn-quaternary:disabled.focus {
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-quaternary.disabled:hover, .btn-quaternary:disabled:hover {
  background-color: #9F99B1;
  border-color: #9F99B1;
}

.btn-active {
  background: #0275d8 !important;
  border-color: #0275d8 !important;
}

.btn-primary-outline {
  color: #40BC8A;
  background-image: none;
  background-color: transparent;
  border-color: #40BC8A;
  border-style: solid;
}
.btn-primary-outline:hover {
  color: #fff;
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-primary-outline:focus, .btn-primary-outline.focus {
  color: #fff;
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-primary-outline:active, .btn-primary-outline.active, .open > .btn-primary-outline.dropdown-toggle {
  color: #fff;
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-primary-outline:active:hover, .btn-primary-outline:active:focus, .btn-primary-outline:active.focus, .btn-primary-outline.active:hover, .btn-primary-outline.active:focus, .btn-primary-outline.active.focus, .open > .btn-primary-outline.dropdown-toggle:hover, .open > .btn-primary-outline.dropdown-toggle:focus, .open > .btn-primary-outline.dropdown-toggle.focus {
  color: #fff;
  background-color: #2a7b5b;
  border-color: #205d44;
}
.btn-primary-outline.disabled:focus, .btn-primary-outline.disabled.focus, .btn-primary-outline:disabled:focus, .btn-primary-outline:disabled.focus {
  border-color: #8bd7b8;
}
.btn-primary-outline.disabled:hover, .btn-primary-outline:disabled:hover {
  border-color: #8bd7b8;
}

.btn-secondary-outline {
  color: #1E025F;
  background-image: none;
  background-color: transparent;
  border-color: #1E025F;
  border-style: solid;
}
.btn-secondary-outline:hover {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary-outline:focus, .btn-secondary-outline.focus {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary-outline:active, .btn-secondary-outline.active, .open > .btn-secondary-outline.dropdown-toggle {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary-outline:active:hover, .btn-secondary-outline:active:focus, .btn-secondary-outline:active.focus, .btn-secondary-outline.active:hover, .btn-secondary-outline.active:focus, .btn-secondary-outline.active.focus, .open > .btn-secondary-outline.dropdown-toggle:hover, .open > .btn-secondary-outline.dropdown-toggle:focus, .open > .btn-secondary-outline.dropdown-toggle.focus {
  color: #fff;
  background-color: #03000a;
  border-color: black;
}
.btn-secondary-outline.disabled:focus, .btn-secondary-outline.disabled.focus, .btn-secondary-outline:disabled:focus, .btn-secondary-outline:disabled.focus {
  border-color: #3e04c3;
}
.btn-secondary-outline.disabled:hover, .btn-secondary-outline:disabled:hover {
  border-color: #3e04c3;
}

.btn-tertiary-outline {
  color: #F85C6A;
  background-image: none;
  background-color: transparent;
  border-color: #F85C6A;
  border-style: solid;
}
.btn-tertiary-outline:hover {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary-outline:focus, .btn-tertiary-outline.focus {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary-outline:active, .btn-tertiary-outline.active, .open > .btn-tertiary-outline.dropdown-toggle {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary-outline:active:hover, .btn-tertiary-outline:active:focus, .btn-tertiary-outline:active.focus, .btn-tertiary-outline.active:hover, .btn-tertiary-outline.active:focus, .btn-tertiary-outline.active.focus, .open > .btn-tertiary-outline.dropdown-toggle:hover, .open > .btn-tertiary-outline.dropdown-toggle:focus, .open > .btn-tertiary-outline.dropdown-toggle.focus {
  color: #fff;
  background-color: #f30a1f;
  border-color: #cc091a;
}
.btn-tertiary-outline.disabled:focus, .btn-tertiary-outline.disabled.focus, .btn-tertiary-outline:disabled:focus, .btn-tertiary-outline:disabled.focus {
  border-color: #fcbec3;
}
.btn-tertiary-outline.disabled:hover, .btn-tertiary-outline:disabled:hover {
  border-color: #fcbec3;
}

.btn-quaternary-outline,
.btn-remark {
  color: #9F99B1;
  background-image: none;
  background-color: transparent;
  border-color: #9F99B1;
  border-style: solid;
}
.btn-quaternary-outline:hover,
.btn-remark:hover {
  color: #fff;
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-quaternary-outline:focus, .btn-quaternary-outline.focus,
.btn-remark:focus,
.btn-remark.focus {
  color: #fff;
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-quaternary-outline:active, .btn-quaternary-outline.active, .open > .btn-quaternary-outline.dropdown-toggle,
.btn-remark:active,
.btn-remark.active,
.open > .btn-remark.dropdown-toggle {
  color: #fff;
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-quaternary-outline:active:hover, .btn-quaternary-outline:active:focus, .btn-quaternary-outline:active.focus, .btn-quaternary-outline.active:hover, .btn-quaternary-outline.active:focus, .btn-quaternary-outline.active.focus, .open > .btn-quaternary-outline.dropdown-toggle:hover, .open > .btn-quaternary-outline.dropdown-toggle:focus, .open > .btn-quaternary-outline.dropdown-toggle.focus,
.btn-remark:active:hover,
.btn-remark:active:focus,
.btn-remark:active.focus,
.btn-remark.active:hover,
.btn-remark.active:focus,
.btn-remark.active.focus,
.open > .btn-remark.dropdown-toggle:hover,
.open > .btn-remark.dropdown-toggle:focus,
.open > .btn-remark.dropdown-toggle.focus {
  color: #fff;
  background-color: #72698a;
  border-color: #5f5873;
}
.btn-quaternary-outline.disabled:focus, .btn-quaternary-outline.disabled.focus, .btn-quaternary-outline:disabled:focus, .btn-quaternary-outline:disabled.focus,
.btn-remark.disabled:focus,
.btn-remark.disabled.focus,
.btn-remark:disabled:focus,
.btn-remark:disabled.focus {
  border-color: #d5d3dd;
}
.btn-quaternary-outline.disabled:hover, .btn-quaternary-outline:disabled:hover,
.btn-remark.disabled:hover,
.btn-remark:disabled:hover {
  border-color: #d5d3dd;
}

.btn-blend {
  color: #9F99B1;
  background-color: #ffffff;
  border-color: #F4F6Fa;
  box-shadow: 0 1rem 2rem -0.125rem rgba(14, 1, 43, 0.06125);
}
.btn-blend:hover {
  color: #9F99B1;
  background-color: #e6e6e6;
  border-color: #cad4e7;
}
.btn-blend:focus, .btn-blend.focus {
  color: #9F99B1;
  background-color: #e6e6e6;
  border-color: #cad4e7;
}
.btn-blend:active, .btn-blend.active, .open > .btn-blend.dropdown-toggle {
  color: #9F99B1;
  background-color: #e6e6e6;
  border-color: #cad4e7;
  background-image: none;
}
.btn-blend:active:hover, .btn-blend:active:focus, .btn-blend:active.focus, .btn-blend.active:hover, .btn-blend.active:focus, .btn-blend.active.focus, .open > .btn-blend.dropdown-toggle:hover, .open > .btn-blend.dropdown-toggle:focus, .open > .btn-blend.dropdown-toggle.focus {
  color: #9F99B1;
  background-color: #d4d4d4;
  border-color: #9caed2;
}
.btn-blend.disabled:focus, .btn-blend.disabled.focus, .btn-blend:disabled:focus, .btn-blend:disabled.focus {
  background-color: #ffffff;
  border-color: #F4F6Fa;
}
.btn-blend.disabled:hover, .btn-blend:disabled:hover {
  background-color: #ffffff;
  border-color: #F4F6Fa;
}
.btn-blend:hover, .btn-blend:focus, .btn-blend:hover:focus {
  border-color: #F4F6Fa;
  background-color: #ffffff;
  box-shadow: 0 1rem 2rem -0.125rem rgba(14, 1, 43, 0.15);
}
.btn-blend:hover, .btn-blend:focus, .btn-blend:active, .btn-blend:active:hover, .btn-blend:active:focus, .btn-blend:active:hover:focus {
  background-color: #ffffff;
  border-color: #F4F6Fa;
  color: #1E025F;
}

.button {
  font-weight: 700;
}

.button[disabled],
.button--disabled {
  color: #ffffff !important;
  opacity: 0.88 !important;
}

.button--primary,
.button--secondary,
.button--tertiary,
.button--quaternary {
  color: #ffffff;
}
.button--primary [class*=icon--],
.button--secondary [class*=icon--],
.button--tertiary [class*=icon--],
.button--quaternary [class*=icon--] {
  color: #ffffff;
}
.button--primary:hover, .button--primary:focus, .button--primary:hover:focus,
.button--secondary:hover,
.button--secondary:focus,
.button--secondary:hover:focus,
.button--tertiary:hover,
.button--tertiary:focus,
.button--tertiary:hover:focus,
.button--quaternary:hover,
.button--quaternary:focus,
.button--quaternary:hover:focus {
  color: #ffffff;
}

.button--primary {
  background: #40BC8A;
}
.button--primary:hover, .button--primary:focus, .button--primary:hover:focus {
  background: #3aa97c;
}

.button--secondary {
  background: #1E025F;
}
.button--secondary:hover, .button--secondary:focus, .button--secondary:hover:focus {
  background: #160146;
}

.button--tertiary {
  background: #F85C6A;
}
.button--tertiary:hover, .button--tertiary:focus, .button--tertiary:hover:focus {
  background: #f74454;
}

.button--quaternary {
  background: #5a5e79;
}
.button--quaternary:hover, .button--quaternary:focus, .button--quaternary:hover:focus {
  background: #4f536a;
}

.button--placeholder {
  border-color: #E4E4EC;
  color: #9F99B1;
}
.button--placeholder:hover, .button--placeholder:focus, .button--placeholder:hover:focus {
  border-color: #9F99B1;
  color: #443469;
}

.button--transparent {
  color: #9F99B1;
}
.button--transparent:hover, .button--transparent:focus, .button--transparent:hover:focus {
  color: #443469;
}

.button--mute {
  border-color: #9F99B1;
  color: #9F99B1;
}
.button--mute:hover, .button--mute:focus, .button--mute:hover:focus {
  border-color: #443469;
  color: #443469;
}

.button--light-outline {
  color: #9F99B1;
  border-color: #9F99B1;
}
.button--light-outline:hover, .button--light-outline:focus, .button--light-outline:hover:focus {
  color: #443469;
  border-color: #443469;
}

.button--primary-outline {
  color: #40BC8A;
  border-color: #40BC8A;
  border-style: solid;
}
.button--primary-outline:hover, .button--primary-outline:focus, .button--primary-outline:hover:focus {
  color: #3aa97c;
  border-color: #3aa97c;
}

.button--secondary-outline {
  color: #1E025F;
  border-color: #1E025F;
  border-style: solid;
}
.button--secondary-outline:hover, .button--secondary-outline:focus, .button--secondary-outline:hover:focus {
  color: #160146;
  border-color: #160146;
}

.button--quaternary-outline {
  color: #5a5e79;
  border-color: #5a5e79;
  border-style: solid;
}
.button--quaternary-outline:hover, .button--quaternary-outline:focus, .button--quaternary-outline:hover:focus {
  color: #4f536a;
  border-color: #4f536a;
}

.button--outline-inverse {
  border-color: #ffffff;
  color: #ffffff;
}
.button--outline-inverse:hover, .button--outline-inverse:focus, .button--outline-inverse:hover:focus {
  opacity: 0.8;
}

.button--primary-inverse,
.button--secondary-inverse,
.button--tertiary-inverse,
.button--quaternary-inverse,
.button--neutral-inverse {
  background: #ffffff;
}
.button--primary-inverse:hover, .button--primary-inverse:focus, .button--primary-inverse:hover:focus,
.button--secondary-inverse:hover,
.button--secondary-inverse:focus,
.button--secondary-inverse:hover:focus,
.button--tertiary-inverse:hover,
.button--tertiary-inverse:focus,
.button--tertiary-inverse:hover:focus,
.button--quaternary-inverse:hover,
.button--quaternary-inverse:focus,
.button--quaternary-inverse:hover:focus,
.button--neutral-inverse:hover,
.button--neutral-inverse:focus,
.button--neutral-inverse:hover:focus {
  background: #ffffff;
}

.button--light-inverse {
  color: #ffffff;
  background-color: #9F99B1;
}
.button--light-inverse:hover, .button--light-inverse:focus, .button--light-inverse:hover:focus {
  background: #443469;
}

.button--primary-inverse {
  color: #40BC8A;
}
.button--primary-inverse:hover, .button--primary-inverse:focus, .button--primary-inverse:hover:focus {
  color: #33966e;
}

.button--secondary-inverse {
  color: #1E025F;
}
.button--secondary-inverse:hover, .button--secondary-inverse:focus, .button--secondary-inverse:hover:focus {
  color: #0e012d;
}

.button--tertiary-inverse {
  color: #F85C6A;
}
.button--tertiary-inverse:hover, .button--tertiary-inverse:focus, .button--tertiary-inverse:hover:focus {
  color: #f62b3d;
}

.button--quaternary-inverse {
  color: #5a5e79;
}
.button--quaternary-inverse:hover, .button--quaternary-inverse:focus, .button--quaternary-inverse:hover:focus {
  color: #44475c;
}

.button--neutral-inverse {
  background: #ffffff;
  color: #443469;
}
.button--neutral-inverse:hover, .button--neutral-inverse:focus, .button--neutral-inverse:hover:focus {
  background: #ffffff;
  color: #1E025F;
}

.button--active-secondary.button--active {
  background: #1E025F;
  color: #ffffff;
}
.button--active-secondary.button--active:hover, .button--active-secondary.button--active:focus, .button--active-secondary.button--active:hover:focus {
  background: #1E025F;
  color: #ffffff;
}

.btn-v3 {
  color: #443469;
  background-color: #ffffff;
  border-color: #ffffff;
  font-weight: 700;
}
.btn-v3:hover {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn-v3:focus, .btn-v3.focus {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn-v3:active, .btn-v3.active, .open > .btn-v3.dropdown-toggle {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  background-image: none;
}
.btn-v3:active:hover, .btn-v3:active:focus, .btn-v3:active.focus, .btn-v3.active:hover, .btn-v3.active:focus, .btn-v3.active.focus, .open > .btn-v3.dropdown-toggle:hover, .open > .btn-v3.dropdown-toggle:focus, .open > .btn-v3.dropdown-toggle.focus {
  color: #443469;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}
.btn-v3.disabled:focus, .btn-v3.disabled.focus, .btn-v3:disabled:focus, .btn-v3:disabled.focus {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-v3.disabled:hover, .btn-v3:disabled:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-v3:focus, .btn-v3:active, .btn-v3:hover {
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-v3--primary {
  color: #ffffff;
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-v3--primary:hover {
  color: #ffffff;
  background-color: #33966e;
  border-color: #308e68;
}
.btn-v3--primary:focus, .btn-v3--primary.focus {
  color: #ffffff;
  background-color: #33966e;
  border-color: #308e68;
}
.btn-v3--primary:active, .btn-v3--primary.active, .open > .btn-v3--primary.dropdown-toggle {
  color: #ffffff;
  background-color: #33966e;
  border-color: #308e68;
  background-image: none;
}
.btn-v3--primary:active:hover, .btn-v3--primary:active:focus, .btn-v3--primary:active.focus, .btn-v3--primary.active:hover, .btn-v3--primary.active:focus, .btn-v3--primary.active.focus, .open > .btn-v3--primary.dropdown-toggle:hover, .open > .btn-v3--primary.dropdown-toggle:focus, .open > .btn-v3--primary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #2a7b5b;
  border-color: #205d44;
}
.btn-v3--primary.disabled:focus, .btn-v3--primary.disabled.focus, .btn-v3--primary:disabled:focus, .btn-v3--primary:disabled.focus {
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-v3--primary.disabled:hover, .btn-v3--primary:disabled:hover {
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.btn-v3--secondary {
  color: #ffffff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-v3--secondary:hover {
  color: #ffffff;
  background-color: #0e012d;
  border-color: #0b0123;
}
.btn-v3--secondary:focus, .btn-v3--secondary.focus {
  color: #ffffff;
  background-color: #0e012d;
  border-color: #0b0123;
}
.btn-v3--secondary:active, .btn-v3--secondary.active, .open > .btn-v3--secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #0e012d;
  border-color: #0b0123;
  background-image: none;
}
.btn-v3--secondary:active:hover, .btn-v3--secondary:active:focus, .btn-v3--secondary:active.focus, .btn-v3--secondary.active:hover, .btn-v3--secondary.active:focus, .btn-v3--secondary.active.focus, .open > .btn-v3--secondary.dropdown-toggle:hover, .open > .btn-v3--secondary.dropdown-toggle:focus, .open > .btn-v3--secondary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #03000a;
  border-color: black;
}
.btn-v3--secondary.disabled:focus, .btn-v3--secondary.disabled.focus, .btn-v3--secondary:disabled:focus, .btn-v3--secondary:disabled.focus {
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-v3--secondary.disabled:hover, .btn-v3--secondary:disabled:hover {
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-v3--secondary svg {
  fill: #ffffff !important;
  stroke: #ffffff !important;
}
.btn-v3--tertiary {
  color: #ffffff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-v3--tertiary:hover {
  color: #ffffff;
  background-color: #f62b3d;
  border-color: #f52134;
}
.btn-v3--tertiary:focus, .btn-v3--tertiary.focus {
  color: #ffffff;
  background-color: #f62b3d;
  border-color: #f52134;
}
.btn-v3--tertiary:active, .btn-v3--tertiary.active, .open > .btn-v3--tertiary.dropdown-toggle {
  color: #ffffff;
  background-color: #f62b3d;
  border-color: #f52134;
  background-image: none;
}
.btn-v3--tertiary:active:hover, .btn-v3--tertiary:active:focus, .btn-v3--tertiary:active.focus, .btn-v3--tertiary.active:hover, .btn-v3--tertiary.active:focus, .btn-v3--tertiary.active.focus, .open > .btn-v3--tertiary.dropdown-toggle:hover, .open > .btn-v3--tertiary.dropdown-toggle:focus, .open > .btn-v3--tertiary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #f30a1f;
  border-color: #cc091a;
}
.btn-v3--tertiary.disabled:focus, .btn-v3--tertiary.disabled.focus, .btn-v3--tertiary:disabled:focus, .btn-v3--tertiary:disabled.focus {
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-v3--tertiary.disabled:hover, .btn-v3--tertiary:disabled:hover {
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-v3--quaternary {
  color: #ffffff;
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-v3--quaternary:hover {
  color: #ffffff;
  background-color: #847c9b;
  border-color: #7e7696;
}
.btn-v3--quaternary:focus, .btn-v3--quaternary.focus {
  color: #ffffff;
  background-color: #847c9b;
  border-color: #7e7696;
}
.btn-v3--quaternary:active, .btn-v3--quaternary.active, .open > .btn-v3--quaternary.dropdown-toggle {
  color: #ffffff;
  background-color: #847c9b;
  border-color: #7e7696;
  background-image: none;
}
.btn-v3--quaternary:active:hover, .btn-v3--quaternary:active:focus, .btn-v3--quaternary:active.focus, .btn-v3--quaternary.active:hover, .btn-v3--quaternary.active:focus, .btn-v3--quaternary.active.focus, .open > .btn-v3--quaternary.dropdown-toggle:hover, .open > .btn-v3--quaternary.dropdown-toggle:focus, .open > .btn-v3--quaternary.dropdown-toggle.focus {
  color: #ffffff;
  background-color: #72698a;
  border-color: #5f5873;
}
.btn-v3--quaternary.disabled:focus, .btn-v3--quaternary.disabled.focus, .btn-v3--quaternary:disabled:focus, .btn-v3--quaternary:disabled.focus {
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-v3--quaternary.disabled:hover, .btn-v3--quaternary:disabled:hover {
  background-color: #9F99B1;
  border-color: #9F99B1;
}
.btn-v3--text-only {
  color: #443469;
  background-color: rgba(255, 255, 255, 0);
  border-color: #40BC8A;
}
.btn-v3--text-only:hover {
  color: #443469;
  background-color: rgba(230, 230, 230, 0);
  border-color: #308e68;
}
.btn-v3--text-only:focus, .btn-v3--text-only.focus {
  color: #443469;
  background-color: rgba(230, 230, 230, 0);
  border-color: #308e68;
}
.btn-v3--text-only:active, .btn-v3--text-only.active, .open > .btn-v3--text-only.dropdown-toggle {
  color: #443469;
  background-color: rgba(230, 230, 230, 0);
  border-color: #308e68;
  background-image: none;
}
.btn-v3--text-only:active:hover, .btn-v3--text-only:active:focus, .btn-v3--text-only:active.focus, .btn-v3--text-only.active:hover, .btn-v3--text-only.active:focus, .btn-v3--text-only.active.focus, .open > .btn-v3--text-only.dropdown-toggle:hover, .open > .btn-v3--text-only.dropdown-toggle:focus, .open > .btn-v3--text-only.dropdown-toggle.focus {
  color: #443469;
  background-color: rgba(212, 212, 212, 0);
  border-color: #205d44;
}
.btn-v3--text-only.disabled:focus, .btn-v3--text-only.disabled.focus, .btn-v3--text-only:disabled:focus, .btn-v3--text-only:disabled.focus {
  background-color: rgba(255, 255, 255, 0);
  border-color: #40BC8A;
}
.btn-v3--text-only.disabled:hover, .btn-v3--text-only:disabled:hover {
  background-color: rgba(255, 255, 255, 0);
  border-color: #40BC8A;
}
.btn-v3--text-only .icon-container .icon-wrap svg {
  fill: #1E025F !important;
  stroke: #1E025F !important;
}
.btn-v3--text-only .icon-container .icon-wrap svg path {
  fill: #1E025F !important;
  stroke: #1E025F !important;
}
.btn-v3--text-only:hover, .btn-v3--text-only:active {
  color: #2d8360 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}
.btn-v3--text-only:hover .icon-container .icon-wrap svg, .btn-v3--text-only:active .icon-container .icon-wrap svg {
  fill: #2d8360 !important;
  stroke: #2d8360 !important;
}
.btn-v3--text-only:hover .icon-container .icon-wrap svg path, .btn-v3--text-only:active .icon-container .icon-wrap svg path {
  fill: #060014 !important;
  stroke: #060014 !important;
}
.btn-v3--positive {
  background-color: #40bc8a;
  color: #ffffff;
}
.btn-v3--positive:hover, .btn-v3--positive:active {
  background-color: #2d8360;
  color: #ffffff;
}
.btn-v3--danger {
  background-color: #F85C6A;
  color: #ffffff;
}
.btn-v3--danger:hover, .btn-v3--danger:active {
  background-color: #f51327;
  color: #ffffff;
}
.btn-v3--white {
  background-color: #ffffff;
  color: #1E025F;
}
.btn-v3--white .icon-container .icon-wrap svg {
  fill: #1E025F !important;
  stroke: #1E025F !important;
}
.btn-v3--white .icon-container .icon-wrap svg path {
  fill: #1E025F !important;
  stroke: #1E025F !important;
}
.btn-v3--white:hover, .btn-v3--white:active {
  background-color: #d9d9d9 !important;
}
.btn-v3--white:hover .icon-container .icon-wrap svg, .btn-v3--white:active .icon-container .icon-wrap svg {
  fill: #060014 !important;
  stroke: #060014 !important;
}
.btn-v3--white:hover .icon-container .icon-wrap svg path, .btn-v3--white:active .icon-container .icon-wrap svg path {
  fill: #060014 !important;
  stroke: #060014 !important;
}
.btn-v3--light {
  background-color: #E4E4EC;
}
.btn-v3--light svg, .btn-v3--light path {
  stroke: #1E025F !important;
}
.btn-v3--light:hover, .btn-v3--light:active {
  background-color: #b7b7cc;
}
.btn-v3--text-only-light {
  background-color: rgba(255, 255, 255, 0);
}
.btn-v3--text-only-light svg, .btn-v3--text-only-light path {
  stroke: #9F99B1 !important;
}
.btn-v3--text-only-light:hover svg, .btn-v3--text-only-light:hover path, .btn-v3--text-only-light:active svg, .btn-v3--text-only-light:active path {
  stroke: #1E025F !important;
}
.btn-v3--grey {
  background-color: #9F99B1;
  color: #ffffff;
}
.btn-v3--grey:hover, .btn-v3--grey:active {
  background-color: #766e90;
  color: #ffffff;
}
.btn-v3--ok {
  background-color: #40bc8a;
  color: #ffffff;
}
.btn-v3--ok:hover, .btn-v3--ok:active {
  color: #ffffff;
  background-color: #2d8360;
}
.btn-v3--nok {
  background-color: #F85C6A;
  color: #ffffff;
}
.btn-v3--nok:hover, .btn-v3--nok:active {
  color: #ffffff;
  background-color: #f51327;
}

.btn-default {
  color: #443469;
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-default:hover {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #c0c0d3;
}
.btn-default:focus, .btn-default.focus {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #c0c0d3;
}
.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #c0c0d3;
  background-image: none;
}
.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
  color: #443469;
  background-color: #d4d4d4;
  border-color: #9999b7;
}
.btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default:disabled:focus, .btn-default:disabled.focus {
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-default.disabled:hover, .btn-default:disabled:hover {
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.btn-default:focus, .btn-default:active, .btn-default:hover {
  background-color: #ffffff;
  border-color: #9F99B1;
}

.form.form--mute .form-control {
  background-color: #F4F6Fa;
  border-color: #1E025F !important;
}
.form.form--mute .form-control:focus {
  border-color: #1E025F;
}

.select2-container--krajee .select2-selection {
  border-color: #1E025F !important;
}

#nokFormModel .select2-container {
  width: 100% !important;
  margin-bottom: 10px;
}

.form-control {
  color: #443469;
}
.has-error .form-control {
  border-color: #F85C6A !important;
}
.has-success .form-control {
  border-color: #40bc8a !important;
}
.form-control:focus {
  color: #1E025F;
}
.form-control.form-control--mute {
  background-color: #F4F6Fa;
  border-color: #F4F6Fa;
}
.form-control.form-control--mute:focus {
  border-color: #1E025F;
}

select.form-control {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16.828" height="9.75" viewBox="0 0 16.828 9.75"><style>.select-drop { fill: rgb(68, 52, 105); }</style><path class="select-drop" d="M12.117-5.918,3.7-14.332l1.324-1.336,7.09,7.09,7.09-7.09,1.324,1.336Z" transform="translate(-3.703 15.668)"/></svg>');
}
select.form-control:focus {
  color: #1E025F;
}

.form-control-label {
  color: #443469;
}
.has-error .form-control-label {
  color: #F85C6A !important;
}
.has-success .form-control-label {
  color: #40bc8a !important;
}

.form-heading {
  font-weight: 700;
}

.help-block {
  color: #9F99B1;
}
.help-block.help-block-success {
  color: #40bc8a;
  font-weight: 700;
}
.help-block.help-block-error {
  color: #F85C6A;
  font-weight: 700;
}

.select2-container--krajee .select2-selection {
  background: #F4F6Fa !important;
}
.select2-container--krajee .select2-selection:focus {
  border-color: #1E025F !important;
}
.select2-container--krajee .select2-selection .select2-selection__rendered {
  color: #443469 !important;
}
.select2-container--krajee .select2-selection .select2-selection__placeholder {
  color: #9F99B1 !important;
}
.select2-container--krajee .select2-selection .select2-selection__clear {
  color: #1E025F !important;
}
.select2-container--krajee .select2-selection.selection2-selection-single .form.form--mute {
  background-color: #F4F6Fa !important;
  border-color: #F4F6Fa !important;
}
.select2-container--krajee .select2-selection.selection2-selection-single .form.form--mute:focus {
  border-color: #1E025F !important;
}
.select2-container--krajee .select2-results__option {
  color: #9F99B1 !important;
}
.select2-container--krajee .select2-results__option[aria-selected] {
  color: #443469 !important;
}
.select2-container--krajee .select2-results__option[aria-selected=true] {
  color: #ffffff !important;
  background: #1E025F !important;
}
.select2-container--krajee .select2-results__option--highlighted[aria-selected] {
  background: #E4E4EC !important;
  color: #443469 !important;
}
.select2-container--krajee .select2-selection__choice {
  color: #443469 !important;
}
.select2-container--krajee .s2-togall-select,
.select2-container--krajee .s2-togall-unselect {
  color: #1E025F !important;
}
.select2-container--krajee .s2-togall-select:hover, .select2-container--krajee .s2-togall-select:focus,
.select2-container--krajee .s2-togall-unselect:hover,
.select2-container--krajee .s2-togall-unselect:focus {
  color: #260378 !important;
}

.logo {
  background-image: url("../images/brand-logo_jobdigger.png");
}

.search .icon-wrap svg {
  stroke: #9F99B1;
  fill: #9F99B1;
}
.search .search-keys.search-keys--selectize {
  background: transparent;
}

.search-profile {
  background: #ffffff;
}

.search-query {
  background: #ffffff;
  transition: box-shadow 150ms ease;
}

.search-submit {
  background-color: #9F99B1;
}
.search-submit .icon-wrap svg {
  stroke: #ffffff;
  fill: #ffffff;
}
.search-submit .search-submit-loading::after {
  border-left-color: #ffffff;
}
.search-submit.search-submit--loading {
  background-color: #1E025F !important;
}

.search-settings,
.search-location {
  width: 38px;
  height: 38px;
  background-color: rgba(159, 153, 177, 0.2);
  border-radius: 7px;
  transform: translate(0, 5px);
}
.search-settings .icon-wrap svg,
.search-location .icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}

.search-profile .icon--location svg,
.search-profile .icon--settings svg,
.search-settings .icon--location svg,
.search-settings .icon--settings svg,
.search-location .icon--location svg,
.search-location .icon--settings svg,
.search-experience .icon--location svg,
.search-experience .icon--settings svg {
  stroke: #443469;
  fill: #443469;
}
.search-profile:hover svg, .search-profile:focus svg, .search-profile:hover:focus svg,
.search-settings:hover svg,
.search-settings:focus svg,
.search-settings:hover:focus svg,
.search-location:hover svg,
.search-location:focus svg,
.search-location:hover:focus svg,
.search-experience:hover svg,
.search-experience:focus svg,
.search-experience:hover:focus svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.search-profile.search-profile--active svg,
.search-settings.search-profile--active svg,
.search-location.search-profile--active svg,
.search-experience.search-profile--active svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.search-profile.search-location--active, .search-profile.search-settings--active,
.search-settings.search-location--active,
.search-settings.search-settings--active,
.search-location.search-location--active,
.search-location.search-settings--active,
.search-experience.search-location--active,
.search-experience.search-settings--active {
  width: 38px;
  height: 38px;
  background-color: rgba(159, 153, 177, 0.2);
  border-radius: 7px;
  transform: translate(0, 5px);
  background-color: #1E025F;
}
.search-profile.search-location--active svg, .search-profile.search-settings--active svg,
.search-settings.search-location--active svg,
.search-settings.search-settings--active svg,
.search-location.search-location--active svg,
.search-location.search-settings--active svg,
.search-experience.search-location--active svg,
.search-experience.search-settings--active svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.search-profile.search-location--active .icon-wrap svg, .search-profile.search-settings--active .icon-wrap svg,
.search-settings.search-location--active .icon-wrap svg,
.search-settings.search-settings--active .icon-wrap svg,
.search-location.search-location--active .icon-wrap svg,
.search-location.search-settings--active .icon-wrap svg,
.search-experience.search-location--active .icon-wrap svg,
.search-experience.search-settings--active .icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}
.search-profile.search-location--active .icon-wrap svg, .search-profile.search-settings--active .icon-wrap svg,
.search-settings.search-location--active .icon-wrap svg,
.search-settings.search-settings--active .icon-wrap svg,
.search-location.search-location--active .icon-wrap svg,
.search-location.search-settings--active .icon-wrap svg,
.search-experience.search-location--active .icon-wrap svg,
.search-experience.search-settings--active .icon-wrap svg {
  fill: #F4F6Fa !important;
  stroke: #F4F6Fa !important;
}

.state-settings .search-submit,
.state-locations .search-submit {
  background: #443469;
}

.search-settings-label {
  color: #1E025F;
  font-weight: 700;
}
.search-settings-label.search-settings-label-add {
  color: #40BC8A;
}

.search-experience {
  color: #9F99B1;
  border-color: #F4F6Fa;
}
.search-experience option {
  color: #443469;
}

.search-summary {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
  color: #E4E4EC;
  background: #1E025F;
}
.search-summary .search-summary-keys,
.search-summary .search-summary-options,
.search-summary .search-summary-place {
  color: #ffffff;
}

.search-location {
  border-left-color: #F4F6Fa;
}

.search-settings-token.search-settings-token-void {
  color: #9F99B1;
}

.search-settings-token-label {
  color: #1E025F;
}

.pane-locations-mode {
  color: #9F99B1;
}
.pane-locations-mode.--active {
  color: #1E025F;
  border-bottom-color: #1E025F;
}
.pane-locations-mode:hover, .pane-locations-mode:focus, .pane-locations-mode:hover:focus {
  color: #443469;
}
.pane-locations-mode:hover.--active, .pane-locations-mode:focus.--active, .pane-locations-mode:hover:focus.--active {
  color: #1E025F;
  border-bottom-color: #1E025F;
}

.nav-link {
  color: #9F99B1;
}
.nav-link.active {
  color: #40BC8A;
  font-weight: 700;
}
.nav-link.active:focus, .nav-link.active:active, .nav-link.active:hover {
  color: #40BC8A;
}

.nav-count {
  font-weight: 900;
  background: #9F99B1;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.nav-count.nav-count--active {
  color: #ffffff;
  background: #1E025F;
}

.nav-tabs--sub .nav-link:hover, .nav-tabs--sub .nav-link:focus, .nav-tabs--sub .nav-link:active, .nav-tabs--sub .nav-link.active {
  color: #1E025F !important;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:active, .nav-tabs .nav-link:hover {
  color: #443469;
}
.nav-tabs .nav-link.active {
  color: #1E025F;
  border-color: transparent transparent #1E025F transparent;
  font-weight: 700;
}
.nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:active, .nav-tabs .nav-link.active:hover {
  color: #1E025F;
  border-color: transparent transparent #1E025F transparent;
}

.tab {
  color: #9F99B1;
  font-weight: 700;
}
.tab:hover, .tab:focus, .tab:hover:focus {
  color: #1E025F;
}
.tab.tab--open {
  color: #ffffff;
}
.tab.tab--open:hover, .tab.tab--open:focus, .tab.tab--open:hover:focus {
  color: #ffffff;
}

.tab--open::before {
  background-color: #40BC8A;
}

.tab--open:hover::before {
  background-color: #2d8360;
}

.menu-item:hover, .menu-item:focus, .menu-item:hover:focus {
  background: #F4F6Fa;
}
.menu-item:hover .menu-item-count, .menu-item:focus .menu-item-count, .menu-item:hover:focus .menu-item-count {
  background: #ffffff;
}
.menu-item:hover .icon-wrap svg, .menu-item:focus .icon-wrap svg, .menu-item:hover:focus .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.menu--underline-hover .menu-item:hover:after, .menu--underline-hover .menu-item:focus:after, .menu--underline-hover .menu-item:hover:focus:after {
  background: #E4E4EC;
}
.menu-item.menu-item--type-heading, .menu-item.menu-item--type-label {
  color: #9F99B1;
}
.menu-item.menu-item--type-heading:hover, .menu-item.menu-item--type-heading:focus, .menu-item.menu-item--type-heading:hover:focus, .menu-item.menu-item--type-label:hover, .menu-item.menu-item--type-label:focus, .menu-item.menu-item--type-label:hover:focus {
  background: transparent;
}
.menu-item.menu-item--type-heading:hover:after, .menu-item.menu-item--type-heading:focus:after, .menu-item.menu-item--type-heading:hover:focus:after, .menu-item.menu-item--type-label:hover:after, .menu-item.menu-item--type-label:focus:after, .menu-item.menu-item--type-label:hover:focus:after {
  background: transparent;
}
.menu-item.menu-item--active {
  background: #1E025F;
}
.menu-item.menu-item--active .menu-item-label,
.menu-item.menu-item--active .menu-item-title {
  color: #ffffff;
}
.menu-item.menu-item--active .menu-item-count {
  background: #1E025F;
}
.menu-item.menu-item--active .menu-item-note {
  color: #9F99B1;
}
.menu-item.menu-item--active .menu-item-indicator {
  background: #160146;
}
.menu-item.menu-item--active .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.menu-item.menu-item--active:hover, .menu-item.menu-item--active:focus, .menu-item.menu-item--active:hover:focus {
  background: #1E025F;
}
.menu-item.menu-item--active:hover .menu-item-label,
.menu-item.menu-item--active:hover .menu-item-title, .menu-item.menu-item--active:focus .menu-item-label,
.menu-item.menu-item--active:focus .menu-item-title, .menu-item.menu-item--active:hover:focus .menu-item-label,
.menu-item.menu-item--active:hover:focus .menu-item-title {
  color: #ffffff;
}
.menu-item.menu-item--active:hover .menu-item-count, .menu-item.menu-item--active:focus .menu-item-count, .menu-item.menu-item--active:hover:focus .menu-item-count {
  background: #ffffff;
}
.menu-item.menu-item--active:hover .menu-item-note, .menu-item.menu-item--active:focus .menu-item-note, .menu-item.menu-item--active:hover:focus .menu-item-note {
  color: #9F99B1;
}
.menu-item.menu-item--active:hover .icon-wrap svg, .menu-item.menu-item--active:focus .icon-wrap svg, .menu-item.menu-item--active:hover:focus .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}

.menu-item-link {
  background: transparent;
}

.menu-item--type-label,
.menu-item-label {
  color: #9F99B1;
  font-weight: 700;
}

.menu-item-title {
  color: #1E025F;
  font-weight: 700;
}
.menu-item-title .icon-container--sync .icon-wrap svg {
  fill: #40bc8a;
  stroke: #40bc8a;
}

.menu-item-note {
  color: #9F99B1;
}

.menu-item-icon .icon-wrap svg,
.menu-item-suffix-icon .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}

.menu-item--level-secondary .menu-item-title {
  color: #443469;
  font-weight: 400;
}

.slider .slider-track {
  background-color: #E4E4EC;
}
.slider .slider-selection {
  background: #1E025F !important;
}
.slider .slider-handle {
  background: #1E025F !important;
}
.slider .tooltip .tooltip-inner {
  color: #1E025F;
}

.action {
  color: #443469;
}
.action:hover .icon-wrap svg, .action .action:focus .icon-wrap svg, .action .action:hover:focus .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.action .portfolio_action_bar .icon-wrap svg {
  fill: #F4F6Fa !important;
  stroke: #F4F6Fa !important;
}
.action:before {
  background: #ffffff;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.action.action--xxs:before {
  box-shadow: 0 1rem 1rem 0 rgba(14, 1, 43, 0.06125);
}
.action:hover, .action:focus, .action:hover:focus {
  color: #1E025F;
}
.action:hover .icon-wrap svg, .action:focus .icon-wrap svg, .action:hover:focus .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.action-label-wrap {
  font-weight: 700;
  color: #9F99B1;
}

.action--blend .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.action--blend:focus .icon-wrap svg, .action--blend:active .icon-wrap svg, .action--blend:hover .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.action--accent [class*=icon--] {
  color: #40BC8A;
}

.action--primary {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.action--primary:before {
  background: #40BC8A;
}
.action--primary [class*=icon--] {
  color: #ffffff;
}
.action--primary .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.action--primary.disabled:before {
  background: #E4E4EC;
}
.action--primary:focus .icon-container, .action--primary:active .icon-container, .action--primary:hover .icon-container {
  opacity: 0.8;
}
.action--primary:focus .icon-container .icon-wrap svg, .action--primary:active .icon-container .icon-wrap svg, .action--primary:hover .icon-container .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}

.action--secondary:before {
  background: #E4E4EC;
  color: #443469;
}

.action--secondary-inverse:before {
  color: #ffffff;
  background: #9F99B1;
}

.action--mute,
.action--mute-inverse,
.action--close {
  color: #9F99B1;
}
.action--mute:before,
.action--mute-inverse:before,
.action--close:before {
  background: transparent;
}
.action--mute:hover:before,
.action--mute-inverse:hover:before,
.action--close:hover:before {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}

.action--disabled:before {
  background: #9F99B1;
}
.action--disabled .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.action--disabled:focus:before, .action--disabled:active:before, .action--disabled:hover:before {
  background: #E4E4EC;
}
.action--disabled:focus .icon-wrap svg, .action--disabled:active .icon-wrap svg, .action--disabled:hover .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}

.action--mute-inverse {
  color: #ffffff;
}
.action--mute-inverse:focus, .action--mute-inverse:active, .action--mute-inverse:hover {
  color: rgba(255, 255, 255, 0.8);
}

.action--drop:hover:before {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}

.action--navigation:before {
  background: transparent;
}
.action--navigation .action--navigation-icon div, .action--navigation .action--navigation-icon:before, .action--navigation .action--navigation-icon:after {
  background: #1E025F;
}
.action--navigation:focus .action--navigation-icon div, .action--navigation:focus .action--navigation-icon:before, .action--navigation:focus .action--navigation-icon:after, .action--navigation:active .action--navigation-icon div, .action--navigation:active .action--navigation-icon:before, .action--navigation:active .action--navigation-icon:after, .action--navigation:hover .action--navigation-icon div, .action--navigation:hover .action--navigation-icon:before, .action--navigation:hover .action--navigation-icon:after {
  background: #000000;
}

.action-count {
  background: #9F99B1;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
  font-weight: 900;
}
.action-count.action-count--active {
  color: #ffffff;
  background: #1E025F;
}

.action--mute .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.action--mute:focus .icon-wrap svg, .action--mute:active .icon-wrap svg, .action--mute:hover .icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}

.action--transparent:before,
.action--transparent-hover:before {
  background: transparent;
  box-shadow: none;
}
.action--transparent .icon-wrap svg,
.action--transparent-hover .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}

.action--transparent-hover:focus:before, .action--transparent-hover:active:before, .action--transparent-hover:hover:before {
  background: #F4F6Fa;
}
.action--transparent-hover:focus .icon-wrap svg, .action--transparent-hover:active .icon-wrap svg, .action--transparent-hover:hover .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.action--accent.action--label .action-label-wrap,
.action--accent-primary.action--label .action-label-wrap {
  color: #40BC8A;
}
.action--accent .icon-wrap svg,
.action--accent-primary .icon-wrap svg {
  fill: #40BC8A;
  stroke: #40BC8A;
}
.action--accent:focus .icon-wrap svg, .action--accent:active .icon-wrap svg, .action--accent:hover .icon-wrap svg,
.action--accent-primary:focus .icon-wrap svg,
.action--accent-primary:active .icon-wrap svg,
.action--accent-primary:hover .icon-wrap svg {
  fill: #40BC8A;
  stroke: #40BC8A;
}

.action--accent-secondary .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.action--accent-secondary:focus .icon-wrap svg, .action--accent-secondary:active .icon-wrap svg, .action--accent-secondary:hover .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.action--rows,
.action--grid {
  margin-left: 0.3rem;
  width: 38px;
  height: 38px;
  background-color: rgba(159, 153, 177, 0.2);
  border-radius: 7px;
  transform: translate(0, 5px);
}
.action--rows:not(.action--accent-secondary),
.action--grid:not(.action--accent-secondary) {
  background-color: #9F99B1 !important;
}
.action--rows:not(.action--accent-secondary):hover,
.action--grid:not(.action--accent-secondary):hover {
  background-color: #766e90 !important;
}
.action--rows:not(.action--accent-secondary):hover svg,
.action--grid:not(.action--accent-secondary):hover svg {
  fill: #ffffff !important;
  stroke: #ffffff !important;
}
.action--rows .icon-wrap svg,
.action--grid .icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}
.action--rows.action--accent-secondary,
.action--grid.action--accent-secondary {
  width: 38px;
  height: 38px;
  background-color: rgba(159, 153, 177, 0.2);
  border-radius: 7px;
  transform: translate(0, 5px);
  background-color: #1E025F;
}
.action--rows.action--accent-secondary .icon-wrap svg,
.action--grid.action--accent-secondary .icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}
.action--rows.action--accent-secondary .icon-wrap svg,
.action--grid.action--accent-secondary .icon-wrap svg {
  fill: #F4F6Fa !important;
  stroke: #F4F6Fa !important;
}
.action--rows.action--accent-secondary:hover,
.action--grid.action--accent-secondary:hover {
  fill: #F4F6Fa !important;
  stroke: #F4F6Fa !important;
}

.portfolio_action_bar--selected {
  background-color: #E4E4EC;
}

.tip,
.action-label {
  color: #ffffff;
  background: rgba(159, 153, 177, 0.88);
  font-weight: 700;
}

.count {
  background: #F4F6Fa;
  color: #9F99B1;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
  font-weight: 900;
}
.count.count--active {
  color: #ffffff;
  background: #1E025F;
}

.grid-container {
  background-color: #F4F6Fa;
}

.grid-view thead th {
  color: #d5d3dd;
}
.grid-view tbody td {
  color: #443469;
}
.grid-view tbody td:first-child {
  color: #1E025F;
}
.grid-view tbody tr:nth-child(even) {
  background: #f8f9fc;
}
.grid-view tbody tr:hover {
  background: #F4F6Fa;
}
.grid-view tbody tr:hover td:first-child {
  color: #1E025F;
}
.grid-view .grid-view-stat-label {
  color: #9F99B1;
}
.grid-view .grid-view-stat-value {
  color: #443469;
}
.grid-view .empty {
  color: #9F99B1;
}
.grid-view .dropdown-toggle {
  color: #9F99B1;
}
.grid-view .dropdown-toggle:hover {
  color: #443469;
  background-color: #ffffff !important;
}
.grid-view .form-control {
  background-color: #F4F6Fa;
  border-color: #F4F6Fa;
}
.grid-view .form-control:hover, .grid-view .form-control:focus, .grid-view .form-control:hover:focus {
  border-color: #1E025F;
}

.grid-view-title {
  color: #1E025F;
}

.grid-view-email {
  color: #9F99B1;
}

.pane--base > .pane-header {
  background-color: #F4F6Fa;
}

.pane-header-title {
  color: #1E025F;
}

.pane-header-dropdown-title {
  color: #1E025F;
}

.pane-header-dropdown-toggle {
  color: #9F99B1;
}
.pane-header-dropdown-toggle:focus, .pane-header-dropdown-toggle:hover {
  color: #1E025F;
}
.pane-header-dropdown-toggle:focus .pane-header-dropdown-title, .pane-header-dropdown-toggle:hover .pane-header-dropdown-title {
  color: #1E025F;
}

.pane-filter-input {
  color: #443469;
  background: #F4F6Fa;
}
.pane-filter-input:hover, .pane-filter-input:focus, .pane-filter-input:hover:focus {
  color: #1E025F;
}

.pane-section--inverse {
  background: #443469;
}
.pane-section--inverse h1,
.pane-section--inverse h2,
.pane-section--inverse h3,
.pane-section--inverse p {
  color: #ffffff;
}

.pane-section--collapsed.pane-section--inverse:after {
  background: linear-gradient(to top, #443469 30%, rgba(68, 52, 105, 0) 100%);
}

@media (min-width: 768px) {
  .pane.pane--popover-md {
    box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
  }
  .pane.pane--popover-md-header .pane-header:after {
    box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
  }
  .pane.pane--popover-md-header .pane-header-title {
    font-weight: 700;
    color: #9F99B1;
  }
}
.modal {
  background-color: transparent;
}
.modal.in {
  background-color: rgba(228, 228, 236, 0.72);
}
.modal.user-modal.in {
  background-color: rgba(228, 228, 236, 0.32);
}

.modal .pane,
.modal .pane-v2 {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.modal.modal--feedback .pane {
  background: #40BC8A;
  box-shadow: inset 0 4rem 6rem 0 #3cb182, inset 0 1rem 2rem -0.25rem #33966e;
}
.modal.modal--feedback .pane-content::-webkit-scrollbar {
  background-color: transparent;
}
.modal.modal--feedback .pane-content::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}
.modal.modal--feedback .pane-footer {
  background: #ffffff;
}
.modal.modal--feedback .modal-feedback-title,
.modal.modal--feedback .modal-feedback-subtitle {
  color: #ffffff;
}
.modal.modal--feedback .modal-feedback-subtitle {
  font-weight: 400;
}
.modal.modal--feedback-inverse-tertiary .pane {
  background: #ffffff;
  box-shadow: inset 0 4rem 6rem 0 white, inset 0 1rem 2rem -0.25rem white;
}
.modal.modal--feedback-inverse-tertiary .pane-content::-webkit-scrollbar {
  background-color: transparent;
}
.modal.modal--feedback-inverse-tertiary .pane-content::-webkit-scrollbar-thumb {
  background-color: #443469;
}
.modal.modal--feedback-inverse-tertiary .modal-feedback-icon {
  color: #F85C6A;
}
.modal.modal--feedback-inverse-tertiary .modal-feedback-title {
  color: #1E025F;
}
.modal.modal--feedback-inverse-tertiary .modal-feedback-subtitle {
  color: #443469;
}
.modal.modal--feedback-inverse-tertiary .action--mute-inverse {
  color: #9F99B1;
}

.modal-dialog {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}

.pane-indicator--loading::after,
.modal-indicator--loading::after,
.kv-grid-loading::after,
.kv-loading-search::after {
  border-left-color: #40BC8A !important;
}

.pagination li.active a,
.pagination li.active span {
  color: #ffffff;
  background-color: #40BC8A;
  border-color: #40BC8A;
}
.pagination li.disabled a,
.pagination li.disabled span {
  color: #9F99B1;
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.pagination a,
.pagination span {
  color: #9F99B1;
  background-color: #ffffff;
  border-color: #E4E4EC;
}
.pagination a:hover, .pagination a:focus, .pagination a:hover:focus,
.pagination span:hover,
.pagination span:focus,
.pagination span:hover:focus {
  color: #9F99B1;
  background-color: #E4E4EC;
  border-color: #E4E4EC;
}

.results-modes .results-modes-toggle-category {
  color: #9F99B1;
}
.results-modes .results-modes-toggle-category:hover {
  color: #40BC8A;
}
.results-modes .results-modes-toggle-category.active {
  color: #1E025F;
}
.results-modes .results-modes-category.active .results-modes-category-link {
  color: #40BC8A;
}
.results-modes .nav .nav-link:hover, .results-modes .nav .nav-link:focus, .results-modes .nav .nav-link:active {
  color: #443469;
  border-bottom-color: #1E025F !important;
}
.results-modes .nav .nav-link.active {
  border-bottom-color: #E4E4EC !important;
}
.results-modes .nav .nav-link.active:hover, .results-modes .nav .nav-link.active:focus, .results-modes .nav .nav-link.active:active {
  border-bottom-color: #E4E4EC !important;
}
.results-modes .nav .nav-link.nav-link--loading:before {
  background: #40BC8A;
}
.results-modes .nav .nav-link.nav-link--loading:after {
  background: #E4E4EC;
}
.results-modes .nav .nav-link.nav-link--loading:hover, .results-modes .nav .nav-link.nav-link--loading:focus, .results-modes .nav .nav-link.nav-link--loading:active {
  border-bottom-color: #E4E4EC !important;
}

.results-meta {
  background: #ffffff;
}
.results-meta .results-meta-dropdown > .btn {
  color: #9F99B1;
}

.results-meta-label {
  color: #9F99B1;
}

.results-meta-setting {
  color: #1E025F;
}
.results-meta-setting:hover {
  color: #1E025F;
}

.focus-description h1, .focus-description h2, .focus-description h3, .focus-description h4, .focus-description h5, .focus-description h6, .focus-description strong, .focus-description b {
  color: #1E025F;
}

.focus-original {
  color: #1E025F;
}
.focus-original:hover, .focus-original:focus, .focus-original:hover:focus {
  color: #1E025F;
}
.focus-original:hover:after, .focus-original:focus:after, .focus-original:hover:focus:after {
  background: #1E025F;
}

.focus-note {
  color: #9F99B1;
}

.focus-property-term {
  color: #9F99B1;
}

.focus-property-description {
  color: #1E025F;
}

.focus-company-google {
  color: #1E025F;
}

.focus-location {
  background-color: #F4F6Fa;
}

.focus-contact {
  background-color: #ffffff;
}

.focus-contact-function,
.focus-contact-name,
.focus-contact-telephone,
.focus-contact-email {
  color: #443469;
  font-weight: 400;
}

.focus-contact-name {
  font-weight: 700;
}

.focus-view {
  border-bottom-color: #E4E4EC;
}

.btn.focus-state {
  background-color: #F4F6Fa;
  color: #40BC8A;
}
.btn.focus-state:focus, .btn.focus-state:active, .btn.focus-state:hover {
  background-color: #F4F6Fa;
  color: #3aa97c;
}
.btn.focus-state.focus-state--denied {
  color: #ffffff;
  background-color: #F85C6A;
}
.btn.focus-state.focus-state--denied:hover, .btn.focus-state.focus-state--denied:focus, .btn.focus-state.focus-state--denied:hover:focus {
  background-color: #f85261;
}
.btn.focus-state.focus-state--accepted {
  color: #ffffff;
  background-color: #40bc8a;
}
.btn.focus-state.focus-state--accepted:hover, .btn.focus-state.focus-state--accepted:focus, .btn.focus-state.focus-state--accepted:hover:focus {
  background-color: #3db484;
}
.btn.focus-state.focus-state--called {
  color: #ffffff;
  background-color: #B2E4D0;
}
.btn.focus-state.focus-state--called:hover, .btn.focus-state.focus-state--called:focus, .btn.focus-state.focus-state--called:hover:focus {
  background-color: #aae1cb;
}
.btn.focus-state.focus-state--applied {
  color: #ffffff;
  background-color: #40bc8a;
}
.btn.focus-state.focus-state--applied:hover, .btn.focus-state.focus-state--applied:focus, .btn.focus-state.focus-state--applied:hover:focus {
  background-color: #3db484;
}
.btn.focus-state.focus-state--passed {
  color: #ffffff;
  background-color: #F85C6A;
}
.btn.focus-state.focus-state--passed:hover, .btn.focus-state.focus-state--passed:focus, .btn.focus-state.focus-state--passed:hover:focus {
  background-color: #f85261;
}
.btn.focus-state.focus-state--invited {
  color: #ffffff;
  background-color: #40bc8a;
}
.btn.focus-state.focus-state--invited:hover, .btn.focus-state.focus-state--invited:focus, .btn.focus-state.focus-state--invited:hover:focus {
  background-color: #3db484;
}
.btn.focus-state.focus-state--judged {
  color: #ffffff;
  background-color: #B2E4D0;
}
.btn.focus-state.focus-state--judged:hover, .btn.focus-state.focus-state--judged:focus, .btn.focus-state.focus-state--judged:hover:focus {
  background-color: #aae1cb;
}
.btn.focus-state.focus-state--expired {
  color: #ffffff;
  background-color: #F85C6A;
}
.btn.focus-state.focus-state--expired:hover, .btn.focus-state.focus-state--expired:focus, .btn.focus-state.focus-state--expired:hover:focus {
  background-color: #f85261;
}
.btn.focus-state.focus-state--ignored {
  color: #ffffff;
  background-color: #B2E4D0;
}
.btn.focus-state.focus-state--ignored:hover, .btn.focus-state.focus-state--ignored:focus, .btn.focus-state.focus-state--ignored:hover:focus {
  background-color: #aae1cb;
}

.focus-cover {
  background: #ffffff;
}
.focus-cover.focus-cover--inverse {
  background: #1E025F;
}
.focus-cover.focus-cover--inverse .focus-cover-title {
  color: #ffffff;
}

.focus-cover-actions .btn {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}

.focus-meta-title {
  color: #9F99B1;
  font-weight: 700;
}

.focus-meta-introduction::first-line {
  color: #443469;
}

.ui-autocomplete {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
  font-family: "neuzeit-grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ui-autocomplete.ui-menu .ui-menu-item {
  color: #443469;
}
.ui-autocomplete.ui-menu .ui-menu-item:hover, .ui-autocomplete.ui-menu .ui-menu-item:active, .ui-autocomplete.ui-menu .ui-menu-item:focus {
  color: #ffffff;
  background: #1E025F;
}
.ui-autocomplete.ui-widget-content {
  border-top-color: #E4E4EC;
}

.content {
  background: #F4F6Fa;
}

.dropdown-menu {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
  color: #443469;
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:hover:focus,
.dropdown-menu > a:hover,
.dropdown-menu > a:focus,
.dropdown-menu > a:hover:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover:focus {
  background: #F4F6Fa;
  color: #1E025F;
}

.dropdown-item {
  color: #443469;
}
.dropdown-item.dropdown-item--active {
  color: #1E025F;
}
.dropdown-item.dropdown-item--active:hover, .dropdown-item.dropdown-item--active:focus, .dropdown-item.dropdown-item--active:hover:focus {
  color: #1E025F;
}

.dropdown-item-note {
  color: #9F99B1;
}

.open .dropdown-sheet {
  box-shadow: 1.5rem 1rem 2rem 1rem rgba(14, 1, 43, 0.06125);
}

.dropdown-sheet-cancel .dropdown-item {
  color: #1E025F;
}

.dropdown-header,
.dropdown-item--header {
  color: #9F99B1;
  font-weight: 700;
}
.dropdown-header:hover, .dropdown-header:focus, .dropdown-header:hover:focus,
.dropdown-item--header:hover,
.dropdown-item--header:focus,
.dropdown-item--header:hover:focus {
  background: transparent;
  color: #9F99B1;
}

.filters .filter-label {
  color: #1E025F;
  font-weight: 700;
}
.filters .checkbox {
  color: #443469;
}
.filters .checkbox .filter-count {
  color: #9F99B1;
}
.filters .checkbox:hover {
  color: #1E025F;
}
.filters .checkbox:hover .filter-count {
  color: #443469;
}
.filters input:checked .checkbox .checkbox-label-text {
  color: #1E025F;
}

.header.header--background:after {
  background: #F4F6Fa;
}
.header .header-anchor,
.header .header-anchor-action,
.header .header-anchor-auth {
  color: #9F99B1;
}
.header .header-anchor-auth {
  color: rgba(159, 153, 177, 0.6);
}
.header .header-anchor-action {
  color: #1E025F;
}
.header .header-anchor-action:after {
  background: #40BC8A;
}

.navigation {
  background: #ffffff;
}

.navigation-toggle {
  background: #1E025F;
}
.navigation-toggle span, .navigation-toggle:before, .navigation-toggle:after {
  background: #ffffff;
}

.navigation-item {
  font-weight: 700;
}
.navigation-item .navigation-link {
  color: #1E025F;
}
.navigation-item .navigation-link .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.navigation-item .navigation-link:hover .icon-wrap svg, .navigation-item .navigation-link:focus .icon-wrap svg, .navigation-item .navigation-link:hover:focus .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.navigation-item.navigation-item--active .navigation-link {
  color: #40BC8A;
}
.navigation-item.navigation-item--active .navigation-link .icon-wrap svg {
  fill: #40BC8A;
  stroke: #40BC8A;
}

.pac-container {
  font-family: "neuzeit-grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}

.pac-item {
  color: #443469;
}
.pac-item:hover {
  background: #F4F6Fa;
}

.pac-item-query {
  color: #1E025F;
}

.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content {
  background: #1E025F;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content .shepherd-title {
  font-weight: 700 !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button {
  color: #443469;
  background-color: #ffffff;
  border-color: #ffffff;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:hover {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.focus {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:active, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.active, .open > .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.dropdown-toggle {
  color: #443469;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  background-image: none;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:active:hover, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:active:focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:active.focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.active:hover, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.active:focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.active.focus, .open > .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.dropdown-toggle:hover, .open > .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.dropdown-toggle:focus, .open > .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.dropdown-toggle.focus {
  color: #443469;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.disabled:focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.disabled.focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:disabled:focus, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:disabled.focus {
  background-color: #ffffff;
  border-color: #ffffff;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.disabled:hover, .shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button:disabled:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-top .shepherd-content:before {
  border-bottom-color: #1E025F !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-bottom .shepherd-content:before {
  border-top-color: #1E025F !important;
}

.tag {
  color: #ffffff;
  background: #b0a2d1;
}
.tag.tag--primary {
  background: #40BC8A;
}

.label {
  color: #ffffff;
  background: #b0a2d1;
}
.label.label--primary {
  background: #40BC8A;
}
.label.label--outline {
  color: #9F99B1;
  background: transparent;
  border-color: #E4E4EC;
}

.user:hover .user-name, .user:focus .user-name, .user:hover:focus .user-name {
  color: #1E025F;
}
.user.user--compact .user-avatar:after {
  color: #9F99B1;
}
.user.user--compact:hover .user-avatar:after,
.user.user--compact:hover .user-avatar h1, .user.user--compact:focus .user-avatar:after,
.user.user--compact:focus .user-avatar h1, .user.user--compact:hover:focus .user-avatar:after,
.user.user--compact:hover:focus .user-avatar h1 {
  color: #1E025F;
}

.user-avatar {
  border: 1px solid #9F99B1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-avatar:after,
.user-avatar h1 {
  color: #9F99B1;
}
.user-avatar:hover {
  border-color: #1E025F;
}
.user-avatar:hover:after,
.user-avatar:hover h1 {
  color: #1E025F;
  border-color: #1E025F;
}

.user-name {
  color: #443469;
}

.user-name-via,
.user-name-email,
.user-name-credits {
  color: #9F99B1;
}

.user-name-location {
  color: #F85C6A;
}

.user-modal-connect {
  color: #ffffff;
  background-color: #F85C6A;
}
.user-modal-connect:hover, .user-modal-connect:focus, .user-modal-connect:hover:focus {
  color: rgba(255, 255, 255, 0.6);
}
.user-modal-connect.user-modal-connect--active {
  background-color: transparent;
  color: #9F99B1;
  border-bottom-color: #E4E4EC;
}
.user-modal-connect.user-modal-connect--active .icon--check {
  color: #40bc8a;
}
.user-modal-connect.user-modal-connect--active:hover, .user-modal-connect.user-modal-connect--active:focus, .user-modal-connect.user-modal-connect--active:hover:focus {
  color: #9F99B1;
}

.vacancy-count {
  background: #f8f9fc;
  color: #9F99B1;
}

.vacancy-sort-link {
  color: #9F99B1;
}

.vacancy-organisation {
  color: #443469;
}

.vacancy-title {
  font-weight: 700;
}

.vacancy-saved .icon-wrap svg,
.vacancy-team .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}

.vacancy-meta,
.vacancy-meta-item {
  color: #9F99B1;
}

.vacancy-link:hover {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.vacancy-link:hover .vacancy-meta-item {
  color: #1E025F;
}

.vacancy--read .vacancy-title h2 em,
.vacancy--read .cabinet-title h3 span {
  font-weight: 300;
}

.vacancy--closed .vacancy-title h2:before {
  background: #9F99B1;
}

.vacancy--open .vacancy-link,
.vacancy--open .vacancy-link:hover {
  background: #1E025F;
  color: #ffffff;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.vacancy--open .vacancy-title h2 em {
  color: #ffffff;
}
.vacancy--open .vacancy-organisation,
.vacancy--open .vacancy-meta-item {
  color: #E4E4EC;
}

.void.void--boxed {
  box-shadow: 0 0 8px -2px #d2d2d2;
  border: 1px solid #D3D9E3;
}
.void.void--boxed .void-inner {
  box-shadow: 0 4rem 6rem 0 rgba(14, 1, 43, 0.06125);
}
.void.void--muted .void-title {
  color: #443469;
}

.cabinet--grid .void {
  grid-column: 1/-1;
}
.cabinet--grid .void-inner {
  box-shadow: 0 0 8px -2px #d2d2d2;
  background-color: #ffffff;
}

.void-title {
  color: #443469;
}

.void-icon {
  color: #E4E4EC;
}

.void-tip,
.void-tip-label {
  color: #9F99B1;
}

.void-tip {
  font-weight: 400;
}

.void-tip-label {
  font-weight: 700;
}

.indicator {
  background: #d8d8e4;
}
.indicator::before {
  background: #ffffff;
}

input:checked ~ .indicator {
  background: #443469 !important;
}

input:checked ~ .indicator--switch {
  background: #40BC8A !important;
}

.indicator-state.indicator-state--denied:before {
  background-color: #F85C6A;
}
.indicator-state.indicator-state--denied .indicator-state-label {
  background-color: #F85C6A;
}
.indicator-state.indicator-state--accepted:before {
  background-color: #40bc8a;
}
.indicator-state.indicator-state--accepted .indicator-state-label {
  background-color: #40bc8a;
}
.indicator-state.indicator-state--called:before {
  background-color: #B2E4D0;
}
.indicator-state.indicator-state--called .indicator-state-label {
  background-color: #B2E4D0;
}
.indicator-state.indicator-state--applied:before {
  background-color: #40bc8a;
}
.indicator-state.indicator-state--applied .indicator-state-label {
  background-color: #40bc8a;
}
.indicator-state.indicator-state--passed:before {
  background-color: #F85C6A;
}
.indicator-state.indicator-state--passed .indicator-state-label {
  background-color: #F85C6A;
}
.indicator-state.indicator-state--invited:before {
  background-color: #40bc8a;
}
.indicator-state.indicator-state--invited .indicator-state-label {
  background-color: #40bc8a;
}
.indicator-state.indicator-state--judged:before {
  background-color: #B2E4D0;
}
.indicator-state.indicator-state--judged .indicator-state-label {
  background-color: #B2E4D0;
}
.indicator-state.indicator-state--expired:before {
  background-color: #F85C6A;
}
.indicator-state.indicator-state--expired .indicator-state-label {
  background-color: #F85C6A;
}
.indicator-state.indicator-state--ignored:before {
  background-color: #B2E4D0;
}
.indicator-state.indicator-state--ignored .indicator-state-label {
  background-color: #B2E4D0;
}

.indicator-progress {
  font-weight: 700;
  background-color: #F4F6Fa;
}

.indicator-progress-bar {
  color: #ffffff;
  background-color: #1E025F;
}

.indicator-progress-bar--light {
  background-color: #9F99B1;
}

.indicator-progress-bar--default {
  background-color: #443469;
}

.indicator-progress-bar--dark {
  background-color: #1E025F;
}

.indicator-progress-bar--primary {
  background-color: #40BC8A;
}

.indicator-progress-bar--secondary {
  background-color: #1E025F;
}

.indicator-progress-bar--warning {
  background-color: #F85C6A;
}

.indicator-progress-bar--danger {
  background-color: #F85C6A;
}

.indicator-progress-bar--transparent {
  background-color: transparent;
}

.indicator-radial {
  background-color: #F4F6Fa;
}
.indicator-radial .circle .mask .fill {
  background-color: #40BC8A;
}
.indicator-radial .inset {
  background-color: #ffffff;
}
.indicator-radial .inset .percentage,
.indicator-radial .inset .counter {
  color: #443469;
  font-weight: 400;
}
.indicator-radial.indicator-radial--shadow .circle .shadow {
  box-shadow: 0.375rem 0.375rem 0.675rem rgba(14, 1, 43, 0.06125) inset;
}
.indicator-radial.indicator-radial--shadow .inset {
  box-shadow: 0.375rem 0.375rem 0.675rem rgba(14, 1, 43, 0.06125);
}

.tooltip {
  font-family: "neuzeit-grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tooltip-inner {
  background: #1E025F;
  font-weight: 700;
  color: #ffffff;
}
.tooltip-inner em {
  font-weight: 400;
}

.tooltip-close {
  color: #443469;
}

.shadow:after {
  box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.06125);
}

.shadow--heavy:after {
  box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
}

ul.fancytree-container {
  font-family: "neuzeit-grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

span.fancytree-node.fancytree-loading:after {
  border-left-color: #40BC8A;
}

span.fancytree-title {
  color: #1E025F;
}

span.fancytree-selected span.fancytree-title,
span.fancytree-selected .fancytree-checkbox {
  color: #1E025F;
}

span.fancytree-empty,
span.fancytree-vline,
span.fancytree-expander,
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-drag-helper-img,
#fancytree-drop-marker {
  color: #9F99B1;
}

.timeline-milestone:nth-child(odd) {
  background: #f8f9fc;
}
.timeline-milestone:before, .timeline-milestone:after {
  background: #E4E4EC;
}
.timeline-milestone:hover {
  background: #F4F6Fa;
}

.timeline-milestone-time,
.timeline-milestone-remark {
  color: #9F99B1;
}

.report-status--neutral {
  background-color: #B2E4D0;
}

.report-status--neutral-2 {
  background-color: #9fddc4;
}

.report-status-label--neutral {
  color: #B2E4D0;
}

.report-status-label--neutral-2 {
  color: #9fddc4;
}

.report-status--positive {
  background-color: #40bc8a;
}

.report-status--positive-2 {
  background-color: #3aa97c;
}

.report-status-label--positive {
  color: #40bc8a;
}

.report-status-label--positive-2 {
  color: #3aa97c;
}

.report-status--negative {
  background-color: #F85C6A;
}

.report-status--negative-2 {
  background-color: #f74454;
}

.report-status--negative-3 {
  background-color: #F85C6A;
}

.report-status--negative-4 {
  background-color: #f74454;
}

.report-status-label--negative {
  color: #F85C6A;
}

.report-status-label--negative-2 {
  color: #f74454;
}

.report-status-label--negative-3 {
  color: #F85C6A;
}

.report-status-label--negative-4 {
  color: #f74454;
}

.report-list-item {
  border-bottom-color: #E4E4EC;
  color: #1E025F;
}
.report-list-item:before {
  color: #9F99B1;
}
.report-list-item .report-list-item-value {
  color: #1E025F;
}
.report-list-item .report-list-item-note {
  color: #9F99B1;
}
.report-list-item:hover, .report-list-item:focus {
  border-bottom-color: #c6c6d7;
}
.report-list-item .organisation-name {
  color: #1E025F;
}

.report-segment-label {
  color: #9F99B1;
  font-weight: 400;
}

.report-segment-value {
  color: #1E025F;
  font-weight: 400;
}
.report-segment--sub .report-segment-value {
  color: #443469;
}

.report-segment-icon {
  color: #9F99B1;
}

.report-advice:hover .report-advice-value {
  color: #443469;
}

.report-advice-value {
  color: #9F99B1;
}

.report-footer-credits {
  color: #9F99B1;
}

.report-footer-warning {
  color: #F85C6A;
}

.report-vu-bar {
  background: #E4E4EC;
}

.report-vu--multi .report-vu-bar-segment {
  background: #40BC8A;
}
.report-vu--multi .report-vu-bar-segment > .report-vu-bar-segment-tip {
  color: #40BC8A;
}
.report-vu--multi .report-vu-bar-segment > .report-vu-bar-segment-tip:before {
  background: #9F99B1;
}
.report-vu--multi .report-vu-bar-segment + .report-vu-bar-segment {
  background: #1E025F;
}
.report-vu--multi .report-vu-bar-segment + .report-vu-bar-segment > .report-vu-bar-segment-tip {
  color: #1E025F;
}
.report-vu--multi .report-vu-bar-segment + .report-vu-bar-segment + .report-vu-bar-segment {
  background: #F85C6A;
}
.report-vu--multi .report-vu-bar-segment + .report-vu-bar-segment + .report-vu-bar-segment > .report-vu-bar-segment-tip {
  color: #F85C6A;
}

.report-vu-bar-min,
.report-vu-bar-max {
  color: #9F99B1;
}

.report-vu-bar-min:before,
.report-vu-bar-max:before,
.report-vu-bar-segment-tip:before {
  background: #E4E4EC;
}

.report-company-profile-term,
.report-company-profile-desc {
  border-top-color: #E4E4EC;
}

.report-company-map,
.report-company-profile-term {
  color: #9F99B1;
}

.report-company-profile-desc {
  color: #1E025F;
}

.search-keys.search-keys--selectize {
  background: #F4F6Fa;
}
.search-keys.search-keys--selectize .selectize-input.focus:before, .search-keys.search-keys--selectize .selectize-input.input-active:before {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.search-keys.search-keys--selectize .selectize-dropdown {
  background: #ffffff;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.search-keys.search-keys--selectize .selectize-dropdown:after {
  border-left-color: #ffffff;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item {
  color: #1E025F;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item-type {
  color: #9F99B1;
}
.search-keys.search-keys--selectize .selectize-dropdown .active {
  background-color: #1E025F;
  color: #ffffff;
}
.search-keys.search-keys--selectize .selectize-dropdown .active .selectize-item-type {
  color: #ffffff;
}
.search-keys.search-keys--selectize .selectize-dropdown .active.selectize-item--create {
  background-color: #ffffff;
  color: #9F99B1;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item--create {
  color: #9F99B1;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item-query {
  color: #9F99B1;
}
.search-keys.search-keys--selectize .search-key .search-key-label {
  color: #ffffff;
  background: #1E025F;
}
.search-keys.search-keys--selectize .search-key.search-key--profession .search-key-label {
  background: #443469;
}
.search-keys.search-keys--selectize .search-key.search-key--primary .search-key-label {
  background: #1E025F;
}

.organisation-name {
  color: #9F99B1;
  font-weight: 400;
}

.organisation-property .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.organisation-property:hover .icon-wrap svg, .organisation-property:focus .icon-wrap svg, .organisation-property:hover:focus .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

td .organisation-name {
  color: #1E025F;
}

.pulse {
  background: #1E025F;
}
.pulse:before, .pulse:after {
  border-color: #1E025F;
}
.pulse.pulse--inactive {
  background: #9F99B1;
}

.dashboard-intro {
  background: #443469;
}
.dashboard-intro h1,
.dashboard-intro p {
  color: #ffffff;
}
.dashboard-intro:before {
  background: #ffffff;
}

.dashboard-item-heading {
  font-weight: 700;
}

.dashboard-intro-video-link {
  background: #1E025F;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}

.dashboard-intro-link,
.dashboard-intro-video-play {
  color: #ffffff;
}

.dashboard-intro-link-title,
.dashboard-qa-question {
  font-weight: 700;
}

.dashboard-credits-link {
  color: #1E025F;
}
.dashboard-credits-link:hover, .dashboard-credits-link:focus, .dashboard-credits-link:hover:focus {
  color: #1E025F;
}

.dashboard-qa-heading:before {
  background: #E4E4EC;
}

.dashboard-qa-heading-title {
  background: #ffffff;
}

.map .gm-style,
.map .gm-style div {
  font-family: "neuzeit-grotesk", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: #1E025F !important;
}
.map img[src*="/images/maps/map-marker-icon-xs.png"],
.map img[src*="/images/maps/map-marker-icon-sm.png"],
.map img[src*="/images/maps/map-marker-icon-md.png"],
.map img[src*="/images/maps/map-marker-icon-lg.png"],
.map img[src*="/images/maps/map-marker-icon-xl.png"] {
  background: #ffffff;
}
.map .gm-style-iw-c {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15) !important;
}
.map .gm-style-iw-d {
  /* Dialog */
}
.map .gm-style-iw-d::-webkit-scrollbar {
  background-color: #F4F6Fa !important;
}
.map .gm-style-iw-d::-webkit-scrollbar-thumb {
  background-color: #d5d5e1 !important;
}
.map .gm-style-iw-d + button {
  color: #443469 !important;
}
.map .gm-style-iw-d + button:hover, .map .gm-style-iw-d + button:focus, .map .gm-style-iw-d + button:hover:focus {
  color: #1E025F !important;
}

.main-alert.alert {
  background: #F85C6A;
}
.main-alert.alert:hover {
  background: #f74454;
}

.alert.alert-success {
  background: #40bc8a;
}
.alert.alert-info {
  background: #B2E4D0;
}
.alert.alert-warning {
  background: #F85C6A;
}
.alert.alert-danger {
  background: #F85C6A;
}

.alerts-item-message {
  font-weight: 700;
}

[data-notify=title],
[data-notify=message] {
  color: #ffffff;
}

.list.list--zebra .list-item:nth-child(even) {
  background-color: #f8f9fc;
}

.list--definition .list-term,
.list--definition .list-desc {
  font-weight: 400;
  color: #9F99B1;
}
.list--definition .list-desc {
  color: #443469;
}

.list-subtitle {
  color: #9F99B1;
  font-weight: 400;
}

.list-section {
  font-weight: 700;
}

.list-note {
  color: #9F99B1;
}

.list-action:not(.toggle) [class*=icon--] {
  color: #443469;
}

.list-item.list-item--hover:hover, .list-item.list-item--hover:focus, .list-item.list-item--hover:hover:focus {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.list-item.list-item--active {
  border-left-color: #40BC8A;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.list-item.list-item--active.list-item--hover:hover, .list-item.list-item--active.list-item--hover:focus, .list-item.list-item--active.list-item--hover:hover:focus {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.list-item.list-item--action-hover-accent:hover .list-action [class*=icon--], .list-item.list-item--action-hover-accent:focus .list-action [class*=icon--], .list-item.list-item--action-hover-accent:hover:focus .list-action [class*=icon--] {
  color: #40BC8A;
}
.list-item.list-item--void {
  border-left-color: #40BC8A;
}
.list-item.list-item--cart {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}

.flickity-page-dots .dot {
  background: #443469;
}

.cards.cards--carousel:before, .cards.cards--carousel:after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.cards.cards--carousel:after {
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.cards.cards--carousel.cards--carousel-lightest:before {
  background: linear-gradient(to right, #f4f6fa 0%, rgba(244, 246, 250, 0) 100%);
}
.cards.cards--carousel.cards--carousel-lightest:after {
  background: linear-gradient(to left, #f4f6fa 0%, rgba(244, 246, 250, 0) 100%);
}

.card-content {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.card-content > h3 {
  color: #1E025F;
}
.card-content > p {
  color: #443469;
}

.icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}

.icon--success {
  color: #40bc8a;
}

.icon--neutral {
  color: #B2E4D0;
}

.icon--warning {
  color: #F85C6A;
}

.icon--danger {
  color: #F85C6A;
}

.icon-container--success .icon-wrap svg {
  fill: #40bc8a;
  stroke: #40bc8a;
}

.icon-container--info .icon-wrap svg {
  fill: #B2E4D0;
  stroke: #B2E4D0;
}

.icon-container--warning .icon-wrap svg {
  fill: #F85C6A;
  stroke: #F85C6A;
}

.icon-container--danger .icon-wrap svg {
  fill: #F85C6A;
  stroke: #F85C6A;
}

.toggle .toggle-label.btn-primary, .toggle .toggle-label.btn-secondary, .toggle .toggle-label.btn-tertiary, .toggle .toggle-label.btn-quaternary {
  color: #ffffff;
}
.toggle .toggle-label.btn-primary [class*=icon--], .toggle .toggle-label.btn-secondary [class*=icon--], .toggle .toggle-label.btn-tertiary [class*=icon--], .toggle .toggle-label.btn-quaternary [class*=icon--] {
  color: #ffffff;
}
.toggle .toggle-label:hover.btn-primary, .toggle .toggle-label:hover.btn-secondary, .toggle .toggle-label:hover.btn-tertiary, .toggle .toggle-label:hover.btn-quaternary, .toggle .toggle-label:focus.btn-primary, .toggle .toggle-label:focus.btn-secondary, .toggle .toggle-label:focus.btn-tertiary, .toggle .toggle-label:focus.btn-quaternary, .toggle .toggle-label:hover:focus.btn-primary, .toggle .toggle-label:hover:focus.btn-secondary, .toggle .toggle-label:hover:focus.btn-tertiary, .toggle .toggle-label:hover:focus.btn-quaternary {
  color: #ffffff;
}
.toggle .toggle-label:hover.btn-primary [class*=icon--], .toggle .toggle-label:hover.btn-secondary [class*=icon--], .toggle .toggle-label:hover.btn-tertiary [class*=icon--], .toggle .toggle-label:hover.btn-quaternary [class*=icon--], .toggle .toggle-label:focus.btn-primary [class*=icon--], .toggle .toggle-label:focus.btn-secondary [class*=icon--], .toggle .toggle-label:focus.btn-tertiary [class*=icon--], .toggle .toggle-label:focus.btn-quaternary [class*=icon--], .toggle .toggle-label:hover:focus.btn-primary [class*=icon--], .toggle .toggle-label:hover:focus.btn-secondary [class*=icon--], .toggle .toggle-label:hover:focus.btn-tertiary [class*=icon--], .toggle .toggle-label:hover:focus.btn-quaternary [class*=icon--] {
  color: #ffffff;
}

.pane-v2 {
  background: #ffffff;
}

.pane-v2--loading:after {
  border-left-color: #40BC8A;
}

.pane-v2-footer--shadow {
  box-shadow: 1.5rem 1rem 2rem 1rem rgba(14, 1, 43, 0.15);
}

.pane-v2-backdrop {
  background: #E4E4EC;
}

.pane-v2-banner,
.pane-v2-content {
  background: #F4F6Fa;
}

.pane-v2-cover {
  background-color: #ffffff;
}

.pane-v2-banner-title {
  color: #9F99B1;
}

.pane-v2-header-title {
  font-weight: 700;
  color: #1E025F;
}

.pane-v2-header-subtitle {
  color: #9F99B1;
}

.pane-v2-crumb .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}

.pane-v2-crumb--root:hover .icon-wrap svg, .pane-v2-crumb--root:focus .icon-wrap svg, .pane-v2-crumb--root:hover:focus .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.pane-v2-crumb--parent,
.pane-v2-crumb--key {
  color: #9F99B1;
}
.pane-v2-crumb--parent:hover, .pane-v2-crumb--parent:focus, .pane-v2-crumb--parent:hover:focus,
.pane-v2-crumb--key:hover,
.pane-v2-crumb--key:focus,
.pane-v2-crumb--key:hover:focus {
  color: #1E025F;
}

.pane-v2-shadow:after {
  box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
}

.pane-v2-shadow--top:before {
  box-shadow: 0 0 1rem 0.125rem rgba(14, 1, 43, 0.15);
}

.pane-v2--aside .pane-v2-banner,
.pane-v2--aside .pane-v2-cover {
  background: #ffffff;
}

.pane-v2--root:not(.pane-v2--banner) .pane-v2-header {
  background: #ffffff;
}

.pane-v2--pop {
  box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
}

.pane-v2-footer-notice--warning .pane-v2-footer-notice {
  background: #F85C6A;
  color: #ffffff;
  font-weight: 700;
}

.pane-v2-slide-cover--inverse {
  background: #1E025F;
}

@media (min-width: 768px) {
  .pane-v2--root.pane-v2--md-banner .pane-v2-header {
    background: #ffffff;
  }
  .pane-v2-shadow--md:after {
    box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
  }
  .pane-v2-bar--md-shrink .pane-v2-bar-title {
    color: #9F99B1;
  }
}
@media (min-width: 992px) {
  .layout-default--split .pane-v2.layout-default--split-secondary {
    box-shadow: 0 5rem 2rem -0.75rem rgba(68, 52, 105, 0.5);
  }
}
.cabinet-drawer--query .cabinet-title {
  color: #9F99B1;
}

.cabinet-properties,
.cabinet-meta-item,
.cabinet-meta-link,
.cabinet-meta-value {
  color: #9F99B1;
}

.cabinet-meta-link:hover, .cabinet-meta-link:focus, .cabinet-meta-link:hover:focus,
.cabinet-properties-link:hover,
.cabinet-properties-link:focus,
.cabinet-properties-link:hover:focus {
  color: #443469;
}

.cabinet--rows .cabinet-drawer:nth-of-type(even) {
  background-color: #F4F6Fa;
}

.cabinet-options .action::before {
  display: none;
}

.trend-icon {
  background: #F4F6Fa;
}

.trend--neutral .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}

.trend--rising .icon-wrap svg {
  fill: #40bc8a;
  stroke: #40bc8a;
}

.trend--falling .icon-wrap svg {
  fill: #F85C6A;
  stroke: #F85C6A;
}

.funnel-step:hover .funnel-title, .funnel-step:focus .funnel-title, .funnel-step:hover:focus .funnel-title {
  color: #40BC8A;
}

.trend-change {
  color: #40BC8A;
}

.funnel-change {
  color: #9F99B1;
}

.funnel-change-value {
  color: #443469;
}

.key {
  color: #ffffff;
  background: #9F99B1;
  font-weight: 900;
}
.tooltip .key {
  background: #443469;
}

.strip .flickity-button svg {
  fill: #443469;
}
.strip .flickity-button:hover, .strip .flickity-button:focus, .strip .flickity-button:hover:focus {
  box-shadow: none;
}
.strip .flickity-button:hover svg, .strip .flickity-button:focus svg, .strip .flickity-button:hover:focus svg {
  fill: #1E025F;
}
.strip.strip--mask:before {
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 80%, white 100%);
}
.strip.strip--mask:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 80%, white 100%);
}
.strip.strip--tour .flickity-prev-next-button.previous .arrow {
  fill: #9F99B1;
}
.strip.strip--tour .flickity-prev-next-button.previous:hover .arrow, .strip.strip--tour .flickity-prev-next-button.previous:focus .arrow, .strip.strip--tour .flickity-prev-next-button.previous:hover:focus .arrow {
  fill: #443469;
}
.strip.strip--tour .flickity-prev-next-button.next {
  background: #40BC8A;
}
.strip.strip--tour .flickity-prev-next-button.next .arrow {
  fill: #ffffff;
}
.strip.strip--tour .flickity-prev-next-button.next:hover, .strip.strip--tour .flickity-prev-next-button.next:focus, .strip.strip--tour .flickity-prev-next-button.next:hover:focus {
  background: #3aa97c;
}

.audience {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.audience:hover, .audience:focus, .audience:hover:focus {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.audience:hover .audience-title, .audience:focus .audience-title, .audience:hover:focus .audience-title {
  color: #443469;
}
.audience:hover .audience-link-icon .icon-wrap svg, .audience:focus .audience-link-icon .icon-wrap svg, .audience:hover:focus .audience-link-icon .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.audience-title {
  color: #E4E4EC;
}

.audience-link-icon .icon-wrap svg {
  fill: #E4E4EC;
  stroke: #E4E4EC;
}

.audience-item,
.audience-credits {
  border-color: #F4F6Fa;
}

.audience-meta-title {
  color: #E4E4EC;
}

.audience-meta-desc,
.audience-credits {
  color: #9F99B1;
}

.audience-meta-title,
.audience-meta-desc {
  font-weight: 700;
}

.audience-meta-value {
  color: #443469;
}

.audience-meta-icon .icon-wrap svg {
  fill: #E4E4EC;
  stroke: #E4E4EC;
}

.audience-item--match .audience-meta-title {
  color: #443469;
  font-weight: 700;
}
.audience-item--match .audience-meta-location {
  color: #9F99B1;
}
.audience-item--match .audience-meta-match {
  color: #443469;
}

.insight:before {
  background: #ffffff;
  border-color: #F85C6A;
}

.insight-tooltip.tooltip .icon-container .icon-wrap svg {
  fill: #F85C6A;
  stroke: #F85C6A;
}
.insight-tooltip.tooltip .tooltip-inner {
  background: #ffffff;
  color: #1E025F;
  font-weight: 400;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.insight-tooltip.tooltip .tooltip-inner em {
  font-weight: 700;
}

.select2-container--krajee .select2-selection__arrow,
select.form-control,
.search-experience {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16.828" height="9.75" viewBox="0 0 16.828 9.75"><style>.select-drop { fill: rgb(68, 52, 105); }</style><path class="select-drop" d="M12.117-5.918,3.7-14.332l1.324-1.336,7.09,7.09,7.09-7.09,1.324,1.336Z" transform="translate(-3.703 15.668)"/></svg>');
}

.steps {
  grid-column: 2;
}

.step .count {
  color: #9F99B1;
  background: transparent;
  font-weight: 400;
  box-shadow: none;
}
.step .step-link {
  border-color: transparent;
}
.step .step-link:before, .step .step-link:after {
  background: #d5d5e1;
}
.step .step-link:focus, .step .step-link:active, .step .step-link:hover {
  border-color: #1E025F;
}
.step.step--active .step-link {
  border-color: #1E025F;
  color: #1E025F;
}
.step.step--active .step-link:focus, .step.step--active .step-link:active, .step.step--active .step-link:hover {
  border-color: #1E025F;
}
.step.step--active .step-title {
  font-weight: 700;
}
.step.step--active .indicator-radial {
  background: #E4E4EC;
}
.step.step--active .indicator-radial .inset {
  background: #F4F6Fa;
}

.navigation .step.step--active {
  background: #F4F6Fa;
}

.pane-header--steps .step.step--active .step-link,
.pane-v2-banner .step.step--active .step-link {
  border-color: #1E025F;
}
.pane-header--steps .step .indicator-radial,
.pane-v2-banner .step .indicator-radial {
  background-color: #E4E4EC;
}
.pane-header--steps .step .indicator-radial .inset,
.pane-v2-banner .step .indicator-radial .inset {
  background: #F4F6Fa;
}

.panel__backdrop {
  background: rgba(228, 228, 236, 0.88);
}
.panel::after {
  border-left-color: #40BC8A;
}
.panel--modal-media-sm .panel__container, .panel--modal-media-md .panel__container {
  background: #ffffff;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.panel--pop {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.panel--pop .panel__container {
  background: #ffffff;
}

@media (min-width: 576px) {
  .panel--pop-media-sm .panel__row--mode-close .heading {
    color: #9F99B1;
  }
}
@media (min-width: 768px) {
  .panel--pop-media-md .panel__row--mode-close .heading {
    color: #9F99B1;
  }
}
.team__member {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}

.control:focus {
  outline-color: #F4F6Fa;
}
.control--mode-tab {
  border-top-color: #F4F6Fa;
}
.control--mode-tab .control__label {
  color: #443469;
}
.control--mode-tab.control--state-active {
  border-top-color: #1E025F;
}
.control--mode-tab.control--state-active .control__label {
  color: #1E025F;
  font-weight: 700;
}
.control--mode-action {
  color: #ffffff;
  background: #40BC8A;
}
.control--mode-action .control__label {
  font-weight: 700;
}
.control--mode-action.control--outline {
  border-color: #1E025F;
  color: #1E025F;
  background: transparent;
}
.control--light .control__icon .icon-wrap svg {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.control--light:hover .control__icon .icon-wrap svg, .control--light:focus .control__icon .icon-wrap svg, .control--light:hover:focus .control__icon .icon-wrap svg {
  fill: #443469;
  stroke: #443469;
}

.dashboard-info-block {
  background-color: #F4F6Fa;
}
.dashboard-info-block h2 {
  color: #1E025F;
}
.dashboard-info-block h2:hover .dashboard-blue-block-icon svg {
  fill: #40BC8A !important;
  stroke: #40BC8A !important;
}
.dashboard-info-block p {
  color: #9F99B1;
}
.dashboard-info-block a {
  color: #40BC8A !important;
  -webkit-appearance: none;
}
.dashboard-info-block a:hover {
  color: #1E025F !important;
}
.dashboard-info-block-icon svg {
  fill: #F4F6Fa !important;
  stroke: #F4F6Fa !important;
}

.pane-v2-banner--absence-style {
  background: #40BC8A;
}
.pane-v2-banner--absence-style h6 {
  color: white !important;
}
.pane-v2-banner--absence-style h6 a:hover, .pane-v2-banner--absence-style h6 a:focus, .pane-v2-banner--absence-style h6 a:hover:focus {
  color: #1E025F;
}

.module-auth .pane-v2--root {
  background-color: #F4F6Fa;
}

.module-search .heading-title {
  color: #9F99B1;
  font-weight: 400;
}
.module-search .heading-action {
  color: #9F99B1;
  border-color: #9F99B1;
}
.module-search .heading-action:hover, .module-search .heading-action:focus, .module-search .heading-action:hover:focus {
  color: #4f536a;
  border-color: #4f536a;
}

.module-career h1 span {
  color: #9F99B1;
}
.module-career .career-slider-title h3 {
  font-weight: 400;
}
.module-career .career-switch h2 {
  font-weight: 700;
}

.module-uwv #vacanciesList-container a,
.module-uwv #vacanciesList-container th,
.module-uwv #uwv-dashboard__vacancies-table--content-container th {
  color: #443469;
}
.module-uwv [name="VacancyClassificationSearch[VacancyTitle]"],
.module-uwv .uwv__filter,
.module-uwv [aria-labelledby=select2-vacancyclassificationsearch-steekproefnummer-container],
.module-uwv .select2-container--krajee .select2-selection--single,
.module-uwv .sla-option {
  border: 2px solid #FFF !important;
  border-bottom-color: #9F99B1 !important;
  background-color: #fff;
}
.module-uwv .sla-chart-container .highcharts-point {
  fill: #1E025F;
}
.module-uwv .manual-judgement-select::after {
  content: "";
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  border: 0 solid #fff;
  border-radius: 5px;
}
.module-uwv .btn--uwv-option {
  background-color: #9F99B1 !important;
  border: 0px solid #9F99B1 !important;
  color: #fff !important;
  border-radius: 8px !important;
}
.module-uwv .btn--uwv-option:hover,
.module-uwv .btn--uwv-option .code-search-option-active {
  background-color: #443469 !important;
}
.module-uwv .code-search-option-active {
  background-color: #443469 !important;
}
.module-uwv .btn-close-small,
.module-uwv .select2-selection__clear {
  background-color: #9F99B1 !important;
  color: #fff !important;
}
.module-uwv .btn-close-small:hover,
.module-uwv .select2-selection__clear:hover {
  background-color: #443469 !important;
}

.btn-nok {
  color: #F85C6A !important;
  border-color: #F85C6A !important;
  background-color: #ffffff !important;
}
.btn-nok:hover {
  background-color: #F85C6A !important;
  color: #fff !important;
}
.btn-nok:disabled {
  color: #fff !important;
  border-color: #F85C6A !important;
  background-color: #F85C6A !important;
  opacity: 0.5;
}

#w0:disabled {
  color: #fff !important;
  border-color: #40BC8A !important;
  background-color: #40BC8A !important;
  opacity: 0.5;
}

.delete__uwv-steekproef {
  background-color: #F85C6A !important;
  border-color: #F85C6A !important;
  color: #fff !important;
  padding: 0.2rem 0.5rem !important;
}

.btn__next-small {
  background-color: #40BC8A;
}

[aria-labelledby=select2-vacancyclassificationsearch-manualclassificationjudgment-container] #select2-vacancyclassificationsearch-manualclassificationjudgment-container {
  color: rgba(0, 0, 0, 0) !important;
}
[aria-labelledby=select2-vacancyclassificationsearch-manualclassificationjudgment-container] .select2-selection__arrow {
  position: relative !important;
  z-index: 999999999;
}

.select2-container--krajee .select2-selection--multiple .select2-search--inline .select2-search__field {
  color: #333 !important;
}

.select2-container--krajee .select2-selection--multiple .select2-search--inline .select2-search__field::placeholder,
.form-control::placeholder {
  color: #737373 !important;
}

.view-results .content {
  background: #F4F6Fa;
}
.view-results .pane-filters {
  background: #E4E4EC;
}
.view-results .pane-filters .pane-content::-webkit-scrollbar {
  background-color: #E4E4EC;
}
.view-results .pane-header .pane-header-dropdown-toggle:after {
  background: linear-gradient(to right, rgba(228, 228, 236, 0) 0%, #e4e4ec 80%, #e4e4ec 100%);
}
.view-results.view-results--filter .pane-results {
  background: #F4F6Fa;
}
.view-results.view-results--filter .pane-results .pane-content::-webkit-scrollbar {
  background-color: #F4F6Fa;
}
.view-results.view-results--focus .pane-results {
  background: #F4F6Fa;
}
.view-results.view-results--focus .pane-results .pane-content::-webkit-scrollbar {
  background-color: #F4F6Fa;
}
.view-results.view-results--focus .results-modes {
  background: #F4F6Fa;
}

.state--client .view-results .main > .content {
  background-color: #F4F6Fa;
}

.report-card--text a {
  color: #40BC8A;
}

.view-results.view-results--report.view-results--report-doelgroependashboard [class*=icon-report--] {
  font-family: "icons-report" !important;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor-icon {
  background: #40BC8A;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor:before, .view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor:after {
  background: #3cb182;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor .report-index-anchor-icon {
  color: #308e68;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor .report-index-anchor-icon:after {
  border-color: #308e68;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor .report-index-anchor-label {
  background: #443469;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor.report-index-anchor--active .report-index-anchor-icon:after {
  border-color: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-index-anchor.report-index-anchor--active .report-index-anchor-icon.report-icon {
  color: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-title h2,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title .results-meta-label {
  font-weight: 700;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta {
  background: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta .icon--settings,
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta .icon--location {
  color: #1E025F;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title .results-meta-label a {
  color: #443469;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-charts {
  background: #F4F6Fa;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-title {
  background: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-title h2 {
  color: #443469;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-prologue p {
  color: #9F99B1;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-icon {
  color: #40BC8A;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-toggle,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-close,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-content {
  background: #F85C6A;
  color: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-toggle:focus, .view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-toggle:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-close:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-close:hover {
  background: #f74454;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-body p {
  color: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-icon {
  background: #ffffff;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-icon:before {
  background: #F4F6Fa;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-header-title {
  color: #443469;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-header-title .btn {
  border-color: transparent;
  background: transparent;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-header-title .btn .icon--info {
  color: #1E025F;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-card:before {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.06125);
}
.view-results.view-results--report.view-results--report-doelgroependashboard.view-results--filter .pane-results {
  background: #ffffff;
}

.view-instrument {
  background: #ffffff;
}

.view-dashboard .search-submit {
  background: #40BC8A;
}

.view-inspiration .view-inspiration-callout {
  background: #1E025F;
}
.view-inspiration .view-inspiration-callout span {
  color: #ffffff;
  font-weight: 700;
}
.view-inspiration .view-inspiration-callout:before {
  background: #1E025F;
}
.view-inspiration .vacancy-organisation {
  font-weight: 400;
}
.view-inspiration .view-inspiration--search .search-submit {
  background: #40BC8A;
}

.signature {
  display: block !important;
}

@-webkit-keyframes repaint {
  from {
    zoom: 0;
  }
  to {
    zoom: 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.75);
    opacity: 0;
  }
}
@keyframes swoosh {
  0% {
    transform: translate3d(-110%, 0, 0);
  }
  10% {
    transform: translate3d(60vw, 0, 0);
  }
  85% {
    transform: translate3d(65vw, 0, 0);
  }
  90% {
    transform: translate3d(150vw, 0, 0);
  }
  to {
    transform: translate3d(190vw, 0, 0);
  }
}
@keyframes bar {
  from {
    left: 0;
    width: 0;
    z-index: 10;
  }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 1;
  }
  to {
    left: 0;
    width: 100%;
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-0.0625rem, 0, 0);
  }
  20%, 80% {
    transform: translate3d(0.125rem, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-0.25rem, 0, 0);
  }
  40%, 60% {
    transform: translate3d(0.25rem, 0, 0);
  }
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  animation-iteration-count: 2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}
.baseline:before {
  content: "";
  display: block;
  background-image: linear-gradient(to bottom, blue 0, rgba(255, 255, 255, 0) 1px);
  background-repeat: repeat-y;
  background-size: 100% 1rem;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9999999;
  opacity: 0.2;
}

/* UI 1.8 */
/* REPORT */
@font-face {
  font-family: "icons-ui";
  src: url("../fonts/icons-ui.ttf?puj9i7") format("truetype"), url("../fonts/icons-ui.woff?puj9i7") format("woff"), url("../fonts/icons-ui.svg?puj9i7#icons-ui") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* UI */
@font-face {
  font-family: "icons-report";
  src: url("../fonts/icons-report.ttf?hwolxz") format("truetype"), url("../fonts/icons-report.woff?hwolxz") format("woff"), url("../fonts/icons-report.svg?hwolxz#icons-report") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* REPORT */
.pac-icon.pac-icon-marker, .report-card.report-card--text p a:after, .grid-view .dropdown-toggle, .icon,
.icon--inline,
[class*=icon--],
[class*=icon-report--] {
  font-family: "icons-ui" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 150ms ease;
}
.pac-icon.pac-icon-marker:before, .grid-view .dropdown-toggle:before, .icon:before,
.icon--inline:before,
[class*=icon--]:before,
[class*=icon-report--]:before {
  position: relative;
  top: 0.075em;
  left: -0.05em;
}

[class*=icon-report--] {
  font-family: "icons-report" !important;
}

.icon--white {
  color: #fff;
}

.icon--inline {
  display: inline;
}
.icon--inline:before {
  top: 0;
  left: 0;
}

.icon-container {
  width: 1rem;
  display: block;
  transition: opacity 150ms ease;
}
.action .icon-container {
  width: 1.25rem;
  margin: -0.675rem 0 0 -0.675rem;
  position: absolute;
  left: 50%;
  top: 50%;
}
.action .icon-container.icon-container--close {
  width: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}
.action .icon-container.icon-container--location, .action .icon-container.icon-container--download, .action .icon-container.icon-container--profile {
  width: 1.5rem;
  margin: -0.75rem 0 0 -0.75rem;
}
.action .icon-container.icon-container--location .icon-wrap svg, .action .icon-container.icon-container--download .icon-wrap svg, .action .icon-container.icon-container--profile .icon-wrap svg {
  stroke-width: 0;
}
.action.action--xs .icon-container {
  width: 0.75rem;
  margin: -0.375rem 0 0 -0.375rem;
}
.action.action--sm .icon-container {
  width: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}

.icon-container--xl {
  width: 1.5rem;
  margin: -0.75rem 0 0 -0.75rem;
}

.icon-container--lg {
  width: 1.25rem;
  margin: -0.675rem 0 0 -0.675rem;
}

.icon-container--md {
  width: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}

.icon-container--sm {
  width: 0.75rem;
  margin: -0.375rem 0 0 -0.375rem;
}

.icon-container--xs {
  width: 0.625rem;
  margin: -0.3125rem 0 0 -0.3125rem;
}

.icon-wrap {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}
.icon-wrap svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  stroke-width: 0.025rem;
  transition: fill 150ms ease, stroke 150ms ease;
}

/* UI 1.8 */
.icon--add:before {
  content: "\e900";
}

.icon--back:before {
  content: "\e901";
}

.icon--chart:before {
  content: "\e902";
}

.icon--clear:before {
  content: "\e903";
}

.icon--close:before {
  content: "\e904";
}

.icon--export:before {
  content: "\e905";
}

.icon--favorite:before {
  content: "\e906";
}

.icon--filter:before {
  content: "\e907";
}

.icon--location:before {
  content: "\e908";
}

.icon--more:before {
  content: "\e909";
}

.icon--reset:before {
  content: "\e90a";
}

.icon--save:before {
  content: "\e90b";
}

.icon--search:before {
  content: "\e90c";
}

.icon--select:before {
  content: "\e90d";
}

.icon--settings:before {
  content: "\e90e";
}

.icon--sort:before {
  content: "\e90f";
}

.icon--view:before {
  content: "\e910";
}

.icon--collapse:before {
  content: "\e911";
}

.icon--admin:before {
  content: "\e912";
}

.icon--config:before {
  content: "\e913";
}

.icon--check:before {
  content: "\e914";
}

.icon--check-small:before {
  content: "\e915";
}

.icon--company:before {
  content: "\e916";
}

.icon--forward:before {
  content: "\e917";
}

.icon--help-outline:before {
  content: "\e918";
}

.icon--help:before {
  content: "\e919";
}

.icon--inbox:before {
  content: "\e91a";
}

.icon--message:before {
  content: "\e91b";
}

.icon--stopwatch:before {
  content: "\e91c";
}

.icon--radius:before {
  content: "\e91d";
}

.icon--trash:before {
  content: "\e91e";
}

.icon--user:before {
  content: "\e91f";
}

.icon--chat:before {
  content: "\e920";
}

.icon--write:before {
  content: "\e921";
}

.icon--rising:before {
  content: "\e922";
}

.icon--falling:before {
  content: "\e923";
}

.icon--neutral:before {
  content: "\e924";
}

.icon--search-select:before {
  content: "\e925";
}

.icon--search-profile:before {
  content: "\e926";
}

.icon--label:before {
  content: "\e927";
}

.icon--info:before {
  font-weight: 900;
  content: "i";
}

/* REPORT */
.icon-report--development:before {
  content: "\e912";
}

.icon-report--desirabilty:before {
  content: "\e913";
}

.icon-report--activity:before {
  content: "\e900";
}

.icon-report--play:before {
  content: "\e901";
}

.icon-report--attractive:before {
  content: "\e902";
}

.icon-report--audience:before {
  content: "\e903";
}

.icon-report--demography:before {
  content: "\e904";
}

.icon-report--download:before {
  content: "\e905";
}

.icon-report--flag:before {
  content: "\e906";
}

.icon-report--gender:before {
  content: "\e907";
}

.icon-report--info:before {
  content: "\e908";
}

.icon-report--properties:before {
  content: "\e909";
}

.icon-report--reach:before {
  content: "\e90a";
}

.icon-report--recruitement:before {
  content: "\e90b";
}

.icon-report--scarcity:before {
  content: "\e90c";
}

.icon-report--summary:before {
  content: "\e90d";
}

.icon-report--team:before {
  content: "\e90e";
}

.icon-report--tip:before {
  content: "\e90f";
}

.icon-report--arrow:before {
  content: "\e910";
}

.icon-report--approach:before {
  content: "\e911";
}

.icon-report--male:before {
  content: "\e913";
}

.icon-report--female:before {
  content: "\e914";
}

.application,
.application-v2 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  min-width: 20rem;
  width: 100%;
  overflow: hidden;
  z-index: 100;
}
.application.application-v2--position-relative,
.application-v2.application-v2--position-relative {
  position: relative;
  height: auto;
}
.application.application-v2--webfontloader-enabled,
.application-v2.application-v2--webfontloader-enabled {
  opacity: 0;
}
.wf-active .application.application-v2--webfontloader-enabled, .wf-inactive .application.application-v2--webfontloader-enabled,
.wf-active .application-v2.application-v2--webfontloader-enabled,
.wf-inactive .application-v2.application-v2--webfontloader-enabled {
  opacity: 1;
}
.safari.tablet .application, .chrome.tablet .application, .webkit.tablet .application,
.safari.tablet .application-v2,
.chrome.tablet .application-v2,
.webkit.tablet .application-v2 {
  transform: none;
}

.pit {
  border-radius: 1.25rem;
  margin: -1.5rem 0 0 -2rem;
  height: 3rem;
  width: 4rem;
  animation: pit-from-bottom 600ms;
  animation-fill-mode: forwards;
  background-color: #FFF;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  opacity: 0;
}

.pit-two {
  animation-delay: 300ms;
}

.pit-three {
  animation-delay: 600ms;
}

.pit-four {
  animation-delay: 750ms;
}

.pit-five {
  animation-delay: 825ms;
}

.pit-medium {
  border-radius: 2.5rem;
  margin: -3rem 0 0 -4rem;
  height: 6rem;
  width: 8rem;
}

.pit-large {
  border-radius: 5rem;
  margin: -6rem 0 0 -8rem;
  height: 12rem;
  width: 16rem;
}

.pit-x-large {
  border-radius: 10rem;
  margin: -12rem 0 0 -16rem;
  height: 24rem;
  width: 32rem;
}

.pit-xx-large {
  border-radius: 20rem;
  margin: -24rem 0 0 -32rem;
  height: 48rem;
  width: 64rem;
}

@keyframes pit-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 0.88;
    transform: translateY(-8rem);
  }
}
.pits {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.pits.pits-fade {
  opacity: 0.75;
}

.pane-indicator--loading::after,
.modal-indicator--loading::after,
.kv-grid-loading::after,
.kv-loading-search::after {
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left-width: 0.125rem;
  border-left-style: solid;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 3rem;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
}

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 0.125rem solid transparent;
  padding: 0.75rem 3rem;
  font-size: 1rem;
  border-radius: 1rem;
}
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover, .btn:focus, .btn:hover:focus {
  text-decoration: none;
}
.btn.focus {
  text-decoration: none;
}
.btn:active, .btn.active {
  background-image: none;
  outline: 0;
}
.btn.disabled, .btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}
.btn-primary:hover {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
}
.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #025aa5;
  border-color: #01549b;
  background-image: none;
}
.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #014682;
  border-color: #01315a;
}
.btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary:disabled:focus, .btn-primary:disabled.focus {
  background-color: #0275d8;
  border-color: #0275d8;
}
.btn-primary.disabled:hover, .btn-primary:disabled:hover {
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-secondary {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #0e012d;
  border-color: #0b0123;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #0e012d;
  border-color: #0b0123;
}
.btn-secondary:active, .btn-secondary.active, .open > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0e012d;
  border-color: #0b0123;
  background-image: none;
}
.btn-secondary:active:hover, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:hover, .btn-secondary.active:focus, .btn-secondary.active.focus, .open > .btn-secondary.dropdown-toggle:hover, .open > .btn-secondary.dropdown-toggle:focus, .open > .btn-secondary.dropdown-toggle.focus {
  color: #fff;
  background-color: #03000a;
  border-color: black;
}
.btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary:disabled:focus, .btn-secondary:disabled.focus {
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary.disabled:hover, .btn-secondary:disabled:hover {
  background-color: #1E025F;
  border-color: #1E025F;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}
.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
  background-image: none;
}
.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a;
}
.btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info:disabled:focus, .btn-info:disabled.focus {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info.disabled:hover, .btn-info:disabled:hover {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}
.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
  background-image: none;
}
.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d;
}
.btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success:disabled:focus, .btn-success:disabled.focus {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success.disabled:hover, .btn-success:disabled:hover {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}
.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
  background-image: none;
}
.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f;
}
.btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning:disabled:focus, .btn-warning:disabled.focus {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning.disabled:hover, .btn-warning:disabled:hover {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
}
.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
  background-image: none;
}
.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e;
}
.btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger:disabled:focus, .btn-danger:disabled.focus {
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-danger.disabled:hover, .btn-danger:disabled:hover {
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-primary {
  color: #0275d8;
  background-image: none;
  background-color: transparent;
  border-color: #0275d8;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}
.btn-outline-primary:active, .btn-outline-primary.active, .open > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}
.btn-outline-primary:active:hover, .btn-outline-primary:active:focus, .btn-outline-primary:active.focus, .btn-outline-primary.active:hover, .btn-outline-primary.active:focus, .btn-outline-primary.active.focus, .open > .btn-outline-primary.dropdown-toggle:hover, .open > .btn-outline-primary.dropdown-toggle:focus, .open > .btn-outline-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #014682;
  border-color: #01315a;
}
.btn-outline-primary.disabled:focus, .btn-outline-primary.disabled.focus, .btn-outline-primary:disabled:focus, .btn-outline-primary:disabled.focus {
  border-color: #43a7fd;
}
.btn-outline-primary.disabled:hover, .btn-outline-primary:disabled:hover {
  border-color: #43a7fd;
}

.btn-outline-secondary {
  color: #1E025F;
  background-image: none;
  background-color: transparent;
  border-color: #1E025F;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-outline-secondary:active, .btn-outline-secondary.active, .open > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-outline-secondary:active:hover, .btn-outline-secondary:active:focus, .btn-outline-secondary:active.focus, .btn-outline-secondary.active:hover, .btn-outline-secondary.active:focus, .btn-outline-secondary.active.focus, .open > .btn-outline-secondary.dropdown-toggle:hover, .open > .btn-outline-secondary.dropdown-toggle:focus, .open > .btn-outline-secondary.dropdown-toggle.focus {
  color: #fff;
  background-color: #03000a;
  border-color: black;
}
.btn-outline-secondary.disabled:focus, .btn-outline-secondary.disabled.focus, .btn-outline-secondary:disabled:focus, .btn-outline-secondary:disabled.focus {
  border-color: #3e04c3;
}
.btn-outline-secondary.disabled:hover, .btn-outline-secondary:disabled:hover {
  border-color: #3e04c3;
}

.btn-outline-info {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:active, .btn-outline-info.active, .open > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:active:hover, .btn-outline-info:active:focus, .btn-outline-info:active.focus, .btn-outline-info.active:hover, .btn-outline-info.active:focus, .btn-outline-info.active.focus, .open > .btn-outline-info.dropdown-toggle:hover, .open > .btn-outline-info.dropdown-toggle:focus, .open > .btn-outline-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1f7e9a;
}
.btn-outline-info.disabled:focus, .btn-outline-info.disabled.focus, .btn-outline-info:disabled:focus, .btn-outline-info:disabled.focus {
  border-color: #b0e1ef;
}
.btn-outline-info.disabled:hover, .btn-outline-info:disabled:hover {
  border-color: #b0e1ef;
}

.btn-outline-success {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:active, .btn-outline-success.active, .open > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:active:hover, .btn-outline-success:active:focus, .btn-outline-success:active.focus, .btn-outline-success.active:hover, .btn-outline-success.active:focus, .btn-outline-success.active.focus, .open > .btn-outline-success.dropdown-toggle:hover, .open > .btn-outline-success.dropdown-toggle:focus, .open > .btn-outline-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #2d672d;
}
.btn-outline-success.disabled:focus, .btn-outline-success.disabled.focus, .btn-outline-success:disabled:focus, .btn-outline-success:disabled.focus {
  border-color: #a3d7a3;
}
.btn-outline-success.disabled:hover, .btn-outline-success:disabled:hover {
  border-color: #a3d7a3;
}

.btn-outline-warning {
  color: #f0ad4e;
  background-image: none;
  background-color: transparent;
  border-color: #f0ad4e;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:active, .btn-outline-warning.active, .open > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:active:hover, .btn-outline-warning:active:focus, .btn-outline-warning:active.focus, .btn-outline-warning.active:hover, .btn-outline-warning.active:focus, .btn-outline-warning.active.focus, .open > .btn-outline-warning.dropdown-toggle:hover, .open > .btn-outline-warning.dropdown-toggle:focus, .open > .btn-outline-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #b06d0f;
}
.btn-outline-warning.disabled:focus, .btn-outline-warning.disabled.focus, .btn-outline-warning:disabled:focus, .btn-outline-warning:disabled.focus {
  border-color: #f8d9ac;
}
.btn-outline-warning.disabled:hover, .btn-outline-warning:disabled:hover {
  border-color: #f8d9ac;
}

.btn-outline-danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border-color: #d9534f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:active, .btn-outline-danger.active, .open > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:active:hover, .btn-outline-danger:active:focus, .btn-outline-danger:active.focus, .btn-outline-danger.active:hover, .btn-outline-danger.active:focus, .btn-outline-danger.active.focus, .open > .btn-outline-danger.dropdown-toggle:hover, .open > .btn-outline-danger.dropdown-toggle:focus, .open > .btn-outline-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #8b211e;
}
.btn-outline-danger.disabled:focus, .btn-outline-danger.disabled.focus, .btn-outline-danger:disabled:focus, .btn-outline-danger:disabled.focus {
  border-color: #eba5a3;
}
.btn-outline-danger.disabled:hover, .btn-outline-danger:disabled:hover {
  border-color: #eba5a3;
}

.btn-link {
  font-weight: normal;
  color: #0275d8;
  border-radius: 0;
}
.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
  background-color: transparent;
}
.btn-link, .btn-link:focus, .btn-link:active {
  border-color: transparent;
}
.btn-link:hover {
  border-color: transparent;
}
.btn-link:hover, .btn-link:focus, .btn-link:hover:focus {
  color: #014c8c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link:disabled:hover, .btn-link:disabled:focus, .btn-link:disabled:hover:focus {
  color: #818a91;
  text-decoration: none;
}

.btn-lg {
  padding: 0.8125rem 4rem;
  font-size: 1.25rem;
  border-radius: 1.25rem;
}

.btn-sm {
  padding: 0.46875rem 2rem;
  font-size: 0.875rem;
  border-radius: 0.875rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn {
  outline: 0;
  appearance: none;
}
.btn.disabled, .btn[disabled] {
  background: #eceeef !important;
  border-color: #eceeef !important;
}
.btn i {
  transition: color 0 none;
}

.btn-xxs {
  padding: 0.125rem 0.5rem;
  font-size: 0.6875rem;
  border-radius: 0.4rem;
}

.btn-xs {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.5rem;
}

.btn-blend {
  color: #55595c;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0.5rem 2rem 0.125rem rgba(14, 1, 43, 0.06125);
}
.btn-blend:hover {
  color: #55595c;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn-blend:focus, .btn-blend.focus {
  color: #55595c;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
}
.btn-blend:active, .btn-blend.active, .open > .btn-blend.dropdown-toggle {
  color: #55595c;
  background-color: #e6e6e6;
  border-color: #e0e0e0;
  background-image: none;
}
.btn-blend:active:hover, .btn-blend:active:focus, .btn-blend:active.focus, .btn-blend.active:hover, .btn-blend.active:focus, .btn-blend.active.focus, .open > .btn-blend.dropdown-toggle:hover, .open > .btn-blend.dropdown-toggle:focus, .open > .btn-blend.dropdown-toggle.focus {
  color: #55595c;
  background-color: #d4d4d4;
  border-color: #bfbfbf;
}
.btn-blend.disabled:focus, .btn-blend.disabled.focus, .btn-blend:disabled:focus, .btn-blend:disabled.focus {
  background-color: #fff;
  border-color: #fff;
}
.btn-blend.disabled:hover, .btn-blend:disabled:hover {
  background-color: #fff;
  border-color: #fff;
}

.btn-remark {
  color: #5a5e79;
  background-image: none;
  background-color: transparent;
  border-color: #5a5e79;
  border-style: solid;
  border-color: #d1d3d5;
  line-height: 1;
  font-weight: 700;
  margin-left: 0.25rem;
}
.btn-remark:hover {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-remark:focus, .btn-remark.focus {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-remark:active, .btn-remark.active, .open > .btn-remark.dropdown-toggle {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-remark:active:hover, .btn-remark:active:focus, .btn-remark:active.focus, .btn-remark.active:hover, .btn-remark.active:focus, .btn-remark.active.focus, .open > .btn-remark.dropdown-toggle:hover, .open > .btn-remark.dropdown-toggle:focus, .open > .btn-remark.dropdown-toggle.focus {
  color: #fff;
  background-color: #353747;
  border-color: #242530;
}
.btn-remark.disabled:focus, .btn-remark.disabled.focus, .btn-remark:disabled:focus, .btn-remark:disabled.focus {
  border-color: #8e92ab;
}
.btn-remark.disabled:hover, .btn-remark:disabled:hover {
  border-color: #8e92ab;
}

.btn-caps {
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 900;
}
.btn-caps.btn-sm {
  font-size: 0.625rem;
  line-height: 1.35rem;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.btn-tertiary {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary:hover {
  color: #fff;
  background-color: #f62b3d;
  border-color: #f52134;
}
.btn-tertiary:focus, .btn-tertiary.focus {
  color: #fff;
  background-color: #f62b3d;
  border-color: #f52134;
}
.btn-tertiary:active, .btn-tertiary.active, .open > .btn-tertiary.dropdown-toggle {
  color: #fff;
  background-color: #f62b3d;
  border-color: #f52134;
  background-image: none;
}
.btn-tertiary:active:hover, .btn-tertiary:active:focus, .btn-tertiary:active.focus, .btn-tertiary.active:hover, .btn-tertiary.active:focus, .btn-tertiary.active.focus, .open > .btn-tertiary.dropdown-toggle:hover, .open > .btn-tertiary.dropdown-toggle:focus, .open > .btn-tertiary.dropdown-toggle.focus {
  color: #fff;
  background-color: #f30a1f;
  border-color: #cc091a;
}
.btn-tertiary.disabled:focus, .btn-tertiary.disabled.focus, .btn-tertiary:disabled:focus, .btn-tertiary:disabled.focus {
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary.disabled:hover, .btn-tertiary:disabled:hover {
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary:hover {
  background-color: #f74d5d;
  border-color: #f74d5d;
}

.btn.btn-primary-outline {
  color: #373a3c;
  font-weight: 700;
  border-style: solid;
}

.btn-secondary-outline {
  color: #1E025F;
  background-image: none;
  background-color: transparent;
  border-color: #1E025F;
  border-style: solid;
}
.btn-secondary-outline:hover {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary-outline:focus, .btn-secondary-outline.focus {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary-outline:active, .btn-secondary-outline.active, .open > .btn-secondary-outline.dropdown-toggle {
  color: #fff;
  background-color: #1E025F;
  border-color: #1E025F;
}
.btn-secondary-outline:active:hover, .btn-secondary-outline:active:focus, .btn-secondary-outline:active.focus, .btn-secondary-outline.active:hover, .btn-secondary-outline.active:focus, .btn-secondary-outline.active.focus, .open > .btn-secondary-outline.dropdown-toggle:hover, .open > .btn-secondary-outline.dropdown-toggle:focus, .open > .btn-secondary-outline.dropdown-toggle.focus {
  color: #fff;
  background-color: #03000a;
  border-color: black;
}
.btn-secondary-outline.disabled:focus, .btn-secondary-outline.disabled.focus, .btn-secondary-outline:disabled:focus, .btn-secondary-outline:disabled.focus {
  border-color: #3e04c3;
}
.btn-secondary-outline.disabled:hover, .btn-secondary-outline:disabled:hover {
  border-color: #3e04c3;
}

.btn-tertiary-outline {
  color: #F85C6A;
  background-image: none;
  background-color: transparent;
  border-color: #F85C6A;
  border-style: solid;
}
.btn-tertiary-outline:hover {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary-outline:focus, .btn-tertiary-outline.focus {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary-outline:active, .btn-tertiary-outline.active, .open > .btn-tertiary-outline.dropdown-toggle {
  color: #fff;
  background-color: #F85C6A;
  border-color: #F85C6A;
}
.btn-tertiary-outline:active:hover, .btn-tertiary-outline:active:focus, .btn-tertiary-outline:active.focus, .btn-tertiary-outline.active:hover, .btn-tertiary-outline.active:focus, .btn-tertiary-outline.active.focus, .open > .btn-tertiary-outline.dropdown-toggle:hover, .open > .btn-tertiary-outline.dropdown-toggle:focus, .open > .btn-tertiary-outline.dropdown-toggle.focus {
  color: #fff;
  background-color: #f30a1f;
  border-color: #cc091a;
}
.btn-tertiary-outline.disabled:focus, .btn-tertiary-outline.disabled.focus, .btn-tertiary-outline:disabled:focus, .btn-tertiary-outline:disabled.focus {
  border-color: #fcbec3;
}
.btn-tertiary-outline.disabled:hover, .btn-tertiary-outline:disabled:hover {
  border-color: #fcbec3;
}

.btn-quaternary {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-quaternary:hover {
  color: #fff;
  background-color: #44475c;
  border-color: #404356;
}
.btn-quaternary:focus, .btn-quaternary.focus {
  color: #fff;
  background-color: #44475c;
  border-color: #404356;
}
.btn-quaternary:active, .btn-quaternary.active, .open > .btn-quaternary.dropdown-toggle {
  color: #fff;
  background-color: #44475c;
  border-color: #404356;
  background-image: none;
}
.btn-quaternary:active:hover, .btn-quaternary:active:focus, .btn-quaternary:active.focus, .btn-quaternary.active:hover, .btn-quaternary.active:focus, .btn-quaternary.active.focus, .open > .btn-quaternary.dropdown-toggle:hover, .open > .btn-quaternary.dropdown-toggle:focus, .open > .btn-quaternary.dropdown-toggle.focus {
  color: #fff;
  background-color: #353747;
  border-color: #242530;
}
.btn-quaternary.disabled:focus, .btn-quaternary.disabled.focus, .btn-quaternary:disabled:focus, .btn-quaternary:disabled.focus {
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-quaternary.disabled:hover, .btn-quaternary:disabled:hover {
  background-color: #5a5e79;
  border-color: #5a5e79;
}

.btn-quaternary-outline {
  color: #5a5e79;
  background-image: none;
  background-color: transparent;
  border-color: #5a5e79;
  border-color: #a7abad;
  border-style: solid;
}
.btn-quaternary-outline:hover {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-quaternary-outline:focus, .btn-quaternary-outline.focus {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-quaternary-outline:active, .btn-quaternary-outline.active, .open > .btn-quaternary-outline.dropdown-toggle {
  color: #fff;
  background-color: #5a5e79;
  border-color: #5a5e79;
}
.btn-quaternary-outline:active:hover, .btn-quaternary-outline:active:focus, .btn-quaternary-outline:active.focus, .btn-quaternary-outline.active:hover, .btn-quaternary-outline.active:focus, .btn-quaternary-outline.active.focus, .open > .btn-quaternary-outline.dropdown-toggle:hover, .open > .btn-quaternary-outline.dropdown-toggle:focus, .open > .btn-quaternary-outline.dropdown-toggle.focus {
  color: #fff;
  background-color: #353747;
  border-color: #242530;
}
.btn-quaternary-outline.disabled:focus, .btn-quaternary-outline.disabled.focus, .btn-quaternary-outline:disabled:focus, .btn-quaternary-outline:disabled.focus {
  border-color: #8e92ab;
}
.btn-quaternary-outline.disabled:hover, .btn-quaternary-outline:disabled:hover {
  border-color: #8e92ab;
}

.button {
  display: inline-block;
  height: 3rem;
  line-height: 2.75rem;
  padding: 0 2.75rem;
  border-radius: 1rem;
  border: 0.125rem solid transparent;
}
.button .icon-container {
  display: inline-block;
  margin: 0;
  position: relative;
  top: 0.125rem;
}
.button .icon-container--xs {
  top: 0.06125rem;
  margin-right: 0.125rem;
}
.button.button--active .button-label, .button.button--loading-active .button-label {
  display: none;
}
.button.button--active .button-label-active, .button.button--loading-active .button-label-active {
  display: inline;
}
.safari .button.button--active, .safari .button.button--loading-active {
  /* DO not use active label on safari */
}
.safari .button.button--active .button-label, .safari .button.button--loading-active .button-label {
  display: inline;
}
.safari .button.button--active .button-label-active, .safari .button.button--loading-active .button-label-active {
  display: none;
}
.button.button--disabled, .button[disabled] {
  cursor: not-allowed;
}
.button.button--sm {
  height: 2.5rem;
  line-height: 2.25rem;
  padding: 0 1.75rem;
  border-radius: 0.875rem;
  font-size: 0.875rem;
}
.button.button--xs {
  height: 1.75rem;
  line-height: 1.5rem;
  padding: 0 0.875rem;
  border-radius: 0.625rem;
  font-size: 0.75rem;
}
.button.button--xxs {
  height: 1.25rem;
  line-height: 1rem;
  padding: 0 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.625rem;
}
.button.button--loading, .button.button--active {
  position: relative;
}

.button--block {
  display: block;
  width: 100%;
}

.button--notice {
  display: block;
  width: 100%;
  height: 1.5rem;
  border-radius: 0;
  line-height: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  font-size: 0.75rem;
}

.button-label-active {
  display: none;
}
.button-label-active.button-label-active--loading::after {
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left-width: 0.125rem;
  border-left-style: solid;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 1.25rem;
  margin: -0.625rem 0 0 -0.625rem;
  width: 1.25rem;
}

.button--action {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.button--light-inverse {
  border-radius: 100px !important;
}

.button--placeholder {
  background: transparent;
  border-style: dashed;
}

.button--sm-expand .button-label,
.button--md-expand .button-label {
  display: none;
}

@media (min-width: 576px) {
  .button--sm-expand .button-init {
    display: none;
  }
  .button--sm-expand .button-label {
    display: inline;
  }
}
@media (min-width: 768px) {
  .button.button--shrink-md, .button.button--md-shrink {
    height: 2.5rem;
    line-height: 2.25rem;
    padding: 0 1.75rem;
    border-radius: 0.875rem;
    font-size: 0.875rem;
  }
  .button.button--md-block {
    display: block;
    width: 100%;
  }
  .button--md-expand .button-init {
    display: none;
  }
  .button--md-expand .button-label {
    display: inline;
  }
}
.search-keys.search-keys--selectize {
  /* KEY */
}
.search-keys.search-keys--selectize .field-search-input {
  width: 100%;
  height: 3rem;
}
.search-keys.search-keys--selectize .field-search-input > input {
  display: none;
}
.search-keys.search-keys--selectize .search-input {
  float: none;
  height: 3rem;
  padding-left: 0 !important;
}
.search-keys.search-keys--selectize .selectize-control {
  position: relative;
  height: 3rem;
}
.search-keys.search-keys--selectize .selectize-control.loading .selectize-dropdown:after {
  display: block;
}
.search-keys.search-keys--selectize .selectize-input {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0;
  cursor: text;
  border: 0;
  box-shadow: none;
  border-radius: 1rem 0 0 1rem;
  height: 3rem;
  line-height: inherit;
  background: transparent;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  /*> * {
      vertical-align: baseline;
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
  }*/
}
.search-keys.search-keys--selectize .selectize-input > input {
  display: block;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 0.0625rem 0 1rem !important; /* 0 1px */
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  user-select: auto !important;
  box-shadow: none !important;
  min-width: 1rem;
  font-size: 1rem;
  height: 3rem;
}
.search-keys.search-keys--selectize .selectize-input > input:focus {
  outline: none !important;
}
.search-keys.search-keys--selectize .selectize-input > input::-ms-clear {
  display: none;
}
.search-keys.search-keys--selectize .selectize-input.focus, .search-keys.search-keys--selectize .selectize-input.input-active {
  overflow: visible;
  padding-top: 4rem;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  height: auto;
}
.search-keys.search-keys--selectize .selectize-input.focus > input, .search-keys.search-keys--selectize .selectize-input.input-active > input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}
.search-keys.search-keys--selectize .selectize-input.focus.has-items, .search-keys.search-keys--selectize .selectize-input.input-active.has-items {
  flex-wrap: wrap;
}
.search-keys.search-keys--selectize .selectize-input.focus.has-items:before, .search-keys.search-keys--selectize .selectize-input.input-active.has-items:before {
  top: 3.5rem;
  left: 0;
  right: -10rem;
  bottom: 0;
  content: "";
  position: absolute;
  background: #fff;
  display: block;
}
.search-keys.search-keys--selectize .selectize-input.focus .search-key, .search-keys.search-keys--selectize .selectize-input.input-active .search-key {
  margin-top: 0;
  margin-bottom: 0.25rem;
}
.search-keys.search-keys--selectize .selectize-input.focus .search-key .search-key-label, .search-keys.search-keys--selectize .selectize-input.input-active .search-key .search-key-label {
  padding-right: 1.5rem;
}
.search-keys.search-keys--selectize .selectize-input.focus .search-key .search-key-remove, .search-keys.search-keys--selectize .selectize-input.input-active .search-key .search-key-remove {
  display: block;
}
.search-keys.search-keys--selectize .selectize-input.focus .search-key:first-child, .search-keys.search-keys--selectize .selectize-input.input-active .search-key:first-child {
  padding-left: 0.125rem;
}
.search-keys.search-keys--selectize .selectize-input.focus .search-key-label, .search-keys.search-keys--selectize .selectize-input.input-active .search-key-label {
  max-width: none;
}
.tablet.safari .search-keys.search-keys--selectize .selectize-input.focus > .search-key, .tablet.chrome .search-keys.search-keys--selectize .selectize-input.focus > .search-key, .tablet.safari .search-keys.search-keys--selectize .selectize-input.input-active > .search-key, .tablet.chrome .search-keys.search-keys--selectize .selectize-input.input-active > .search-key {
  float: none;
  vertical-align: baseline;
  display: inline-block;
}
.search-keys.search-keys--selectize .selectize-input.dropdown-active.has-items:before {
  display: none !important;
}
.search-keys.search-keys--selectize .selectize-input.dropdown-active .search-key {
  display: none !important;
}
.search-keys.search-keys--selectize .selectize-input.disabled {
  opacity: 0.5;
  cursor: default !important;
}
.search-keys.search-keys--selectize .selectize-input.disabled * {
  cursor: default !important;
}
.search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) {
  padding-right: 15%;
  overflow: hidden;
  justify-content: flex-end;
}
.search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active):before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: -0.06125rem;
  height: 3rem;
  width: 4rem;
  pointer-events: none;
  border-radius: 1rem 0 0 1rem;
  z-index: 2;
  border-left: 0.06125rem solid #fff;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 100%);
}
.search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > input {
  position: absolute !important;
  top: 0;
  left: 85%;
}
.search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > .search-key:first-child {
  padding-left: 0.125rem;
}
.windows.ie11 .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active), .tablet.safari .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active), .tablet.chrome .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) {
  display: block;
  direction: rtl;
  white-space: nowrap;
  text-align: left; /* Required by windows 7 + ie11 */
}
.windows.ie11 .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > input, .tablet.safari .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > input, .tablet.chrome .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > input {
  direction: ltr;
  text-align: left;
}
.windows.ie11 .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > .search-key, .tablet.safari .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > .search-key, .tablet.chrome .search-keys.search-keys--selectize .selectize-input.overflow:not(.focus):not(.input-active) > .search-key {
  direction: ltr;
  text-align: left;
  float: none;
  vertical-align: baseline;
  display: inline-block;
}
.search-keys.search-keys--selectize .selectize-input:after {
  content: " ";
  display: block;
  clear: left;
}
.search-keys.search-keys--selectize .selectize-dropdown {
  position: absolute;
  z-index: 10;
  margin: -0.75rem 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 1rem;
  width: calc(100% + 10rem) !important;
  /* Calculation of top height by JS is not correct, force top position. Issue on iOS8 mobile and tablet Chrome + Safari */
  top: 4.25rem !important;
}
.search-keys.search-keys--selectize .selectize-dropdown::after {
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left-width: 0.125rem;
  border-left-style: solid;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 1.25rem;
  margin: -0.625rem 0 0 -0.625rem;
  width: 1.25rem;
}
.search-keys.search-keys--selectize .selectize-dropdown:after {
  display: none;
  left: auto;
  right: 1.5rem;
  top: 1.5rem;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item {
  cursor: pointer;
  overflow: hidden;
  height: 3rem;
  line-height: 3rem;
  white-space: nowrap;
  margin: 0;
  padding: 0 2rem 0 1rem;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item-type {
  position: relative;
  display: inline-block;
  top: -0.25rem;
  font-size: 0.75rem;
  padding-left: 0.125rem;
}
.search-keys.search-keys--selectize .selectize-dropdown .active .selectize-item-type {
  opacity: 0.8;
}
.search-keys.search-keys--selectize .selectize-dropdown .selectize-item-query {
  font-weight: 700;
}
.search-keys.search-keys--selectize .selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 15rem; /*  200px */
}
.search-keys.search-keys--selectize .search-key {
  margin: 0.5rem 0 0 0;
  position: relative;
}
.search-keys.search-keys--selectize .search-key:first-child {
  padding-left: 0.5rem;
}
.search-keys.search-keys--selectize .search-key + .search-key {
  padding-left: 0.125rem;
}
.search-keys.search-keys--selectize .search-key.active {
  opacity: 0.5;
}
.search-keys.search-keys--selectize .search-key:hover .search-key-label {
  cursor: pointer !important;
}
.search-keys.search-keys--selectize .search-key-label {
  display: block;
  height: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  padding: 0 0.75rem 0 0.75rem;
  margin: 0;
  border-radius: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 500;
  max-width: 8rem;
  cursor: text;
}
.search-keys.search-keys--selectize .search-key-remove {
  z-index: 1;
  position: absolute;
  top: -0.06125rem;
  right: -0.25rem;
  height: 2rem;
  width: 2rem;
  text-align: center;
  line-height: 2rem;
  opacity: 0.5;
  font-size: 0.75rem;
  display: none;
}
.search-keys.search-keys--selectize .search-key-remove:hover {
  opacity: 1;
}

[data-notify=container], .pane-v2-shadow, .focus-cover, .shadow {
  position: relative;
}
[data-notify=container]:after, .pane-v2-shadow:after, .focus-cover:after, .shadow:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 75%;
  left: 50%;
  margin-left: -37.5%;
  height: 2rem;
  pointer-events: none;
  bottom: -0.25rem;
  transform: perspective(50rem) rotateY(0deg) rotateX(45deg);
  /* Default color - DEPRECATED */
  box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
}

.pane-v2-shadow--top:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 75%;
  left: 50%;
  margin-left: -37.5%;
  height: 2rem;
  pointer-events: none;
  height: 0;
  top: 0;
  box-shadow: 0 0 1rem 0.125rem rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .shadow--md {
    position: relative;
  }
  .shadow--md:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
    height: 2rem;
    pointer-events: none;
    bottom: -0.25rem;
    transform: perspective(50rem) rotateY(0deg) rotateX(45deg);
    /* Default color - DEPRECATED */
    box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
  }
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 0.125rem solid #fff;
  border-radius: 1rem;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #55595c;
  background-color: #fff;
  border-color: #1E025F;
  outline: none;
}
.form-control::placeholder {
  color: #b7bdc1;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #eceeef;
  opacity: 1;
}
.form-control:disabled {
  cursor: not-allowed;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(4.5rem - 0.25rem);
}
select.form-control:focus::-ms-value {
  color: #55595c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.275rem;
  padding-bottom: 0.275rem;
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-static {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-static.form-control-sm, .input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn, .form-control-static.form-control-lg, .input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.275rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.875rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 1.8625rem;
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 1.25rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 3.1666666667rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.75rem;
}
.form-check + .form-check {
  margin-top: -0.25rem;
}
.form-check.disabled .form-check-label {
  color: #818a91;
  cursor: not-allowed;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}
.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  position: relative;
  display: inline-block;
  padding-left: 1.25rem;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: pointer;
}
.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}
.form-check-inline.disabled {
  color: #818a91;
  cursor: not-allowed;
}

.form-control-feedback {
  margin-top: 0.25rem;
}

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 3rem;
  background-repeat: no-repeat;
  background-position: center right 1.125rem;
  background-size: 2.25rem 2.25rem;
}

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .form-check-label,
.has-success .form-check-inline,
.has-success .custom-control {
  color: #5cb85c;
}
.has-success .form-control {
  border-color: #5cb85c;
}
.has-success .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #a3d7a3;
}
.has-success .input-group-addon {
  color: #5cb85c;
  border-color: #5cb85c;
  background-color: #eaf6ea;
}
.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#5cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .form-check-label,
.has-warning .form-check-inline,
.has-warning .custom-control {
  color: #f0ad4e;
}
.has-warning .form-control {
  border-color: #f0ad4e;
}
.has-warning .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f8d9ac;
}
.has-warning .input-group-addon {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-color: white;
}
.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .form-check-label,
.has-danger .form-check-inline,
.has-danger .custom-control {
  color: #d9534f;
}
.has-danger .form-control {
  border-color: #d9534f;
}
.has-danger .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eba5a3;
}
.has-danger .input-group-addon {
  color: #d9534f;
  border-color: #d9534f;
  background-color: #fdf7f7;
}
.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.input-group {
  position: relative;
  width: 100%;
  display: flex;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1;
  margin-bottom: 0;
}
.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #55595c;
  text-align: center;
  background-color: #eceeef;
  border: 0.125rem solid #fff;
  border-radius: 1rem;
}
.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.275rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.875rem;
}
.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 1.25rem;
}
.input-group-addon input[type=radio],
.input-group-addon input[type=checkbox] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -0.125rem;
}
.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -0.125rem;
}
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -0.125rem;
}
.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: inline-block;
  padding-left: 1.5rem;
  cursor: pointer;
}
.custom-control + .custom-control {
  margin-left: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #0074d9;
}
.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}
.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #84c6ff;
}
.custom-control-input:disabled ~ .custom-control-indicator {
  cursor: not-allowed;
  background-color: #eee;
}
.custom-control-input:disabled ~ .custom-control-description {
  color: #767676;
  cursor: not-allowed;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 1rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #0074d9;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked .custom-control {
  float: left;
  clear: left;
}
.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(4.5rem - 0.25rem);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  padding-right: 0.75rem \9 ;
  color: #55595c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-image: none \9 ;
  background-size: 8px 10px;
  border: 0.125rem solid rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.custom-select:focus {
  border-color: #51a7e8;
  outline: none;
}
.custom-select:focus::-ms-value {
  color: #55595c;
  background-color: #fff;
}
.custom-select:disabled {
  color: #818a91;
  cursor: not-allowed;
  background-color: #eceeef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  cursor: pointer;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}
.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  user-select: none;
  background-color: #fff;
  border: 0.125rem solid #ddd;
  border-radius: 1rem;
}
.custom-file-control:lang(en)::after {
  content: "Choose file...";
}
.custom-file-control::before {
  position: absolute;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.125rem solid #ddd;
  border-radius: 0 1rem 1rem 0;
}
.custom-file-control:lang(en)::before {
  content: "Browse";
}

input {
  appearance: none;
}

.form-control:focus {
  font-weight: 400;
}
.has-success .form-control:focus {
  box-shadow: none;
}

.form .form-heading {
  padding-left: 1rem;
}

.form-group {
  margin: 0;
}
.form-group + .form-group,
.form-group + .button {
  margin-top: 1rem;
}

input.form-control::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input.form-control.form-control--sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  border-radius: 0.625rem;
  height: 2rem;
  line-height: 2rem;
}

select.form-control {
  display: block;
  border-width: 0.125rem;
  border-radius: 1rem;
  width: 100%;
  height: 3rem;
  border-radius: 1rem;
  background-size: 0.75rem 0.75rem;
  background-position: top 1.125rem right 1rem;
  background-repeat: no-repeat;
  outline: none;
  appearance: none; /* for MOZ + Webkit */
}
select.form-control::-ms-expand { /* for IE 11 */
  display: none;
}
select.form-control:not([size]):not([multiple]) {
  height: 3rem;
}

.form-control-label {
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.52124rem;
  padding-top: 0.22876rem;
  display: block;
  font-weight: 400;
  cursor: pointer;
  padding-left: 1rem;
}
.form-control-label + .form-control,
.form-control-label ~ .select2 {
  margin-top: 0.19001rem;
}

.form-control-hidden {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form-heading {
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: 1.27124rem;
  padding-top: 0.72876rem;
}

.help-block {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.73249rem;
  padding-top: 0.26751rem;
  padding-top: 0.375rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.help-block:empty {
  display: none;
}

.help-block-error {
  font-weight: 700;
}

.select2-container--krajee .select2-selection {
  border-radius: 1rem !important;
  border: 0.125rem solid transparent !important;
  box-shadow: none !important;
  padding: 0.375rem 1rem !important;
  line-height: 1.25 !important;
  font-size: 1rem !important;
}
.select2-container--krajee .select2-selection:focus {
  box-shadow: 0 !important;
}
.select2-container--krajee .select2-selection.select2-selection--multiple {
  min-height: 3rem !important;
  padding: 0.375rem 0.375rem 0.25rem !important;
}
.select2-container--krajee .select2-selection.select2-selection--single {
  height: 3rem !important;
}
.select2-container--krajee .select2-selection.select2-selection--single .select2-selection__rendered {
  padding: 0.375rem 0 0 !important;
}
.select2-container--krajee .select2-selection.selection2-selection-single {
  background: #fff !important;
  border-color: #fff !important;
}
.select2-container--krajee .select2-selection .select2-selection__arrow {
  top: 0 !important;
  right: 0.5rem !important;
  height: 3rem !important;
  border-left: transparent !important;
  background-position: left center !important;
  background-repeat: no-repeat !important;
  background-size: 1rem 1rem !important;
  width: 1.5rem !important;
}
.select2-container--krajee .select2-selection .select2-selection__arrow b {
  display: none;
}
.select2-container--krajee .select2-dropdown {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15) !important;
  border: 0 !important;
}
.select2-container--krajee .select2-results__option {
  background: #fff !important;
  padding: 0.75rem 1rem !important;
  line-height: 1.25 !important;
}
.select2-container--krajee .select2-results__option[aria-selected=true] {
  color: #fff !important;
}
.select2-container--krajee .select2-search--dropdown {
  padding: 0 !important;
}
.select2-container--krajee .select2-search__field {
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0.25rem 1rem !important;
}
.select2-container--krajee .select2-selection__choice {
  border: 0 !important;
  background: #fff !important;
  height: 2rem !important;
  line-height: 2rem !important;
  margin: 0 0.125rem 0.125rem 0 !important;
  padding: 0 0.75rem !important;
  font-size: 0.875rem !important;
  border-radius: 0.75rem !important;
}
.select2-container--krajee .s2-togall-select,
.select2-container--krajee .s2-togall-unselect {
  padding: 0 1rem !important;
  font-size: 0.75rem !important;
}
.select2-container--krajee .s2-togall-select:hover, .select2-container--krajee .s2-togall-select:focus,
.select2-container--krajee .s2-togall-unselect:hover,
.select2-container--krajee .s2-togall-unselect:focus {
  text-decoration: none !important;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.in {
  opacity: 0.9;
}
.tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.tooltip-right, .tooltip.bs-tether-element-attached-left {
  padding: 0 5px;
  margin-left: 3px;
}
.tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  top: 50%;
  left: 0;
  margin-top: -5px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.tooltip-bottom, .tooltip.bs-tether-element-attached-top {
  padding: 5px 0;
  margin-top: 3px;
}
.tooltip.tooltip-bottom .tooltip-inner::before, .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  top: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.tooltip-left, .tooltip.bs-tether-element-attached-right {
  padding: 0 5px;
  margin-left: -3px;
}
.tooltip.tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  top: 50%;
  right: 0;
  margin-top: -5px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 1rem;
}
.tooltip-inner::before {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip {
  z-index: 130;
  pointer-events: none;
}
.touch .tooltip {
  display: none !important;
}

.tooltip-inner {
  border-radius: 1.25rem;
  margin: -1.5rem 0 0 -2rem;
  height: 3rem;
  width: 4rem;
  width: auto;
  margin-left: 0;
  padding: 0.5rem 1.25rem;
  max-width: 18rem;
  text-align: left;
  height: auto;
  margin: 0;
  line-height: 1rem;
  font-size: 0.75rem;
  border-radius: 1rem;
}
.tooltip-inner:before, .tooltip-inner:after {
  display: none !important;
}

.tooltip-arrow:before, .tooltip-arrow:after {
  display: none !important;
}

.tooltip-close {
  position: absolute;
  top: 0.25rem;
  right: 0;
  border: 0;
  background: transparent;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
}

.logo {
  width: 10.44444446rem;
  height: 2rem;
}
@media (min-width: 576px) {
  .logo {
    width: 11.75rem;
    height: 2.25rem;
  }
}

.logo {
  top: 2rem;
  left: 2rem;
}
@media (min-width: 576px) {
  .logo {
    left: 3rem;
  }
}

.logo {
  display: block;
  overflow: hidden;
  text-indent: -999rem;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 920;
}

.signature {
  width: 8.8125rem;
  height: 1.6875rem;
  pointer-events: none;
  background-size: contain;
}
@media (min-width: 992px) {
  .signature {
    width: 10.44444446rem;
    height: 2rem;
  }
}

.navigation {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 700;
}

.navigation-toggle {
  border: 0;
  border-radius: 1.25rem;
  display: block;
  height: 3rem;
  outline: 0;
  padding-top: 0.375rem;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  top: 1rem;
  width: 4rem;
  z-index: 910;
  opacity: 0.99;
}
.navigation-toggle span, .navigation-toggle:before, .navigation-toggle:after {
  border-radius: 0.05rem;
  content: "";
  display: block;
  height: 0.125rem;
  margin: 0 auto 0.25rem;
  width: 1.5rem;
}
.state-navigation .navigation-toggle span {
  opacity: 0;
}
.state-navigation .navigation-toggle:before {
  position: relative;
  top: 0.375rem;
  transform: rotate(-45deg);
}
.state-navigation .navigation-toggle:after {
  position: relative;
  top: -0.375rem;
  transform: rotate(45deg);
}

/*.navigation-menu {
    list-style-type: none;
	display: block;

    .navigation-link {
        @include baseline(1.125,2,1);
    }
}

.navigation-link {
    display: block;
    height: 3rem;
    line-height: 3rem;
    padding: 0 spacing(3);
}*/
@media (min-width: 992px) {
  .navigation-toggle {
    display: none;
  }
}
@keyframes hint-scroll {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
    opacity: 0.4;
  }
  40% {
    transform: translateY(-2rem);
    opacity: 0.8;
  }
  60% {
    transform: translateY(-1rem);
    opacity: 0.6;
  }
}
[class^=hint-]:before, [class*=" hint-"]:before {
  position: absolute;
  display: block;
  content: "";
  height: 1.5rem;
  width: 0.75rem;
  z-index: 400;
  background-size: 0.75rem 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
}

.hint-scroll:before {
  background-image: url("../images/hint-scroll.png");
  bottom: 2rem;
  left: 50%;
  animation: hint-scroll 2s infinite;
}
@media (min-width: 768px) {
  .hint-scroll:before {
    bottom: 8rem;
  }
}

.feedback .pit.pit-one {
  left: 80%;
  top: 25%;
}
.feedback .pit.pit-two {
  left: 75%;
  top: 75%;
}
.feedback .pit.pit-three {
  left: -10%;
  top: 50%;
}
.feedback .lead {
  text-align: center;
}
.feedback .pit {
  display: block;
}
.feedback .container-fluid {
  position: relative;
  z-index: 1;
}

.dashboard {
  overflow: hidden;
  min-height: 100%;
}

.dashboard-cover {
  display: none;
  height: 110%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: -10%;
  width: 100%;
  z-index: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.dashboard-cover-item {
  display: none;
  animation: swoosh 6s 1.5s infinite;
  height: 40%;
  max-width: 300px;
  position: absolute;
  transform: translate3d(-110%, 0, 0);
  background-repeat: no-repeat;
}
.dashboard-cover-item:after {
  display: block;
  width: 100vw;
  height: 100px;
  content: " ";
  position: relative;
  top: -100%;
}
.dashboard-cover-item.--first {
  top: 12%;
  background-image: url("/images/brands/randstad/randstad-arrow--red.svg");
}
.dashboard-cover-item.--middle {
  animation-delay: 1.6s;
  left: -150px;
  top: 39%;
  background-image: url("/images/brands/randstad/randstad-arrow--yellow.svg");
}
.dashboard-cover-item.--last {
  animation-delay: 1.7s;
  left: -300px;
  top: 66%;
  background-image: url("/images/brands/randstad/randstad-arrow--blue.svg");
}

.dashboard-item {
  background: #fff;
  width: 100%;
  margin: 0;
  position: relative;
  min-height: 1rem;
  padding-bottom: 10rem;
}
.dashboard-item::after {
  content: "";
  display: table;
  clear: both;
}
.dashboard-item .dashboard-item-header {
  padding: 0 2rem 3rem;
}
@media (min-width: 576px) {
  .dashboard-item .dashboard-item-header .col-xs-12:first-child {
    padding-top: 0.5rem;
  }
}
@media (min-width: 768px) {
  .dashboard-item {
    margin-bottom: 0;
  }
  .dashboard-item .dashboard-item-header {
    padding: 0 3rem 3rem;
  }
  .col-md-4 .dashboard-item {
    padding-bottom: calc(150% + 1.5rem);
  }
}

.dashboard-item-heading {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 1.38749rem;
  padding-top: 0.61251rem;
  margin: 0;
}

.dashboard-intro {
  position: relative;
}
.dashboard-intro h1 {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 1.38749rem;
  padding-top: 0.61251rem;
}
@media (min-width: 992px) {
  .dashboard-intro h1 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 1.29249rem;
    padding-top: 0.70751rem;
  }
}
.dashboard-intro p {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: -0.56501rem;
  padding-top: 0.56501rem;
}
.dashboard-intro p + p {
  padding-top: 1rem;
}
.dashboard-intro:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  height: 4rem;
  width: 100%;
}
@media (min-width: 768px) {
  .dashboard-intro {
    padding-right: 50%;
  }
  .dashboard-intro:before {
    width: 20%;
    height: auto;
    bottom: 0;
  }
}

.dashboard-intro-link {
  display: block;
  padding: 2rem 0 1rem;
}
.dashboard-intro-link .dashboard-intro-link-title {
  position: relative;
}
.dashboard-intro-link .icon-report--arrow {
  font-size: 1.75rem;
  position: absolute;
  bottom: -0.25rem;
  right: -2.5rem;
}

.dashboard-intro-video {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .dashboard-intro-video {
    position: absolute;
    left: calc(50% + 3rem);
    right: 3rem;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
  }
}

.dashboard-intro-video-play {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  line-height: 2rem;
  margin-top: -2rem;
}
.dashboard-intro-video-play .icon-report--play {
  font-size: 3rem;
}

.dashboard-intro-video-action {
  display: block;
  padding-top: 0.5rem;
}

.dashboard-intro-video-link {
  display: block;
  width: 100%;
  padding-bottom: 56%;
  position: relative;
}

.dashboard-qa {
  padding-top: 0;
}
.dashboard-qa::after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  .dashboard-qa-item {
    float: left;
    width: 50%;
    padding-left: 1rem;
  }
  .dashboard-qa-item:nth-child(odd) {
    clear: left;
    padding-left: 0;
    padding-right: 1rem;
  }
}

.dashboard-qa-question {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0.43499rem;
  padding-top: 0.56501rem;
}

.dashboard-qa-answer {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: -0.56501rem;
  padding-top: 0.56501rem;
}

.dashboard-qa-heading {
  text-align: center;
  position: relative;
  margin: 0 0 2rem;
}
.dashboard-qa-heading:before {
  content: "";
  display: block;
  position: absolute;
  height: 0.125rem;
  left: 2rem;
  right: 2rem;
  top: 50%;
  margin-top: 0.5rem;
}
@media (min-width: 576px) {
  .dashboard-qa-heading:before {
    left: 3rem;
    right: 3rem;
  }
}
.dashboard-qa-heading-title {
  display: inline-block;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: -0.53501rem;
  padding-top: 0.53501rem;
}
@media (min-width: 576px) {
  .dashboard-qa-heading-title {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: -0.38001rem;
    padding-top: 0.38001rem;
  }
}

.search {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  height: 0;
  transition: margin 150ms ease, opacity 150ms ease, top 225ms ease, transform 150ms ease;
}
.search .search-profile {
  top: 0;
  left: 0;
  right: auto;
  width: 4rem;
  text-align: left;
}
.search .search-query {
  margin-left: 4.5rem;
  margin-right: 0;
}
.search.search--profile .search-profile {
  width: 12rem;
}
.search.search--profile .search-query {
  margin-left: 12.5rem;
}
@media (min-width: 768px) {
  .search {
    left: 6rem;
    right: 12rem;
  }
}
@media (min-width: 992px) {
  .search {
    left: 8rem;
    right: 14rem;
  }
}
@media (min-width: 1200px) {
  .search {
    left: 50%;
    right: auto;
    width: 50rem;
    margin-left: -25rem;
  }
}
.search .touch {
  opacity: 1;
}
.no-touch .search:hover .search-settings {
  opacity: 1;
}
.search .pane-close {
  display: block;
}
.search.search--profile .icon-container--profiles {
  left: 1.5rem;
}
@media (max-width: 767px) {
  .search {
    /* For mobile only */
    top: 4.5rem;
  }
  .search .search-profile {
    top: -3.5rem;
    left: 0;
    right: 0;
    width: auto;
    text-align: center;
  }
  .search .search-query {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 64em) and (min-height: 48em) {
  .search .search-pane-profiles,
  .search .search-pane-settings,
  .search .search-pane-locations {
    position: absolute;
    width: 100%;
    top: 3.5rem;
    left: 0;
    right: 0;
    height: calc(100vh - 15rem);
    bottom: auto;
    box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
    transform: translateY(50%);
    opacity: 0;
    pointer-events: none;
    display: block;
    transform: none;
  }
}

.search-pane-profiles {
  height: auto !important;
}
.search-pane-profiles .pane-content.grid-container {
  background-color: #fff;
}
.search-pane-profiles .pane-content.grid-container .pane-section.cabinet--rows {
  box-shadow: none !important;
  padding: 0 !important;
}

.search-profile,
.search-query {
  height: 3rem;
  border-radius: 1rem;
  transition: margin 150ms ease, opacity 150ms ease, top 225ms ease, transform 150ms ease;
  will-change: transform, opacity;
}

.search-profile {
  position: absolute;
  padding: 0.75rem 0 0 0;
  vertical-align: middle;
}
.search-profile:hover, .search-profile:focus, .search-profile:hover:focus {
  cursor: pointer;
}
@media (min-width: 768px) {
  .search-profile {
    height: 3rem;
    line-height: 3rem;
    padding: 0;
  }
}

.search-profile-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
  padding: 0 0.5rem 0 2.75rem;
  max-width: 100%;
  white-space: nowrap;
  text-align: left;
}
@media (min-width: 768px) {
  .search-profile-title {
    padding-right: 1rem;
  }
}

.search-query {
  position: relative;
}
.search-query > .form-group + .form-group {
  margin-top: 0;
}
.search-query .ui-autocomplete {
  top: 3.5rem !important;
  margin-left: -0.0625rem;
  transition: opacity 150ms ease;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 15rem; /*  240px */
}

.search-segment-count {
  border-radius: 0.4rem;
  padding: 0 0.4rem;
  margin: -0.5rem 0 0 -0.66666666rem;
  height: 1rem;
  line-height: 1rem;
  width: 1.33333334rem;
  min-width: 1.33333334rem;
  width: auto;
  margin-left: 0;
  position: absolute;
  background: #1E025F;
  top: 50%;
  left: 1rem;
  color: #fff;
  font-weight: 900;
  text-align: center;
  line-height: 1rem;
  font-size: 0.75rem;
}

.search-segment-label {
  display: none;
}
@media (min-width: 768px) {
  .search-segment-label {
    display: block;
  }
}

.search-segments {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
  height: 3rem;
  overflow: hidden;
  width: 13rem;
  border-right: 0.0625rem solid #eceeef;
}
.search-segments:after {
  content: "";
  display: block;
  height: 3rem;
  position: absolute;
  top: 0;
  right: 0.5rem;
  width: 2rem;
  pointer-events: none;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  z-index: 1;
}
.no-touch .search-segments:hover {
  cursor: pointer;
}
.search-segments .search-token {
  margin: 0;
}

.search-segments-add {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  z-index: 2;
}

.search-token {
  display: block;
  float: left;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}
@media (min-width: 768px) {
  .search-token {
    font-size: 0.875rem;
  }
}

.search-token-label {
  display: block;
  color: #fff;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  margin: 0;
  font-weight: 700;
  border-radius: 0.75rem;
  letter-spacing: -0.01rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  background: #818a91;
}
.search-token-label[data-search-settings-open] {
  cursor: pointer;
}
.search-token--primary .search-token-label {
  background: #F85C6A;
}

.search-segments-placeholder .search-token-label {
  background: #fff;
  color: #818a91;
  font-size: 1rem;
  font-weight: 400;
  max-width: 10rem;
}
.search-token + .search-segments-placeholder {
  display: none !important;
}

.search-keys {
  position: absolute;
  left: 0;
  top: 0;
  right: 10rem;
  height: 3rem;
}
.search-keys .search-summary {
  left: 0;
  margin-left: -0.5rem;
}

.search-input {
  border: 0;
  display: block;
  height: 3rem;
  line-height: 3rem;
  padding: 0 0 0 1rem;
  float: left;
  width: 100%;
  border-radius: 1.5rem;
}
.field-searchmodel-userquery .search-input {
  width: 100%;
}
.search-input::placeholder {
  color: #b7bdc1;
  opacity: 1;
}
.search-input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.search-input:focus {
  outline: none;
  color: #373a3c;
}
@media (min-width: 576px) {
  .search-input {
    padding: 0 0 0 1.5rem;
  }
}

/*.search-input-measure {
    white-space: pre;
    display: none;
    padding: 0 0 0 1rem;
    float: left;

    @include media-breakpoint-up(sm) {
        padding: 0 0 0 1.5rem;
    }
}

.search-input-spec {
    display: block;
    height: $search-height;
    line-height: $search-height;
    position: relative;
    color: $gray-lighter;
    overflow: hidden;

    &:after {
        top: 0;
        right: 0;
        content: '';
        position: absolute;
        width: 1.5rem;
        height: $search-height;
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        pointer-events: none;
    }

    .no-touch & {
        &:hover {
            .search-input-spec-placeholder {
                color: $gray-light;
            }
        }
    }
}

.search-input-spec-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    line-height: $search-height;
    white-space: nowrap;
    transition: color 150ms ease;
    padding-left: .5rem;
}*/
.search-settings-tokens,
.search-settings-token {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.search-settings-token {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: -0.44001rem;
  padding-top: 0.44001rem;
}
.search-settings-token.search-settings-token-void {
  font-weight: 300;
}
.search-settings-token + .search-settings-token {
  margin-top: 0.5rem;
}

.search-settings-edit {
  display: block;
  margin-bottom: 2rem;
}
.search-settings-edit .form-heading + .search-settings-tokens {
  margin-top: -0.5rem;
}

.search-settings-label {
  font-size: 0.75rem;
  line-height: 2rem;
  margin-bottom: -0.76751rem;
  padding-top: 0.76751rem;
  display: block;
  clear: both;
  width: 100%;
}

.search-settings,
.search-location,
.search-experience {
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  top: 0;
  padding: 0 1rem;
  text-align: center;
}
.no-touch .search-settings:hover,
.no-touch .search-location:hover,
.no-touch .search-experience:hover {
  cursor: pointer;
}

.search-settings,
.search-location {
  padding: 0;
}

.search-experience {
  right: 10rem;
  width: 2rem;
  padding-left: 0.5rem;
  padding-right: 1.75rem;
  border-width: 0 0.0625rem 0 0;
  border-color: transparent;
  border-style: solid;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-size: 0.75rem 0.75rem;
  background-position: top 1.1825rem right 0.6125rem;
  background-repeat: no-repeat;
  font-size: 0.75rem;
  text-align: right;
  overflow: hidden;
  direction: rtl;
  /*&select:required:invalid {
    color: $gray-light;
  }*/
}
.search-experience:focus {
  outline: none;
}
.search-experience option[value=""][disabled] {
  display: none;
}
.search-experience option {
  direction: ltr;
}
@media (min-width: 576px) {
  .search-experience {
    text-indent: 0;
    width: auto;
  }
}

.search-settings {
  right: 7rem;
}
.search-settings .btn {
  position: relative;
  right: -0.5rem;
  top: 0.875rem;
  display: none;
}

.search-location {
  right: 4.3rem;
  border-left: 0.0625rem solid transparent;
}

@media (min-width: 768px) {
  .no-touch .search-keys:hover .search-summary, .no-touch .search-keys:focus .search-summary,
  .no-touch .search-profile:hover .search-summary,
  .no-touch .search-profile:focus .search-summary,
  .no-touch .search-settings:hover .search-summary,
  .no-touch .search-settings:focus .search-summary,
  .no-touch .search-location:hover .search-summary,
  .no-touch .search-location:focus .search-summary {
    display: block;
  }
}

.search-profile .icon-container,
.search-settings .icon-container,
.search-location .icon-container,
.search-submit .icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  margin: -0.625rem 0 0 -0.625rem;
}
.search-profile .icon-container svg,
.search-settings .icon-container svg,
.search-location .icon-container svg,
.search-submit .icon-container svg {
  stroke-width: 0;
}

.search-summary {
  display: none;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: -0.35376rem;
  padding-top: 0.35376rem;
  position: absolute;
  top: 3.75rem;
  width: 17rem;
  margin-left: -8.5rem;
  left: 50%;
  background: #fff;
  padding: 0.75rem 2rem;
  border-radius: 1.5rem;
  text-indent: 0;
  text-align: left;
  word-wrap: break-word;
}
.search-summary .search-summary-keys,
.search-summary .search-summary-place {
  font-weight: 400;
}
.search--profiles .search-summary, .search--settings .search-summary, .search--locations .search-summary {
  display: none !important;
}

.search-clear.action,
.search-submit {
  display: block;
  height: 3rem;
  width: 3rem;
  position: absolute;
  right: 3rem;
  top: 0;
  overflow: hidden;
  border: 0;
  transition: opacity 150ms ease;
}

.search-clear.action {
  right: 0;
  opacity: 0;
  margin: 0;
  width: 3rem;
}
.search-clear:before {
  display: none;
}
.search-clear.search-clear--show {
  opacity: 1;
}

.search-submit {
  width: 4rem;
  right: 0;
  border-radius: 0 1rem 1rem 0;
  font-size: 1.75rem;
  padding: 0;
  transition: background-color 150ms ease;
}
.search-submit .search-submit-loading {
  display: none;
}
.search-submit .search-submit-loading::after {
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left-width: 0.125rem;
  border-left-style: solid;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 1.25rem;
  margin: -0.625rem 0 0 -0.625rem;
  width: 1.25rem;
}
.search-submit .icon-container {
  opacity: 1;
  transition: opacity 150ms ease;
}
.search-submit:hover .icon-container, .search-submit:focus .icon-container, .search-submit:hover:focus .icon-container {
  opacity: 0.8;
}
html:not(.safari) .search-submit.search-submit--loading .icon-container,
html:not(.safari) .search-submit.search-submit--loading .icon--search {
  display: none;
}
html:not(.safari) .search-submit.search-submit--loading .search-submit-loading {
  display: inline;
}

.search-pane-profiles,
.search-pane-settings,
.search-pane-locations {
  transform: translateZ(0);
}
.search-pane-profiles .pane-footer,
.search-pane-settings .pane-footer,
.search-pane-locations .pane-footer {
  padding-top: 1rem;
  text-align: right;
  line-height: 3rem;
}

.search-pane-profiles > .pane-content .pane-section:first-child {
  padding-top: 0;
}
.search-pane-profiles > .pane-content .search-heading-saved {
  margin-top: 0 !important;
}

.search-pane-settings .tab-pane {
  padding-bottom: 6rem;
}
.search-pane-settings .tab-pane > .pane-section:first-child {
  padding-top: 2rem;
}
.search-pane-settings .form .form-group {
  margin-bottom: 1.5rem;
}
.search-pane-settings .nav-tabs {
  margin: 0 -1.5rem;
  text-align: center;
}
.search-pane-settings .pane-footer {
  background: #fff;
}
.search-pane-settings .btn-block {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 64em) and (min-height: 48em) {
  .search-pane-settings.pane > .pane-header {
    display: none;
  }
  .search-pane-settings.pane > .pane-header + .pane-tabs {
    top: 1rem;
  }
  .search-pane-settings.pane > .pane-header + .pane-tabs .nav-link {
    padding-top: 0.5rem;
  }
  .search-pane-settings.pane > .pane-header + .pane-tabs + .pane-content {
    top: 5rem;
    height: auto;
  }
}

.search-pane-locations .pane-content {
  overflow-y: hidden;
}
.search-pane-locations .pane-indicator--loading:after {
  margin-top: 4rem;
}
.search-pane-locations .pane-map {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto !important;
  top: 6rem;
}
.search-pane-locations .pane-filter-input {
  padding-left: 2rem;
}
.search-pane-locations .form-group.field-searchmodel-polygon {
  display: none;
}
@media (min-width: 768px) {
  .search-pane-locations .pane-filter-input {
    padding-left: 4rem;
  }
}

.pane-locations-modes {
  position: absolute;
  top: 0;
  left: 2rem;
  height: 6rem;
  width: 8rem;
  display: flex;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .pane-locations-modes {
    left: 4rem;
  }
}

.pane-locations-mode {
  font-size: 0.75rem;
  line-height: 1.5rem;
  display: block;
  flex: 0 0 auto;
  max-width: calc(50% - 0.75rem);
  height: 6rem;
  margin-right: 0.75rem;
  border-bottom: 0.1875rem solid transparent;
}
.pane-locations-mode .icon {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.pane-locations-mode span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
}
.pane-locations-mode + .pane-locations-mode {
  margin-right: 0;
  margin-left: 0.75rem;
}

.pane-locations-slider {
  padding: 2.5rem 2rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 9rem;
  height: 6rem;
}
.pane-locations-slider .form-group,
.pane-locations-slider .help-block {
  margin: 0;
}
@media (min-width: 576px) {
  .pane-locations-slider {
    left: 10rem;
  }
}
@media (min-width: 768px) {
  .pane-locations-slider {
    left: 14rem;
  }
}
@media (min-width: 992px) {
  .pane-locations-slider {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.search--profiles .ui-autocomplete,
.search--settings .ui-autocomplete,
.search--locations .ui-autocomplete {
  opacity: 0;
}
@media (max-width: 991px) {
  .search--profiles .header,
  .search--profiles .logo,
  .search--profiles .navigation,
  .search--profiles .navigation-toggle,
  .search--settings .header,
  .search--settings .logo,
  .search--settings .navigation,
  .search--settings .navigation-toggle,
  .search--locations .header,
  .search--locations .logo,
  .search--locations .navigation,
  .search--locations .navigation-toggle {
    display: none;
  }
}
@media screen and (max-width: 63.9375em) and (max-height: 47.9375em) {
  .search--profiles .content,
  .search--settings .content,
  .search--locations .content {
    overflow: hidden;
  }
}

.search--profiles .search-pane-profiles {
  display: block;
}
@media (min-width: 992px) {
  .search--profiles .search-pane-profiles {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
  }
}

.search--locations .search-pane-locations {
  display: block;
}
@media (min-width: 992px) {
  .search--locations .search-pane-locations {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
  }
}
.safari.tablet .search--locations .search-pane-locations {
  animation: repaint 100ms;
}

.search--settings .search-pane-settings {
  display: block;
}
@media (min-width: 992px) {
  .search--settings .search-pane-settings {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
  }
}

.search-profile--clear {
  display: inline-block;
  width: 100%;
}
.search-profile--clear .icon-container {
  left: auto !important;
  right: 0.5rem !important;
  transform: scale(0.7);
  background-color: #fff;
}

.sheet {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background: #fff;
  text-align: center;
  transition-property: transform opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
  transform: translateY(100%);
}
.sheet.sheet-top {
  top: 0;
  transform: translateY(-100%);
}
.sheet.sheet-active {
  transform: translateY(0%);
}
@media (min-width: 768px) {
  .sheet {
    position: absolute;
    width: 15rem;
    left: auto;
    bottom: 4rem;
    right: 1rem;
    opacity: 0;
    transform: translateY(0%);
  }
  .sheet.sheet-active {
    opacity: 1;
  }
}

.sheet-menu,
.sheet-menu-item {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sheet-menu-item {
  line-height: 2rem;
}

.results {
  background: #fff;
}
.no-touch .results .actions {
  opacity: 0;
}
.no-touch .results:hover .actions {
  opacity: 1;
}

.results-filter {
  z-index: 2;
}
.results-filter.action {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  top: auto;
}

.results-profile {
  position: absolute;
  left: 6rem;
  right: 6rem;
  top: 1rem;
  text-align: center;
  z-index: auto;
}
.results-profile .results-profile-icon {
  left: auto;
  right: -2.25rem;
}
@media (min-width: 768px) {
  .results-profile .results-profile-icon {
    right: -3rem;
  }
}
.results-profile .results-profile-menu {
  left: 50%;
  margin-left: -10rem;
  width: 20rem;
}
@media (min-width: 768px) {
  .results-profile .results-profile-menu {
    margin-left: -15rem;
    width: 30rem;
  }
}

.results-modes {
  height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 3;
  transition: background 150ms ease, opacity 150ms ease;
  display: block;
  white-space: nowrap;
  background: transparent;
  text-align: center;
}
@media (min-width: 992px) {
  .results-modes {
    height: 5rem;
    padding-top: 1rem;
    left: 12rem;
    right: 12rem;
  }
  .results-modes + .pane-content .results-meta {
    background: #fff;
    margin-top: -4rem;
    height: 4rem;
  }
}
@media (min-width: 1200px) {
  .results-modes {
    left: 14rem;
    right: 14rem;
  }
}
.results-modes .results-modes-toggle {
  display: inline-block;
  border: 0;
  background: transparent;
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
}
@media (min-width: 992px) {
  .results-modes .results-modes-toggle {
    width: 36rem;
  }
  .results-modes .results-modes-toggle[aria-expanded=true] .results-modes-toggle-category {
    display: block;
    float: left;
    width: 12rem;
    padding: 0 0 0 2rem;
  }
}
@media (min-width: 1200px) {
  .results-modes .results-modes-toggle {
    width: 48rem;
  }
  .results-modes .results-modes-toggle[aria-expanded=true] .results-modes-toggle-category {
    width: 16rem;
  }
}
.results-modes .results-modes-dropdown {
  width: 100%;
}
.results-modes .results-modes-toggle-category {
  display: none;
  position: relative;
  padding: 0 3rem;
  height: 4rem;
  line-height: 4rem;
  text-align: left;
}
.results-modes .results-modes-toggle-category .results-modes-toggle-label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.results-modes .results-modes-toggle-category .icon--select {
  display: inline-block;
  position: relative;
  top: -1.5rem;
  left: auto;
  right: auto;
  margin: 0;
  font-size: 1rem;
  line-height: 4rem;
  height: 4rem;
}
.results-modes .results-modes-toggle-category .icon--select:before {
  left: 0;
}
.ios.chrome .results-modes .results-modes-toggle-category .icon--select, .ios.safari .results-modes .results-modes-toggle-category .icon--select, .desktop.safari .results-modes .results-modes-toggle-category .icon--select {
  top: 0;
}
.chrome.desktop .results-modes .results-modes-toggle-category .icon--select:before {
  top: 0;
}
@media (min-width: 992px) {
  .results-modes .results-modes-toggle-category .icon--select {
    top: -1.25rem;
  }
}
.results-modes .results-modes-toggle-category.active {
  display: inline-block;
}
@media (min-width: 992px) {
  .results-modes .results-modes-toggle-category {
    display: inline-block;
    width: auto;
    padding: 0 1rem;
    transition: width 150ms ease;
    line-height: 3.5rem;
  }
  .results-modes .results-modes-toggle-category .results-modes-toggle-label {
    max-width: 10rem;
  }
}
@media (min-width: 1200px) {
  .results-modes .results-modes-toggle-category {
    font-size: 1.125rem;
  }
  .results-modes .results-modes-toggle-category .results-modes-toggle-label {
    max-width: 12rem;
  }
}
.results-modes .results-modes-sections::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 992px) {
  .results-modes .results-modes-sections {
    margin: 0 auto;
    width: 36rem;
    position: relative;
  }
  .results-modes .results-modes-sections .results-modes-section {
    width: 12rem;
  }
}
@media (min-width: 1200px) {
  .results-modes .results-modes-sections {
    width: 48rem;
  }
  .results-modes .results-modes-sections .results-modes-section {
    width: 16rem;
  }
}
.results-modes .results-modes-section {
  float: left;
  width: 100%;
  margin: 0 0 2rem;
  padding-top: 1rem;
}
@media (min-width: 768px) {
  .results-modes .results-modes-section {
    width: 33.3333%;
  }
}
.results-modes .results-modes-section-title {
  font-size: 0.675rem;
  line-height: 1.5rem;
  margin-bottom: -0.54076rem;
  padding-top: 0.54076rem;
  font-weight: 700;
  display: block;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 992px) {
  .results-modes .results-modes-section-title {
    display: none;
  }
}
.results-modes .results-modes-categories {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.results-modes .results-modes-category-link {
  display: block;
  padding: 0 2rem;
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: -0.69001rem;
  padding-top: 0.69001rem;
}
.results-modes .nav {
  text-align: center;
  display: inline-block;
}
.results-modes .nav .nav-item {
  float: none;
  display: inline-block;
}
.results-modes .nav .nav-link {
  position: relative;
}
.results-modes .nav .nav-link.nav-link--loading:before, .results-modes .nav .nav-link.nav-link--loading:after {
  content: "";
  display: block;
  height: 0.1875rem;
  width: 0;
  position: absolute;
  bottom: -0.1875rem;
  left: 0;
  animation: bar 1.5s linear infinite;
}
.results-modes .nav .nav-link.nav-link--loading:after {
  animation: bar 1.5s linear 750ms infinite;
}
@media (min-width: 992px) {
  .results-modes .nav .nav-link {
    font-size: 0.875rem;
    line-height: 2rem;
    margin-bottom: -0.72876rem;
    padding-top: 0.72876rem;
  }
}

.results-meta {
  height: 3rem;
  position: relative;
  padding: 0 2rem;
  z-index: 1;
}
.view-results--report .results-meta {
  margin-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .view-results--report .results-meta {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) {
  .results-meta {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.results-meta .results-meta-dropdown {
  position: absolute;
  top: 0;
}
.results-meta .results-meta-dropdown > .btn {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
}
.results-meta .results-meta-dropdown--right {
  right: 2rem;
  left: auto;
  margin-left: 0;
}
@media (min-width: 1200px) {
  .results-meta .results-meta-dropdown--right {
    right: 3rem;
  }
}
.no-touch .results-meta:hover .results-meta-settings {
  opacity: 1;
}
.results-meta + .report-chart {
  z-index: 0;
}

.results-meta-dropdown-toggle {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}
.results-meta-dropdown-toggle .icon--select,
.results-meta-dropdown-toggle .icon--sort {
  left: auto;
  right: -2rem;
  margin-top: -0.25rem;
}
.results-meta-dropdown-toggle .icon--sort {
  right: -1.25rem;
}

.results-meta-label {
  font-size: 0.75rem;
  line-height: 2rem;
  margin-bottom: 0.23249rem;
  padding-top: 0.76751rem;
  display: inline-block;
  position: relative;
}
@media (min-width: 992px) {
  .results-meta-label {
    font-size: 0.875rem;
    line-height: 2rem;
    margin-bottom: 0.27124rem;
    padding-top: 0.72876rem;
  }
}

.results-meta-settings {
  opacity: 0.24;
  transition: opacity 150ms ease;
  display: none;
}
@media (min-width: 768px) {
  .results-meta-settings {
    display: inline-block;
  }
}

.results-meta-setting {
  display: inline;
}

.list,
.list-item {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.pane-section--inset .list {
  margin: 0 -2rem;
}

.list-item.list-item--active {
  border-left: 0.1875rem solid transparent;
  z-index: 1;
}
.list-item.list-item--void {
  text-align: center;
  padding: 2rem 1rem;
}
.list-item.list-item--cart {
  position: absolute;
  z-index: 100;
  opacity: 0.4;
  width: 60%;
  transform: scale(0.9);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}
.list-item.list-item--cart .list-action {
  display: none;
}
.list-item.list-item--cart-collapse {
  opacity: 0.1;
  transform: scale(0.1);
}

/* Mimic DL */
.list--definition {
  display: flex;
  flex-flow: row wrap;
}
.list--definition .list-term {
  flex-basis: 40%;
  padding-left: 2rem;
}
.list--definition .list-desc {
  text-align: right;
  flex-basis: 60%;
  flex-grow: 1;
  margin: 0;
  padding-right: 2rem;
}

.list-title,
.list-subtitle {
  display: block;
  margin: 0;
  padding: 0;
  clear: both;
  overflow: hidden;
}

.list-title {
  font-size: 1rem;
  line-height: 1.5rem;
}

.list-subtitle {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.list-title-wrap,
.list-subtitle-wrap {
  display: block;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  width: auto;
  white-space: nowrap;
  max-width: 60%;
  padding-right: 0.5rem;
}

.list-title-wrap {
  max-width: 100%;
}

.list-header,
.list-content,
.list-footer,
.list-link {
  display: block;
  position: relative;
  overflow: hidden;
}
.list-header:empty,
.list-content:empty,
.list-footer:empty,
.list-link:empty {
  display: none !important;
}

.list-content p {
  margin: 0;
}

button.list-link, button.list-action {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}
button.list-link {
  width: 100%;
  text-align: left;
}

.list-header,
.list-content,
.list-footer,
.list-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.list-link {
  margin-left: -2rem;
  margin-right: -2rem;
}

.list-item--pad-v {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.list-item--action .list-action,
.list-item--action-wide .list-action {
  position: absolute;
  top: -0.125rem;
  right: 2rem;
  z-index: 1;
}
.list-item--action .list-action + .list-link,
.list-item--action-wide .list-action + .list-link {
  padding-right: 5rem !important;
}
.list-item--action .list-action.action--sm,
.list-item--action-wide .list-action.action--sm {
  right: 1.25rem;
}
.list-item--action .list-header .list-action,
.list-item--action-wide .list-header .list-action {
  top: 0.75rem;
}
.list-item--action .list-header .list-action.action,
.list-item--action-wide .list-header .list-action.action {
  top: 0.25rem;
}

.list-item--hover:hover, .list-item--hover:focus, .list-item--hover:hover:focus {
  cursor: pointer;
  z-index: 1;
}

.list-item--action-wide .list-action + .list-link {
  padding-right: 8rem !important;
}

.list-header {
  padding-top: 0.75rem;
}
.list-header .list-header-link {
  padding-top: 0.75rem;
  margin-top: -0.75rem;
}
.list-header.list-header--flex {
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
  overflow: hidden;
  min-width: 0;
}
.list-header.list-header--flex .list-titles {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
}
.list-header.list-header--flex .list-titles .list-title-wrapper-wrap {
  max-width: 100%;
  min-width: 0;
}
.list-header.list-header--flex .list-titles .list-title-wrapper-wrap .list-title-wrapper {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  overflow: hidden;
}
.list-header.list-header--flex .list-titles .list-title-wrapper-wrap .list-title-wrapper .list-title {
  flex-basis: min-content;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
  max-width: calc(100% - 3rem); /* 1 action + 1rem spacer */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-header.list-header--flex .list-titles .list-title-wrapper-wrap .list-title-wrapper .list-title-actions {
  flex-basis: min-content;
  flex-grow: 0;
  flex-shrink: 0;
}
.list-header.list-header--flex .list-titles .list-subtitle {
  flex-basis: min-content;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
}
.list-header.list-header--flex .list-actions {
  flex-basis: min-content;
  flex-grow: 0;
  flex-shrink: 1;
}
.list-header.list-header--flex .list-actions .list-action {
  position: relative;
  right: 0;
  top: 0;
}

.list-item > *:last-child {
  padding-bottom: 0.75rem;
}
.list-item > *:last-child .list-link {
  padding-bottom: 0.75rem;
  margin-bottom: -0.75rem;
}

.list-section {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

.list-value {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
}

.list-note {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
}

.list-status {
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.no-touch .list-item {
  /*&.list-item--action-hover-accent {
  	 See brand --->
  }*/
}
.no-touch .list-item.list-item--action-hover-show .list-action {
  opacity: 0;
  transition: opacity 150ms ease;
}
.no-touch .list-item.list-item--action-hover-show:hover .list-action, .no-touch .list-item.list-item--action-hover-show:focus .list-action, .no-touch .list-item.list-item--action-hover-show:hover:focus .list-action {
  opacity: 1;
}
@media (min-width: 768px) {
  .no-touch .list-item.list-item--action-toggle-hover .list-action.toggle .toggle-label {
    opacity: 0;
    transition: opacity 150ms ease;
  }
}
.no-touch .list-item.list-item--action-toggle-hover .list-action.toggle > input:checked + .toggle-label + .toggle-label {
  opacity: 1 !important;
}
.no-touch .list-item.list-item--action-toggle-hover:hover .list-action.toggle .toggle-label, .no-touch .list-item.list-item--action-toggle-hover:focus .list-action.toggle .toggle-label, .no-touch .list-item.list-item--action-toggle-hover:hover:focus .list-action.toggle .toggle-label {
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .pane-section--expand .list {
    margin: 0 -3rem;
  }
  .pane-section--expand .list .list-header,
  .pane-section--expand .list .list-content,
  .pane-section--expand .list .list-footer,
  .pane-section--expand .list .list-link {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pane-section--expand .list .list-link {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
@media (min-width: 768px) {
  .list-content p {
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .list--lg-enlarge-type .list-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0;
  }
  .list--lg-enlarge-type .list-subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0rem;
  }
}
.vacancy-list,
.vacancy {
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: all 300ms ease;
}

.vacancy-list {
  padding: 0 0 5rem;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.vacancy::after {
  content: "";
  display: table;
  clear: both;
}

.vacancy-count {
  font-size: 0.875rem;
  padding: 0 2rem;
}

.vacancy-count-total {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: -0.26751rem;
  padding-top: 0.26751rem;
}

.vacancy-sort {
  float: right;
}

.vacancy-sort-link {
  margin: 0;
  padding: 0;
  display: block;
  border: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: -0.26751rem;
  padding-top: 0.26751rem;
  background: transparent;
  padding-right: 1.25rem;
  position: relative;
}
.vacancy-sort-link .icon--sort {
  left: auto;
  right: 0rem;
  margin-top: -0.25rem;
  margin-left: 0;
}

.vacancy-link {
  width: 100%;
  display: block;
  padding: 0 2rem;
  box-shadow: 0 1rem 4rem -0.25rem rgba(51, 52, 80, 0);
  position: relative;
}
.vacancy-link::after {
  content: "";
  display: table;
  clear: both;
}
.vacancy-link:hover {
  z-index: 1;
}
.vacancy-link:hover .indicator-state-label {
  opacity: 1;
}
.vacancy-link:focus {
  background: #fff;
}
.vacancy-link:focus:hover .indicator-state-label {
  opacity: 0;
}

.vacancy-title h2 {
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  width: auto;
  max-width: 80%;
  position: relative;
}
.vacancy-title h2:before {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  margin-top: 0.375rem;
  height: 0.125rem;
  left: -0.25rem;
  right: -0.25rem;
}
.vacancy-title em {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: -0.69001rem;
  padding-top: 0.69001rem;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vacancy-title .indicator-state {
  padding-left: 0.5rem;
}
.vacancy-title .tag:empty {
  display: none;
}
.vacancy--closed .vacancy-title h2:before {
  display: block;
}

.vacancy-saved,
.vacancy-team {
  display: inline-block;
  margin: 0 0 0 1rem;
  width: 0.75rem;
  height: 0.75rem;
}
.vacancy-saved .icon-container,
.vacancy-team .icon-container {
  margin: 0;
}

.vacancy-team {
  margin-left: 0.75rem;
}

.vacancy .organisation {
  padding: 0.25rem 2rem 0 0;
}
.vacancy .organisation-name {
  line-height: 2rem;
  font-size: 1rem;
}
.vacancy .organisation-property .icon-container {
  width: 0.75rem;
  margin: -0.375rem 0 0 -0.375rem;
  margin: 0;
}

.vacancy-note {
  position: relative;
  display: inline-block;
  top: -0.25rem;
  font-size: 0.6125rem;
  padding-left: 0.125rem;
}

.vacancy-level {
  margin: 0;
}

.vacancy-meta,
.vacancy-meta-item {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.vacancy-meta {
  font-weight: 300;
  white-space: nowrap;
}
.vacancy-meta::after {
  content: "";
  display: table;
  clear: both;
}

.vacancy-meta-item {
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: 0.27124rem;
  padding-top: 0.72876rem;
  display: inline-block;
  padding-right: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 35%;
  text-overflow: ellipsis;
}
.vacancy-meta-item.vacancy-location {
  text-transform: lowercase;
}
.vacancy-meta-item.vacancy-location:first-letter {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .vacancy-link {
    padding-bottom: 1rem;
  }
  .vacancy-link .vacancy-heading {
    width: 60%;
  }
  .vacancy-link .vacancy-meta {
    position: absolute;
    width: 40%;
    top: 0rem;
    left: auto;
    right: 2rem;
  }
  .vacancy-link .vacancy-meta-item {
    font-size: 0.875rem;
    line-height: 2rem;
    margin-bottom: -0.72876rem;
    padding-top: 0.72876rem;
    float: left;
    display: block;
    max-width: 50%;
    width: 50%;
    text-align: right;
    padding-right: 0;
    padding-left: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .vacancy-link {
    padding-top: 0.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1.5rem;
  }
  .vacancy-link .vacancy-meta {
    right: 3rem;
    top: 0.5rem;
  }
}
@media (min-width: 992px) {
  .vacancy-title h2 em {
    font-size: 1.125rem;
  }
  .vacancy-heading {
    width: 58%;
  }
  .vacancy-note {
    font-size: 0.75rem;
  }
  .vacancy-count {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.close:hover, .close:focus, .close:hover:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  border: 0;
  background: transparent;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
}

/*
default
mute
primary
secondary
*/
.action {
  display: block;
  border: none;
  width: 3rem;
  height: 3rem;
  overflow: visible;
  transition: all 150ms ease;
  padding: 0;
  position: relative;
  background: transparent;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
}
.action:focus {
  outline: none;
}
.action::before {
  box-shadow: none !important;
  content: "";
  display: block;
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1.25rem;
  transition: all 150ms ease;
}
.action.action--navigation::before {
  display: none;
}
.action [class*=icon--] {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.75rem 0 0 -0.75rem;
}
.action [class*=icon--]:before {
  left: 0;
}
.action .icon--close,
.action .icon--favorite,
.action .icon--clear {
  font-size: 1.25rem;
}
.action .icon--favorite {
  margin-left: -0.675rem;
}
.action.disabled {
  opacity: 1;
}
.action .count {
  top: -0.25rem;
  position: absolute;
  right: -0.125rem;
}
.action.action--label {
  margin-bottom: 2rem;
}
.no-touch .action:hover > .tip,
.no-touch .action:hover > .action-label, .no-touch .action:focus > .tip,
.no-touch .action:focus > .action-label, .no-touch .action:hover:focus > .tip,
.no-touch .action:hover:focus > .action-label {
  display: block;
}

.action-count {
  /* Deprecated */
  top: -0.25rem;
  position: absolute;
  right: -0.125rem;
}

.action-label-wrap {
  display: block;
  position: absolute;
  top: 100%;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
}

.action--drop:before,
.action--mute:before,
.action--close:before {
  background: transparent;
  box-shadow: none;
}

.action--close [class*=icon--] {
  font-size: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}

.action--navigation {
  padding-top: 0.375rem;
  text-align: center;
}
.action--navigation .action--navigation-icon {
  z-index: 1;
  position: relative;
}
.action--navigation .action--navigation-icon div, .action--navigation .action--navigation-icon:before, .action--navigation .action--navigation-icon:after {
  border-radius: 0.2rem;
  content: "";
  display: block;
  height: 0.1875rem;
  margin: 0 auto 0.25rem;
  width: 1.5rem;
  transition: opacity 150ms ease, transform 150ms ease, background 150ms ease;
}
.pane--open-navigation .action--navigation .action--navigation-icon div {
  opacity: 0;
}
.pane--open-navigation .action--navigation .action--navigation-icon:before {
  position: relative;
  top: 0.375rem;
  transform: rotate(-45deg);
}
.pane--open-navigation .action--navigation .action--navigation-icon:after {
  position: relative;
  top: -0.45rem;
  transform: rotate(45deg);
}

.action--xs {
  width: 2rem;
  height: 1.5rem;
}
.action--xs:before {
  width: 1rem;
  height: 0.75rem;
  margin: -0.375rem 0 0 -0.5rem;
  border-radius: 0.3125rem;
}
.action--xs [class*=icon--] {
  font-size: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}

.action--sm {
  width: 3rem;
  height: 2.25rem;
}
.action--sm::before {
  width: 2rem;
  height: 1.5rem;
  margin: -0.75rem 0 0 -1rem;
  border-radius: 0.625rem;
}
.action--sm [class*=icon--] {
  font-size: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}

.actions {
  position: absolute;
  transition: opacity 150ms ease;
}
.actions .action,
.actions .dropdown,
.actions .form {
  float: left;
}
.actions .action + .action,
.actions .action + .dropdown,
.actions .action + .form,
.actions .dropdown + .action,
.actions .dropdown + .dropdown,
.actions .dropdown + .form,
.actions .form + .action,
.actions .form + .dropdown,
.actions .form + .form {
  margin-left: 0.5rem;
}

.actions-header {
  top: 1rem;
  left: 1rem;
  z-index: 2;
}
.actions-header.actions-header--right {
  left: auto;
  right: 1rem;
}

.actions-footer {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000000000;
}

.actions-footer--fixed {
  position: fixed !important;
  bottom: 0;
  right: 1rem;
}

.actions-footer--fixed-wide {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .action.action--shrink-md, .action.action--md-shrink {
    width: 3rem;
    height: 2.25rem;
  }
  .action.action--shrink-md::before, .action.action--md-shrink::before {
    width: 2rem;
    height: 1.5rem;
    margin: -0.75rem 0 0 -1rem;
    border-radius: 0.625rem;
  }
  .action.action--shrink-md [class*=icon--], .action.action--md-shrink [class*=icon--] {
    font-size: 1rem;
    margin: -0.5rem 0 0 -0.5rem;
  }
  .action.action--shrink-md .tip.tip--left,
  .action.action--shrink-md .action-label.action-label--left, .action.action--md-shrink .tip.tip--left,
  .action.action--md-shrink .action-label.action-label--left {
    right: 3.5rem;
  }
}
.tip,
.action-label {
  border-radius: 0.625rem;
  padding: 0 0.625rem;
  margin: -0.75rem 0 0 -1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 2rem;
  min-width: 2rem;
  width: auto;
  margin-left: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  display: none;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  left: calc(100% + 1rem);
  color: #fff;
  text-align: center;
  white-space: nowrap;
  text-indent: 0;
  padding: 0 1.25rem;
}
.tip:before,
.action-label:before {
  display: none;
}
.tip.tip--wrap,
.action-label.tip--wrap {
  white-space: normal;
  max-width: 10rem;
  height: auto;
  width: auto;
  min-width: 8rem;
  line-height: 1.25;
  padding: 0.5rem 1rem;
}
.tip.tip--left, .tip.action-label--left,
.action-label.tip--left,
.action-label.action-label--left {
  left: auto;
  right: calc(100% + 1rem);
}
.tip.tip--top, .tip.action-label--top,
.action-label.tip--top,
.action-label.action-label--top {
  left: 50%;
  top: -1.75rem;
  transform: translateX(-50%);
}
.tip.tip--bottom, .tip.action-label--bottom,
.action-label.tip--bottom,
.action-label.action-label--bottom {
  left: 50%;
  top: auto;
  bottom: -1.75rem;
  transform: translateX(-50%);
}
.tip.tip--bottom-right, .tip.action-label--bottom-right,
.action-label.tip--bottom-right,
.action-label.action-label--bottom-right {
  left: 0;
  right: auto;
  top: auto;
  bottom: -1.75rem;
}
.tip.tip--top-left, .tip.action-label--top-left,
.action-label.tip--top-left,
.action-label.action-label--top-left {
  right: 0;
  left: auto;
  top: auto;
  bottom: calc(100% + 0.5rem);
}

.filters {
  padding: 5rem 0 6rem;
}
.filters .filter--open .form-group {
  visibility: visible;
  max-height: 9999rem;
  opacity: 1;
  height: auto;
}
.filters .filter-label {
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: -0.72876rem;
  padding-top: 0.72876rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  display: block;
  width: 100%;
  text-align: left;
}
.filters .form-group {
  padding: 1rem 0 0;
  visibility: hidden;
  height: 0;
  max-height: 0;
  opacity: 0;
  transition: max-height 350ms ease, opacity 150ms ease;
}
.filters .form-group .form-group {
  display: none;
}
.filters .checkbox {
  padding: 0 2rem 0;
  margin: 0;
}
.filters .checkbox:last-child {
  padding-bottom: 1.5rem;
}
.filters .checkbox .checkbox-label {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: -0.69001rem;
  padding-top: 0.69001rem;
  display: block;
  width: 100%;
  padding-left: 0;
  position: relative;
}
.filters .checkbox .checkbox-label-text {
  padding-left: 2rem;
  display: inline-block;
  position: relative;
  text-overflow: ellipsis;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
}
.filters .checkbox .indicator {
  position: absolute;
  left: 0;
  top: 1.25rem;
  margin: 0;
}
.filters .checkbox .filter-count {
  font-size: 0.75rem;
  position: absolute;
  top: 0.375rem;
  right: -0.25rem;
  width: auto;
  text-align: right;
}
.filters .checkbox input[type=checkbox] {
  display: none;
}
.filters .checkbox input:checked ~ .checkbox-label-text {
  font-weight: 600;
}

/* ---------------------
	FILTER ITEMS
	-----------------*/
.filter-item,
.filter-add--hide {
  display: none;
}

.filter-item--added,
.filter-item--active {
  display: inline-block;
}

[data-filter-item-added=true] {
  display: none !important;
}

.focus {
  background: #fff;
}
.focus .pane-content {
  padding-bottom: 5rem;
}
@media (min-width: 992px) {
  .focus .pane-header {
    height: 8rem;
  }
}

.focus-title {
  font-size: 2rem;
  line-height: 2.25rem;
  margin-bottom: 0.49499rem;
  padding-top: 0.50501rem;
  padding-right: 3rem;
}

.focus-data {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.focus-data .focus-description,
.focus-data .focus-aside {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 100%;
  max-width: 100%;
  align-content: flex-start;
}

.focus-description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 1.43499rem;
  padding-top: 0.56501rem;
}
.focus-description p:empty {
  display: none;
}
.focus-description a {
  display: block;
}
.focus-description ul,
.focus-description ol {
  padding-left: 1.125rem;
  list-style: circle;
}
.focus-description ul p,
.focus-description ol p {
  margin-bottom: 0;
}
.focus-description ul + br,
.focus-description ol + br {
  display: none;
}
.focus-description h1 {
  font-size: 1.5rem;
  line-height: 2.125rem;
}
.focus-description h2, .focus-description h3, .focus-description h4, .focus-description h5, .focus-description h6 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.focus-description p + h1,
.focus-description p + h2,
.focus-description p + h3,
.focus-description p + h4,
.focus-description p + h5,
.focus-description p + h6 {
  margin-top: 0;
}

.focus-note {
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.77124rem;
  padding-top: 0.22876rem;
}

.focus-cta--scrolled {
  position: absolute;
  left: 999rem;
  opacity: 0;
  visibility: hidden;
  top: -3rem;
  max-width: calc(100% - 4rem);
  transition: opacity 150ms ease;
}
.focus-cta--scrolled.button--active {
  position: absolute;
}
.pane-v2-content--scrolled .focus-cta--scrolled {
  left: 2rem;
  visibility: visible;
  opacity: 1;
}

.focus-cta {
  opacity: 1;
  transition: opacity 150ms ease;
}
.pane-v2-content--scrolled .focus-cta {
  opacity: 0;
  pointer-events: none;
}

.focus-cta--scrolled {
  position: absolute;
  left: 999rem;
  opacity: 0;
  visibility: hidden;
  top: -3rem;
  max-width: calc(100% - 4rem);
  transition: opacity 150ms ease;
}
.pane-content--scrolled .focus-cta--scrolled {
  left: 2rem;
  visibility: visible;
  opacity: 1;
}

.focus-cta {
  opacity: 1;
  transition: opacity 150ms ease;
}
.pane-content--scrolled .focus-cta {
  opacity: 0;
  pointer-events: none;
}

.focus-original {
  padding-top: 2rem;
  display: block;
  width: 100%;
  overflow: hidden;
  cursor: default;
}
.focus-original.js-focus-original {
  cursor: -webkit-zoom-in;
}
.focus-original.js-focus-original .focus-original-image {
  opacity: 0.5;
  transition: opacity 150ms ease;
}
.focus-original.js-focus-original .focus-original-image:hover, .focus-original.js-focus-original .focus-original-image:focus, .focus-original.js-focus-original .focus-original-image:hover:focus {
  opacity: 1;
}

.focus-original-external {
  display: none;
}

.focus-original-image {
  width: 100%;
  max-width: 100%;
}

.focus-properties,
.focus-property {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.focus-properties {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.focus-property {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  padding: 0 1rem 0 0;
  margin-bottom: 1rem;
  white-space: nowrap;
  flex: 0 0 100%;
  max-width: 100%;
}
.focus-property::after {
  content: "";
  display: table;
  clear: both;
}

.focus-property-term,
.focus-property-description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: -0.56501rem;
  padding-top: 0.56501rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 100%;
  max-width: 100%;
}

.focus-property-term {
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: -0.72876rem;
  padding-top: 0.72876rem;
  font-weight: 500;
}

.focus-property-description.focus-property-description--short {
  max-width: 80%;
}

.focus--actions .action--accent .icon-container--redirect path {
  fill: #40BC8A;
  stroke: #40BC8A;
}
.focus--actions .action--accent .icon-container--redirect path:hover {
  fill: #40BC8A;
  stroke: #40BC8A;
}
.focus--actions .action--redirect .action-label-wrap {
  color: #40BC8A !important;
}

.focus-property-actions {
  width: 0px;
  height: 0px;
  display: inline-block;
  position: relative;
}
.focus-property-actions .btn {
  position: absolute;
  top: -1rem;
  right: -2rem;
}

.focus--actions .action--accent .icon-container--redirect path {
  fill: #40BC8A;
  stroke: #40BC8A;
}
.focus--actions .action--accent .icon-container--redirect path:hover {
  fill: #40BC8A;
  stroke: #40BC8A;
}
.focus--actions .action--redirect .action-label-wrap {
  color: #40BC8A !important;
}

.focus-location + .pane-section {
  margin-top: 0 !important;
}

.focus-map {
  height: 12rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}

.focus-cover {
  display: block;
  height: 8rem;
  position: relative;
}
.focus-cover + .focus-map {
  position: absolute;
  top: 8rem;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}
.focus-cover.focus-cover--actions {
  height: 12rem;
}

.focus-cover-title {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: -0.53501rem;
  padding-top: 0.53501rem;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
}

.focus-cover-actions {
  position: absolute;
  right: 2rem;
  bottom: -1rem;
}
.focus-cover-actions .btn,
.focus-cover-actions .button {
  margin: 0;
}

.focus-meta {
  background: #fff;
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  transition: all 100ms ease;
  overflow-x: hidden;
  overflow-y: auto;
}
.focus-meta .last {
  margin-bottom: 5rem;
}

.focus-meta-toggle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.5rem;
  width: 100%;
}
.focus-meta-toggle:before, .focus-meta-toggle:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 1.25rem;
  height: 0.215rem;
  width: 1rem;
  background: #818a91;
  margin-left: -0.875rem;
  border-radius: 0.09375rem;
  transition: margin 150ms ease, transform 150ms ease;
  transform: rotate(-20deg);
}
.focus-meta-toggle:after {
  margin-right: -0.875rem;
  margin-left: 0;
  transform: rotate(20deg);
}
.focus-meta-toggle:hover:before, .focus-meta-toggle:hover:after {
  background: #55595c;
}
.focus-meta-toggle:hover:before {
  transform: rotate(-20deg);
}
.focus-meta-toggle:hover:after {
  transform: rotate(20deg);
}
.--profession-meta .focus-meta-toggle:hover:before {
  transform: rotate(20deg);
}
.--profession-meta .focus-meta-toggle:hover:after {
  transform: rotate(-20deg);
}

.focus-meta-title {
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-bottom: 0.48249rem;
  padding-top: 0.51751rem;
}

.focus-meta-introduction {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.47374rem;
  padding-top: 0.52626rem;
}

.focus-meta-cite {
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin-bottom: 0.66999rem;
  padding-top: 0.33001rem;
}

.focus-meta-interview-title {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.46499rem;
  padding-top: 0.53501rem;
}

.focus-company {
  margin: 0;
}
.focus-company address {
  margin: 0;
}

.focus-company-address {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 0.30999rem;
  padding-top: 0.69001rem;
  padding: 1rem 0;
  position: relative;
}

.focus-company-google {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: -0.26751rem;
  padding-top: 0.26751rem;
  position: absolute;
  top: 1rem;
  right: 0;
  text-align: right;
}

.focus-company-city {
  text-transform: lowercase;
  display: block;
}
.focus-company-city:first-letter {
  text-transform: uppercase;
}

.focus-meta-stats {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.focus-meta-stats .card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.focus-meta-stats .card .card-content {
  height: 100%;
}

.focus-meta-stat-value {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.21499rem;
  padding-top: 0.53501rem;
}

.focus-meta-stat-title {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 0.09874rem;
  padding-top: 0.40126rem;
}

.focus-meta-stat-desc {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: -0.47876rem;
  padding-top: 0.47876rem;
}

.focus-contact-person,
.focus-contact-methods {
  float: left;
  width: 50%;
}
.focus-contact-person::after,
.focus-contact-methods::after {
  content: "";
  display: table;
  clear: both;
}

.focus-contact-function,
.focus-contact-name,
.focus-contact-telephone,
.focus-contact-email {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: -0.69001rem;
  padding-top: 0.69001rem;
  color: #fff;
  white-space: nowrap;
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
  width: 100%;
  padding-right: 1rem;
  text-align: left;
}
.focus-contact-function::after,
.focus-contact-name::after,
.focus-contact-telephone::after,
.focus-contact-email::after {
  content: "";
  display: table;
  clear: both;
}

.focus-contact-function,
.focus-contact-email {
  position: relative;
  top: -0.25rem;
}

.focus-contact-function {
  text-transform: lowercase;
}
.focus-contact-function:first-letter {
  text-transform: capitalize;
}

.focus-view {
  display: block;
  border-bottom: 0.125rem solid transparent;
}

.focus-original {
  max-width: 100%;
  width: 100%;
}

.focus-state {
  border-radius: 0;
  border: 0;
}
.focus-state .icon--add {
  font-size: 0.75rem;
}

.focus-save {
  margin-bottom: 1rem;
}
.organisation + .focus-save {
  margin-top: 1.5rem;
}

.focus-map-toggle {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.focus-state-form .pane-content {
  padding-bottom: 13rem;
}
.focus-state-form .pane-footer {
  padding-top: 1rem;
  height: 13rem;
  background: #fff;
}
.focus-state-form textarea {
  resize: none;
}
@media (min-width: 768px) {
  .focus-state-form .pane-content {
    padding-bottom: 14rem;
  }
  .focus-state-form .pane-footer {
    height: 14rem;
  }
}

@media (min-width: 768px) {
  .focus-modal .actions-header {
    right: 5.5rem;
  }
}
.focus-modal .modal-content {
  border: none;
}
@media (max-width: 991px) {
  .focus-modal .modal-dialog {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;
  }
  .focus-modal .modal-dialog .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .focus-modal .modal-dialog .modal-content .pane {
    height: 100vh;
  }
}

@media (min-width: 576px) {
  .focus-meta-stats .card {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .focus-data .focus-description {
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 2rem;
  }
  .focus-data .focus-aside {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .focus-property {
    padding: 0 1rem;
  }
  .focus-meta-stats .card {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .focus-meta-stats .card {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .focus-cover .focus-cover-title {
    left: 4rem;
  }
  .focus-cover .focus-cover-actions {
    right: 4rem;
  }
}
.pane {
  position: fixed;
  background: #fff;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  height: 100vh;
  z-index: 10000;
  display: none;
  width: 100vw;
  transition-property: transform top margin width;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
}
.pane > .pane-filter,
.pane > .pane-tabs,
.pane > [data-pjax-container] > .pane-filter,
.pane > [data-pjax-container] > .pane-tabs,
.pane > .pane-content-wrap > .pane-filter,
.pane > .pane-content-wrap > .pane-tabs {
  /* Case for panes without .pane-header */
  width: 100%;
  position: absolute;
  left: 0;
  top: 1rem;
  z-index: 2;
}
.pane > .pane-filter + .pane-content,
.pane > .pane-tabs + .pane-content,
.pane > [data-pjax-container] > .pane-filter + .pane-content,
.pane > [data-pjax-container] > .pane-tabs + .pane-content,
.pane > .pane-content-wrap > .pane-filter + .pane-content,
.pane > .pane-content-wrap > .pane-tabs + .pane-content {
  top: 5rem;
  height: auto;
}
.pane > .pane-filter + .pane-filter,
.pane > .pane-filter + .pane-tabs,
.pane > .pane-tabs + .pane-filter,
.pane > .pane-tabs + .pane-tabs,
.pane > [data-pjax-container] > .pane-filter + .pane-filter,
.pane > [data-pjax-container] > .pane-filter + .pane-tabs,
.pane > [data-pjax-container] > .pane-tabs + .pane-filter,
.pane > [data-pjax-container] > .pane-tabs + .pane-tabs,
.pane > .pane-content-wrap > .pane-filter + .pane-filter,
.pane > .pane-content-wrap > .pane-filter + .pane-tabs,
.pane > .pane-content-wrap > .pane-tabs + .pane-filter,
.pane > .pane-content-wrap > .pane-tabs + .pane-tabs {
  top: 5rem;
}
.pane > .pane-filter + .pane-filter + .pane-content,
.pane > .pane-filter + .pane-tabs + .pane-content,
.pane > .pane-tabs + .pane-filter + .pane-content,
.pane > .pane-tabs + .pane-tabs + .pane-content,
.pane > [data-pjax-container] > .pane-filter + .pane-filter + .pane-content,
.pane > [data-pjax-container] > .pane-filter + .pane-tabs + .pane-content,
.pane > [data-pjax-container] > .pane-tabs + .pane-filter + .pane-content,
.pane > [data-pjax-container] > .pane-tabs + .pane-tabs + .pane-content,
.pane > .pane-content-wrap > .pane-filter + .pane-filter + .pane-content,
.pane > .pane-content-wrap > .pane-filter + .pane-tabs + .pane-content,
.pane > .pane-content-wrap > .pane-tabs + .pane-filter + .pane-content,
.pane > .pane-content-wrap > .pane-tabs + .pane-tabs + .pane-content {
  top: 9rem;
  height: auto;
}
.pane > .pane-header,
.pane > [data-pjax-container] > .pane-header {
  /* Case for panes with .pane-header */
}
.pane > .pane-header + .pane-content,
.pane > .pane-header + .pane-content-wrap,
.pane > [data-pjax-container] > .pane-header + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-content-wrap {
  top: 5rem;
  height: auto;
}
.pane > .pane-header + .pane-filter,
.pane > .pane-header + .pane-tabs,
.pane > [data-pjax-container] > .pane-header + .pane-filter,
.pane > [data-pjax-container] > .pane-header + .pane-tabs {
  top: 5rem;
}
.pane > .pane-header + .pane-filter + .pane-content,
.pane > .pane-header + .pane-tabs + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-filter + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-tabs + .pane-content {
  top: 9rem;
  height: auto;
}
.pane > .pane-header + .pane-filter + .pane-filter,
.pane > .pane-header + .pane-filter + .pane-tabs,
.pane > .pane-header + .pane-tabs + .pane-filter,
.pane > .pane-header + .pane-tabs + .pane-tabs,
.pane > [data-pjax-container] > .pane-header + .pane-filter + .pane-filter,
.pane > [data-pjax-container] > .pane-header + .pane-filter + .pane-tabs,
.pane > [data-pjax-container] > .pane-header + .pane-tabs + .pane-filter,
.pane > [data-pjax-container] > .pane-header + .pane-tabs + .pane-tabs {
  top: 9rem;
}
.pane > .pane-header + .pane-filter + .pane-filter + .pane-content,
.pane > .pane-header + .pane-filter + .pane-tabs + .pane-content,
.pane > .pane-header + .pane-tabs + .pane-filter + .pane-content,
.pane > .pane-header + .pane-tabs + .pane-tabs + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-filter + .pane-filter + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-filter + .pane-tabs + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-tabs + .pane-filter + .pane-content,
.pane > [data-pjax-container] > .pane-header + .pane-tabs + .pane-tabs + .pane-content {
  top: 13rem;
  height: auto;
}
.pane > .pane-content-wrap > .pane-filter,
.pane > .pane-content-wrap > .pane-tabs {
  top: 0rem;
}
.pane > .pane-content-wrap > .pane-filter + .pane-content,
.pane > .pane-content-wrap > .pane-tabs + .pane-content {
  top: 4rem;
  height: auto;
}
.pane > .pane-content-wrap > .pane-filter + .pane-filter,
.pane > .pane-content-wrap > .pane-filter + .pane-tabs,
.pane > .pane-content-wrap > .pane-tabs + .pane-filter,
.pane > .pane-content-wrap > .pane-tabs + .pane-tabs {
  top: 5rem;
}
.pane > .pane-content-wrap > .pane-filter + .pane-filter + .pane-content,
.pane > .pane-content-wrap > .pane-filter + .pane-tabs + .pane-content,
.pane > .pane-content-wrap > .pane-tabs + .pane-filter + .pane-content,
.pane > .pane-content-wrap > .pane-tabs + .pane-tabs + .pane-content {
  top: 9rem;
  height: auto;
}
.pane.pane--static {
  position: relative;
  z-index: 1;
}
.pane.pane--active {
  opacity: 1;
  pointer-events: all;
  margin-left: 0;
}
.pane.pane--left {
  margin-left: -100vw;
}
.pane.pane--right {
  margin-left: 100vw;
}
.pane.pane--base {
  opacity: 1;
  pointer-events: all;
  margin-left: 0;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: block;
}
.pane.pane--close {
  /* Show close button */
}
.pane.pane--close .pane-close {
  display: block;
}
.pane.pane--back {
  /* Show back button */
}
.pane.pane--back .pane-back {
  display: block;
}
.pane.pane--loading .pane-loader {
  display: block;
}
.pane.pane--sub, .pane.pane--filter-tab {
  display: none;
  z-index: 11;
  width: 100%;
}
.pane.pane--sub.pane--open, .pane.pane--sub.pane--filter-tab--open, .pane.pane--filter-tab.pane--open, .pane.pane--filter-tab.pane--filter-tab--open {
  display: block;
}
.mobile.safari .pane, .tablet.safari7 .pane, .tablet.safari8 .pane, .android.chrome .pane {
  height: auto;
}

.pane-content-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: none;
}
.pane-content-wrap.active {
  display: block;
}

.pane-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: opacity 150ms ease;
  z-index: 1;
}
.pane-content.pane-content--loading {
  opacity: 0.32;
}
.pane-content.pane-content--loading + .pane-indicator--loading {
  display: block;
  z-index: 900;
}
.pane-content.pane-content--loading ~ .pane-footer {
  opacity: 0;
}
.pane-content.pane-content--map-full, .pane-content.pane-content--full {
  top: 0 !important;
}

.pane-indicator--loading {
  display: none;
  pointer-events: none;
}

.pane-pagination {
  display: none;
}

.pane-header,
.pane-footer {
  height: 5rem;
  padding: 0 1rem;
  transition: background-color 150ms ease;
}
@media (min-width: 576px) {
  .pane-header,
  .pane-footer {
    padding: 0 2rem;
  }
}

.pane-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: right;
}
.safari .pane-footer {
  -webkit-transform: translate3d(0, 0, 0);
}
.pane-footer.pane-footer--hidden {
  height: 0;
  padding-top: 0;
}
.pane-footer.pane-footer--hidden .button {
  position: relative;
  top: -4rem;
}
.pane-footer.pane-footer--inset {
  padding-top: 1rem;
  padding-left: 5.5rem;
  padding-right: 1rem;
}

.pane-header-title,
.pane-header-dropdown {
  margin: 0 5rem;
}

.pane-header-title,
.pane-header-dropdown-toggle {
  font-size: 1.125rem;
  line-height: 3rem;
  margin-bottom: -1.15126rem;
  padding-top: 1.15126rem;
  text-align: center;
  font-weight: 500;
}
@media (min-width: 768px) {
  .pane-header-title,
  .pane-header-dropdown-toggle {
    font-size: 1.25rem;
    line-height: 3rem;
    margin-bottom: -1.11251rem;
    padding-top: 1.11251rem;
  }
}

.pane-header-dropdown-toggle {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pane-header-dropdown-toggle .action {
  position: absolute;
  left: 50%;
  bottom: -0.75rem;
  margin-left: -1rem;
  opacity: 0.25;
  transition: opacity 150ms ease;
}
.open .pane-header-dropdown-toggle .action {
  display: none;
}
.pane-header-dropdown-toggle:focus .action, .pane-header-dropdown-toggle:hover .action {
  opacity: 1;
}

.pane-header .pane-header-dropdown-menu {
  left: 50%;
  width: 15rem;
  margin-left: -7.5rem;
}
@media (min-width: 768px) {
  .pane-header .pane-header-dropdown-menu {
    width: 30rem;
    margin-left: -15rem;
  }
}

.pane-filter.pane-filter--search .pane-filter-search {
  margin: 0 2rem;
  width: calc(100% - 4rem);
}
.webkit .pane-filter.pane-filter--search .pane-filter-search {
  width: calc(100% - 4.25rem);
}
.pane-filter.pane-filter--search .search-keys--selectize {
  margin: 0 2rem 0.25rem;
  width: calc(100% - 4rem);
  border-radius: 1rem;
}
.pane-filter.pane-filter--search .search-keys--selectize > input {
  display: none;
}
.pane-filter.pane-filter--search .search-keys--selectize .selectize-dropdown {
  top: 4rem !important;
  width: 100% !important;
}

.pane-filter-label {
  position: absolute;
  left: 2rem;
  top: 1.25rem;
}
.pane-filter-label .icon--filter {
  font-size: 1.25rem;
}

.pane-filter-input {
  display: block;
  width: 100%;
  border: 0;
  height: 4rem;
  line-height: 4rem;
  padding: 0 2rem 0 4rem;
  font-size: 0.875rem;
}

.pane-filter-search + .pills {
  margin-top: 0.5rem;
}

.pane-section::after {
  content: "";
  display: table;
  clear: both;
}
.pane-section.pane-section--first {
  padding-top: 2rem;
}
.pane-section.pane-section--last {
  padding-bottom: 4rem;
}
@media (min-width: 576px) {
  .pane-section.pane-section--expand.pane-section--collapsed .pane-section--collapsed-toggle {
    left: 3rem;
  }
  .pane-section.pane-section--expand.pane-section--collapsed .focus-meta-cite {
    right: 3rem;
  }
}
@media (min-width: 992px) {
  .pane-section.pane-section--capped {
    max-width: 45rem;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .pane-section.pane-section--capped-wide {
    max-width: 60rem;
    margin: 0 auto;
  }
}
.pane-section.pane-section--collapsed {
  max-height: 15rem;
  position: relative;
  transition: height 150ms ease;
  overflow: hidden;
}
.pane-section.pane-section--collapsed > *:first-child {
  padding-top: 2rem;
}
.pane-section.pane-section--collapsed > .last {
  padding-bottom: 6rem;
}
.pane-section.pane-section--collapsed .pane-section--collapsed-toggle,
.pane-section.pane-section--collapsed .focus-meta-cite {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 1;
}
.pane-section.pane-section--collapsed .focus-meta-cite {
  left: auto;
  right: 2rem;
}
.pane-section.pane-section--collapsed:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60%;
}
.pane-section.pane-section--collapsed.show {
  max-height: none;
}
.pane-section.pane-section--collapsed.show:after {
  display: none;
}
.pane-section + .pane-section {
  margin-top: 1rem;
}
.pane-content > .pane-section:first-child {
  padding-top: 6rem;
}

.pane-close,
.pane-back,
.pane-dropdown {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

.pane-back,
.pane-dropdown {
  left: 1rem;
  right: auto;
}
.pane-back .icon--back,
.pane-dropdown .icon--back {
  font-size: 1.75rem;
  margin: -0.75rem 0 0 -0.75rem;
}

.pane-dropdown.open .action-label {
  opacity: 0;
}

.pane-map {
  height: 20rem;
}

@media (min-width: 768px) {
  .pane.pane--popover-md {
    position: absolute;
    height: auto;
    min-height: 12rem;
    max-height: 20rem;
    top: 0;
    right: 1rem;
    left: 1rem;
    width: auto;
  }
  .pane.pane--popover-md .pane-header-title {
    display: none;
  }
  .pane.pane--popover-md .pane-back {
    display: none;
  }
  .pane.pane--popover-md .pane-close {
    display: block;
  }
  .pane.pane--popover-md .pane-content {
    top: 0;
    padding-bottom: 5rem;
  }
  .pane.pane--popover-md .pane-content .pane-section:first-child {
    padding-top: 2.25rem;
  }
  .pane.pane--popover-md .pane-footer {
    text-align: right;
  }
  .pane.pane--popover-md .pane-footer.pane-footer--inset {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pane.pane--popover-md.pane--popover-md-header .pane-header {
    position: relative;
  }
  .pane.pane--popover-md.pane--popover-md-header .pane-header:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
    height: 2rem;
    pointer-events: none;
    bottom: -0.25rem;
    transform: perspective(50rem) rotateY(0deg) rotateX(45deg);
    /* Default color - DEPRECATED */
    box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
  }
  .pane.pane--popover-md.pane--popover-md-header .pane-content {
    top: 5rem;
  }
  .pane.pane--popover-md.pane--popover-md-header .pane-header-title {
    display: block;
    text-align: left;
    margin-left: 0;
    font-size: 0.75rem;
  }
  .pane.pane--close-md .pane-back {
    display: none;
  }
  .pane.pane--close-md .pane-close {
    display: block;
  }
  .pane.pane--back-md--hide .pane-back {
    display: none;
  }
  .pane-section + .pane-section + .pane-section {
    margin-top: 2rem;
  }
  .pane-map {
    height: 30rem;
  }
}
@media (min-width: 1200px) {
  .pane-filter-label {
    left: 3rem;
  }
  .pane-filter-input {
    padding-left: 6rem;
    padding-right: 3rem;
  }
}
/* https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336 */
.pane-v2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
}
.pane-v2 .pane-section + .pane-section {
  margin-top: 1.5rem;
}
.pane-v2 .navigation {
  position: relative;
  height: auto;
  overflow: visible;
  z-index: 1;
}
.pane-v2 .navigation .navigation-menu.navigation-primary {
  top: 0;
}
.pane-v2.pane-v2--loading::after {
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left-width: 0.125rem;
  border-left-style: solid;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 3rem;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
}
.pane-v2.pane-v2--loading .pane-v2-content,
.pane-v2.pane-v2--loading .pane-v2-tabs,
.pane-v2.pane-v2--loading .pane-v2-actions,
.pane-v2.pane-v2--loading .pane-v2-footer {
  opacity: 0.25;
  pointer-events: none;
}
.pane-v2.pane--open.pane-v2--pop {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.pane-v2.pane--open.pane-v2--aside {
  margin-left: 0;
}
.pane-v2.pane--open.pane-v2--aside-right {
  margin-right: 0;
}
.pane-v2.pane-v2--root {
  position: relative;
  top: 4.5rem;
  right: auto;
  bottom: auto;
  left: auto;
  height: calc(100% - 9rem);
  z-index: 1000;
}
.pane-v2.pane-v2--search {
  height: 0;
  bottom: auto;
  display: none;
}
.pane-v2.pane-v2--search.pane--open {
  display: block;
}
.pane-v2.pane-v2--navigation {
  z-index: 1200;
}
.pane-v2.pane-v2--navigation .user {
  display: block;
}
.pane-v2.pane-v2--navigation-portfolio, .pane-v2.pane-v2--navigation-queries {
  z-index: 1300;
}
.ie11 .pane-v2 {
  overflow: hidden;
}
.ie11 .pane-v2 .pane-v2-container {
  overflow: hidden;
  max-height: 100%;
}
.ie11 .pane-v2.pane-v2--search {
  overflow: visible;
}
.ie11 .pane-v2.pane-v2--search .pane-v2-container {
  overflow: visible;
}

.pane-v2-backdrop {
  /* pane-v2! */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  opacity: 0;
}
.pane-v2-backdrop.pane-v2-backdrop--open {
  opacity: 0.72;
  bottom: 0;
}
.pane-v2-backdrop.pane-v2-backdrop--close {
  bottom: auto;
}
.pane-v2-backdrop.pane-v2-backdrop--navigation {
  z-index: 1200;
}
.pane-v2-backdrop.pane-v2-backdrop--navigation-portfolio, .pane-v2-backdrop.pane-v2-backdrop--navigation-queries {
  z-index: 1300;
}

.pane-v2-container,
.pane-v2-tabs-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for Firefox */
  min-height: 0;
}

.pane-v2-tabs-container {
  visibility: hidden;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateY(1rem);
  transition: transform 150ms ease, opacity 150ms ease;
}
.pane-v2-tabs-container.pane-v2-tabs-container--open {
  visibility: visible;
  min-height: 100%;
  height: 100%;
  position: static;
  transform: translateY(0rem);
  opacity: 1;
  top: auto;
}

.pane-v2-header,
.pane-v2-bar,
.pane-v2-crumbs,
.pane-v2-subheader,
.pane-v2-tabs,
.pane-v2-actions,
.pane-v2-footer {
  min-height: min-content;
  position: relative;
  z-index: 1;
  opacity: 1;
}
.pane-v2-header:empty,
.pane-v2-bar:empty,
.pane-v2-crumbs:empty,
.pane-v2-subheader:empty,
.pane-v2-tabs:empty,
.pane-v2-actions:empty,
.pane-v2-footer:empty {
  display: none;
}

.pane-v2-bar .action--left,
.pane-v2-bar .action--close {
  display: none;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.pane-v2-bar .action--close {
  display: block;
  left: auto;
  right: 1rem;
}
.pane-v2-bar .pane-section {
  position: relative;
}
@media (min-width: 992px) {
  .pane-v2-bar .pane-section.pane-section--capped .pane-back, .pane-v2-bar .pane-section.pane-section--capped-wide .pane-back {
    left: 2rem;
  }
}

.pane-v2-tabs {
  min-height: 4rem;
}

.pane-v2-crumbs {
  min-height: min-content;
  display: flex;
  align-items: center;
}

.pane-v2-crumb {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.pane-v2-crumb--root {
  padding-right: 0.375rem;
}

.pane-v2-crumb--parent,
.pane-v2-crumb--key {
  line-height: 1rem;
  padding: 0 0.375rem;
}

.pane-v2-crumb--divider {
  padding: 0.125rem 0.375rem;
}
.pane-v2-crumb--divider .icon-container {
  width: 0.75rem;
  margin: -0.375rem 0 0 -0.375rem;
  margin: 0;
}

#focus-content .pane-v2-content {
  background-color: #fff !important;
}

.pane-v2-content {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 0;
  scroll-behavior: smooth;
  /* for Firefox */
  min-height: 0;
  opacity: 1;
}
.pane-v2-content.pane-v2-content--contain {
  overflow: hidden;
}
.pane-v2-content.pane-v2-content--slides {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  max-height: 100%;
}

.account-actions {
  position: fixed;
  right: 0;
  bottom: 1rem;
}

.pane-v2-actions {
  height: 0;
  min-height: 0; /* safari */
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
.pane-v2-actions > .action,
.pane-v2-actions > .dropdown,
.pane-v2-actions > .signature {
  flex: 0 0 auto;
  position: relative;
  top: -4rem;
  margin-left: 0.5rem;
}
.pane-v2-actions > .dropdown .dropdown-menu {
  right: 0;
  left: auto;
  bottom: 100%;
  top: auto;
  margin-bottom: 0.125rem;
}
.pane-v2-actions .signature {
  top: -3rem;
}
.pane-v2-actions.pane-v2-actions--down > .action,
.pane-v2-actions.pane-v2-actions--down > .dropdown,
.pane-v2-actions.pane-v2-actions--down > .signature {
  top: 1rem;
}
.pane-v2-actions.pane-v2-actions--down > .dropdown .dropdown-menu {
  bottom: auto;
  top: 100%;
  margin-top: 0.125rem;
}

.pane-v2-banner {
  min-height: 4rem;
  z-index: 1;
}
.pane-v2-banner .pane-v2-banner-title {
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  left: 5rem;
  right: 5rem;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 5rem;
  font-size: 1.125rem;
}
.pane-v2-banner .pane-v2-banner-url {
  display: inline-block;
}
.pane-v2-banner .action--support,
.pane-v2-banner .control--icon-support {
  float: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  padding-top: 0;
}
.pane-v2-banner .action--support,
.pane-v2-banner .control--icon-support {
  right: 4.5rem;
}
.pane-v2-banner .control--icon-support {
  top: 1.5rem;
}
.pane-v2-banner .action--back,
.pane-v2-banner .action--close,
.pane-v2-banner .action--search {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
}
.pane-v2-banner .action--close,
.pane-v2-banner .action--search {
  left: auto;
  right: 1rem;
}
.pane-v2-banner .action--navigation-icon {
  opacity: 0.75;
}
.pane-v2-banner--absence-style {
  min-height: 1.8rem;
  display: flex !important;
  justify-content: center;
  box-shadow: none !important;
}
.pane-v2-banner--absence-style h6 a {
  border-bottom: 1px solid;
}

.pane-v2-banner--logo {
  min-height: 6rem;
}
.pane-v2-banner--logo .logo {
  left: 7rem !important;
}

.pane-v2-cover {
  min-height: 6rem;
  position: relative;
}
.pane-v2-cover .logo {
  top: 3rem;
  z-index: 1;
}
.pane-v2-cover.pane-v2-cover--expand {
  min-height: 16rem;
}

.pane-v2-bg {
  display: none;
  height: 110%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: -10%;
  width: 100%;
  z-index: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pane-v2-bg-item {
  display: none;
  animation: swoosh 6s 1.5s infinite;
  height: 40%;
  max-width: 12rem;
  position: absolute;
  transform: translate3d(-110%, 0, 0);
  background-repeat: no-repeat;
}
.pane-v2-bg-item:after {
  display: block;
  width: 100vw;
  height: 8rem;
  content: " ";
  position: relative;
  top: -100%;
}
.pane-v2-bg-item.--first {
  top: -1%;
}
.pane-v2-bg-item.--middle {
  animation-delay: 1.75s;
  left: -6rem;
  top: 34%;
}
.pane-v2-bg-item.--last {
  animation-delay: 2s;
  left: -12rem;
  top: 68%;
}

.pane-v2-footer {
  padding-bottom: 1rem;
}
.pane-v2-footer .user {
  float: left;
  padding-left: 1rem;
}
.pane-v2-footer .list {
  padding-top: 0.5rem;
}
.pane-v2-footer > * {
  position: relative;
  z-index: 1;
}
.pane-v2-footer.pane-v2-footer--buttons > .pane-section {
  display: flex;
  flex-direction: row;
  padding: 1rem 0.75rem 0 0.75rem;
  background: #fff;
}
.pane-v2-footer.pane-v2-footer--buttons > .pane-section .button {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 0.25rem;
}
.pane-v2-footer.pane-v2-footer--buttons > .pane-section .button.button--primary {
  order: 9;
}
.pane-v2-footer.pane-v2-footer--buttons-stacked .button {
  display: block;
  width: 100%;
}
.pane-v2-footer.pane-v2-footer--buttons-stacked .button + .button {
  margin-top: 0.5rem;
}

.pane-v2-footer-notice {
  font-size: 0.75rem;
  text-align: center;
  height: 1.25rem;
  line-height: 1.25rem;
}

.pane-v2-bar-title {
  display: block;
  margin: 0 5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 10rem);
  text-overflow: ellipsis;
  height: 5rem;
  line-height: 3rem;
  padding: 1.125rem 0 0.75rem;
  font-size: 1.125rem;
}
.pane-v2-bar-title .count {
  position: relative;
  top: -0.25rem;
}

.pane-v2--aside,
.pane-v2--aside-right {
  position: absolute;
  width: 100%;
  margin-left: -100%;
  top: 0;
  left: 0;
  margin-right: 0;
  transition: margin 150ms ease-in;
}

.pane-v2--aside-right {
  left: auto;
  right: 0;
  margin-left: 0;
  margin-right: -100%;
}

.pane-v2--pop {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease, margin 150ms ease;
}

@media (max-width: 767px) {
  .pane-v2--pop {
    transform: none !important;
  }
}
@media (min-width: 576px) {
  .pane-v2--aside,
  .pane-v2--aside-right {
    width: 20rem;
    margin-left: -20rem;
  }
  .pane-v2--aside-right {
    margin-left: 0;
    margin-right: -20rem;
  }
  .pane-v2-footer.pane-v2-footer--buttons > .pane-section {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}
@media (min-width: 768px) {
  .pane-v2--pop {
    width: 16rem;
    height: 20rem;
    margin-top: 1rem;
    transform: translate3d(0, 0, 0);
    transition: transform 150ms ease;
  }
  .pane-v2--pop .pane-v2-bar-title {
    margin-left: 2rem;
    width: calc(100% - 3rem);
    text-align: left;
  }
  .pane-v2--pop .pane-v2-bar .action--md-shrink .icon-container {
    width: 0.75rem;
    margin: -0.375rem 0 0 -0.375rem;
  }
  .pane-v2--pop.pane--open {
    margin-top: 0;
  }
  .pane-v2-shadow--md {
    position: relative;
  }
  .pane-v2-shadow--md:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
    height: 2rem;
    pointer-events: none;
    bottom: -0.25rem;
    transform: perspective(50rem) rotateY(0deg) rotateX(45deg);
    /* Default color - DEPRECATED */
    box-shadow: 0 1rem 2rem -0.75rem rgba(14, 1, 43, 0.15);
  }
  .pane-v2--root .pane-v2-bar-title {
    font-size: 1.375rem;
  }
  .pane-v2-bar.pane-v2-bar--md-shrink .pane-v2-bar-title {
    font-size: 1rem;
    height: 4rem;
    line-height: 2rem;
  }
  .pane-v2-banner--logo {
    min-height: 7rem;
  }
}
.pane-v2-header {
  min-height: min-content;
  padding: 2rem 0 1.25rem;
  min-height: 8rem;
}
.pane-v2-header > .pane-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pane-v2-header > .pane-section:after {
  /* Clearfix */
  display: none;
}

.pane-v2-header-title,
.pane-v2-header-options {
  flex: 0 0 auto;
}

.pane-v2-header-filters {
  flex: 1 0 auto;
  padding: 0.5rem 1rem 0 0;
}

.pane-v2-header-title {
  flex-basis: 100%;
  font-size: 1.75rem;
  line-height: 2.5rem;
  white-space: nowrap;
  /*overflow: hidden;
  text-overflow: ellipsis;*/
  padding-right: 1rem;
}

.pane-v2-header-options.pane-v2-header-options--cabinet {
  opacity: 0;
  display: none;
  flex-dirextion: row;
  justify-content: center;
  align-content: center;
}
.pane-v2-header-options.pane-v2-header-options--cabinet .action {
  flex: 0 0 auto;
}
.pane-v2-header-options.pane-v2-header-options--cabinet.js-enabled {
  opacity: 1;
}

@media (min-width: 768px) {
  .pane-v2-header-options.pane-v2-header-options--cabinet {
    display: flex;
  }
  .pane-v2-header-title {
    flex-basis: auto;
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}
.pane-v2-slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.pane-v2-slides .pane-v2-slide {
  position: relative;
  height: 100%;
  width: 100%;
}

.pane-v2-slide {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.pane-v2-slide-cover {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pane-v2-slide-cover .logo {
  position: static;
}

.pane-v2-slide-content {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  text-align: center;
  padding: 2rem 0 0;
}
.pane-v2-slide-content p {
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 0 0 2rem;
}

.user {
  float: right;
  position: relative;
  display: none;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
}
.user .pulse {
  display: none;
  top: 3.5rem;
  right: 0;
  left: auto;
}
.header .user {
  padding-top: 3.125rem;
}
@media (min-width: 768px) {
  .user {
    display: block;
  }
}

.user-avatar {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  float: right;
  position: relative;
}
.user-avatar:after {
  content: attr(data-initials);
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: 0;
  left: 0;
}
.windows7.ie .user-avatar:after {
  top: 1.5rem;
}

.user-name,
.user-name-via,
.user-name-location,
.user-name-credits {
  padding: 0 0.75rem 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7rem;
}
@media (min-width: 1200px) {
  .user-name,
  .user-name-via,
  .user-name-location,
  .user-name-credits {
    max-width: 10rem;
  }
}

.user-name {
  float: left;
  height: 3rem;
  line-height: 3rem;
  font-size: 0.875rem;
  position: relative;
}
@media (min-width: 1200px) {
  .user-name {
    font-size: 1rem;
  }
}

.user-name-via,
.user-name-location,
.user-name-credits {
  font-size: 0.6125rem;
  line-height: 1.5rem;
  position: absolute;
  top: 1.875rem;
  width: 100%;
  right: 0;
  text-align: right;
}

.user-excerpt {
  text-align: center;
  padding: 2rem 1rem 2rem;
  white-space: nowrap;
}
.user-excerpt .user-avatar {
  display: inline-block;
  height: 4.5rem;
  width: 4.5rem;
  float: none;
}
.user-excerpt .user-avatar:after {
  top: 0.75rem;
  left: 0.75rem;
}
.user-excerpt .user-name {
  display: block;
  max-width: 100%;
  padding: 0;
  width: 100%;
  float: none;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: -0.61251rem;
  padding-top: 0.61251rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-excerpt .user-name-via,
.user-excerpt .user-name-location,
.user-excerpt .user-name-email {
  display: inline;
  text-align: center;
  position: static;
  width: auto;
  max-width: 50%;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  line-height: 2rem;
}

.user-modal-connect,
.user-modal-logout {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: center;
  display: block;
  height: 3rem;
  line-height: 3rem;
  padding: 0 2rem;
  font-weight: 600;
  font-size: 0.875rem;
}
.user-modal-connect .icon--check,
.user-modal-logout .icon--check {
  display: none;
}
.user-modal-connect.user-modal-connect--active,
.user-modal-logout.user-modal-connect--active {
  font-weight: 400;
  border-bottom: 0.0625rem solid transparent;
}
.user-modal-connect.user-modal-connect--active .icon--check,
.user-modal-logout.user-modal-connect--active .icon--check {
  display: inline-block;
}

.user-modal.modal .modal-dialog {
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
  bottom: 1rem !important;
  left: 1rem !important;
  margin: 0 !important;
  max-width: none !important;
}
.user-modal.modal .modal-dialog .pane {
  height: 100%;
  min-height: 16rem;
  overflow: visible;
}
.user-modal.modal .pane-header {
  height: 12rem;
}
.user-modal.modal .pane-content {
  top: 12rem;
  bottom: 3rem;
}
.user-modal.modal .pane-content > .pane-section:first-child {
  padding-top: 0;
}
.user-modal.modal .pane-footer {
  padding: 0;
  height: 3rem;
}
.user-modal.modal .timeline-milestone {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.user-modal.modal.user-modal--footer .pane-content {
  bottom: 6rem;
}
.user-modal.modal.user-modal--footer .pane-footer {
  height: 6rem;
}
@media (min-width: 992px) {
  .user-modal.modal .modal-dialog {
    transform: none !important;
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: auto !important;
    width: 25rem !important;
    background: transparent;
  }
  .user-modal.modal .modal-dialog .user-excerpt {
    padding-bottom: 0;
  }
  .user-modal.modal .modal-dialog .pane-close {
    display: none !important;
  }
}

#userModal .user-avatar {
  position: relative;
  left: 7.5rem;
}

.kv-grid-table {
  border: 0 !important;
  width: 100%;
}
.kv-grid-table th,
.kv-grid-table td {
  border: 0 !important;
}

/*.table-row {
    margin: 0;
    padding: 1rem 0;
    transition: background 150ms ease;
    border-top: 1px solid $gray-lightest;
    position: relative;

    .no-touch & {
        &:hover {
            background: $gray-lightest;

            .table-row-actions {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: .5rem 0;
    }
}

.table-row-actions {
    position: absolute;
    right: 2rem;
    top: 1rem;
    opacity: 0;
    transition: opacity 150ms ease;

    .dropdown-menu {
        display: block;
    }

    .touch & {
        opacity: 1;
    }
}

.table-cell {
    padding: 0 2.25rem;

    @include media-breakpoint-up(md) {
        padding: 1.5rem 2.25rem;
    }
}

.table-title {
    color: $gray-dark;
    font-weight: 400;
    padding: 0 2.25rem;
    margin: 0 -2.25rem;

    @include media-breakpoint-up(md) {
        padding: 1.5rem 2.25rem;
        margin: -1.5rem -2.25rem;
    }
}*/
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: inline-block;
}
.nav-link:hover, .nav-link:focus, .nav-link:hover:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #818a91;
}
.nav-link.disabled, .nav-link.disabled:focus, .nav-link.disabled:hover {
  color: #818a91;
  cursor: not-allowed;
  background-color: transparent;
}

.nav-inline .nav-item {
  display: inline-block;
}
.nav-inline .nav-item + .nav-item,
.nav-inline .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-tabs {
  border-bottom: 0.125rem solid transparent;
}
.nav-tabs::after {
  content: "";
  display: table;
  clear: both;
}
.nav-tabs .nav-item {
  float: left;
  margin-bottom: -0.125rem;
}
.nav-tabs .nav-item + .nav-item {
  margin-left: 0.2rem;
}
.nav-tabs .nav-link {
  display: block;
  padding: 0.5em 1em;
  border: 0.125rem solid transparent;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover:focus {
  border-color: #eceeef #eceeef transparent;
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link.disabled:focus, .nav-tabs .nav-link.disabled:hover {
  color: #818a91;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover {
  color: #55595c;
  background-color: transparent;
  border-color: #ddd #ddd transparent;
}
.nav-tabs .dropdown-menu {
  margin-top: -0.125rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.nav-pills::after {
  content: "";
  display: table;
  clear: both;
}
.nav-pills .nav-item {
  float: left;
}
.nav-pills .nav-item + .nav-item {
  margin-left: 0.2rem;
}
.nav-pills .nav-link {
  display: block;
  padding: 0.5em 1em;
  border-radius: 1rem;
}
.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover,
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover {
  color: #fff;
  cursor: default;
  background-color: #0275d8;
}

.nav-stacked .nav-item {
  display: block;
  float: none;
}
.nav-stacked .nav-item + .nav-item {
  margin-top: 0.2rem;
  margin-left: 0;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.nav-tabs--sub .nav-link {
  border-width: 0 !important;
}

.nav-tabs {
  border-bottom: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  padding: 0 1.5rem;
  display: block;
}
.nav-tabs .nav-item {
  height: 4rem;
  display: inline-block;
  float: none;
  padding: 0 0.75rem;
  margin: 0;
}
.nav-tabs .nav-item + .nav-item {
  margin: 0;
}
.nav-tabs .nav-link {
  font-size: 0.875rem;
  line-height: 2.5rem;
  margin-bottom: 0.02124rem;
  padding-top: 0.97876rem;
  padding-left: 0;
  padding-right: 0;
  border-width: 0 0 0.1875rem 0;
  border-radius: 0;
  height: 4rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:active {
  border-color: transparent;
  background: transparent;
}
.nav-tabs .nav-link.active {
  background: transparent;
}
.nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:active {
  background: transparent;
}
.nav-tabs .nav-heading {
  display: inline-block;
  position: relative;
}
.nav-tabs .nav-title,
.nav-tabs .nav-subtitle {
  display: block;
}
.nav-tabs .indicator-radial {
  position: absolute;
  right: -2.5rem;
  top: 0;
}
.nav-tabs .nav-count {
  font-size: 0.675rem;
  color: #fff;
  display: inline-block;
  padding: 0 0.25rem 0 0.25rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  min-width: 1rem;
  text-indent: 0;
  position: relative;
  top: -0.375rem;
  right: -0.125rem;
}
.nav-tabs .nav-count .icon--favorite {
  font-size: 0.5rem;
}
.pane-section .nav-tabs {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  list-style: none;
  max-width: 100%;
  overflow: hidden;
}

.tabs-item {
  flex: 0 1 auto;
  display: flex;
  align-items: flex-start;
}
.tabs-item + .tabs-item {
  margin-left: 1.5rem;
}

.tab {
  display: block;
  border-bottom: 0.1875rem solid transparent;
  height: 4rem;
  line-height: 4.5rem;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
}

.tab-container {
  visibility: hidden;
  height: 0;
  opacity: 0;
  position: absolute;
  transform: translateY(1rem);
  transition: transform 150ms ease, opacity 150ms ease;
}
.tab-container.tab-container--open {
  visibility: visible;
  height: auto;
  position: static;
  transform: translateY(0rem);
  opacity: 1;
}

@media (min-width: 768px) {
  .tabs--md-expand .tabs-link {
    height: 5rem;
    line-height: 5.5rem;
  }
  .pane-v2-banner .nav-tabs.nav-tabs--steps .nav-item {
    width: 25% !important;
  }
  .pane-v2-banner .nav-tabs.nav-tabs--steps .nav-item:not(.active) {
    display: block;
  }
  .pane-v2-banner .nav-tabs.nav-tabs--steps .nav-item .nav-link:before, .pane-v2-banner .nav-tabs.nav-tabs--steps .nav-item .nav-link:after {
    display: block;
  }
  .pane-v2-banner .nav-tabs.nav-tabs--steps .nav-item:first-child .nav-link:before, .pane-v2-banner .nav-tabs.nav-tabs--steps .nav-item:last-child .nav-link:after {
    display: none;
  }
  .pane-v2-banner .nav-tabs.nav-tabs--steps.nav-tabs--steps-three .nav-item {
    width: 33% !important;
  }
}
.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:focus {
  outline: 0;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
}

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: #eceeef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: normal;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:hover:focus {
  color: #2b2d2f;
  text-decoration: none;
  background-color: #f7f7f9;
}
.dropdown-item.active, .dropdown-item.active:focus, .dropdown-item.active:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0275d8;
  outline: 0;
}
.dropdown-item.disabled, .dropdown-item.disabled:focus, .dropdown-item.disabled:hover {
  color: #818a91;
}
.dropdown-item.disabled:hover, .dropdown-item.disabled:focus, .dropdown-item.disabled:hover:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
}

.open > .dropdown-menu {
  display: block;
}
.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #818a91;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 0.3em solid;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 0.125rem;
}

.dropdown-menu {
  border-radius: 0;
  border: 0;
  padding: 0;
}
.show > .dropdown-menu {
  opacity: 1;
}
.dropdown-menu.dropdown-menu--actions .dropdown-item,
.dropdown-menu.dropdown-menu--actions > a,
.dropdown-menu.dropdown-menu--actions > li > a {
  padding: 0.625rem 3.25rem 0.625rem 2rem;
}
@media (min-width: 768px) {
  .dropdown-menu {
    min-width: 15rem;
  }
}

.dropdown-item,
.dropdown-menu > a,
.dropdown-menu > li > a {
  font-size: 1rem;
  line-height: 1.75rem;
  display: block;
  padding: 0.625rem 2rem;
}

.dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.no-touch .dropdown-item + .dropdown-item-actions {
  opacity: 0;
}
.no-touch .dropdown-item:hover + .dropdown-item-actions {
  opacity: 1;
}

.dropdown-item-note {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.dropdown-item-actions {
  height: 0;
  position: relative;
  z-index: 1;
  width: 100%;
  transition: opacity 150ms ease;
}
.dropdown-item-actions .action {
  position: absolute;
  bottom: 0.375rem;
  right: 1rem;
}
.no-touch .dropdown-item-actions:hover {
  opacity: 1;
}

.dropdown-header,
.dropdown-item--header {
  padding: 0;
  font-size: 0.875rem;
  line-height: 2rem;
  margin-bottom: 0.27124rem;
  padding-top: 0.72876rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.dropdown-sheet {
  transition-property: transform opacity box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
}
@media (max-width: 991px) {
  .dropdown-sheet {
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;
    min-width: 100%;
    margin: 0;
    transform: translateY(50%);
    opacity: 0;
    pointer-events: none;
    display: block;
    box-shadow: 0;
    padding-bottom: 5rem;
  }
  .dropdown-sheet .dropdown-sheet-cancel,
  .dropdown-sheet .dropdown-sheet-header {
    display: block;
  }
  .open .dropdown-sheet {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
  }
}

.dropdown-sheet-cancel,
.dropdown-sheet-header {
  display: none;
}

.dropdown-sheet-cancel > .dropdown-item {
  text-align: center;
}

.dropdown-form {
  padding: 0.625rem 2rem;
}
@media (min-width: 992px) {
  .dropdown-form {
    padding-bottom: 1.5rem;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.in {
  opacity: 0.9;
}
.tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.tooltip-right, .tooltip.bs-tether-element-attached-left {
  padding: 0 5px;
  margin-left: 3px;
}
.tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  top: 50%;
  left: 0;
  margin-top: -5px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.tooltip-bottom, .tooltip.bs-tether-element-attached-top {
  padding: 5px 0;
  margin-top: 3px;
}
.tooltip.tooltip-bottom .tooltip-inner::before, .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  top: 0;
  left: 50%;
  margin-left: -5px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.tooltip-left, .tooltip.bs-tether-element-attached-right {
  padding: 0 5px;
  margin-left: -3px;
}
.tooltip.tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  top: 50%;
  right: 0;
  margin-top: -5px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 1rem;
}
.tooltip-inner::before {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip {
  z-index: 130;
  pointer-events: none;
}
.touch .tooltip {
  display: none !important;
}

.tooltip-inner {
  border-radius: 1.25rem;
  margin: -1.5rem 0 0 -2rem;
  height: 3rem;
  width: 4rem;
  width: auto;
  margin-left: 0;
  padding: 0.5rem 1.25rem;
  max-width: 18rem;
  text-align: left;
  height: auto;
  margin: 0;
  line-height: 1rem;
  font-size: 0.75rem;
  border-radius: 1rem;
}
.tooltip-inner:before, .tooltip-inner:after {
  display: none !important;
}

.tooltip-arrow:before, .tooltip-arrow:after {
  display: none !important;
}

.tooltip-close {
  position: absolute;
  top: 0.25rem;
  right: 0;
  border: 0;
  background: transparent;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
}

.indicator {
  border-radius: 0.4rem;
  padding: 0 0.4rem;
  margin: -0.5rem 0 0 -0.66666666rem;
  height: 1rem;
  line-height: 1rem;
  width: 1.33333334rem;
  min-width: 1.33333334rem;
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 1.25rem;
  vertical-align: text-bottom;
  top: -0.125rem;
  transition: all 150ms ease;
}
.indicator::before {
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  margin: -0.25rem 0 0 -0.3333334rem;
  height: 0.5rem;
  line-height: 0.5rem;
  width: 0.66666667rem;
  min-width: 0.66666667rem;
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  opacity: 0;
}
input:checked ~ .indicator::before {
  opacity: 1;
}
.indicator.indicator--switch {
  width: auto;
  margin-left: 0;
  width: 2.5rem;
}
.indicator.indicator--switch::before {
  opacity: 1;
  left: 0.25rem;
  margin-left: 0;
}
input:checked ~ .indicator.indicator--switch::before {
  left: auto;
  right: 0.25rem;
}
.indicator.indicator--radio {
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}
.indicator.indicator--radio::before {
  border-radius: 0.25rem;
  height: 0.5rem;
  width: 0.5rem;
}

.indicator-state {
  display: none;
  position: relative;
}
.indicator-state:before {
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  margin: -0.25rem 0 0 -0.3333334rem;
  height: 0.5rem;
  line-height: 0.5rem;
  width: 0.66666667rem;
  min-width: 0.66666667rem;
  content: "";
  display: inline-block;
  position: relative;
}

.indicator-state-label {
  border-radius: 0.4rem;
  padding: 0 0.4rem;
  margin: -0.5rem 0 0 -0.66666666rem;
  height: 1rem;
  line-height: 1rem;
  width: 1.33333334rem;
  min-width: 1.33333334rem;
  width: auto;
  margin-left: 0;
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  margin-top: -0.5rem;
  transition: opacity 150ms ease;
  color: #fff;
  font-size: 0.675rem;
  font-weight: 700;
  margin-left: 0;
  white-space: nowrap;
}
.safari .indicator-state-label {
  transition-property: none;
}

.indicator-progress {
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  margin: -0.25rem 0 0 -0.3333334rem;
  height: 0.5rem;
  line-height: 0.5rem;
  width: 0.66666667rem;
  min-width: 0.66666667rem;
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.675rem;
  padding: 0;
  margin: 0;
  width: auto;
}
.indicator-progress.indicator-progress--narrow {
  text-indent: -999rem;
  height: 0.1875rem;
  line-height: 0.1875rem;
}

.indicator-progress-bar {
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  margin: -0.25rem 0 0 -0.3333334rem;
  height: 0.5rem;
  line-height: 0.5rem;
  width: 0.66666667rem;
  min-width: 0.66666667rem;
  margin: 0;
  padding: 0;
  height: auto;
  min-width: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  width: 0%;
  transition: width 1.5s ease;
}
.indicator-progress--narrow .indicator-progress-bar {
  border-radius: 0.09375rem;
}

.indicator-radial {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}
.indicator-radial .circle .mask,
.indicator-radial .circle .fill,
.indicator-radial .circle .shadow {
  width: 4rem;
  height: 4rem;
}
.indicator-radial .circle .mask {
  clip: rect(0rem, 4rem, 4rem, 2rem);
}
.indicator-radial .circle .mask .fill {
  clip: rect(0rem, 2rem, 4rem, 0rem);
}
.indicator-radial .inset {
  width: 3.5rem;
  height: 3.5rem;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.indicator-radial .inset .percentage,
.indicator-radial .inset .counter {
  line-height: 3.71875rem;
  width: 3.5rem;
}
.indicator-radial .circle .mask,
.indicator-radial .circle .fill,
.indicator-radial .circle .shadow {
  position: absolute;
  border-radius: 50%;
}
.indicator-radial .circle .shadow {
  display: none;
}
.indicator-radial .circle .mask,
.indicator-radial .circle .fill {
  backface-visibility: hidden;
  transition: transform 1s;
  border-radius: 50%;
}
.indicator-radial .inset {
  position: absolute;
  border-radius: 50%;
}
.indicator-radial .inset .percentage,
.indicator-radial .inset .counter {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  font-size: 1.125rem;
}
.indicator-radial .inset .counter-limit {
  font-size: 0.75rem;
  position: relative;
  top: -0.375rem;
}
.indicator-radial .inset .counter-limit:before {
  content: "/ ";
  font-size: 0.5rem;
  display: inline-block;
  padding: 0 0.25rem;
}
.indicator-radial[data-progress="0"] .circle .mask.full,
.indicator-radial[data-progress="0"] .circle .fill {
  transform: rotate(0deg);
}
.indicator-radial[data-progress="0"] .circle .fill.fix {
  transform: rotate(0deg);
}
.indicator-radial[data-progress="0"] .inset .percentage:before {
  content: "0 %";
}
.indicator-radial[data-progress="1"] .circle .mask.full,
.indicator-radial[data-progress="1"] .circle .fill {
  transform: rotate(1.8deg);
}
.indicator-radial[data-progress="1"] .circle .fill.fix {
  transform: rotate(3.6deg);
}
.indicator-radial[data-progress="1"] .inset .percentage:before {
  content: "1 %";
}
.indicator-radial[data-progress="2"] .circle .mask.full,
.indicator-radial[data-progress="2"] .circle .fill {
  transform: rotate(3.6deg);
}
.indicator-radial[data-progress="2"] .circle .fill.fix {
  transform: rotate(7.2deg);
}
.indicator-radial[data-progress="2"] .inset .percentage:before {
  content: "2 %";
}
.indicator-radial[data-progress="3"] .circle .mask.full,
.indicator-radial[data-progress="3"] .circle .fill {
  transform: rotate(5.4deg);
}
.indicator-radial[data-progress="3"] .circle .fill.fix {
  transform: rotate(10.8deg);
}
.indicator-radial[data-progress="3"] .inset .percentage:before {
  content: "3 %";
}
.indicator-radial[data-progress="4"] .circle .mask.full,
.indicator-radial[data-progress="4"] .circle .fill {
  transform: rotate(7.2deg);
}
.indicator-radial[data-progress="4"] .circle .fill.fix {
  transform: rotate(14.4deg);
}
.indicator-radial[data-progress="4"] .inset .percentage:before {
  content: "4 %";
}
.indicator-radial[data-progress="5"] .circle .mask.full,
.indicator-radial[data-progress="5"] .circle .fill {
  transform: rotate(9deg);
}
.indicator-radial[data-progress="5"] .circle .fill.fix {
  transform: rotate(18deg);
}
.indicator-radial[data-progress="5"] .inset .percentage:before {
  content: "5 %";
}
.indicator-radial[data-progress="6"] .circle .mask.full,
.indicator-radial[data-progress="6"] .circle .fill {
  transform: rotate(10.8deg);
}
.indicator-radial[data-progress="6"] .circle .fill.fix {
  transform: rotate(21.6deg);
}
.indicator-radial[data-progress="6"] .inset .percentage:before {
  content: "6 %";
}
.indicator-radial[data-progress="7"] .circle .mask.full,
.indicator-radial[data-progress="7"] .circle .fill {
  transform: rotate(12.6deg);
}
.indicator-radial[data-progress="7"] .circle .fill.fix {
  transform: rotate(25.2deg);
}
.indicator-radial[data-progress="7"] .inset .percentage:before {
  content: "7 %";
}
.indicator-radial[data-progress="8"] .circle .mask.full,
.indicator-radial[data-progress="8"] .circle .fill {
  transform: rotate(14.4deg);
}
.indicator-radial[data-progress="8"] .circle .fill.fix {
  transform: rotate(28.8deg);
}
.indicator-radial[data-progress="8"] .inset .percentage:before {
  content: "8 %";
}
.indicator-radial[data-progress="9"] .circle .mask.full,
.indicator-radial[data-progress="9"] .circle .fill {
  transform: rotate(16.2deg);
}
.indicator-radial[data-progress="9"] .circle .fill.fix {
  transform: rotate(32.4deg);
}
.indicator-radial[data-progress="9"] .inset .percentage:before {
  content: "9 %";
}
.indicator-radial[data-progress="10"] .circle .mask.full,
.indicator-radial[data-progress="10"] .circle .fill {
  transform: rotate(18deg);
}
.indicator-radial[data-progress="10"] .circle .fill.fix {
  transform: rotate(36deg);
}
.indicator-radial[data-progress="10"] .inset .percentage:before {
  content: "10 %";
}
.indicator-radial[data-progress="11"] .circle .mask.full,
.indicator-radial[data-progress="11"] .circle .fill {
  transform: rotate(19.8deg);
}
.indicator-radial[data-progress="11"] .circle .fill.fix {
  transform: rotate(39.6deg);
}
.indicator-radial[data-progress="11"] .inset .percentage:before {
  content: "11 %";
}
.indicator-radial[data-progress="12"] .circle .mask.full,
.indicator-radial[data-progress="12"] .circle .fill {
  transform: rotate(21.6deg);
}
.indicator-radial[data-progress="12"] .circle .fill.fix {
  transform: rotate(43.2deg);
}
.indicator-radial[data-progress="12"] .inset .percentage:before {
  content: "12 %";
}
.indicator-radial[data-progress="13"] .circle .mask.full,
.indicator-radial[data-progress="13"] .circle .fill {
  transform: rotate(23.4deg);
}
.indicator-radial[data-progress="13"] .circle .fill.fix {
  transform: rotate(46.8deg);
}
.indicator-radial[data-progress="13"] .inset .percentage:before {
  content: "13 %";
}
.indicator-radial[data-progress="14"] .circle .mask.full,
.indicator-radial[data-progress="14"] .circle .fill {
  transform: rotate(25.2deg);
}
.indicator-radial[data-progress="14"] .circle .fill.fix {
  transform: rotate(50.4deg);
}
.indicator-radial[data-progress="14"] .inset .percentage:before {
  content: "14 %";
}
.indicator-radial[data-progress="15"] .circle .mask.full,
.indicator-radial[data-progress="15"] .circle .fill {
  transform: rotate(27deg);
}
.indicator-radial[data-progress="15"] .circle .fill.fix {
  transform: rotate(54deg);
}
.indicator-radial[data-progress="15"] .inset .percentage:before {
  content: "15 %";
}
.indicator-radial[data-progress="16"] .circle .mask.full,
.indicator-radial[data-progress="16"] .circle .fill {
  transform: rotate(28.8deg);
}
.indicator-radial[data-progress="16"] .circle .fill.fix {
  transform: rotate(57.6deg);
}
.indicator-radial[data-progress="16"] .inset .percentage:before {
  content: "16 %";
}
.indicator-radial[data-progress="17"] .circle .mask.full,
.indicator-radial[data-progress="17"] .circle .fill {
  transform: rotate(30.6deg);
}
.indicator-radial[data-progress="17"] .circle .fill.fix {
  transform: rotate(61.2deg);
}
.indicator-radial[data-progress="17"] .inset .percentage:before {
  content: "17 %";
}
.indicator-radial[data-progress="18"] .circle .mask.full,
.indicator-radial[data-progress="18"] .circle .fill {
  transform: rotate(32.4deg);
}
.indicator-radial[data-progress="18"] .circle .fill.fix {
  transform: rotate(64.8deg);
}
.indicator-radial[data-progress="18"] .inset .percentage:before {
  content: "18 %";
}
.indicator-radial[data-progress="19"] .circle .mask.full,
.indicator-radial[data-progress="19"] .circle .fill {
  transform: rotate(34.2deg);
}
.indicator-radial[data-progress="19"] .circle .fill.fix {
  transform: rotate(68.4deg);
}
.indicator-radial[data-progress="19"] .inset .percentage:before {
  content: "19 %";
}
.indicator-radial[data-progress="20"] .circle .mask.full,
.indicator-radial[data-progress="20"] .circle .fill {
  transform: rotate(36deg);
}
.indicator-radial[data-progress="20"] .circle .fill.fix {
  transform: rotate(72deg);
}
.indicator-radial[data-progress="20"] .inset .percentage:before {
  content: "20 %";
}
.indicator-radial[data-progress="21"] .circle .mask.full,
.indicator-radial[data-progress="21"] .circle .fill {
  transform: rotate(37.8deg);
}
.indicator-radial[data-progress="21"] .circle .fill.fix {
  transform: rotate(75.6deg);
}
.indicator-radial[data-progress="21"] .inset .percentage:before {
  content: "21 %";
}
.indicator-radial[data-progress="22"] .circle .mask.full,
.indicator-radial[data-progress="22"] .circle .fill {
  transform: rotate(39.6deg);
}
.indicator-radial[data-progress="22"] .circle .fill.fix {
  transform: rotate(79.2deg);
}
.indicator-radial[data-progress="22"] .inset .percentage:before {
  content: "22 %";
}
.indicator-radial[data-progress="23"] .circle .mask.full,
.indicator-radial[data-progress="23"] .circle .fill {
  transform: rotate(41.4deg);
}
.indicator-radial[data-progress="23"] .circle .fill.fix {
  transform: rotate(82.8deg);
}
.indicator-radial[data-progress="23"] .inset .percentage:before {
  content: "23 %";
}
.indicator-radial[data-progress="24"] .circle .mask.full,
.indicator-radial[data-progress="24"] .circle .fill {
  transform: rotate(43.2deg);
}
.indicator-radial[data-progress="24"] .circle .fill.fix {
  transform: rotate(86.4deg);
}
.indicator-radial[data-progress="24"] .inset .percentage:before {
  content: "24 %";
}
.indicator-radial[data-progress="25"] .circle .mask.full,
.indicator-radial[data-progress="25"] .circle .fill {
  transform: rotate(45deg);
}
.indicator-radial[data-progress="25"] .circle .fill.fix {
  transform: rotate(90deg);
}
.indicator-radial[data-progress="25"] .inset .percentage:before {
  content: "25 %";
}
.indicator-radial[data-progress="26"] .circle .mask.full,
.indicator-radial[data-progress="26"] .circle .fill {
  transform: rotate(46.8deg);
}
.indicator-radial[data-progress="26"] .circle .fill.fix {
  transform: rotate(93.6deg);
}
.indicator-radial[data-progress="26"] .inset .percentage:before {
  content: "26 %";
}
.indicator-radial[data-progress="27"] .circle .mask.full,
.indicator-radial[data-progress="27"] .circle .fill {
  transform: rotate(48.6deg);
}
.indicator-radial[data-progress="27"] .circle .fill.fix {
  transform: rotate(97.2deg);
}
.indicator-radial[data-progress="27"] .inset .percentage:before {
  content: "27 %";
}
.indicator-radial[data-progress="28"] .circle .mask.full,
.indicator-radial[data-progress="28"] .circle .fill {
  transform: rotate(50.4deg);
}
.indicator-radial[data-progress="28"] .circle .fill.fix {
  transform: rotate(100.8deg);
}
.indicator-radial[data-progress="28"] .inset .percentage:before {
  content: "28 %";
}
.indicator-radial[data-progress="29"] .circle .mask.full,
.indicator-radial[data-progress="29"] .circle .fill {
  transform: rotate(52.2deg);
}
.indicator-radial[data-progress="29"] .circle .fill.fix {
  transform: rotate(104.4deg);
}
.indicator-radial[data-progress="29"] .inset .percentage:before {
  content: "29 %";
}
.indicator-radial[data-progress="30"] .circle .mask.full,
.indicator-radial[data-progress="30"] .circle .fill {
  transform: rotate(54deg);
}
.indicator-radial[data-progress="30"] .circle .fill.fix {
  transform: rotate(108deg);
}
.indicator-radial[data-progress="30"] .inset .percentage:before {
  content: "30 %";
}
.indicator-radial[data-progress="31"] .circle .mask.full,
.indicator-radial[data-progress="31"] .circle .fill {
  transform: rotate(55.8deg);
}
.indicator-radial[data-progress="31"] .circle .fill.fix {
  transform: rotate(111.6deg);
}
.indicator-radial[data-progress="31"] .inset .percentage:before {
  content: "31 %";
}
.indicator-radial[data-progress="32"] .circle .mask.full,
.indicator-radial[data-progress="32"] .circle .fill {
  transform: rotate(57.6deg);
}
.indicator-radial[data-progress="32"] .circle .fill.fix {
  transform: rotate(115.2deg);
}
.indicator-radial[data-progress="32"] .inset .percentage:before {
  content: "32 %";
}
.indicator-radial[data-progress="33"] .circle .mask.full,
.indicator-radial[data-progress="33"] .circle .fill {
  transform: rotate(59.4deg);
}
.indicator-radial[data-progress="33"] .circle .fill.fix {
  transform: rotate(118.8deg);
}
.indicator-radial[data-progress="33"] .inset .percentage:before {
  content: "33 %";
}
.indicator-radial[data-progress="34"] .circle .mask.full,
.indicator-radial[data-progress="34"] .circle .fill {
  transform: rotate(61.2deg);
}
.indicator-radial[data-progress="34"] .circle .fill.fix {
  transform: rotate(122.4deg);
}
.indicator-radial[data-progress="34"] .inset .percentage:before {
  content: "34 %";
}
.indicator-radial[data-progress="35"] .circle .mask.full,
.indicator-radial[data-progress="35"] .circle .fill {
  transform: rotate(63deg);
}
.indicator-radial[data-progress="35"] .circle .fill.fix {
  transform: rotate(126deg);
}
.indicator-radial[data-progress="35"] .inset .percentage:before {
  content: "35 %";
}
.indicator-radial[data-progress="36"] .circle .mask.full,
.indicator-radial[data-progress="36"] .circle .fill {
  transform: rotate(64.8deg);
}
.indicator-radial[data-progress="36"] .circle .fill.fix {
  transform: rotate(129.6deg);
}
.indicator-radial[data-progress="36"] .inset .percentage:before {
  content: "36 %";
}
.indicator-radial[data-progress="37"] .circle .mask.full,
.indicator-radial[data-progress="37"] .circle .fill {
  transform: rotate(66.6deg);
}
.indicator-radial[data-progress="37"] .circle .fill.fix {
  transform: rotate(133.2deg);
}
.indicator-radial[data-progress="37"] .inset .percentage:before {
  content: "37 %";
}
.indicator-radial[data-progress="38"] .circle .mask.full,
.indicator-radial[data-progress="38"] .circle .fill {
  transform: rotate(68.4deg);
}
.indicator-radial[data-progress="38"] .circle .fill.fix {
  transform: rotate(136.8deg);
}
.indicator-radial[data-progress="38"] .inset .percentage:before {
  content: "38 %";
}
.indicator-radial[data-progress="39"] .circle .mask.full,
.indicator-radial[data-progress="39"] .circle .fill {
  transform: rotate(70.2deg);
}
.indicator-radial[data-progress="39"] .circle .fill.fix {
  transform: rotate(140.4deg);
}
.indicator-radial[data-progress="39"] .inset .percentage:before {
  content: "39 %";
}
.indicator-radial[data-progress="40"] .circle .mask.full,
.indicator-radial[data-progress="40"] .circle .fill {
  transform: rotate(72deg);
}
.indicator-radial[data-progress="40"] .circle .fill.fix {
  transform: rotate(144deg);
}
.indicator-radial[data-progress="40"] .inset .percentage:before {
  content: "40 %";
}
.indicator-radial[data-progress="41"] .circle .mask.full,
.indicator-radial[data-progress="41"] .circle .fill {
  transform: rotate(73.8deg);
}
.indicator-radial[data-progress="41"] .circle .fill.fix {
  transform: rotate(147.6deg);
}
.indicator-radial[data-progress="41"] .inset .percentage:before {
  content: "41 %";
}
.indicator-radial[data-progress="42"] .circle .mask.full,
.indicator-radial[data-progress="42"] .circle .fill {
  transform: rotate(75.6deg);
}
.indicator-radial[data-progress="42"] .circle .fill.fix {
  transform: rotate(151.2deg);
}
.indicator-radial[data-progress="42"] .inset .percentage:before {
  content: "42 %";
}
.indicator-radial[data-progress="43"] .circle .mask.full,
.indicator-radial[data-progress="43"] .circle .fill {
  transform: rotate(77.4deg);
}
.indicator-radial[data-progress="43"] .circle .fill.fix {
  transform: rotate(154.8deg);
}
.indicator-radial[data-progress="43"] .inset .percentage:before {
  content: "43 %";
}
.indicator-radial[data-progress="44"] .circle .mask.full,
.indicator-radial[data-progress="44"] .circle .fill {
  transform: rotate(79.2deg);
}
.indicator-radial[data-progress="44"] .circle .fill.fix {
  transform: rotate(158.4deg);
}
.indicator-radial[data-progress="44"] .inset .percentage:before {
  content: "44 %";
}
.indicator-radial[data-progress="45"] .circle .mask.full,
.indicator-radial[data-progress="45"] .circle .fill {
  transform: rotate(81deg);
}
.indicator-radial[data-progress="45"] .circle .fill.fix {
  transform: rotate(162deg);
}
.indicator-radial[data-progress="45"] .inset .percentage:before {
  content: "45 %";
}
.indicator-radial[data-progress="46"] .circle .mask.full,
.indicator-radial[data-progress="46"] .circle .fill {
  transform: rotate(82.8deg);
}
.indicator-radial[data-progress="46"] .circle .fill.fix {
  transform: rotate(165.6deg);
}
.indicator-radial[data-progress="46"] .inset .percentage:before {
  content: "46 %";
}
.indicator-radial[data-progress="47"] .circle .mask.full,
.indicator-radial[data-progress="47"] .circle .fill {
  transform: rotate(84.6deg);
}
.indicator-radial[data-progress="47"] .circle .fill.fix {
  transform: rotate(169.2deg);
}
.indicator-radial[data-progress="47"] .inset .percentage:before {
  content: "47 %";
}
.indicator-radial[data-progress="48"] .circle .mask.full,
.indicator-radial[data-progress="48"] .circle .fill {
  transform: rotate(86.4deg);
}
.indicator-radial[data-progress="48"] .circle .fill.fix {
  transform: rotate(172.8deg);
}
.indicator-radial[data-progress="48"] .inset .percentage:before {
  content: "48 %";
}
.indicator-radial[data-progress="49"] .circle .mask.full,
.indicator-radial[data-progress="49"] .circle .fill {
  transform: rotate(88.2deg);
}
.indicator-radial[data-progress="49"] .circle .fill.fix {
  transform: rotate(176.4deg);
}
.indicator-radial[data-progress="49"] .inset .percentage:before {
  content: "49 %";
}
.indicator-radial[data-progress="50"] .circle .mask.full,
.indicator-radial[data-progress="50"] .circle .fill {
  transform: rotate(90deg);
}
.indicator-radial[data-progress="50"] .circle .fill.fix {
  transform: rotate(180deg);
}
.indicator-radial[data-progress="50"] .inset .percentage:before {
  content: "50 %";
}
.indicator-radial[data-progress="51"] .circle .mask.full,
.indicator-radial[data-progress="51"] .circle .fill {
  transform: rotate(91.8deg);
}
.indicator-radial[data-progress="51"] .circle .fill.fix {
  transform: rotate(183.6deg);
}
.indicator-radial[data-progress="51"] .inset .percentage:before {
  content: "51 %";
}
.indicator-radial[data-progress="52"] .circle .mask.full,
.indicator-radial[data-progress="52"] .circle .fill {
  transform: rotate(93.6deg);
}
.indicator-radial[data-progress="52"] .circle .fill.fix {
  transform: rotate(187.2deg);
}
.indicator-radial[data-progress="52"] .inset .percentage:before {
  content: "52 %";
}
.indicator-radial[data-progress="53"] .circle .mask.full,
.indicator-radial[data-progress="53"] .circle .fill {
  transform: rotate(95.4deg);
}
.indicator-radial[data-progress="53"] .circle .fill.fix {
  transform: rotate(190.8deg);
}
.indicator-radial[data-progress="53"] .inset .percentage:before {
  content: "53 %";
}
.indicator-radial[data-progress="54"] .circle .mask.full,
.indicator-radial[data-progress="54"] .circle .fill {
  transform: rotate(97.2deg);
}
.indicator-radial[data-progress="54"] .circle .fill.fix {
  transform: rotate(194.4deg);
}
.indicator-radial[data-progress="54"] .inset .percentage:before {
  content: "54 %";
}
.indicator-radial[data-progress="55"] .circle .mask.full,
.indicator-radial[data-progress="55"] .circle .fill {
  transform: rotate(99deg);
}
.indicator-radial[data-progress="55"] .circle .fill.fix {
  transform: rotate(198deg);
}
.indicator-radial[data-progress="55"] .inset .percentage:before {
  content: "55 %";
}
.indicator-radial[data-progress="56"] .circle .mask.full,
.indicator-radial[data-progress="56"] .circle .fill {
  transform: rotate(100.8deg);
}
.indicator-radial[data-progress="56"] .circle .fill.fix {
  transform: rotate(201.6deg);
}
.indicator-radial[data-progress="56"] .inset .percentage:before {
  content: "56 %";
}
.indicator-radial[data-progress="57"] .circle .mask.full,
.indicator-radial[data-progress="57"] .circle .fill {
  transform: rotate(102.6deg);
}
.indicator-radial[data-progress="57"] .circle .fill.fix {
  transform: rotate(205.2deg);
}
.indicator-radial[data-progress="57"] .inset .percentage:before {
  content: "57 %";
}
.indicator-radial[data-progress="58"] .circle .mask.full,
.indicator-radial[data-progress="58"] .circle .fill {
  transform: rotate(104.4deg);
}
.indicator-radial[data-progress="58"] .circle .fill.fix {
  transform: rotate(208.8deg);
}
.indicator-radial[data-progress="58"] .inset .percentage:before {
  content: "58 %";
}
.indicator-radial[data-progress="59"] .circle .mask.full,
.indicator-radial[data-progress="59"] .circle .fill {
  transform: rotate(106.2deg);
}
.indicator-radial[data-progress="59"] .circle .fill.fix {
  transform: rotate(212.4deg);
}
.indicator-radial[data-progress="59"] .inset .percentage:before {
  content: "59 %";
}
.indicator-radial[data-progress="60"] .circle .mask.full,
.indicator-radial[data-progress="60"] .circle .fill {
  transform: rotate(108deg);
}
.indicator-radial[data-progress="60"] .circle .fill.fix {
  transform: rotate(216deg);
}
.indicator-radial[data-progress="60"] .inset .percentage:before {
  content: "60 %";
}
.indicator-radial[data-progress="61"] .circle .mask.full,
.indicator-radial[data-progress="61"] .circle .fill {
  transform: rotate(109.8deg);
}
.indicator-radial[data-progress="61"] .circle .fill.fix {
  transform: rotate(219.6deg);
}
.indicator-radial[data-progress="61"] .inset .percentage:before {
  content: "61 %";
}
.indicator-radial[data-progress="62"] .circle .mask.full,
.indicator-radial[data-progress="62"] .circle .fill {
  transform: rotate(111.6deg);
}
.indicator-radial[data-progress="62"] .circle .fill.fix {
  transform: rotate(223.2deg);
}
.indicator-radial[data-progress="62"] .inset .percentage:before {
  content: "62 %";
}
.indicator-radial[data-progress="63"] .circle .mask.full,
.indicator-radial[data-progress="63"] .circle .fill {
  transform: rotate(113.4deg);
}
.indicator-radial[data-progress="63"] .circle .fill.fix {
  transform: rotate(226.8deg);
}
.indicator-radial[data-progress="63"] .inset .percentage:before {
  content: "63 %";
}
.indicator-radial[data-progress="64"] .circle .mask.full,
.indicator-radial[data-progress="64"] .circle .fill {
  transform: rotate(115.2deg);
}
.indicator-radial[data-progress="64"] .circle .fill.fix {
  transform: rotate(230.4deg);
}
.indicator-radial[data-progress="64"] .inset .percentage:before {
  content: "64 %";
}
.indicator-radial[data-progress="65"] .circle .mask.full,
.indicator-radial[data-progress="65"] .circle .fill {
  transform: rotate(117deg);
}
.indicator-radial[data-progress="65"] .circle .fill.fix {
  transform: rotate(234deg);
}
.indicator-radial[data-progress="65"] .inset .percentage:before {
  content: "65 %";
}
.indicator-radial[data-progress="66"] .circle .mask.full,
.indicator-radial[data-progress="66"] .circle .fill {
  transform: rotate(118.8deg);
}
.indicator-radial[data-progress="66"] .circle .fill.fix {
  transform: rotate(237.6deg);
}
.indicator-radial[data-progress="66"] .inset .percentage:before {
  content: "66 %";
}
.indicator-radial[data-progress="67"] .circle .mask.full,
.indicator-radial[data-progress="67"] .circle .fill {
  transform: rotate(120.6deg);
}
.indicator-radial[data-progress="67"] .circle .fill.fix {
  transform: rotate(241.2deg);
}
.indicator-radial[data-progress="67"] .inset .percentage:before {
  content: "67 %";
}
.indicator-radial[data-progress="68"] .circle .mask.full,
.indicator-radial[data-progress="68"] .circle .fill {
  transform: rotate(122.4deg);
}
.indicator-radial[data-progress="68"] .circle .fill.fix {
  transform: rotate(244.8deg);
}
.indicator-radial[data-progress="68"] .inset .percentage:before {
  content: "68 %";
}
.indicator-radial[data-progress="69"] .circle .mask.full,
.indicator-radial[data-progress="69"] .circle .fill {
  transform: rotate(124.2deg);
}
.indicator-radial[data-progress="69"] .circle .fill.fix {
  transform: rotate(248.4deg);
}
.indicator-radial[data-progress="69"] .inset .percentage:before {
  content: "69 %";
}
.indicator-radial[data-progress="70"] .circle .mask.full,
.indicator-radial[data-progress="70"] .circle .fill {
  transform: rotate(126deg);
}
.indicator-radial[data-progress="70"] .circle .fill.fix {
  transform: rotate(252deg);
}
.indicator-radial[data-progress="70"] .inset .percentage:before {
  content: "70 %";
}
.indicator-radial[data-progress="71"] .circle .mask.full,
.indicator-radial[data-progress="71"] .circle .fill {
  transform: rotate(127.8deg);
}
.indicator-radial[data-progress="71"] .circle .fill.fix {
  transform: rotate(255.6deg);
}
.indicator-radial[data-progress="71"] .inset .percentage:before {
  content: "71 %";
}
.indicator-radial[data-progress="72"] .circle .mask.full,
.indicator-radial[data-progress="72"] .circle .fill {
  transform: rotate(129.6deg);
}
.indicator-radial[data-progress="72"] .circle .fill.fix {
  transform: rotate(259.2deg);
}
.indicator-radial[data-progress="72"] .inset .percentage:before {
  content: "72 %";
}
.indicator-radial[data-progress="73"] .circle .mask.full,
.indicator-radial[data-progress="73"] .circle .fill {
  transform: rotate(131.4deg);
}
.indicator-radial[data-progress="73"] .circle .fill.fix {
  transform: rotate(262.8deg);
}
.indicator-radial[data-progress="73"] .inset .percentage:before {
  content: "73 %";
}
.indicator-radial[data-progress="74"] .circle .mask.full,
.indicator-radial[data-progress="74"] .circle .fill {
  transform: rotate(133.2deg);
}
.indicator-radial[data-progress="74"] .circle .fill.fix {
  transform: rotate(266.4deg);
}
.indicator-radial[data-progress="74"] .inset .percentage:before {
  content: "74 %";
}
.indicator-radial[data-progress="75"] .circle .mask.full,
.indicator-radial[data-progress="75"] .circle .fill {
  transform: rotate(135deg);
}
.indicator-radial[data-progress="75"] .circle .fill.fix {
  transform: rotate(270deg);
}
.indicator-radial[data-progress="75"] .inset .percentage:before {
  content: "75 %";
}
.indicator-radial[data-progress="76"] .circle .mask.full,
.indicator-radial[data-progress="76"] .circle .fill {
  transform: rotate(136.8deg);
}
.indicator-radial[data-progress="76"] .circle .fill.fix {
  transform: rotate(273.6deg);
}
.indicator-radial[data-progress="76"] .inset .percentage:before {
  content: "76 %";
}
.indicator-radial[data-progress="77"] .circle .mask.full,
.indicator-radial[data-progress="77"] .circle .fill {
  transform: rotate(138.6deg);
}
.indicator-radial[data-progress="77"] .circle .fill.fix {
  transform: rotate(277.2deg);
}
.indicator-radial[data-progress="77"] .inset .percentage:before {
  content: "77 %";
}
.indicator-radial[data-progress="78"] .circle .mask.full,
.indicator-radial[data-progress="78"] .circle .fill {
  transform: rotate(140.4deg);
}
.indicator-radial[data-progress="78"] .circle .fill.fix {
  transform: rotate(280.8deg);
}
.indicator-radial[data-progress="78"] .inset .percentage:before {
  content: "78 %";
}
.indicator-radial[data-progress="79"] .circle .mask.full,
.indicator-radial[data-progress="79"] .circle .fill {
  transform: rotate(142.2deg);
}
.indicator-radial[data-progress="79"] .circle .fill.fix {
  transform: rotate(284.4deg);
}
.indicator-radial[data-progress="79"] .inset .percentage:before {
  content: "79 %";
}
.indicator-radial[data-progress="80"] .circle .mask.full,
.indicator-radial[data-progress="80"] .circle .fill {
  transform: rotate(144deg);
}
.indicator-radial[data-progress="80"] .circle .fill.fix {
  transform: rotate(288deg);
}
.indicator-radial[data-progress="80"] .inset .percentage:before {
  content: "80 %";
}
.indicator-radial[data-progress="81"] .circle .mask.full,
.indicator-radial[data-progress="81"] .circle .fill {
  transform: rotate(145.8deg);
}
.indicator-radial[data-progress="81"] .circle .fill.fix {
  transform: rotate(291.6deg);
}
.indicator-radial[data-progress="81"] .inset .percentage:before {
  content: "81 %";
}
.indicator-radial[data-progress="82"] .circle .mask.full,
.indicator-radial[data-progress="82"] .circle .fill {
  transform: rotate(147.6deg);
}
.indicator-radial[data-progress="82"] .circle .fill.fix {
  transform: rotate(295.2deg);
}
.indicator-radial[data-progress="82"] .inset .percentage:before {
  content: "82 %";
}
.indicator-radial[data-progress="83"] .circle .mask.full,
.indicator-radial[data-progress="83"] .circle .fill {
  transform: rotate(149.4deg);
}
.indicator-radial[data-progress="83"] .circle .fill.fix {
  transform: rotate(298.8deg);
}
.indicator-radial[data-progress="83"] .inset .percentage:before {
  content: "83 %";
}
.indicator-radial[data-progress="84"] .circle .mask.full,
.indicator-radial[data-progress="84"] .circle .fill {
  transform: rotate(151.2deg);
}
.indicator-radial[data-progress="84"] .circle .fill.fix {
  transform: rotate(302.4deg);
}
.indicator-radial[data-progress="84"] .inset .percentage:before {
  content: "84 %";
}
.indicator-radial[data-progress="85"] .circle .mask.full,
.indicator-radial[data-progress="85"] .circle .fill {
  transform: rotate(153deg);
}
.indicator-radial[data-progress="85"] .circle .fill.fix {
  transform: rotate(306deg);
}
.indicator-radial[data-progress="85"] .inset .percentage:before {
  content: "85 %";
}
.indicator-radial[data-progress="86"] .circle .mask.full,
.indicator-radial[data-progress="86"] .circle .fill {
  transform: rotate(154.8deg);
}
.indicator-radial[data-progress="86"] .circle .fill.fix {
  transform: rotate(309.6deg);
}
.indicator-radial[data-progress="86"] .inset .percentage:before {
  content: "86 %";
}
.indicator-radial[data-progress="87"] .circle .mask.full,
.indicator-radial[data-progress="87"] .circle .fill {
  transform: rotate(156.6deg);
}
.indicator-radial[data-progress="87"] .circle .fill.fix {
  transform: rotate(313.2deg);
}
.indicator-radial[data-progress="87"] .inset .percentage:before {
  content: "87 %";
}
.indicator-radial[data-progress="88"] .circle .mask.full,
.indicator-radial[data-progress="88"] .circle .fill {
  transform: rotate(158.4deg);
}
.indicator-radial[data-progress="88"] .circle .fill.fix {
  transform: rotate(316.8deg);
}
.indicator-radial[data-progress="88"] .inset .percentage:before {
  content: "88 %";
}
.indicator-radial[data-progress="89"] .circle .mask.full,
.indicator-radial[data-progress="89"] .circle .fill {
  transform: rotate(160.2deg);
}
.indicator-radial[data-progress="89"] .circle .fill.fix {
  transform: rotate(320.4deg);
}
.indicator-radial[data-progress="89"] .inset .percentage:before {
  content: "89 %";
}
.indicator-radial[data-progress="90"] .circle .mask.full,
.indicator-radial[data-progress="90"] .circle .fill {
  transform: rotate(162deg);
}
.indicator-radial[data-progress="90"] .circle .fill.fix {
  transform: rotate(324deg);
}
.indicator-radial[data-progress="90"] .inset .percentage:before {
  content: "90 %";
}
.indicator-radial[data-progress="91"] .circle .mask.full,
.indicator-radial[data-progress="91"] .circle .fill {
  transform: rotate(163.8deg);
}
.indicator-radial[data-progress="91"] .circle .fill.fix {
  transform: rotate(327.6deg);
}
.indicator-radial[data-progress="91"] .inset .percentage:before {
  content: "91 %";
}
.indicator-radial[data-progress="92"] .circle .mask.full,
.indicator-radial[data-progress="92"] .circle .fill {
  transform: rotate(165.6deg);
}
.indicator-radial[data-progress="92"] .circle .fill.fix {
  transform: rotate(331.2deg);
}
.indicator-radial[data-progress="92"] .inset .percentage:before {
  content: "92 %";
}
.indicator-radial[data-progress="93"] .circle .mask.full,
.indicator-radial[data-progress="93"] .circle .fill {
  transform: rotate(167.4deg);
}
.indicator-radial[data-progress="93"] .circle .fill.fix {
  transform: rotate(334.8deg);
}
.indicator-radial[data-progress="93"] .inset .percentage:before {
  content: "93 %";
}
.indicator-radial[data-progress="94"] .circle .mask.full,
.indicator-radial[data-progress="94"] .circle .fill {
  transform: rotate(169.2deg);
}
.indicator-radial[data-progress="94"] .circle .fill.fix {
  transform: rotate(338.4deg);
}
.indicator-radial[data-progress="94"] .inset .percentage:before {
  content: "94 %";
}
.indicator-radial[data-progress="95"] .circle .mask.full,
.indicator-radial[data-progress="95"] .circle .fill {
  transform: rotate(171deg);
}
.indicator-radial[data-progress="95"] .circle .fill.fix {
  transform: rotate(342deg);
}
.indicator-radial[data-progress="95"] .inset .percentage:before {
  content: "95 %";
}
.indicator-radial[data-progress="96"] .circle .mask.full,
.indicator-radial[data-progress="96"] .circle .fill {
  transform: rotate(172.8deg);
}
.indicator-radial[data-progress="96"] .circle .fill.fix {
  transform: rotate(345.6deg);
}
.indicator-radial[data-progress="96"] .inset .percentage:before {
  content: "96 %";
}
.indicator-radial[data-progress="97"] .circle .mask.full,
.indicator-radial[data-progress="97"] .circle .fill {
  transform: rotate(174.6deg);
}
.indicator-radial[data-progress="97"] .circle .fill.fix {
  transform: rotate(349.2deg);
}
.indicator-radial[data-progress="97"] .inset .percentage:before {
  content: "97 %";
}
.indicator-radial[data-progress="98"] .circle .mask.full,
.indicator-radial[data-progress="98"] .circle .fill {
  transform: rotate(176.4deg);
}
.indicator-radial[data-progress="98"] .circle .fill.fix {
  transform: rotate(352.8deg);
}
.indicator-radial[data-progress="98"] .inset .percentage:before {
  content: "98 %";
}
.indicator-radial[data-progress="99"] .circle .mask.full,
.indicator-radial[data-progress="99"] .circle .fill {
  transform: rotate(178.2deg);
}
.indicator-radial[data-progress="99"] .circle .fill.fix {
  transform: rotate(356.4deg);
}
.indicator-radial[data-progress="99"] .inset .percentage:before {
  content: "99 %";
}
.indicator-radial[data-progress="100"] .circle .mask.full,
.indicator-radial[data-progress="100"] .circle .fill {
  transform: rotate(180deg);
}
.indicator-radial[data-progress="100"] .circle .fill.fix {
  transform: rotate(360deg);
}
.indicator-radial[data-progress="100"] .inset .percentage:before {
  content: "100 %";
}
.indicator-radial.indicator-radial--sm {
  width: 2rem;
  height: 2rem;
}
.indicator-radial.indicator-radial--sm .circle .mask,
.indicator-radial.indicator-radial--sm .circle .fill,
.indicator-radial.indicator-radial--sm .circle .shadow {
  width: 2rem;
  height: 2rem;
}
.indicator-radial.indicator-radial--sm .circle .mask {
  clip: rect(0rem, 2rem, 2rem, 1rem);
}
.indicator-radial.indicator-radial--sm .circle .mask .fill {
  clip: rect(0rem, 1rem, 2rem, 0rem);
}
.indicator-radial.indicator-radial--sm .inset {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.125rem;
  margin-top: 0.125rem;
}
.indicator-radial.indicator-radial--sm .inset .percentage,
.indicator-radial.indicator-radial--sm .inset .counter {
  line-height: 1.859375rem;
  width: 1.75rem;
}
.indicator-radial.indicator-radial--sm .inset {
  position: absolute;
  border-radius: 50%;
}
.indicator-radial.indicator-radial--sm .inset .percentage {
  font-size: 0.75rem;
}
.indicator-radial.indicator-radial--sm .inset .counter {
  top: 0;
  font-size: 0.75rem;
}
.indicator-radial.indicator-radial--sm .inset .counter .counter-limit {
  font-size: 0.5625rem;
  top: -0.1875rem;
}
.indicator-radial.indicator-radial--sm .inset .counter .counter-limit:before {
  padding: 0 0.0625rem;
  font-size: 0.375rem;
}
.indicator-radial.indicator-radial--shadow .circle .shadow {
  display: block;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.125rem solid transparent;
  border-radius: 1.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #f7f7f9;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: 0.6;
}

.modal-header {
  padding: 2rem;
  border-bottom: 0.125rem solid transparent;
}
.modal-header::after {
  content: "";
  display: table;
  clear: both;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  padding: 2rem;
}

.modal-footer {
  padding: 2rem;
  text-align: right;
  border-top: 0.125rem solid transparent;
}
.modal-footer::after {
  content: "";
  display: table;
  clear: both;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 40rem;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 30rem;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 76rem;
  }
}
.modal {
  z-index: 120;
}
@media (min-width: 992px) {
  .no-touch .modal {
    height: 100%;
  }
  .no-touch .modal.in .modal-dialog {
    transform: translate(0, -50%);
  }
  .no-touch .modal .modal-dialog {
    top: 50%;
    position: relative;
    margin: 0 auto;
  }
}
.modal.modal--full .modal-dialog {
  top: 6rem;
  left: 0.5rem;
  right: 0.5rem;
  height: calc(100% - 1rem);
  max-width: none;
  margin: 0;
  position: absolute;
  transition: transform 150ms ease;
}
.modal.modal--full .modal-dialog .pane {
  height: 100%;
}
.modal.modal--full .modal-dialog .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal.modal--full.in .modal-dialog {
  transform: translate(0, -5.5rem);
}
@media (min-width: 768px) {
  .modal.modal--full .modal-dialog {
    top: 6rem;
    left: 1rem;
    right: 1rem;
    height: calc(100% - 2rem);
  }
  .modal.modal--full.in .modal-dialog {
    transform: translate(0, -5rem);
  }
}
.modal .pane {
  /* Pane inside Modal */
  width: 100%;
  height: calc(100vh - 12rem);
  position: relative;
  display: block;
}
.modal .pane .pane-close {
  display: block;
}
.modal .pane-v2 {
  position: relative;
  z-index: 1;
  min-height: 60vh;
  height: calc(100vh - 12rem);
}
.modal.bootbox .bootbox-body {
  padding-top: 2.5rem !important;
}
.modal.bootbox .bootbox-close-button {
  margin-top: 1rem !important;
  text-indent: -999rem;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
}
.modal.bootbox .bootbox-close-button:before {
  font-family: "icons-ui" !important;
  content: "\e904";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  text-indent: 0;
  text-align: center;
}
.modal.bootbox .modal-footer .btn + .btn {
  margin-left: 0.5rem;
}

.modal-backdrop {
  /* Do not use because of multiple stacked modals */
  z-index: 110;
}

.modal-dialog {
  border-radius: 0;
}

.modal-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
}

.modal-header,
.modal-body {
  display: block;
}

.modal-footer {
  display: flex;
}

.modal-header {
  padding-top: 1.5rem;
}
.modal-header .close,
.modal-header .action--close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  margin: 0;
}
.modal-header .action--close {
  top: 1rem;
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-content {
  border-radius: 0;
  border: 0;
}
.modal-content .list-group {
  margin-left: -2rem;
  margin-right: -2rem;
}
.modal-content.modal-content--loading .modal-header,
.modal-content.modal-content--loading .modal-body,
.modal-content.modal-content--loading .modal-footer {
  opacity: 0.32;
}
.modal-content.modal-content--loading + .modal-indicator.modal-indicator--loading {
  display: block;
}

.modal-footer .btn-block {
  margin-left: 0;
  margin-right: 0;
}

.modal-indicator--loading {
  display: none;
}

.modal--feedback .pane {
  height: calc(100vh - 1.5rem);
}
.modal--feedback .pane-content {
  bottom: 10.5rem;
  height: auto;
}
.modal--feedback .pane-content > .pane-section {
  color: #fff;
  text-align: center;
}
.modal--feedback .pane-content > .pane-section:first-child {
  padding-top: 2rem;
}
.modal--feedback .pane-footer {
  height: 10.5rem;
  padding-top: 2rem;
}
.mobile.safari .modal--feedback .modal-dialog {
  height: calc(100vh - 9.5rem);
}
.mobile.safari .modal--feedback .pane {
  height: 100%;
}
@media (min-width: 576px) {
  .modal--feedback .pane {
    height: calc(100vh - 12rem);
  }
}

.modal-feedback-icon {
  font-size: 7rem;
  display: block;
  margin-bottom: 1rem;
}
.modal-feedback-icon.icon--add {
  font-size: 4rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.modal-feedback-title {
  font-size: 1.75rem;
  line-height: 2rem;
  margin-bottom: 0.54249rem;
  padding-top: 0.45751rem;
}

.modal-feedback-subtitle {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.51249rem;
  padding-top: 0.48751rem;
}

.modal--pane-v2.modal .pane-v2 {
  position: absolute;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  transform: translateY(5rem);
  transition: transform 150ms ease;
  transform-origin: top left;
}
.modal--pane-v2.modal.in .pane-v2 {
  transform: translateY(0);
}

[data-modal-id=contact] .contact-title,
[data-modal-id=contact] .contact-lead {
  text-align: center;
}
[data-modal-id=contact] .contact-title {
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 1rem;
}
[data-modal-id=contact] .contact-lead {
  font-size: 1.125rem;
  line-height: 1.875rem;
}
#quickstartPopupModal {
  z-index: 999;
}
#quickstartPopupModal.modal.in {
  background: none;
}
#quickstartPopupModal .modal-dialog {
  margin-left: 0;
}
#quickstartPopupModal .modal-lg {
  max-width: 100%;
}

#quickstartOverviewModal {
  background-color: rgba(211, 217, 227, 0.72);
}
#quickstartOverviewModal .modal-dialog {
  width: 25% !important;
  height: 92%;
}
#quickstartOverviewModal nav {
  height: calc(100% - 22rem);
  overflow: scroll;
}

@media (min-width: 1028px) {
  /** Custom size to fit original images **/
  [data-modal-id=focus-original].modal--pane-v2.modal .pane-v2 {
    width: 64.25rem; /* TODO: consider width of scrollbar? */
    max-width: 100%;
    left: 50%;
    right: auto;
    margin-left: -32.125rem;
  }
}
@media (min-width: 576px) {
  .modal--pane-v2.modal.modal--xs .pane-v2 {
    width: 22.5rem;
    left: 50%;
    right: auto;
    margin-left: -11.25rem;
  }
}
@media only screen and (min-width: 576px) and (min-height: 32rem) {
  .modal--pane-v2.modal.modal--xs .pane-v2 {
    height: 30rem;
    top: 50%;
    bottom: auto;
    margin-top: -15rem;
  }
}
@media (min-width: 768px) {
  .modal--pane-v2.modal.modal--sm .pane-v2 {
    width: 30rem;
    left: 50%;
    right: auto;
    margin-left: -15rem;
  }
}
@media only screen and (min-width: 768px) and (min-height: 42rem) {
  .modal--pane-v2.modal.modal--sm .pane-v2 {
    height: 40rem;
    top: 50%;
    bottom: auto;
    margin-top: -20rem;
  }
}
@media (min-width: 1200px) {
  .modal--pane-v2.modal.modal--lg .pane-v2 {
    width: 75rem;
    left: 50%;
    right: auto;
    margin-left: -37.5rem;
  }
}
@media only screen and (min-width: 1200px) and (min-height: 58.25rem) {
  .modal--pane-v2.modal.modal--lg .pane-v2 {
    height: 56.25rem;
    top: 50%;
    bottom: auto;
    margin-top: -28.125rem;
  }
}
.menu .icon-container {
  width: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}
.menu .icon-container--arrow-right,
.menu .icon-container--add {
  width: 0.75rem;
  margin: -0.375rem 0 0 -0.375rem;
}
.menu .icon-container,
.menu .icon-container--arrow-right {
  margin: 0;
}
.menu .icon-container--arrow-upright {
  width: 1.25rem;
  margin: -0.675rem 0 0 -0.675rem;
}
.menu .icon-container--sync {
  display: inline-block;
  margin-right: 0.75rem;
}

.menu-item {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 0;
  align-items: center;
  border-bottom: 0.125rem solid transparent;
  position: relative;
  min-height: 3.125rem;
}
.menu--underline-hover .menu-item:after {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.125rem;
}
.menu--underline-hover .menu-item:hover:after, .menu--underline-hover .menu-item:focus:after, .menu--underline-hover .menu-item:hover:focus:after {
  width: 100%;
}
.menu-item.menu-item--type-heading, .menu-item.menu-item--type-label {
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin-bottom: 0.5rem;
}
.menu-item.menu-item--type-label {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}
.menu-item.menu-item--icon-home .menu-item-title {
  order: 1;
}
.menu-item.menu-item--icon-home .menu-item-icon {
  order: 0;
}
.menu-item.menu-item--active:not(.menu-item--icon-home) .menu-item-icon {
  display: none;
}
.menu-item.menu-item--active:not(.menu-item--icon-home) .menu-item-indicator:before {
  opacity: 1;
}

.menu-item-link {
  padding-top: 0.625rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: left;
  border: 0;
  margin: 0;
  flex: 1 1 auto;
}
.menu-item--suffix .menu-item-link {
  padding-right: 0;
}

.menu-item-suffix {
  display: block;
  flex: 0 0 auto;
  border: 0;
  height: 3rem;
  width: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-item-label {
  font-size: 0.875rem;
  line-height: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  flex: 0 1 100%;
  margin: 0 0 0.125rem;
}

.menu-item-title {
  font-size: 1rem;
  line-height: 1.75rem;
  flex: 1 1 auto;
  margin: 0;
  position: relative;
}
.menu-item-title:before {
  content: " ";
}
@media (min-width: 768px) {
  .pane-v2--pop .menu-item-title {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

.menu-item-title-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  width: 96%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.menu-item-count {
  margin-right: 0.75rem;
}

.menu-item-indicator,
.menu-item-icon {
  flex: 0 0 auto;
  min-width: min-intrinsic;
}

.menu-item-indicator {
  margin: 0;
}

.menu-item-note {
  font-size: 0.875rem;
  line-height: 1.5rem;
  flex: 0 1 100%;
  margin: 0;
}

.ui-helper-hidden-accessible {
  display: none;
}

.ui-autocomplete {
  position: absolute;
  left: 0;
  cursor: default;
  width: 100%;
  z-index: 101;
  display: block;
  font-size: 1rem;
}
.ui-autocomplete.ui-menu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.ui-autocomplete.ui-menu .ui-menu-item {
  list-style-type: none;
  padding: 0 1rem;
  background: transparent;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  overflow: hidden;
}
.ui-autocomplete.ui-widget-content {
  border-top: 0.0625rem solid transparent;
  background: #fff;
}

/*
$alert-padding:               16px;
$alert-border-radius:         0;
$alert-link-font-weight:      700;
$alert-border-width:          0;

$alert-success-bg:            $brand-primary;
$alert-success-text:          #fff;
$alert-success-border:        $brand-primary;

$alert-info-bg:               $brand-secondary;
$alert-info-text:             #fff;
$alert-info-border:           $brand-secondary;

$alert-warning-bg:            $brand-warning;
$alert-warning-text:          #fff;
$alert-warning-border:        $brand-warning;

$alert-danger-bg:             $brand-danger;
$alert-danger-text:           #fff;
$alert-danger-border:         $brand-danger;


@import "../../../../vendor/twbs/bootstrap/scss/alert";
*/
.alerts {
  padding: 2rem 2rem 0;
}
.alerts .icon-container {
  margin: 0;
}

.alerts-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.alerts-item-icon {
  flex: 0 0 auto;
}

.alerts-item-message {
  font-size: 1rem;
  line-height: 1.75rem;
  flex: 1 0 auto;
  padding: 0.25rem 0 0.25rem 1rem;
  hyphens: auto;
  max-width: calc(100% - 2rem);
}

[data-notify=container] {
  padding: 1.5rem 2rem !important;
  border-radius: 1.75rem !important;
  z-index: 140;
  display: block;
}
[data-notify=container] [data-notify=icon] {
  display: none;
}
[data-notify=container] [data-notify=dismiss].close {
  left: auto !important;
  top: 1rem !important;
  right: 1rem !important;
  position: absolute !important;
  text-indent: -999rem !important;
  opacity: 1 !important;
}
[data-notify=container] [data-notify=dismiss].close:after {
  content: "";
  display: block;
  opacity: 1;
  transition: opacity 150ms ease;
}
[data-notify=container] [data-notify=dismiss].close:hover:after {
  opacity: 0.6;
}
[data-notify=container] [data-notify=title] {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: -0.44001rem;
  padding-top: 0.44001rem;
  display: block !important;
  font-weight: 700;
  margin: 0 !important;
  padding: 0 !important;
}
[data-notify=container] [data-notify=message] {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: -0.35376rem;
  padding-top: 0.35376rem;
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
}
[data-notify=container][data-notify-position=top-right] {
  top: 1rem !important;
  right: 1rem !important;
}
[data-notify=container] hr {
  display: none;
}

.grid-view {
  margin: 0 0 2rem;
  max-width: 100%;
}
.grid-view.row {
  margin-left: -1rem;
  margin-right: -1rem;
  max-width: none;
}
.pane-section--inset .grid-view {
  max-width: none;
  margin-bottom: 0;
}
.pane-section--expand .grid-view {
  max-width: none;
  margin-bottom: 0;
}
.grid-view .summary {
  display: none;
}
.grid-view th,
.grid-view td {
  line-height: 1.5rem;
}
.grid-view th:first-child,
.grid-view td:first-child {
  padding-left: 1.5rem;
}
.grid-view th:first-child.kv-align-center,
.grid-view td:first-child.kv-align-center {
  text-align: left;
}
.grid-view th:last-child,
.grid-view td:last-child {
  padding-right: 1.5rem;
}
@media (min-width: 768px) {
  .grid-view th:first-child,
  .grid-view td:first-child {
    padding-left: 3rem;
  }
  .grid-view th:last-child,
  .grid-view td:last-child {
    padding-right: 3rem;
  }
}
.grid-view thead th {
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top !important;
}
.grid-view tbody td:first-child {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.grid-view tbody td *:last-child {
  margin-bottom: 0;
}
.grid-view .grid-view--actions thead th:last-child,
.grid-view .grid-view--actions thead td:last-child,
.grid-view .grid-view--actions tbody th:last-child,
.grid-view .grid-view--actions tbody td:last-child {
  text-align: center;
}
@media (max-width: 991px) {
  .grid-view .grid-view--actions td:last-child {
    position: absolute;
    top: 0;
    right: -1rem;
    width: auto !important;
  }
  .grid-view .grid-view--actions td:last-child .dropdown-menu {
    right: 2rem;
  }
}
@media (max-width: 991px) {
  .grid-view .grid-view--selectable td:first-child, .grid-view .grid-view--selectable td.kv-row-select {
    position: absolute;
    top: 0;
    left: 2rem;
    width: auto !important;
    padding-top: 0.75rem;
    margin: 0 !important;
  }
  .grid-view .grid-view--selectable td:first-child + td, .grid-view .grid-view--selectable td.kv-row-select + td {
    padding-top: 1.5rem;
  }
}
@media (min-width: 992px) {
  .grid-view--expand .grid-view tbody td:first-child {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.grid-view .grid-view-stat {
  display: inline-block;
  padding: 0 0.375rem;
}
@media (min-width: 992px) {
  .grid-view .grid-view-stat {
    display: block;
  }
  .grid-view .grid-view-stat + .grid-view-stat {
    padding-top: 0.5rem;
  }
}
.grid-view .grid-view-stat-label,
.grid-view .grid-view-stat-value {
  display: block;
  text-align: center;
}
.grid-view .grid-view-stat-label {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: -0.26751rem;
  padding-top: 0.26751rem;
}
.grid-view .grid-view-stat-value {
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: -0.19001rem;
  padding-top: 0.19001rem;
}
.grid-view .dropdown-toggle {
  text-indent: -999rem;
  background: transparent !important;
  border-color: transparent !important;
  width: 5rem;
}
.grid-view .dropdown-toggle:before {
  content: "\e90d";
  text-indent: 0;
  position: absolute;
  top: 50%;
  left: 52%;
  display: block;
  margin: -0.5rem 0 0 -0.5rem;
}
.grid-view .dropdown-toggle .caret {
  display: none;
}
.grid-view .dropdown-menu {
  left: auto;
  right: 0;
}
.grid-view .kv-row-checkbox {
  position: absolute;
  left: -999rem;
  height: 0;
}
.grid-view .kv-panel-before,
.grid-view .kv-panel-after {
  border: 0;
  padding: 0 1.5rem;
}
@media (min-width: 992px) {
  .grid-view .kv-panel-before,
  .grid-view .kv-panel-after {
    padding: 0 4rem;
  }
}
.grid-view .kv-panel-after {
  padding-top: 1.5rem;
  display: flex;
}
.grid-view .kv-panel-after .btn + .btn {
  margin-left: 0.25rem;
}
.grid-view .form-control {
  margin-left: -0.5rem;
}
.grid-view .filters {
  display: none;
}
.grid-view .filters td {
  padding-right: 1rem;
  padding-bottom: 0.25rem;
}
@media (min-width: 992px) {
  .grid-view .filters {
    display: table-row;
  }
}
@media (max-width: 767px) {
  .grid-view .kv-table-wrap tr > td:first-child {
    font-size: 1rem;
  }
}
@media (max-width: 991px) {
  .grid-view .kv-table-wrap th,
  .grid-view .kv-table-wrap td {
    display: block;
    width: 100% !important;
    text-align: center;
    font-size: inherit;
    padding-left: 0;
    padding-right: 0;
  }
  .grid-view .kv-table-wrap thead th {
    display: none !important;
  }
  .grid-view .kv-table-wrap thead th.kv-all-select {
    display: block !important;
  }
  .grid-view .kv-table-wrap tbody tr {
    display: block;
    position: relative;
    padding-bottom: 2rem;
  }
  .grid-view .kv-table-wrap tbody .highcharts-container {
    margin: 0 auto;
    padding: 0.5rem 0;
  }
}
.grid-view thead,
.grid-view .kv-row-select {
  width: 6rem !important;
}
.grid-view thead label,
.grid-view .kv-row-select label {
  margin: 0;
}
.grid-view thead .indicator,
.grid-view .kv-row-select .indicator {
  top: 0;
  margin: 0;
}
.grid-view .empty {
  padding: 10rem 0;
  text-align: center;
}

.kv-grid-loading {
  position: relative;
  background-image: none !important;
  opacity: 1;
}
.kv-grid-loading > .grid-view {
  opacity: 0.5;
}

.grid-view-title,
.grid-view-properties,
.grid-view-email,
.grid-view-date {
  display: block;
  margin: 0;
  padding: 0;
}

.grid-view-title {
  font-size: 1.125rem;
  line-height: 1.875rem;
}
.grid-view-title + p {
  margin-top: 0.25rem;
}

.grid-view-email,
.grid-view-properties {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.grid-view-date {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

/*.kv-tree-wrapper {
    border: 0;
    font-size: .875rem;

    &.form-control {
        font-size: .875rem;
    }

    .kv-header-container {
        padding: 0;
    }

    .kv-tree-container {
        padding: 0 2rem;
        border: 0;
        overflow: visible;

        @include media-breakpoint-up(lg) {
            padding-left: 4rem;
            padding-right: 4rem;
        }

        .kv-tree {
            padding-left: 0;
            margin-top: 1rem !important;

            > ul {
                padding-left: 0 !important;
            }
        }
    }

    .kv-tree-root {
        padding: 0;
        display: none;
    }

    .kv-tree-list {
        top: 1.125rem;
    }

    .kv-tree ul,
    .kv-tree li {
        padding-left: .75rem;
    }

    .kv-tree li {
        line-height: 2rem;
        border-style: solid;
    }

    .kv-tree li ul {
        border-top-style: solid;
    }

    .kv-node-detail,
    .kv-node-label {
        white-space: nowrap;
    }

    .kv-has-checkbox .kv-selected > .kv-tree-list .kv-node-detail {
        background: transparent;

        .kv-node-label {
            font-weight: 700;
        }
    }

    .kv-node-detail:hover {
        background: transparent !important;
    }

    .kv-highlight {
        padding: 0;
        background: transparent;
    }
}

.kv-search-clear {
    //@extend %icon;
    //@extend %icon-mute;
    //background-position: 0 $icon-clear;
    text-indent: -999rem;
    overflow: hidden;
    cursor: pointer;
    left: auto;
    right: 2rem !important;

    .no-touch & {
        opacity: 0;
        transition: opacity 150ms ease;
    }

    .no-touch .kv-search-container:hover & {
        opacity: 1;
    }

    @include media-breakpoint-up(xl) {
        right: 4rem !important;
    }
}

.kv-loading-search {
    position: relative;
    background-image: none !important;
}*/
/*
 * Fancytree "Lion" skin.
 *
 * DON'T EDIT THE CSS FILE DIRECTLY, since it is automatically generated from
 * the LESS templates.
 */
/*
 Lion colors:
	gray highlight bar: #D4D4D4
	blue highlight-bar and -border #3875D7

*/
/*******************************************************************************
 * Common Styles for Fancytree Skins.
 *
 * This section is automatically generated from the `skin-common.less` template.
 ******************************************************************************/
/*------------------------------------------------------------------------------
 * Helpers
 *----------------------------------------------------------------------------*/
.ui-helper-hidden {
  display: none;
}

/*------------------------------------------------------------------------------
 * Container and UL / LI
 *----------------------------------------------------------------------------*/
ul.fancytree-container {
  font-family: tahoma, arial, helvetica;
  font-size: 10pt;
  white-space: nowrap;
  padding: 3px;
  margin: 0;
  background-color: white;
  border: 1px dotted gray;
  min-height: 0%;
  position: relative;
}

ul.fancytree-container ul {
  padding: 0 0 0 16px;
  margin: 0;
}

ul.fancytree-container ul > li:before {
  content: none;
}

ul.fancytree-container li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  background-image: none;
  margin: 0;
}

ul.fancytree-container li.fancytree-lastsib {
  background-image: none;
}

.ui-fancytree-disabled ul.fancytree-container {
  opacity: 0.5;
  background-color: silver;
}

ul.fancytree-connectors.fancytree-container li {
  background-image: url("../skin-lion/vline.gif");
  background-position: 0 0;
}

ul.fancytree-container li.fancytree-lastsib,
ul.fancytree-no-connector > li {
  background-image: none;
}

li.fancytree-animating {
  position: relative;
}

/*------------------------------------------------------------------------------
 * Common icon definitions
 *----------------------------------------------------------------------------*/
span.fancytree-empty,
span.fancytree-vline,
span.fancytree-expander,
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-drag-helper-img,
#fancytree-drop-marker {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../skin-lion/icons.gif");
  background-position: 0px 0px;
}

span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-expander,
span.fancytree-custom-icon {
  margin-top: 0px;
}

/* Used by icon option: */
span.fancytree-custom-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 3px;
  background-position: 0px 0px;
}

/* Used by 'icon' node option: */
img.fancytree-icon {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 0px;
  vertical-align: top;
  border-style: none;
}

/*------------------------------------------------------------------------------
 * Expander icon
 *
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *		 so we create combined class names that can be used in the CSS.
 *
 * Prefix: fancytree-exp-
 * 1st character: 'e': expanded, 'c': collapsed, 'n': no children
 * 2nd character (optional): 'd': lazy (Delayed)
 * 3rd character (optional): 'l': Last sibling
 *----------------------------------------------------------------------------*/
span.fancytree-expander {
  cursor: pointer;
}

.fancytree-exp-n span.fancytree-expander,
.fancytree-exp-nl span.fancytree-expander {
  background-image: none;
  cursor: default;
}

.fancytree-connectors .fancytree-exp-n span.fancytree-expander,
.fancytree-connectors .fancytree-exp-nl span.fancytree-expander {
  background-image: url("../skin-lion/icons.gif");
  margin-top: 0;
}

.fancytree-connectors .fancytree-exp-n span.fancytree-expander,
.fancytree-connectors .fancytree-exp-n span.fancytree-expander:hover {
  background-position: 0px -64px;
}

.fancytree-connectors .fancytree-exp-nl span.fancytree-expander,
.fancytree-connectors .fancytree-exp-nl span.fancytree-expander:hover {
  background-position: -16px -64px;
}

.fancytree-exp-c span.fancytree-expander {
  background-position: 0px -80px;
}

.fancytree-exp-c span.fancytree-expander:hover {
  background-position: -16px -80px;
}

.fancytree-exp-cl span.fancytree-expander {
  background-position: 0px -96px;
}

.fancytree-exp-cl span.fancytree-expander:hover {
  background-position: -16px -96px;
}

.fancytree-exp-cd span.fancytree-expander {
  background-position: -64px -80px;
}

.fancytree-exp-cd span.fancytree-expander:hover {
  background-position: -80px -80px;
}

.fancytree-exp-cdl span.fancytree-expander {
  background-position: -64px -96px;
}

.fancytree-exp-cdl span.fancytree-expander:hover {
  background-position: -80px -96px;
}

.fancytree-exp-e span.fancytree-expander,
.fancytree-exp-ed span.fancytree-expander {
  background-position: -32px -80px;
}

.fancytree-exp-e span.fancytree-expander:hover,
.fancytree-exp-ed span.fancytree-expander:hover {
  background-position: -48px -80px;
}

.fancytree-exp-el span.fancytree-expander,
.fancytree-exp-edl span.fancytree-expander {
  background-position: -32px -96px;
}

.fancytree-exp-el span.fancytree-expander:hover,
.fancytree-exp-edl span.fancytree-expander:hover {
  background-position: -48px -96px;
}

/* Fade out expanders, when container is not hovered or active */
.fancytree-fade-expander span.fancytree-expander {
  transition: opacity 1.5s;
  opacity: 0;
}

.fancytree-fade-expander:hover span.fancytree-expander,
.fancytree-fade-expander.fancytree-treefocus span.fancytree-expander,
.fancytree-fade-expander .fancytree-treefocus span.fancytree-expander,
.fancytree-fade-expander [class*=fancytree-statusnode-] span.fancytree-expander {
  transition: opacity 0.6s;
  opacity: 1;
}

/*------------------------------------------------------------------------------
 * Checkbox icon
 *----------------------------------------------------------------------------*/
span.fancytree-checkbox {
  margin-left: 3px;
  background-position: 0px -32px;
}

span.fancytree-checkbox:hover {
  background-position: -16px -32px;
}

span.fancytree-checkbox.fancytree-radio {
  background-position: 0px -48px;
}

span.fancytree-checkbox.fancytree-radio:hover {
  background-position: -16px -48px;
}

.fancytree-partsel span.fancytree-checkbox {
  background-position: -64px -32px;
}

.fancytree-partsel span.fancytree-checkbox:hover {
  background-position: -80px -32px;
}

.fancytree-partsel span.fancytree-checkbox.fancytree-radio {
  background-position: -64px -48px;
}

.fancytree-partsel span.fancytree-checkbox.fancytree-radio:hover {
  background-position: -80px -48px;
}

.fancytree-selected span.fancytree-checkbox {
  background-position: -32px -32px;
}

.fancytree-selected span.fancytree-checkbox:hover {
  background-position: -48px -32px;
}

.fancytree-selected span.fancytree-checkbox.fancytree-radio {
  background-position: -32px -48px;
}

.fancytree-selected span.fancytree-checkbox.fancytree-radio:hover {
  background-position: -48px -48px;
}

.fancytree-unselectable span.fancytree-checkbox {
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.fancytree-unselectable span.fancytree-checkbox:hover {
  background-position: 0px -32px;
}

.fancytree-unselectable.fancytree-partsel span.fancytree-checkbox:hover {
  background-position: -64px -32px;
}

.fancytree-unselectable.fancytree-selected span.fancytree-checkbox:hover {
  background-position: -32px -32px;
}

/*------------------------------------------------------------------------------
 * Node type icon
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *		 so we create combined class names that can be used in the CSS.
 *
 * Prefix: fancytree-ico-
 * 1st character: 'e': expanded, 'c': collapsed
 * 2nd character (optional): 'f': folder
 *----------------------------------------------------------------------------*/
span.fancytree-icon {
  margin-left: 3px;
  background-position: 0px 0px;
}

/* Documents */
.fancytree-ico-c span.fancytree-icon:hover {
  background-position: -16px 0px;
}

.fancytree-has-children.fancytree-ico-c span.fancytree-icon {
  background-position: -32px 0px;
}

.fancytree-has-children.fancytree-ico-c span.fancytree-icon:hover {
  background-position: -48px 0px;
}

.fancytree-ico-e span.fancytree-icon {
  background-position: -64px 0px;
}

.fancytree-ico-e span.fancytree-icon:hover {
  background-position: -80px 0px;
}

/* Folders */
.fancytree-ico-cf span.fancytree-icon {
  background-position: 0px -16px;
}

.fancytree-ico-cf span.fancytree-icon:hover {
  background-position: -16px -16px;
}

.fancytree-has-children.fancytree-ico-cf span.fancytree-icon {
  background-position: -32px -16px;
}

.fancytree-has-children.fancytree-ico-cf span.fancytree-icon:hover {
  background-position: -48px -16px;
}

.fancytree-ico-ef span.fancytree-icon {
  background-position: -64px -16px;
}

.fancytree-ico-ef span.fancytree-icon:hover {
  background-position: -80px -16px;
}

.fancytree-loading span.fancytree-expander,
.fancytree-loading span.fancytree-expander:hover,
.fancytree-statusnode-loading span.fancytree-icon,
.fancytree-statusnode-loading span.fancytree-icon:hover {
  background-image: url("../skin-lion/loading.gif");
  background-position: 0px 0px;
}

/* Status node icons */
.fancytree-statusnode-error span.fancytree-icon,
.fancytree-statusnode-error span.fancytree-icon:hover {
  background-position: 0px -112px;
}

/*------------------------------------------------------------------------------
 * Node titles and highlighting
 *----------------------------------------------------------------------------*/
span.fancytree-node {
  /* See #117 */
  display: inherit;
  width: 100%;
  margin-top: 1px;
  min-height: 16px;
}

span.fancytree-title {
  color: black;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  min-height: 16px;
  padding: 0 3px 0 3px;
  margin: 0px 0 0 3px;
  border: 1px solid transparent;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}

span.fancytree-node.fancytree-error span.fancytree-title {
  color: red;
}

/*------------------------------------------------------------------------------
 * Drag'n'drop support
 *----------------------------------------------------------------------------*/
div.fancytree-drag-helper span.fancytree-childcounter,
div.fancytree-drag-helper span.fancytree-dnd-modifier {
  display: inline-block;
  color: #fff;
  background: #337ab7;
  border: 1px solid gray;
  min-width: 10px;
  height: 10px;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 9px;
}

div.fancytree-drag-helper span.fancytree-childcounter {
  position: absolute;
  top: -6px;
  right: -6px;
}

div.fancytree-drag-helper span.fancytree-dnd-modifier {
  background: #5cb85c;
  border: none;
  font-weight: bolder;
}

div.fancytree-drag-helper.fancytree-drop-accept span.fancytree-drag-helper-img {
  background-position: -32px -112px;
}

div.fancytree-drag-helper.fancytree-drop-reject span.fancytree-drag-helper-img {
  background-position: -16px -112px;
}

/*** Drop marker icon *********************************************************/
#fancytree-drop-marker {
  width: 32px;
  position: absolute;
  background-position: 0px -128px;
  margin: 0;
}

#fancytree-drop-marker.fancytree-drop-after,
#fancytree-drop-marker.fancytree-drop-before {
  width: 64px;
  background-position: 0px -144px;
}

#fancytree-drop-marker.fancytree-drop-copy {
  background-position: -64px -128px;
}

#fancytree-drop-marker.fancytree-drop-move {
  background-position: -32px -128px;
}

/*** Source node while dragging ***********************************************/
span.fancytree-drag-source.fancytree-drag-remove {
  opacity: 0.15;
}

/*** Target node while dragging cursor is over it *****************************/
/*------------------------------------------------------------------------------
 * 'rtl' option
 *----------------------------------------------------------------------------*/
.fancytree-container.fancytree-rtl .fancytree-title {
  /*unicode-bidi: bidi-override;*/
  /* optional: reverse title letters */
}

.fancytree-container.fancytree-rtl span.fancytree-connector,
.fancytree-container.fancytree-rtl span.fancytree-expander,
.fancytree-container.fancytree-rtl span.fancytree-icon,
.fancytree-container.fancytree-rtl span.fancytree-drag-helper-img,
.fancytree-container.fancytree-rtl #fancytree-drop-marker {
  background-image: url("../skin-lion/icons-rtl.gif");
}

.fancytree-container.fancytree-rtl .fancytree-exp-n span.fancytree-expander,
.fancytree-container.fancytree-rtl .fancytree-exp-nl span.fancytree-expander {
  background-image: none;
}

.fancytree-container.fancytree-rtl.fancytree-connectors .fancytree-exp-n span.fancytree-expander,
.fancytree-container.fancytree-rtl.fancytree-connectors .fancytree-exp-nl span.fancytree-expander {
  background-image: url("../skin-lion/icons-rtl.gif");
}

ul.fancytree-container.fancytree-rtl ul {
  padding: 0 16px 0 0;
}

ul.fancytree-container.fancytree-rtl.fancytree-connectors li {
  background-position: right 0;
  background-image: url("../skin-lion/vline-rtl.gif");
}

ul.fancytree-container.fancytree-rtl li.fancytree-lastsib,
ul.fancytree-container.fancytree-rtl.fancytree-no-connector > li {
  background-image: none;
}

/*------------------------------------------------------------------------------
 * 'table' extension
 *----------------------------------------------------------------------------*/
table.fancytree-ext-table {
  border-collapse: collapse;
}

table.fancytree-ext-table span.fancytree-node {
  display: inline-block;
  box-sizing: border-box;
}

/*------------------------------------------------------------------------------
 * 'columnview' extension
 *----------------------------------------------------------------------------*/
table.fancytree-ext-columnview tbody tr td {
  position: relative;
  border: 1px solid gray;
  vertical-align: top;
  overflow: auto;
}

table.fancytree-ext-columnview tbody tr td > ul {
  padding: 0;
}

table.fancytree-ext-columnview tbody tr td > ul li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  background-image: none;
  /* no v-lines */
  margin: 0;
}

table.fancytree-ext-columnview span.fancytree-node {
  position: relative;
  /* allow positioning of embedded spans */
  display: inline-block;
}

table.fancytree-ext-columnview span.fancytree-node.fancytree-expanded {
  background-color: #CBE8F6;
}

table.fancytree-ext-columnview .fancytree-has-children span.fancytree-cv-right {
  position: absolute;
  right: 3px;
  background-position: 0px -80px;
}

table.fancytree-ext-columnview .fancytree-has-children span.fancytree-cv-right:hover {
  background-position: -16px -80px;
}

/*------------------------------------------------------------------------------
 * 'filter' extension
 *----------------------------------------------------------------------------*/
.fancytree-ext-filter-dimm span.fancytree-node span.fancytree-title {
  color: silver;
  font-weight: lighter;
}

.fancytree-ext-filter-dimm tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: black;
  font-weight: normal;
}

.fancytree-ext-filter-dimm tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-match span.fancytree-title {
  color: black;
  font-weight: bold;
}

.fancytree-ext-filter-hide tr.fancytree-hide,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-hide {
  display: none;
}

.fancytree-ext-filter-hide tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: silver;
  font-weight: lighter;
}

.fancytree-ext-filter-hide tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-match span.fancytree-title {
  color: black;
  font-weight: normal;
}

/* Hide expanders if all child nodes are hidden by filter */
.fancytree-ext-filter-hide-expanders tr.fancytree-match span.fancytree-expander,
.fancytree-ext-filter-hide-expanders span.fancytree-node.fancytree-match span.fancytree-expander {
  visibility: hidden;
}

.fancytree-ext-filter-hide-expanders tr.fancytree-submatch span.fancytree-expander,
.fancytree-ext-filter-hide-expanders span.fancytree-node.fancytree-submatch span.fancytree-expander {
  visibility: visible;
}

.fancytree-ext-childcounter span.fancytree-icon,
.fancytree-ext-filter span.fancytree-icon {
  position: relative;
}

.fancytree-ext-childcounter span.fancytree-childcounter,
.fancytree-ext-filter span.fancytree-childcounter {
  color: #fff;
  background: #777;
  border: 1px solid gray;
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 10px;
  height: 10px;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 9px;
}

/*------------------------------------------------------------------------------
 * 'wide' extension
 *----------------------------------------------------------------------------*/
ul.fancytree-ext-wide {
  position: relative;
  min-width: 100%;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul.fancytree-ext-wide span.fancytree-node > span {
  position: relative;
  z-index: 2;
}

ul.fancytree-ext-wide span.fancytree-node span.fancytree-title {
  position: absolute;
  z-index: 1;
  left: 0px;
  min-width: 100%;
  margin-left: 0;
  margin-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*------------------------------------------------------------------------------
 * 'fixed' extension
 *----------------------------------------------------------------------------*/
.fancytree-ext-fixed-wrapper .fancytree-ext-fixed-hidden {
  display: none;
}

.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-scroll-border-bottom {
  border-bottom: 3px solid rgba(0, 0, 0, 0.75);
}

.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-scroll-border-right {
  border-right: 3px solid rgba(0, 0, 0, 0.75);
}

.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-tl {
  position: absolute;
  overflow: hidden;
  z-index: 3;
  top: 0px;
  left: 0px;
}

.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-tr {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  top: 0px;
}

.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-bl {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  left: 0px;
}

.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-br {
  position: absolute;
  overflow: scroll;
  z-index: 1;
}

/*******************************************************************************
 * Styles specific to this skin.
 *
 * This section is automatically generated from the `ui-fancytree.less` template.
 ******************************************************************************/
/*******************************************************************************
 * Node titles
 */
span.fancytree-title {
  border: 1px solid transparent;
  border-radius: 0;
}

span.fancytree-focused span.fancytree-title {
  outline: 1px dotted black;
}

span.fancytree-selected span.fancytree-title,
span.fancytree-active span.fancytree-title {
  background-color: #D4D4D4;
}

span.fancytree-selected span.fancytree-title {
  font-style: italic;
}

.fancytree-treefocus span.fancytree-selected span.fancytree-title,
.fancytree-treefocus span.fancytree-active span.fancytree-title {
  color: white;
  background-color: #3875D7;
}

/*******************************************************************************
 * 'table' extension
 */
table.fancytree-ext-table {
  border-collapse: collapse;
}

table.fancytree-ext-table tbody tr.fancytree-focused {
  background-color: #99DEFD;
}

table.fancytree-ext-table tbody tr.fancytree-active {
  background-color: royalblue;
}

table.fancytree-ext-table tbody tr.fancytree-selected {
  background-color: #99DEFD;
}

/*******************************************************************************
 * 'columnview' extension
 */
table.fancytree-ext-columnview tbody tr td {
  border: 1px solid gray;
}

table.fancytree-ext-columnview span.fancytree-node.fancytree-expanded {
  background-color: #ccc;
}

table.fancytree-ext-columnview span.fancytree-node.fancytree-active {
  background-color: royalblue;
}

/*******************************************************************************
 * CUSTOM
 */
ul.fancytree-container {
  font-size: 0.875rem;
  padding: 2rem 2rem 0;
  border: 0;
  position: static;
}
ul.fancytree-container ul {
  padding-left: 1rem;
}
ul.fancytree-container.fancytree-treefocus {
  box-shadow: none;
  outline: none;
}
@media (min-width: 992px) {
  ul.fancytree-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

span.fancytree-node {
  margin-top: 0.125rem;
  min-height: 1rem;
  line-height: normal;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
span.fancytree-node.fancytree-loading::after {
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left-width: 0.125rem;
  border-left-style: solid;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 3rem;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
}

span.fancytree-title {
  min-height: 1.5rem;
  padding: 0 0.25rem 0 0.25rem;
  margin: 0;
  border: 0.06125rem solid transparent;
}

span.fancytree-empty,
span.fancytree-vline,
span.fancytree-expander,
span.fancytree-icon,
span.fancytree-checkbox {
  width: 1rem;
  height: 1.5rem;
  padding-top: 0.125rem;
  margin-left: 0.25rem;
  background-image: none;
  font: normal normal normal 0.875rem/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
}
span.fancytree-empty:hover,
span.fancytree-vline:hover,
span.fancytree-expander:hover,
span.fancytree-icon:hover,
span.fancytree-checkbox:hover {
  opacity: 0.5;
}

span.fancytree-expander {
  margin-left: 0;
  margin-right: 0.75rem;
}

.fancytree-loading span.fancytree-expander,
.fancytree-loading span.fancytree-expander:hover,
.fancytree-statusnode-loading span.fancytree-icon,
.fancytree-statusnode-loading span.fancytree-icon:hover {
  background-image: none;
}

#search-settings-class .ui-autocomplete,
#search-settings-branch .ui-autocomplete {
  opacity: 1 !important;
}
#search-settings-class .ui-autocomplete.ui-menu .ui-menu-item,
#search-settings-branch .ui-autocomplete.ui-menu .ui-menu-item {
  padding: 0 2rem;
}
@media (min-width: 992px) {
  #search-settings-class .ui-autocomplete.ui-menu .ui-menu-item,
  #search-settings-branch .ui-autocomplete.ui-menu .ui-menu-item {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.fancytree-exp-n span.fancytree-expander:before,
.fancytree-exp-nl span.fancytree-expander:before {
  display: none;
}

span.fancytree-expander,
.fancytree-exp-e span.fancytree-expander,
.fancytree-exp-ed span.fancytree-expander,
.fancytree-exp-el span.fancytree-expander,
.fancytree-exp-edl span.fancytree-expander {
  background-image: none;
}
span.fancytree-expander:before,
.fancytree-exp-e span.fancytree-expander:before,
.fancytree-exp-ed span.fancytree-expander:before,
.fancytree-exp-el span.fancytree-expander:before,
.fancytree-exp-edl span.fancytree-expander:before {
  content: "\f068";
}

.fancytree-exp-c span.fancytree-expander,
.fancytree-exp-cl span.fancytree-expander,
.fancytree-exp-cd span.fancytree-expander,
.fancytree-exp-cdl span.fancytree-expander {
  background-image: none;
}
.fancytree-exp-c span.fancytree-expander:before,
.fancytree-exp-cl span.fancytree-expander:before,
.fancytree-exp-cd span.fancytree-expander:before,
.fancytree-exp-cdl span.fancytree-expander:before {
  content: "\f067";
}

span.fancytree-checkbox:before,
span.fancytree-checkbox.fancytree-radio:before,
.fancytree-partsel span.fancytree-checkbox:before,
.fancytree-partsel span.fancytree-checkbox.fancytree-radio:before {
  content: "\f096";
}

.fancytree-selected span.fancytree-checkbox:before,
.fancytree-selected span.fancytree-checkbox.fancytree-radio:before {
  content: "\f046";
}

/* Documents */
.fancytree-ico-c span.fancytree-icon:before,
.fancytree-has-children.fancytree-ico-c span.fancytree-icon:before,
.fancytree-ico-e span.fancytree-icon:before {
  content: "\f016";
}

/* Folders */
.fancytree-ico-cf span.fancytree-icon:before,
.fancytree-has-children.fancytree-ico-cf span.fancytree-icon:before,
.fancytree-ico-ef span.fancytree-icon:before {
  content: "\f07b";
}

/* Active */
span.fancytree-focused span.fancytree-title {
  outline: none;
}

span.fancytree-selected span.fancytree-title,
span.fancytree-active span.fancytree-title {
  background-color: transparent;
}

span.fancytree-selected span.fancytree-title {
  font-style: normal;
}

.fancytree-treefocus span.fancytree-active span.fancytree-title,
.fancytree-treefocus span.fancytree-selected span.fancytree-title,
.fancytree-treefocus span.fancytree-selected .fancytree-checkbox {
  color: inherit;
  background-color: transparent;
}

span.fancytree-selected span.fancytree-title,
span.fancytree-selected .fancytree-checkbox {
  font-weight: 700;
}

/* Customisations */
.fancytree-title {
  display: inline !important;
}

.pagination {
  display: block;
  padding-left: 0;
  margin: 2rem auto;
  border-radius: 0.75rem;
  text-align: center;
}
.pagination li {
  display: inline-block;
}
.pagination li:first-child a,
.pagination li:first-child span {
  margin-left: 0;
  border-radius: 0.75rem;
}
.pagination li:last-child a,
.pagination li:last-child span {
  border-radius: 0.75rem;
}
.pagination li.active a, .pagination li.active a:focus, .pagination li.active a:hover,
.pagination li.active span,
.pagination li.active span:focus,
.pagination li.active span:hover {
  z-index: 2;
  cursor: default;
}
.pagination li.disabled a, .pagination li.disabled a:focus, .pagination li.disabled a:hover,
.pagination li.disabled span,
.pagination li.disabled span:focus,
.pagination li.disabled span:hover {
  pointer-events: none;
  cursor: not-allowed;
}
.pagination a,
.pagination span {
  border-radius: 0.75rem;
  position: relative;
  float: left;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  text-decoration: none;
  border: 0.125rem solid transparent;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
}

.report-charts {
  padding: 2rem 0.5rem 0;
}
.report-charts::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 576px) {
  .report-charts {
    padding: 2rem 1rem 0;
  }
}
@media (min-width: 1200px) {
  .report-charts {
    padding: 2rem 3rem 0;
  }
}

.report-summary,
.report-chart,
.report-list,
.report-tags,
.report-segments,
.report-advice,
.report-company {
  padding: 0 1rem 6rem;
  position: relative;
}
.report-summary::after,
.report-chart::after,
.report-list::after,
.report-tags::after,
.report-segments::after,
.report-advice::after,
.report-company::after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 768px) {
  .report-summary,
  .report-chart,
  .report-list,
  .report-tags,
  .report-segments,
  .report-advice,
  .report-company {
    float: left;
    width: 50%;
    min-height: 29rem;
  }
  .report-summary:nth-child(odd),
  .report-chart:nth-child(odd),
  .report-list:nth-child(odd),
  .report-tags:nth-child(odd),
  .report-segments:nth-child(odd),
  .report-advice:nth-child(odd),
  .report-company:nth-child(odd) {
    clear: left;
  }
  .report-summary.report--narrow,
  .report-chart.report--narrow,
  .report-list.report--narrow,
  .report-tags.report--narrow,
  .report-segments.report--narrow,
  .report-advice.report--narrow,
  .report-company.report--narrow {
    min-height: 15rem;
  }
  .report-summary.report--narrow .report-content,
  .report-chart.report--narrow .report-content,
  .report-list.report--narrow .report-content,
  .report-tags.report--narrow .report-content,
  .report-segments.report--narrow .report-content,
  .report-advice.report--narrow .report-content,
  .report-company.report--narrow .report-content {
    min-height: 10rem;
  }
}

.report-card {
  width: 100%;
  position: relative;
}
.report-card:before {
  /* Background */
  content: "";
  display: block;
  position: absolute;
  top: -1.75rem;
  right: -2rem;
  left: -2rem;
  bottom: -1.75rem;
  z-index: -1;
  background: #fff;
}
.report-card.report-card--text h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.46499rem;
  padding-top: 0.53501rem;
}
.report-card.report-card--text p {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0.43499rem;
  padding-top: 0.56501rem;
}
.report-card.report-card--text p a:after {
  content: "\e917";
  padding-left: 0.25rem;
  transform: rotate(-45deg);
  position: relative;
  top: 0.125rem;
  display: inline-block;
}
@media (min-width: 992px) {
  .report-card.report-card--text h2,
  .report-card.report-card--text p {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1200px) {
  .report-card.report-card--text h2,
  .report-card.report-card--text p {
    padding-left: 0;
    padding-right: 0;
  }
}

.report-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  min-height: 21rem;
  width: 100%;
  max-width: 100%;
}

.report-heading {
  display: block;
  margin-bottom: 2rem;
}

.report-heading-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: -0.52626rem;
  padding-top: 0.52626rem;
  text-align: center;
}

.report-status-indicator {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  text-indent: -999rem;
  overflow: hidden;
}

.report-tags {
  text-align: center;
}
.report-tags .btn {
  margin-bottom: 0.25rem;
}
.report-tags .btn + .btn {
  margin-left: 0.25rem;
}
.report-tags .report-tag + .report-tag:before {
  content: ", ";
}
@media (min-width: 992px) {
  .windows7.ie11 .report-tags .report-content {
    height: 21rem;
  }
}

.report-chart-info {
  position: absolute;
  bottom: 2.5rem;
  left: 0;
  right: 0;
  padding: 0 2rem;
  text-align: right;
}

.report-chart-info-btn {
  font-weight: 900;
}

.report-summary-list {
  list-style-type: none;
  margin: 0;
  padding: 2rem 0 0;
}
.report-summary-list .report-summary-item {
  position: relative;
  margin-bottom: 1rem;
  padding-right: 2rem;
}
.report-summary-list .report-summary-item .report-summary-title {
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.77124rem;
  padding-top: 0.22876rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.06125rem;
}
.report-summary-list .report-summary-item .report-status-indicator {
  position: absolute;
  top: 0;
  right: 0;
}
.report-summary-list .report-summary-item:last-child {
  margin-bottom: 0;
}

.report-list-items {
  list-style: none;
  counter-reset: li;
  margin: 0;
  padding: 0;
  position: relative;
  top: -0.25rem;
}

.report-list-item {
  border-bottom: 0.0625rem solid transparent;
  position: relative;
  height: 2.5rem;
  line-height: 2.875rem;
  margin: 0;
  padding: 0 0 0 2rem;
  transition: border-color 150ms ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.report-list-item:before {
  content: counter(li); /* Use the counter as content */
  counter-increment: li;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: -0.56501rem;
  padding-top: 0.56501rem;
  position: absolute;
  top: 0;
  left: 0;
}
.report-list-item:last-child {
  border-bottom-color: transparent;
}
.report-list-item .report-list-item-value,
.report-list-item .report-list-item-note {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.report-list-item .report-list-item-value {
  flex: 1 0 60%;
}
.report-list-item .report-list-item-value {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: -0.56501rem;
  padding-top: 0.56501rem;
}
.report-list-item .report-list-item-note {
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin-bottom: -0.60376rem;
  padding-top: 0.60376rem;
  flex: 0 1 40%;
  text-align: right;
}
.report-list-item .organisation {
  height: 2.5rem;
  margin: 0;
  padding: 0;
  width: 100%;
}
.report-list-item .organisation-name {
  font-size: 1rem;
  flex: 1 1 auto;
  height: 100%;
  position: relative;
  max-width: 100%;
}
.report-list-item .organisation-name span {
  position: absolute;
  top: 0;
  left: 0;
  right: 1rem;
  width: auto;
  line-height: 2.5rem;
}
.no-touch .report-list-item .organisation-actions {
  opacity: 0;
}
.no-touch .report-list-item:hover .organisation-actions, .no-touch .report-list-item:focus .organisation-actions, .no-touch .report-list-item:hover:focus .organisation-actions {
  opacity: 1;
}
.no-touch .report-list-item.active .organisation-actions {
  opacity: 1;
}

.report-segment {
  float: left;
  width: 50%;
  padding-top: 2rem;
  padding-bottom: 3rem;
  overflow: hidden;
  position: relative;
}
@media (min-width: 576px) {
  .report-segment {
    padding-left: 3rem;
  }
  .report-segment + .report-segment {
    padding-left: 0rem;
    padding-right: 3rem;
  }
}
.report-segments--three .report-segment {
  width: 33.3333%;
}
@media (min-width: 576px) {
  .report-segments--three .report-segment {
    padding-left: 2rem;
  }
  .report-segments--three .report-segment + .report-segment {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .report-segments--three .report-segment + .report-segment + .report-segment {
    padding-left: 0rem;
    padding-right: 2rem;
  }
}

.report-segment-icon {
  display: block;
  text-align: center;
  font-size: 7rem;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .report-segment-icon {
    font-size: 6rem;
  }
}

.report-segment-label {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: -0.61251rem;
  padding-top: 0.61251rem;
  display: block;
  text-align: center;
}

.report-segment-value {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: -0.61251rem;
  padding-top: 0.61251rem;
  display: block;
  text-align: center;
}
@media (min-width: 992px) {
  .report-segment-value {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: -0.53501rem;
    padding-top: 0.53501rem;
  }
}

.report-segment-items,
.report-segment-average,
.report-advice-sentences {
  width: 100%;
}

.report-advice-sentences {
  text-align: center;
}

.report-advice-item .report-advice-value {
  text-align: center;
  font-size: 8vw;
  transition: color 150ms ease;
}
.report-advice-item .report-advice-value.report-advice-item--rising:after, .report-advice-item .report-advice-value.report-advice-item--falling:after, .report-advice-item .report-advice-value.report-advice-item--neutral:after {
  display: inline-block;
  content: "";
  background-image: url("../images/jd-report-indicator_rising.png");
  background-size: contain;
  color: #fff;
  height: 2rem;
  width: 2rem;
  position: relative;
  right: -0.5rem;
  top: 0rem;
}
.report-advice-item .report-advice-value.report-advice-item--falling:after {
  background-image: url("../images/jd-report-indicator_falling.png");
}
.report-advice-item .report-advice-value.report-advice-item--neutral:after {
  background-image: url("../images/jd-report-indicator_neutral.png");
}
.report-advice-item .report-advice-label,
.report-advice-item .report-advice-meta {
  text-align: center;
  display: block;
  font-size: 4vw;
  line-height: 4vw;
  padding-top: 0.5vw;
}
@media (min-width: 768px) {
  .report-advice-item .report-advice-value {
    font-size: 3vw;
  }
  .report-advice-item .report-advice-label,
  .report-advice-item .report-advice-meta {
    display: block;
    font-size: 1.5vw;
    line-height: 1.5vw;
  }
}
.report-advice-item.report-advice-item--single .report-advice-value {
  font-size: 8vw;
  line-height: 8vw;
}
.report-advice-item.report-advice-item--sentence .report-advice-value {
  font-size: 6vw;
  line-height: 9vw;
}
@media (min-width: 768px) {
  .report-advice-item.report-advice-item--sentence .report-advice-value {
    font-size: 3vw;
    line-height: 4.5vw;
    padding: 0 4vw;
  }
}
@media (min-width: 992px) {
  .report-advice-item.report-advice-item--sentence .report-advice-value {
    font-size: 2vw;
    line-height: 3vw;
  }
}

.report-header,
.report-footer {
  display: block;
}

.report-header {
  margin-bottom: 2rem;
}

.report-header-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: -0.52626rem;
  padding-top: 0.52626rem;
  text-align: center;
  position: relative;
}
.report-header-title.report-header-title--icon {
  padding-left: 3rem !important;
}

.no-touch .report-chart .report-header-export-form {
  opacity: 0;
  transition: opacity 150ms ease;
}
.no-touch .report-chart:hover .report-header-export-form {
  opacity: 1;
}

.report-header-info,
.report-header-export {
  width: 1.5rem;
  height: 1.125rem;
  line-height: 1rem;
  padding: 0;
  text-align: center;
  position: relative;
}

.report-header-info.btn {
  font-style: normal;
  font-weight: 900;
  margin-left: 0.25rem;
}

.report-header-export {
  margin-right: -1.5rem;
}
.report-header-export [class*=icon--] {
  font-size: 0.675rem;
}
.report-header-export [class*=icon--]:before {
  top: 0;
  left: 0;
}

.report-header-export-form {
  display: inline;
}

.report-footer {
  text-align: center;
}
.report-footer::after {
  content: "";
  display: table;
  clear: both;
}

.report-footer-credits {
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-bottom: -0.39251rem;
  padding-top: 0.39251rem;
}

.report-footer-warning {
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-bottom: -0.39251rem;
  padding-top: 0.39251rem;
  font-weight: 700;
}

.report-vu {
  display: block;
  width: 100%;
  position: relative;
}

.report-vu-bar {
  height: 1rem;
  margin: 0 2rem;
  position: relative;
  top: -0.25rem;
  border-radius: 0.375rem;
}

.report-vu-bar-segment {
  height: 1rem;
  float: left;
  position: relative;
}
.report-vu-bar-segment:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.report-vu-bar-segment:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.report-vu-bar-segment:last-child > .report-vu-bar-segment-tip {
  right: 0.5rem;
}
.report-vu-bar-segment > .report-vu-bar-segment-tip {
  top: -1.125rem;
  line-height: 0.875rem;
  right: 0rem;
  font-weight: 700;
}

.report-vu-bar-min,
.report-vu-bar-max,
.report-vu-bar-segment-tip {
  position: absolute;
  font-size: 0.75rem;
  top: 0.75rem;
  left: 2.5rem;
  padding-left: 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  white-space: nowrap;
}
.report-vu-bar-min:before,
.report-vu-bar-max:before,
.report-vu-bar-segment-tip:before {
  display: block;
  content: "";
  height: 1.125rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
}

.report-vu-bar-min,
.report-vu-bar-max {
  max-width: calc(50% - 2.5rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.report-vu-bar-max {
  text-align: right;
}

.report-vu-bar-max,
.report-vu-bar-segment-tip {
  left: auto;
  right: 2.5rem;
  padding-left: 0;
  padding-right: 0.5rem;
}
.report-vu-bar-max:before,
.report-vu-bar-segment-tip:before {
  left: auto;
  right: 0;
}

.report-company-name {
  text-align: center;
}

.report-company-map {
  font-size: 0.75rem;
}

.report-company-profile-term,
.report-company-profile-desc {
  border-top-width: 0.06125rem;
  border-top-style: solid;
  padding: 0.5rem 0 1.25rem;
}

.report-company-profile-term {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: -0.47876rem;
  padding-top: 0.47876rem;
  float: left;
  clear: left;
  width: 40%;
  font-weight: 400;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
  white-space: nowrap;
}

.report-company-profile-desc {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: -0.44001rem;
  padding-top: 0.44001rem;
  display: block;
  overflow: hidden;
}

.report-company-profile-desc {
  display: block;
}

.report-chapter-heading {
  text-align: center;
  clear: both;
  overflow: hidden;
}

.report-chapter-title {
  padding: 1rem 2rem;
}
.report-chapter-title h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.46499rem;
  padding-top: 0.53501rem;
  font-weight: 700;
}

.report-chapter-prologue {
  padding: 1.5rem 2rem 2rem;
}
.report-chapter-prologue p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.55999rem;
  padding-top: 0.44001rem;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
}

.report-chapter-footer {
  text-align: center;
}

.report-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  margin: -2rem 0 0 -2rem;
  display: block;
  line-height: 4rem;
  background: #fff;
  font-size: 3rem;
  text-align: center;
}
.report-icon:before {
  top: 0;
  left: 0;
}
.report-icon .report-icon-export {
  display: none;
}

.report-index {
  overflow: visible;
}
.report-index.report-index--2 .report-index-anchor {
  height: 50%;
}
.report-index.report-index--3 .report-index-anchor {
  height: 33.3333%;
}
.report-index.report-index--4 .report-index-anchor {
  height: 25%;
}
.report-index.report-index--5 .report-index-anchor {
  height: 20%;
}
.report-index.report-index--6 .report-index-anchor {
  height: 16.6666%;
}
.report-index.report-index--7 .report-index-anchor {
  height: 14.2857%;
}
.report-index.report-index--8 .report-index-anchor {
  height: 12.5%;
}
.report-index.report-index--active .report-index-anchor {
  opacity: 1;
}

.report-index-anchor {
  float: left;
  width: 6rem;
  position: relative;
  opacity: 0;
  transition: opacity 300ms ease;
}
.report-index-anchor:before, .report-index-anchor:after {
  content: "";
  display: block;
  position: absolute;
  height: 50%;
  top: 0;
  left: 50%;
  width: 0.25rem;
  margin-left: -0.125rem;
  z-index: 0;
}
.report-index-anchor:after {
  top: auto;
  bottom: 0;
}
.report-index-anchor:first-child:before {
  display: none;
}
.report-index-anchor:last-child:after {
  display: none;
}
.no-touch .report-index-anchor:hover .report-index-anchor-label {
  display: block;
}
.no-touch .report-index-anchor:hover .report-index-anchor-label:empty {
  display: none !important;
}

.report-index-anchor-icon,
.report-index-anchor-label {
  display: block;
}

.report-index-anchor-icon {
  height: 3rem;
  width: 6rem;
  position: absolute;
  top: 50%;
  margin-top: -1.5rem;
  z-index: 1;
}
.report-index-anchor-icon:after {
  content: "";
  display: block;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.75rem 0 0 -0.75rem;
  border-radius: 50%;
  border-width: 0.375rem;
  border-style: solid;
}
.report-index-anchor-icon.report-icon {
  margin-left: -3rem;
  line-height: 3rem;
  font-size: 2rem;
}
.report-index-anchor-icon.report-icon:after {
  display: none;
}

.report-index-anchor-label {
  border-radius: 0.625rem;
  padding: 0 0.625rem;
  margin: -0.75rem 0 0 -1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 2rem;
  min-width: 2rem;
  width: auto;
  margin-left: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  display: none;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  left: 5rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  text-align: center;
  text-indent: 0;
  padding: 0 1.25rem;
  z-index: 1;
}

@media (min-width: 768px) {
  .report-header {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.pac-container {
  border: 0;
}

.pac-item,
.pac-item-query {
  font-size: 0.875rem;
}

.pac-item {
  padding: 0 2rem 0 4rem;
  line-height: 3rem;
  border: 0;
  position: relative;
}
@media (min-width: 992px) {
  .pac-item {
    padding: 0 4rem 0 6rem;
  }
}
@media (min-width: 1200px) {
  .pac-item {
    padding: 0 4.5rem 0 6.5rem;
  }
}

.pac-item-query .pac-matched {
  font-weight: 700;
}

.pac-logo:after {
  display: none;
}

.pac-icon {
  display: inline-block;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  vertical-align: middle;
  background: none;
}
.pac-icon.pac-icon-marker {
  margin-top: 1rem;
  margin-left: 2rem;
}
.pac-icon.pac-icon-marker:before {
  content: "\e908";
}
@media (min-width: 992px) {
  .pac-icon.pac-icon-marker {
    margin-left: 4rem;
  }
}

.tag {
  position: relative;
  top: -0.0625rem;
  border-radius: 0.5rem;
  font-size: 0.675rem;
  font-weight: 700;
  padding: 0 0.375rem;
  height: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.tag :empty {
  display: none;
}

.label {
  height: 1.25rem;
  border-radius: 0.5rem;
  font-size: 0.675rem;
  font-weight: 700;
  padding: 0.25em 0.375rem;
  line-height: 1;
  border: 0.125rem solid transparent;
}
.label :empty {
  display: none;
}

.label-select {
  display: block;
}
.label-select.label-select--collapsed .label {
  display: none;
}
.label-select.label-select--open .label-pane {
  display: block;
}
.label-select .pane-v2 {
  display: none;
}

.label-pane.pane .pane-close {
  display: block;
}

.label-button {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  border: 0;
}

@media (min-width: 768px) {
  .label-pane.pane-v2 {
    top: 100%;
    right: 0;
    left: 0;
    min-height: 15rem;
  }
}
.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-top.shepherd-target-attached-left .shepherd-content:before,
.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-top.shepherd-target-attached-right .shepherd-content:before,
.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-target-attached-left .shepherd-content:before,
.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-target-attached-right .shepherd-content:before {
  display: none;
}

.shepherd-element, .shepherd-element:after, .shepherd-element:before, .shepherd-element *, .shepherd-element *:after, .shepherd-element *:before {
  box-sizing: border-box;
}

.shepherd-element {
  position: absolute;
  display: none;
}

.shepherd-element.shepherd-open {
  display: block;
}

.shepherd-element.shepherd-theme-square {
  max-width: 100%;
  max-height: 100%;
}

.shepherd-element.shepherd-theme-square .shepherd-content {
  border-radius: 5px;
  position: relative;
  font-family: inherit;
  background: #f6f6f6;
  color: #444;
  padding: 1em;
  font-size: 1.1em;
  line-height: 1.5em;
}

.shepherd-element.shepherd-theme-square .shepherd-content:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-width: 16px;
  border-style: solid;
  pointer-events: none;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-content {
  margin-bottom: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-content:before {
  top: 100%;
  left: 50%;
  margin-left: -16px;
  border-top-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-content {
  margin-top: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-content:before {
  bottom: 100%;
  left: 50%;
  margin-left: -16px;
  border-bottom-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-right.shepherd-element-attached-middle .shepherd-content {
  margin-right: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-right.shepherd-element-attached-middle .shepherd-content:before {
  left: 100%;
  top: 50%;
  margin-top: -16px;
  border-left-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-left.shepherd-element-attached-middle .shepherd-content {
  margin-left: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-left.shepherd-element-attached-middle .shepherd-content:before {
  right: 100%;
  top: 50%;
  margin-top: -16px;
  border-right-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-left.shepherd-target-attached-center .shepherd-content {
  left: -32px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-right.shepherd-target-attached-center .shepherd-content {
  left: 32px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content {
  margin-top: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content:before {
  bottom: 100%;
  left: 16px;
  border-bottom-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content {
  margin-top: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content:before {
  bottom: 100%;
  right: 16px;
  border-bottom-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content {
  margin-bottom: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-middle .shepherd-content:before {
  top: 100%;
  left: 16px;
  border-top-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content {
  margin-bottom: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-middle .shepherd-content:before {
  top: 100%;
  right: 16px;
  border-top-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom .shepherd-content {
  margin-top: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom .shepherd-content:before {
  bottom: 100%;
  left: 16px;
  border-bottom-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom .shepherd-content {
  margin-top: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom .shepherd-content:before {
  bottom: 100%;
  right: 16px;
  border-bottom-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-top .shepherd-content {
  margin-bottom: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-top .shepherd-content:before {
  top: 100%;
  left: 16px;
  border-top-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-top .shepherd-content {
  margin-bottom: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-top .shepherd-content:before {
  top: 100%;
  right: 16px;
  border-top-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content {
  margin-right: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content:before {
  top: 16px;
  left: 100%;
  border-left-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content {
  margin-left: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content:before {
  top: 16px;
  right: 100%;
  border-right-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content {
  margin-right: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-right.shepherd-target-attached-left .shepherd-content:before {
  bottom: 16px;
  left: 100%;
  border-left-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content {
  margin-left: 16px;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-bottom.shepherd-element-attached-left.shepherd-target-attached-right .shepherd-content:before {
  bottom: 16px;
  right: 100%;
  border-right-color: #f6f6f6;
}

.shepherd-element.shepherd-theme-square {
  border-radius: 0;
  z-index: 9999;
  max-width: 24em;
  font-size: 1em;
}

.shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title .shepherd-content:before, .shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-right.shepherd-target-attached-bottom.shepherd-has-title .shepherd-content:before, .shepherd-element.shepherd-theme-square.shepherd-element-attached-top.shepherd-element-attached-left.shepherd-target-attached-bottom.shepherd-has-title .shepherd-content:before {
  border-bottom-color: #e6e6e6;
}

.shepherd-element.shepherd-theme-square.shepherd-has-title .shepherd-content header {
  background: #e6e6e6;
  padding: 1em;
}

.shepherd-element.shepherd-theme-square.shepherd-has-title .shepherd-content header a.shepherd-cancel-link {
  padding: 0;
  margin-bottom: 0;
}

.shepherd-element.shepherd-theme-square.shepherd-has-cancel-link .shepherd-content header h3 {
  float: left;
}

.shepherd-element.shepherd-theme-square .shepherd-content {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17);
  border-radius: 0;
  padding: 0;
}

.shepherd-element.shepherd-theme-square .shepherd-content * {
  font-size: inherit;
}

.shepherd-element.shepherd-theme-square .shepherd-content header {
  *zoom: 1;
  border-radius: 0;
}

.shepherd-element.shepherd-theme-square .shepherd-content header:after {
  content: "";
  display: table;
  clear: both;
}

.shepherd-element.shepherd-theme-square .shepherd-content header h3 {
  margin: 0;
  line-height: 1;
  font-weight: normal;
}

.shepherd-element.shepherd-theme-square .shepherd-content header a.shepherd-cancel-link {
  float: right;
  text-decoration: none;
  font-size: 1.25em;
  line-height: 0.8em;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.25;
  position: relative;
  top: 0.1em;
  padding: 0.8em;
  margin-bottom: -0.8em;
}

.shepherd-element.shepherd-theme-square .shepherd-content header a.shepherd-cancel-link:hover {
  opacity: 1;
}

.shepherd-element.shepherd-theme-square .shepherd-content .shepherd-text {
  padding: 1em;
}

.shepherd-element.shepherd-theme-square .shepherd-content .shepherd-text p {
  margin: 0 0 0.5em 0;
  line-height: 1.3em;
}

.shepherd-element.shepherd-theme-square .shepherd-content .shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-element.shepherd-theme-square .shepherd-content footer {
  padding: 0 1em 1em;
}

.shepherd-element.shepherd-theme-square .shepherd-content footer .shepherd-buttons {
  text-align: right;
  list-style: none;
  padding: 0;
  margin: 0;
}

.shepherd-element.shepherd-theme-square .shepherd-content footer .shepherd-buttons li {
  display: inline;
  padding: 0;
  margin: 0;
}

.shepherd-element.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  border-radius: 0;
  cursor: pointer;
  border: 0;
  margin: 0 0.5em 0 0;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.75em 2em;
  background: #3288e6;
  color: #fff;
}

.shepherd-element.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary {
  background: #eee;
  color: #888;
}

.shepherd-element.shepherd-theme-square .shepherd-content footer .shepherd-buttons li:last-child .shepherd-button {
  margin-right: 0;
}

.shepherd-start-tour-button.shepherd-theme-square {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  border-radius: 0;
  cursor: pointer;
  border: 0;
  margin: 0 0.5em 0 0;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.75em 2em;
  background: #3288e6;
  color: #fff;
}

.shepherd-element.shepherd-step.shepherd-theme-square {
  max-width: 20rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-left .shepherd-content:before {
  left: 1.5rem !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-middle .shepherd-content:before {
  left: 50% !important;
  margin-left: -0.5rem !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-right .shepherd-content:before {
  right: 1.5rem !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-top .shepherd-content:before {
  top: -1rem !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-element-attached-bottom .shepherd-content:before {
  bottom: -1rem !important;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content {
  color: #fff;
  border-radius: 1.5rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content:before {
  border-width: 0.5rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer {
  padding: 0 2rem 1.5rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content footer .shepherd-buttons li .shepherd-button {
  padding: 0.46875rem 2rem;
  font-size: 0.875rem;
  border-radius: 0.875rem;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.5rem;
  font-weight: 700;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content .shepherd-text {
  padding: 0 2rem 2rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content .shepherd-text p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.55999rem;
  padding-top: 0.44001rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square .shepherd-content .shepherd-text p:last-child {
  margin-bottom: 0;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-has-title .shepherd-content header {
  background: transparent;
  padding: 2rem 2rem 0rem;
}
.shepherd-element.shepherd-step.shepherd-theme-square.shepherd-has-title .shepherd-content header .shepherd-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  color: #fff;
  font-weight: 500;
}

.void {
  text-align: center;
}
.void-content {
  /** DEPRECATED **/
  margin: 0 auto;
  max-width: 25rem;
  text-align: center;
}

.void-inner {
  /** pane-section **/
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.void-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
}

.void-description {
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 1.43499rem;
  padding-top: 0.56501rem;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  max-width: 20rem;
  margin: 0 auto;
}
.void-description + .void-tip {
  padding-top: 1rem;
}

.void-icon {
  font-size: 4rem;
  display: block;
  margin-bottom: 2rem;
}

.void-tip {
  font-size: 0.875rem;
  max-width: 20rem;
  margin: 0 auto;
  line-height: 1.25rem;
}
.void-tip .action {
  display: inline-block;
}
.void-tip .action.action--xs {
  width: 1rem;
  height: 0.75rem;
  top: 0.125rem;
}
.void-tip .action.action--xs [class*=icon--] {
  font-size: 0.5rem;
  margin: -0.25rem 0 0 -0.25rem;
}

.void-action,
.void-cancel {
  margin: 1rem auto 0;
  display: block;
  max-width: 15rem;
}

@media (min-width: 768px) {
  .void-inner {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.shop .section {
  background: #fff;
}

.shop-addons {
  text-align: center;
}

.shop-addon {
  padding: 0 1rem 3rem;
}

.shop-addon-value,
.shop-addon-title,
.shop-addon-price {
  text-align: center;
  display: block;
}

.shop-addon-value {
  font-size: 3rem;
  line-height: 2.5rem;
  margin-bottom: 0.67999rem;
  padding-top: 0.32001rem;
  font-weight: 400;
  color: #eceeef;
}

.shop-addon-title {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: -0.61251rem;
  padding-top: 0.61251rem;
  color: #55595c;
}

.shop-addon-price {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: -0.69001rem;
  padding-top: 0.69001rem;
  color: #818a91;
}

.slider.slider-horizontal {
  margin-right: 4rem;
  width: auto !important;
  display: block !important;
}
.slider.slider-horizontal .slider-track {
  height: 0.25rem !important;
  background-image: none;
}
.slider.slider-horizontal .slider-handle {
  height: 1rem;
  width: 1rem;
  margin: -0.125rem 0 0 -0.5rem;
}
.slider.slider-horizontal .tooltip.tooltip-main {
  position: absolute;
  right: -6rem;
  left: auto !important;
  opacity: 1 !important;
  top: 50%;
  height: 2rem;
  line-height: 2rem;
  margin: -1.25rem 0 0 0 !important;
  text-align: right;
  white-space: nowrap;
}
.slider.slider-horizontal .tooltip.tooltip-main:before, .slider.slider-horizontal .tooltip.tooltip-main:after {
  display: inline-block;
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
}
.slider.slider-horizontal .tooltip.tooltip-main:before {
  content: "+";
  padding-right: 0.125rem;
}
.slider.slider-horizontal .tooltip.tooltip-main:after {
  content: "km";
  padding-left: 0.125rem;
}
.slider.slider-horizontal .tooltip.tooltip-main > .tooltip-inner {
  background: transparent;
  padding: 0;
  text-align: right;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2rem;
  display: inline-block;
}
.slider.slider-horizontal .tooltip.tooltip-main > .tooltip-arrow {
  display: none;
}
.slider.slider-horizontal .tooltip.tooltip-min, .slider.slider-horizontal .tooltip.tooltip-max {
  display: none;
}
.slider.slider-horizontal#time-slider-slider .tooltip.tooltip-main:after {
  content: "min";
}

.timeline {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.modal-body .timeline {
  margin: 0 -2rem;
}
@media (min-width: 576px) {
  .timeline .timeline-milestone {
    padding-left: 4rem;
  }
  .timeline .timeline-milestone:before, .timeline .timeline-milestone:after {
    left: 2.56125rem;
  }
  .timeline .timeline-milestone .indicator-state {
    left: 2.6125rem;
  }
  .timeline .timeline-milestone.timeline-milestone--actions .timeline-milestone-time {
    right: 4.5rem;
  }
  .timeline .timeline-milestone.timeline-milestone--actions .timeline-milestone-actions {
    right: 1.5rem;
  }
}

.timeline-anchor {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -999rem;
  overflow: hidden;
}

.timeline-milestone {
  padding: 0.5rem 8rem 0.5rem 2.5rem;
  position: relative;
}
.timeline-milestone:before, .timeline-milestone:after {
  content: "";
  width: 0.125rem;
  z-index: 0;
  position: absolute;
  left: 1.5rem;
  display: none;
}
.timeline-milestone:after {
  display: block;
  height: calc(100% - 1rem);
  bottom: 0;
}
.timeline-milestone + .timeline-milestone:before {
  display: block;
  height: 1rem;
  top: 0;
}
.timeline-milestone:last-child:after {
  display: none;
}
.timeline-milestone .indicator-state {
  position: absolute;
  left: 1.56125rem;
  top: 0.5rem;
  z-index: 1;
}
.timeline-milestone .pulse {
  top: 1rem;
  left: 2.25rem;
  margin: 0;
  z-index: 1;
}
.timeline-milestone.timeline-milestone--actions .timeline-milestone-time {
  right: 3.5rem;
}

.timeline-milestone-actions {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

.timeline-milestone-time {
  position: absolute;
  right: 2rem;
  font-size: 0.75rem;
}

.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

.organisation {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.organisation-name {
  flex: 0 1 auto;
  font-size: 1.25rem;
  margin: 0;
  padding: 0 0.75rem 0 0;
  max-width: 100%;
}
.organisation-name span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
}
.organisation-property {
  flex: 0 0 auto;
  margin: 0 0.75rem 0 0;
}
.organisation-property + .organisation-property {
  margin-left: -0.375rem;
}

.organisation-label {
  flex: 0 0 auto;
}

.organisation-dropdown {
  flex: 0 0 auto;
}
.organisation-dropdown .action:before {
  display: none;
}

.organisation-follow {
  flex: 0 0 auto;
  margin-right: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pulse {
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  margin: -0.25rem 0 0 -0.3333334rem;
  height: 0.5rem;
  line-height: 0.5rem;
  width: 0.66666667rem;
  min-width: 0.66666667rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  background: red;
  transform: scale(0.75);
}
.pulse:before, .pulse:after {
  background: transparent;
  border-width: 0.125rem;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  top: -0.125rem;
  left: -0.125rem;
  bottom: -0.125rem;
  right: -0.125rem;
  border-radius: 0.2rem;
  width: auto;
  height: auto;
  animation: pulse 1.5s linear infinite;
  opacity: 0;
  backface-visibility: hidden;
}
.pulse:after {
  animation-delay: 0.5s;
}
.pulse.pulse--inactive:before, .pulse.pulse--inactive:after {
  display: none;
}

.support-toggle {
  position: absolute;
  right: 1.5rem;
  bottom: 4.5rem;
  z-index: 910;
}

.map--full {
  position: absolute;
  top: 4rem;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 768px) {
  .map--full {
    top: 0rem;
  }
}

.map iframe + div {
  display: none;
}
.map iframe + div + div {
  display: none;
}
.map iframe + div + div + div {
  display: none;
}
.map .gm-style-cc {
  display: none;
}
.map .gm-style,
.map .gm-style div {
  font-family: "neuzeit-grotesk", sans-serif !important;
  color: blue !important;
  font-size: 0.875rem !important;
}
.map img[src*="/images/maps/map-marker-icon-xs.png"],
.map img[src*="/images/maps/map-marker-icon-sm.png"],
.map img[src*="/images/maps/map-marker-icon-md.png"],
.map img[src*="/images/maps/map-marker-icon-lg.png"],
.map img[src*="/images/maps/map-marker-icon-xl.png"] {
  border-radius: 50%;
}
.map img[src*="/images/maps/map-marker-icon-xs.png"] {
  opacity: 1;
}
.map img[src*="/images/maps/map-marker-icon-sm.png"] {
  opacity: 0.875;
}
.map img[src*="/images/maps/map-marker-icon-md.png"] {
  opacity: 0.75;
}
.map img[src*="/images/maps/map-marker-icon-lg.png"] {
  opacity: 0.675;
}
.map img[src*="/images/maps/map-marker-icon-xl.png"] {
  opacity: 0.5;
}
.map .gm-style-iw {
  padding-top: 1.5rem !important;
  padding-left: 0 !important;
  border-radius: 0 !important;
  transform: translate3d(-50%, -100%, 0);
}
.map .gm-style-iw-d {
  /* Dialog */
}
.map .gm-style-iw-d ::-webkit-scrollbar {
  width: 0.25rem !important;
  background-color: transparent !important;
}
.map .gm-style-iw-d ::-webkit-scrollbar:horizontal {
  height: 0.125rem !important;
}
.map .gm-style-iw-d ::-webkit-scrollbar-button {
  display: none !important;
}
.map .gm-style-iw-d ::-webkit-scrollbar-thumb {
  border-radius: 0rem !important;
}
@media (min-width: 768px) {
  .map .gm-style-iw-d ::-webkit-scrollbar {
    width: 0.25rem !important;
  }
  .map .gm-style-iw-d ::-webkit-scrollbar:horizontal {
    height: 0.25rem !important;
  }
  .map .gm-style-iw-d .windows.webkit ::-webkit-scrollbar {
    width: 0.75rem !important;
  }
}
.map .gm-style-iw-d::-webkit-scrollbar {
  width: 0.25rem !important;
}
.map .gm-style-iw-d::-webkit-scrollbar:horizontal {
  height: 0.125rem !important;
}
.map .gm-style-iw-d::-webkit-scrollbar-button {
  display: none !important;
}
.map .gm-style-iw-d::-webkit-scrollbar-thumb {
  border-radius: 0rem !important;
}
@media (min-width: 768px) {
  .map .gm-style-iw-d::-webkit-scrollbar {
    width: 0.25rem !important;
  }
  .map .gm-style-iw-d::-webkit-scrollbar:horizontal {
    height: 0.25rem !important;
  }
}
.map .gm-style-iw-d + button {
  /* Close Button */
  font-family: "icons-ui" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color 150ms ease;
  right: 0.5rem !important;
  top: 0.5rem !important;
  height: 2rem !important;
  width: 2rem !important;
}
.map .gm-style-iw-d + button:before {
  position: relative;
  top: 0.075em;
  left: -0.05em;
}
.map .gm-style-iw-d + button:before {
  content: "\e904";
}
.map .gm-style-iw-d + button img {
  display: none !important;
}
.map .gm-bundled-control {
  right: 3.625rem !important;
}
.map .gm-bundled-control.gm-bundled-control-on-bottom {
  bottom: 10rem !important;
}

.toggle {
  display: block;
  margin: 0;
}
.toggle > input {
  display: none;
}
.toggle > input + .toggle-label + .toggle-label {
  display: none;
}
.toggle > input:checked + .toggle-label {
  display: none;
}
.toggle > input:checked + .toggle-label + .toggle-label {
  display: inline-block;
}

.pills {
  width: 100%;
  overflow: hidden;
  clear: both;
  padding: 0;
}
.pills .pill {
  position: relative;
  float: left;
  display: block;
  font-size: 0.875rem;
  padding: 0 0.5rem 0 0.75rem;
  border-radius: 0.625rem;
  border: 0.125rem solid #eceeef;
}
.pills .pill + .pill {
  margin-left: 0.25rem;
}
.pills .pill:first-child {
  margin-left: 2rem;
}
.pills .pill .icon--add,
.pills .pill .icon--close {
  font-size: 0.675rem;
}
.pills .pill .pill-label,
.pills .pill .pill-value {
  display: inline-block;
}
.pills .pill .pill-value {
  display: none;
}
.pills .pill .pill-action,
.pills .pill .pill-clear {
  appearance: normal;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  height: 1.75rem;
  line-height: 1.75rem;
  display: block;
  float: left;
  color: #55595c;
}
.pills .pill .pill-action:hover, .pills .pill .pill-action:focus, .pills .pill .pill-action:hover:focus,
.pills .pill .pill-clear:hover,
.pills .pill .pill-clear:focus,
.pills .pill .pill-clear:hover:focus {
  color: #373a3c;
}
.pills .pill .pill-clear {
  width: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  text-align: center;
  opacity: 1;
}
.pills .pill .pill-clear:hover, .pills .pill .pill-clear:focus, .pills .pill .pill-clear:hover:focus {
  opacity: 0.5;
}
.pills .pill.pill--active {
  padding-right: 2rem;
  background: #1E025F;
  border-color: #1E025F;
}
.pills .pill.pill--active .pill-label,
.pills .pill.pill--active .pill-add {
  display: none;
}
.pills .pill.pill--active .pill-value,
.pills .pill.pill--active .pill-clear {
  color: #fff;
}
.pills .pill.pill--active .pill-value {
  display: inline-block;
}
.pills .pill.pill--active .pill-clear {
  display: block;
}
.pills .pill.pill--open {
  background: #373a3c !important;
  border-color: #373a3c !important;
}
.pills .pill.pill--open .icon--add,
.pills .pill.pill--open .icon--close,
.pills .pill.pill--open .pill-label,
.pills .pill.pill--open .pill-value,
.pills .pill.pill--open .pill-clear {
  color: #fff !important;
}

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.cards {
  position: relative;
}
.cards::after {
  content: "";
  display: table;
  clear: both;
}
.cards.cards--carousel {
  padding: 0 1rem;
}
.cards.cards--carousel .flickity-viewport {
  overflow: visible;
}
.cards.cards--carousel .card {
  width: 80%;
}
.cards.cards--carousel:before, .cards.cards--carousel:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: -2rem;
  width: 2rem;
  pointer-events: none;
  z-index: 1;
}
.cards.cards--carousel:after {
  left: auto;
  right: 0;
  width: 4rem;
}
@media (min-width: 576px) {
  .cards.cards--carousel .card {
    width: 60%;
  }
  .cards.cards--two .card, .cards.cards--sm-two .card {
    float: left;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .cards.cards--carousel .card {
    width: 40%;
  }
  .cards.cards--md-two .card {
    float: left;
    width: 50%;
  }
  .cards.cards--three .card, .cards.cards--md-three .card {
    float: left;
    width: 33.3334%;
  }
}
@media (min-width: 992px) {
  .cards.cards--carousel {
    padding: 0 2rem;
  }
  .cards.cards--carousel .card {
    width: 35%;
  }
  .cards.cards--lg-three .card {
    float: left;
    width: 33.3334%;
  }
}

.cards-filter {
  padding: 0 0.5rem 2rem;
  text-align: center;
}

.card {
  width: 100%;
  padding: 0.5rem;
  display: block;
}
.card::after {
  content: "";
  display: table;
  clear: both;
}
.card > h3:last-child,
.card > p:last-child,
.card > span:last-child,
.card > em:last-child {
  margin: 0;
}
.card > img {
  pointer-events: none;
}
.card:hover .card-content, .card:focus .card-content, .card:hover:focus .card-content {
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
}
.card.card--list .list {
  margin-left: -2rem;
  margin-right: -2rem;
}

.card-content {
  border-top: 0.1875rem solid transparent;
  background: #fff;
  padding: 1.5rem 2rem 2rem;
  display: block;
  transition: box-shadow 150ms ease, border-color 150ms ease;
}
.card-content::after {
  content: "";
  display: table;
  clear: both;
}
.card-content > h3 {
  font-size: 1.75rem;
  line-height: 2.5rem;
}

.card-filter,
.card-meta,
.card-action {
  font-size: 0.75rem;
  font-weight: 600;
  display: block;
  margin: 0 0 1rem;
}

.card-title {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0.63749rem;
  padding-top: 0.36251rem;
}

.card-meta {
  color: #818a91;
}

.card-cite {
  font-size: 0.75rem;
}
.card-cite:before {
  content: "— ";
}

.card-action {
  color: #0275d8;
}

.card-image {
  float: left;
  display: block;
  max-width: 50%;
  margin-left: -2rem;
}
.card-image.card-image--right {
  float: right;
  margin-right: -2rem;
  margin-left: -1rem;
}

.card--newsletter .card-content {
  background: #55595c;
}
.card--newsletter .card-content > h3,
.card--newsletter .card-content > p {
  color: #fff;
}
.card--newsletter .card-content > .btn {
  border-color: #fff;
  color: #fff;
}
.card--newsletter .card-content > .btn:hover, .card--newsletter .card-content > .btn:focus, .card--newsletter .card-content > .btn:hover:focus {
  border-color: transparent;
}

.card--news:hover .card-content, .card--news:focus .card-content, .card--news:hover:focus .card-content {
  border-top-color: #1E025F;
}

.card--reference-primary .card-content {
  border-top-color: #0275d8;
}
.card--reference-primary .card-filter {
  color: #0275d8;
}

.card--reference-secondary .card-content {
  border-top-color: #1E025F;
}
.card--reference-secondary .card-filter {
  color: #1E025F;
}

.card--reference-tertiary .card-content {
  border-top-color: #F85C6A;
}
.card--reference-tertiary .card-filter {
  color: #F85C6A;
}

.card--summary .card-filter {
  color: #818a91;
}

.card--test .indicator-progress {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .card.card--inset-expand .card-content {
    padding: 2.5rem 3rem 3rem;
  }
  .card.card--inset-expand.card--list .list {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .card.card--inset-expand.card--list .list .list-header,
  .card.card--inset-expand.card--list .list .list-content,
  .card.card--inset-expand.card--list .list .list-footer,
  .card.card--inset-expand.card--list .list .list-link {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .card.card--inset-expand.card--list .list .list-link {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
.cart-total {
  font-weight: bold !important;
}

.cart-credits-add {
  position: relative;
}
.cart-credits-add .icon--add {
  margin-left: 0.125rem;
  font-size: 0.75rem;
}
.cart-credits-add:hover .tip, .cart-credits-add:focus .tip, .cart-credits-add:hover:focus .tip {
  display: block;
}

.cart-add,
.cart-remove {
  display: none;
}

.cart-remove--active,
.cart-add--active {
  display: block;
}

.cart-credits-notification {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.cart-credits-notification .icon--forward {
  margin-left: 0.25rem;
}

.count, .action-count {
  font-size: 0.675rem;
  color: #fff;
  display: inline-block;
  padding: 0 0.25rem;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  min-width: 1.5rem;
  text-indent: 0;
  transition: background 150ms ease;
}

.funnel {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-bottom: 1rem;
  height: 10rem;
  margin-bottom: 0;
}

.funnel-step {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.funnel-title {
  line-height: 2.25rem;
  flex: 0 0 auto;
}

.funnel-trend {
  flex: 0 0 auto;
}

.funnel-change {
  flex: 0 0 auto;
  font-size: 0.875rem;
  padding-top: 0.5rem;
}

@media (min-width: 768px) {
  .funnel-step {
    flex: 0 0 33.3334%;
    max-width: 33.3334%;
    padding-top: 0;
  }
}
.trend.trend--enlarge .trend-change {
  font-size: 5rem !important;
}

.cabinet {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.cabinet {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.cabinet--rows {
  gap: 0;
}

.cabinet-rows:nth-child(odd) .cabinet-drawer {
  background-color: #0a10c0;
}

.cabinet-drawer,
.view-dashboard .cabinet--rows .cabinet-drawer {
  border-width: 2px 0px;
  border-style: solid;
  border-color: rgba(211, 211, 211, 0.5) !important;
}
.cabinet-drawer:hover,
.view-dashboard .cabinet--rows .cabinet-drawer:hover {
  border-color: rgb(211, 211, 211) !important;
  z-index: 99;
}

.view-dashboard .cabinet--grid .cabinet-drawer {
  border: none !important;
}

.cabinet-drawer {
  margin: 1rem 1rem 0 0 !important;
}

.grid-container .cabinet-drawer {
  border: none !important;
  margin: 0 !important;
}

.cabinet-drawer {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.strip .cabinet-drawer {
  display: block;
  width: 100%;
  max-width: 100%;
  flex: none;
  padding-left: 0;
  padding-right: 0;
}
.strip .cabinet-drawer .cabinet-card {
  display: block;
  height: 100%;
  width: 100%;
}

.cabinet--grid .cabinet-drawer {
  min-width: 100%;
}

:not(.view-dashboard) section:not(.cabinet--rows) .cabinet-drawer {
  border: 2px solid rgba(211, 217, 227, 0.3);
}

.cabinet-options.dropdown {
  position: absolute;
}

.cabinet--rows .cabinet-options.dropdown {
  right: 0rem !important;
}

.cabinet-drawer-selected {
  border: 2px solid #D3D9E3 !important;
  padding: 0 !important;
}

.cabinet-card {
  padding: 1rem 1.125rem 1rem 1.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cabinet-card:after {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.cabinet-card:hover .cabinet-title-save, .cabinet-card:focus .cabinet-title-save, .cabinet-card:hover:focus .cabinet-title-save {
  opacity: 1;
}

.cabinet-title {
  flex: 1 0 auto;
  margin: 0;
  max-width: 100%;
}
.cabinet-title span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.cabinet-drawer--team .cabinet-title {
  max-width: calc(100% - 6rem);
}

.cabinet-title-link {
  display: block;
  font-size: 1.125rem;
  line-height: 2.25rem;
  max-width: 100%;
}

.cabinet-title-save {
  flex: 0 0 auto;
  opacity: 0;
}

.cabinet-options {
  flex: 0 0 auto;
  flex-basis: min-content;
}

.cabinet-properties,
.cabinet-labels,
.cabinet-meta {
  flex: 0 0 100%;
  z-index: 1;
  z-index: 1;
}

.cabinet-properties {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5rem;
  white-space: nowrap;
}

.cabinet-properties-link span {
  display: inline-block;
}
.cabinet-properties-link span:first-letter {
  text-transform: uppercase;
}

.cabinet-labels {
  padding: 0.25rem 0;
}

.cabinet-meta {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5rem;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cabinet-meta .cabinet-meta-item {
  flex: 1 0 50%;
}
.cabinet-meta .cabinet-meta-link {
  flex: 0 0 auto;
  padding-right: 0.25rem;
}
.cabinet-meta .cabinet-meta-value {
  display: inline-block;
}
.cabinet-meta .trend {
  flex: 0 0 auto;
  padding-right: 0.25rem;
}

.cabinet .void {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.no-touch.desktop .cabinet-labels {
  opacity: 0;
}
.no-touch.desktop .cabinet-drawer:focus .cabinet-labels, .no-touch.desktop .cabinet-drawer:active .cabinet-labels, .no-touch.desktop .cabinet-drawer:hover .cabinet-labels {
  opacity: 1;
}
.no-touch.desktop .cabinet-drawer--labelled .cabinet-labels {
  opacity: 1 !important;
}

.view-organisations section:not(.cabinet--rows) .cabinet-card,
.view-vacancies .section:not(cabininet--rows) .cabinet-card {
  padding: 1rem 2rem !important;
}

.view-organisations .cabinet--rows .cabinet-card,
.view-vacancies .cabinet--rows .cabinet-card {
  padding: 0.7rem 2rem !important;
}

.cabinet-card-flex-container {
  width: 100%;
  display: flex;
}
.cabinet-card-flex-container .cabinet-title {
  flex-basis: 34% !important;
}
.cabinet-card-flex-container .cabinet-properties, .cabinet-card-flex-container .cabinet-meta {
  flex-basis: 24% !important;
}
.cabinet-card-flex-container .cabinet-labels {
  flex-basis: 18% !important;
}

@media (max-width: 767px) {
  .cabinet.pane-section--inset {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 576px) {
  .strip .cabinet-drawer {
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) {
  .cabinet-drawer {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cabinet.cabinet--rows .cabinet-drawer {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: -2px;
  }
  .cabinet.cabinet--rows .cabinet-card {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0;
  }
  .cabinet.cabinet--rows .cabinet-title {
    flex-basis: 25%;
    min-width: 20%;
  }
  .cabinet.cabinet--rows .cabinet-properties,
  .cabinet.cabinet--rows .cabinet-labels,
  .cabinet.cabinet--rows .cabinet-meta {
    flex: 0 0 auto;
    width: auto;
  }
  .cabinet.cabinet--rows .cabinet-properties {
    flex-basis: 25%;
  }
  .cabinet.cabinet--rows .cabinet-labels {
    flex-basis: 25%;
  }
  .cabinet.cabinet--rows .cabinet-options {
    order: 10;
  }
  .cabinet.cabinet--rows .cabinet-properties,
  .cabinet.cabinet--rows .cabinet-meta {
    padding-left: 0.5rem;
    padding-right: 5rem;
  }
  .cabinet.cabinet--rows .cabinet-meta {
    flex-basis: 25%;
    justify-content: flex-start;
  }
  .cabinet.cabinet--rows .cabinet-meta .cabinet-meta-item,
  .cabinet.cabinet--rows .cabinet-meta .cabinet-meta-link {
    order: 1;
    text-align: right;
  }
  .cabinet.cabinet--rows .cabinet-meta .cabinet-meta-value {
    display: inline-block;
    min-width: 2rem;
  }
  .cabinet.cabinet--rows .cabinet-meta .trend {
    order: 0;
    padding-right: 1rem;
  }
}
@media (min-width: 992px) {
  .cabinet-drawer {
    flex: 0 0 33.3334%;
    max-width: 33.3334%;
  }
}
@media (min-width: 1200px) {
  .cabinet-drawer {
    flex: 0 0 23%;
    max-width: 23%;
  }
  .cabinet.cabinet--rows .cabinet-meta {
    flex-basis: 25%;
  }
}
.heading {
  display: flex;
  padding: 0 0 1rem;
}
.heading.heading--sm .heading-title {
  font-size: 0.875rem;
  position: relative;
  top: 1rem;
}

.heading-pane-section .heading {
  padding-bottom: 0;
}

.heading-title {
  font-size: 1rem;
  line-height: 1.75rem;
  flex: 1 0 60%;
  margin: 0;
}

.heading-action {
  flex: 0 0 auto;
  margin-left: 1rem;
}

@media (min-width: 576px) {
  .heading-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.trend .trend-icon {
  flex: 0 0 auto;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: relative;
  margin-left: 0.5rem;
}
.trend .trend-icon .icon-container {
  width: 1.25rem;
  margin: -0.675rem 0 0 -0.675rem;
  position: absolute;
  left: 50%;
  top: 50%;
}
.trend .trend-icon .icon-container.icon-container--neutral {
  width: 1rem;
  margin: -0.5rem 0 0 -0.5rem;
}
.trend.trend--enlarge .trend-change {
  font-size: 1.5rem;
}
.trend.trend--rising .trend-change:before {
  content: "+ ";
}
.trend.trend--falling .trend-change {
  color: #F85C6A;
}
.trend.trend--falling .trend-change:before {
  content: "- ";
}

.key {
  display: none;
  line-height: 0.875rem;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  margin-left: 0.125rem;
  text-transform: uppercase;
}
.no-touch.desktop .key {
  display: inline-block;
}

.strip .flickity-prev-next-button {
  z-index: 2;
  background: transparent;
  height: 2rem;
  width: 2rem;
}
.strip .flickity-prev-next-button.previous {
  left: 1.25rem;
}
.strip .flickity-prev-next-button.next {
  right: 1.25rem;
}
.strip .flickity-prev-next-button[disabled] {
  display: none;
}
.strip .flickity-prev-next-button:hover, .strip .flickity-prev-next-button:focus, .strip .flickity-prev-next-button:hover:focus {
  background: transparent;
}
.strip .strip-item {
  margin-right: 2rem;
  position: absolute; /* Prevent jumps */
}
.strip .strip-item .action--label {
  margin-left: 1rem;
}
.strip .flickity-page-dots {
  bottom: -1.75rem;
  height: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.strip .flickity-page-dots .dot {
  flex: 0 0 auto;
  width: 1.25rem;
  height: 0.25rem;
  margin: 0 0.25rem;
  border-radius: 0.125rem;
}
.strip.strip--mask:before, .strip.strip--mask:after {
  content: "";
  display: block;
  position: absolute;
  width: 2rem;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
.strip.strip--mask:after {
  left: auto;
  right: 0;
}
.strip.strip--tour {
  height: 100%;
}
.strip.strip--tour .flickity-viewport {
  height: calc(100% - 5rem) !important;
}
.strip.strip--tour .flickity-viewport .strip-item {
  height: 100%;
  width: 100%;
}
.strip.strip--tour .flickity-page-dots {
  bottom: 2rem;
}
.strip.strip--tour .flickity-prev-next-button {
  top: auto;
  bottom: 1rem;
  transition: background 150ms ease;
}
.strip.strip--tour .flickity-prev-next-button .arrow {
  transition: fill 150ms ease;
}
.strip.strip--gutter-collapse .strip-item {
  margin-right: 0;
}
.strip.strip--gutter-sm .strip-item {
  margin-right: 1rem;
}
.no-touch .strip.strip--hover .flickity-prev-next-button {
  opacity: 0;
}
.no-touch .strip.strip--hover:hover .flickity-prev-next-button, .no-touch .strip.strip--hover:focus .flickity-prev-next-button, .no-touch .strip.strip--hover:hover:focus .flickity-prev-next-button {
  opacity: 1;
}
.strip.strip--tour .flickity-prev-next-button {
  width: 4rem;
  height: 3rem;
  border-radius: 1.25rem;
  margin: 0;
  bottom: 1rem;
  transform: none;
}
.strip.strip--tour .flickity-prev-next-button .flickity-button-icon {
  width: 1.25rem;
  height: 1.25rem;
  left: 50%;
  top: 50%;
  margin: -0.625rem 0 0 -0.625rem;
}
.strip.strip--tour .flickity-prev-next-button.previous {
  left: 1rem;
}
.strip.strip--tour .flickity-prev-next-button.previous .arrow {
  fill: gray;
}
.strip.strip--tour .flickity-prev-next-button.next {
  background: green;
  right: 1rem;
}
.strip.strip--tour .flickity-prev-next-button.next .arrow {
  fill: white;
}
#strip-search-history {
  /* Inside section with inset */
}
#strip-search-history .strip-item {
  width: 60%;
}
#strip-search-history .strip-item:first-child {
  width: calc(60% + 2rem);
}
#strip-search-history .strip-item:last-child {
  width: calc(60% + 2rem);
}

@media (min-width: 576px) {
  .pane-section--expand .strip.strip--mask.strip--mask:before, .pane-section--expand .strip.strip--mask.strip--mask:after {
    width: 3rem;
  }
  #strip-search-history .strip-item {
    width: 50%;
  }
  #strip-search-history .strip-item:first-child {
    width: calc(50% + 2rem);
  }
  #strip-search-history .strip-item:last-child {
    width: calc(50% + 2rem);
  }
}
@media (min-width: 768px) {
  #strip-search-history .strip-item {
    width: 35%;
  }
  #strip-search-history .strip-item:first-child {
    width: calc(35% + 2rem);
  }
  #strip-search-history .strip-item:last-child {
    width: calc(35% + 2rem);
  }
}
.audience {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  transition: box-shadow 150ms ease;
  margin-top: 2rem;
}

.audience-header {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 1.5rem 0;
}

.audience-title {
  flex: 1 0 auto;
  font-size: 0.875rem;
  margin: 0;
}

.audience-link-icon {
  flex: 0 0 auto;
  width: 0.75rem;
  margin: -0.375rem 0 0 -0.375rem;
}

.audience-item {
  flex: 1 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 0 0.375rem;
  border-top: 0.0625rem solid transparent;
  transition: border-color 150ms ease;
}
.audience-item:last-child {
  padding-bottom: 1rem;
}

.audience-progress {
  flex: 0 0 100%;
  padding: 0 1.5rem 1rem;
}

.audience-meta {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 1.5rem;
}

.audience-meta-title {
  flex: 0 0 auto;
  padding: 0 0 0.25rem;
}

.audience-meta-items {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.audience-meta-item {
  flex: 1 0 33.33334%;
  position: relative;
  padding: 0 0.25rem;
}
.audience-meta-item .icon-container {
  position: absolute;
  bottom: 0.375rem;
  right: 1rem;
}
.audience-meta-item:first-child {
  padding-left: 0;
}
.audience-meta-item:last-child {
  padding-right: 0;
}

.audience-meta-title,
.audience-meta-desc,
.audience-meta-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audience-meta-title,
.audience-meta-desc {
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin: 0;
}

.audience-meta-value {
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin: 0;
}
.audience-meta-value::first-letter {
  text-transform: uppercase;
}

.audience-credits {
  flex: 0 0 100%;
  padding: 0.375rem 1.5rem;
  border-top: 0.0625rem solid transparent;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: left;
}

.audience--matches {
  display: none;
}

.audience-item--match .audience-meta-title {
  font-size: 1rem;
  line-height: 1.5rem;
}
.audience-item--match .audience-meta-title::first-letter {
  text-transform: uppercase;
}
.audience-item--match .audience-meta-match {
  flex: 1 0 25%;
  text-align: right;
}
.audience-item--match .audience-meta-location {
  flex: 1 0 75%;
  font-size: 0.875rem;
}
.audience-item--match .audience-meta-location::first-letter {
  text-transform: uppercase;
}

.insight {
  position: absolute;
  top: 1.375rem;
  left: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}
.insight:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  height: 0.625rem;
  width: 0.625rem;
  margin: -0.3125rem 0 0 -0.3125rem;
  border: 0.1875rem solid transparent;
}

.insight-tooltip.tooltip {
  opacity: 1;
  margin: 0 0 0.5rem 6.75rem;
}
.insight-tooltip.tooltip .icon-container {
  width: 1.5rem;
  margin: -0.75rem 0 0 -0.75rem;
  top: 1.875rem;
  left: 1.75rem;
  position: absolute;
}
.insight-tooltip.tooltip .tooltip-inner {
  padding: 0.75rem 1.5rem 0.75rem 3.25rem;
  border-radius: 0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  width: 18rem;
}

@media (min-width: 768px) {
  .cabinet.cabinet--rows .insight {
    top: 50%;
    margin-top: -0.75rem;
  }
}
.steps,
.step {
  list-style: none;
  margin: 0;
  padding: 0;
}

.steps {
  display: flex;
  flex-flow: column nowrap;
  height: 5rem;
}

.step {
  flex: 1 1 20%;
  text-align: center;
}
.step .indicator-radial,
.step .count {
  position: absolute;
  right: -2.5rem;
  top: -0.5rem;
}
.step .count {
  width: 100%;
  top: -0.625rem;
  left: 0;
  right: 0;
}

.step-link {
  height: 5rem;
  margin: 0;
  border-width: 0 0 0 0.1875rem;
  border-style: solid;
  font-size: 1rem;
  position: relative;
  line-height: 5rem;
  width: 100%;
}

.step-title {
  display: inline-block;
  position: relative;
}

.pane-header--steps .steps,
.pane-v2-banner .steps {
  padding: 0 6rem;
  flex-flow: row nowrap;
}
.pane-header--steps .steps .step-link,
.pane-v2-banner .steps .step-link {
  border-width: 0.1875rem 0 0 0;
}
.pane-header--steps .steps .step-link:before, .pane-header--steps .steps .step-link:after,
.pane-v2-banner .steps .step-link:before,
.pane-v2-banner .steps .step-link:after {
  display: none;
  content: "";
  position: absolute;
  width: 8%;
  height: 0.125rem;
  left: 0;
  top: 2.75rem;
}
.pane-header--steps .steps .step-link:after,
.pane-v2-banner .steps .step-link:after {
  left: auto;
  right: 0;
}
.pane-header--steps .steps .step,
.pane-v2-banner .steps .step {
  display: none;
}
@media (min-width: 992px) {
  .pane-header--steps .steps .step,
  .pane-v2-banner .steps .step {
    display: block;
  }
  .pane-header--steps .steps .step .step-link:before,
  .pane-header--steps .steps .step .step-link:after,
  .pane-v2-banner .steps .step .step-link:before,
  .pane-v2-banner .steps .step .step-link:after {
    display: block;
  }
}
.upload {
  position: relative;
  padding: 2rem 0;
  text-align: center;
}

.upload-input {
  display: none;
}

.upload-file {
  padding: 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.upload-file-name,
.upload-file-clear {
  flex: 0 0 auto;
}

.upload-file-clear {
  margin: 0 0 0 1rem;
}

.feedback-form__container {
  position: absolute;
  right: -22rem;
  top: 1rem;
  z-index: 999999999;
  width: 22rem;
  transition: all 0.5s;
}
.feedback-form__container--open {
  right: 0 !important;
}
.feedback-form__container #btn-feedback {
  position: absolute;
  left: -6rem;
  top: -2rem;
  height: 2rem;
  width: 6rem;
  background-color: #40BC8A;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999999999;
}
.feedback-form__container .feedback-body {
  height: auto;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15) !important;
  padding: 2rem;
  transition: all 0.5s;
}
.feedback-form__container .smileys__container {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
.feedback-form__container .feedback-smiley-container {
  width: 4.5rem;
  height: 4.5rem;
  margin: 0;
  padding: 0;
}
.feedback-form__container .rating-smiley {
  width: 90%;
  height: 90%;
  margin: auto;
  border-radius: 2000px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.feedback-form__container .rating-smiley .icon-container {
  margin: 0 !important;
  transform: scale(2.4);
  opacity: 0.8;
}
.feedback-form__container .icon-wrap svg {
  fill: #fff !important;
  stroke: #fff !important;
}
.feedback-form__container .rating-smiley-inactive {
  opacity: 0.5;
}
.feedback-form__container .rating-smiley-inactive:hover {
  opacity: 0.75;
}
.feedback-form__container .field-rating_radiobtn {
  visibility: hidden;
}
.feedback-form__container .field-rating_radiobtn .help-block {
  visibility: visible;
  position: relative;
  bottom: 1.5rem;
  margin: 0;
}
.feedback-form__container .btn-close-feedback {
  transform: scale(0.75);
  right: 1rem;
  top: 1rem;
  margin-bottom: 0.5rem;
}
.feedback-form__container #feedbackform-explanation {
  resize: none;
}
.feedback-form__container #feedbackform .form-control {
  border: 2px solid #bbb;
}

.loading-skeleton-body {
  position: absolute;
  z-index: 1000000000000000000000000;
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.loading-skeleton-body .skeleton-bar {
  width: 100%;
  background-color: #ccc;
  margin: 1rem;
  border-radius: 1000px;
  animation-name: skeleton-flash;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  height: 1.8rem;
}
.loading-skeleton-body .skeleton-bar-1 {
  width: 10%;
}
.loading-skeleton-body .skeleton-bar-2 {
  width: 20%;
}
.loading-skeleton-body .skeleton-bar-3 {
  width: 40%;
}
.loading-skeleton-body .skeleton-bar-4 {
  width: 60%;
}
.loading-skeleton-body .skeleton-bar-5 {
  width: 80%;
}
.loading-skeleton-body .skeleton-bar-bold {
  height: 2.4rem;
}
.loading-skeleton-body .skeleton-bar-std {
  height: 1.8rem;
}
.loading-skeleton-body .skeleton-bar-med {
  height: 1.2rem;
}
.loading-skeleton-body .skeleton-bar-thin {
  height: 0.8rem;
}
.loading-skeleton-body .skeleton-bar-extra-thin {
  height: 0.4rem;
}
.loading-skeleton-body .skeleton-bar-super-thin {
  height: 0.2rem;
}
.loading-skeleton-body .skeleton-bar-whitespace {
  background-color: #fff;
}
.loading-skeleton-body .skeleton-icon {
  background-color: #ccc;
  animation-name: skeleton-flash;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.loading-skeleton-body .skeleton-icon-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.loading-skeleton-body .skeleton-icon-std {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
@keyframes skeleton-flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#vacancy_modal-skeleton-body {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000000000000000000000;
  background-color: rgba(211, 217, 227, 0.72);
}
#vacancy_modal-skeleton-body .skeleton-box {
  background-color: #fff;
  box-shadow: 1rem 2rem -0.25rem rgba(14, 1, 43, 0.15);
  max-width: 76rem;
  height: 90vh;
  margin: auto;
  margin-top: 4.5vh;
  padding: 1rem;
}

#inzage_grid-skeleton-body {
  overflow: hidden;
  width: 100%;
  position: absolute;
  z-index: 10000000000000000;
  padding-top: 7rem;
}

#vacanciesList-skeleton {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
#vacanciesList-skeleton table {
  table-layout: fixed !important;
  width: 100%;
}
#vacanciesList-skeleton table [data-col-seq="0"] {
  width: 7% !important;
  padding-right: 1rem;
}
#vacanciesList-skeleton table [data-col-seq="1"] {
  width: 34% !important;
  padding-right: 3rem;
}
#vacanciesList-skeleton table [data-col-seq="2"] {
  width: 34% !important;
  padding-right: 3rem;
}
#vacanciesList-skeleton table [data-col-seq="3"] {
  width: 12% !important;
}
#vacanciesList-skeleton table [data-col-seq="4"] {
  width: 13% !important;
  padding: 0 2rem;
}

.inzage-skel-row-1 {
  width: 10rem;
}

#focus_content-skeleton-body {
  padding-right: 2rem;
  overflow: hidden;
}

.cluster-modaration-form {
  width: 27rem !important;
  height: 50rem;
  margin: 2rem auto;
}

.cluster-category-btn,
.cluster-category-back-btn {
  width: 12rem !important;
  margin: 0.5rem;
  position: relative !important;
  border: none !important;
  height: 3rem;
  clip-path: url(#clipRoundedRect);
}

[data-cluster-category="0"] {
  background-color: #e8d000 !important;
}

.cluster-category-title {
  width: 100%;
  text-align: center;
  margin: 1rem;
}

#cluster-count-togo {
  display: inline-block;
  text-align: center;
  width: 12rem;
}

.cluster_category_score_bar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  height: 100%;
  background-color: #F85C6A;
}

.cluster_category_text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  font: inherit;
  line-height: inherit;
  vertical-align: inherit;
  padding: inherit;
}

#skill_analysis_category-container table {
  table-layout: fixed;
}
#skill_analysis_category-container table th {
  color: #1E025F;
}
#skill_analysis_category-container [data-col-seq="0"] {
  width: 25% !important;
  text-align: right;
  padding-right: 1rem !important;
}
#skill_analysis_category-container [data-col-seq="1"], #skill_analysis_category-container [data-col-seq="2"], #skill_analysis_category-container [data-col-seq="3"], #skill_analysis_category-container [data-col-seq="4"], #skill_analysis_category-container [data-col-seq="5"] {
  width: 5% !important;
  text-align: center !important;
  padding-right: 1rem;
}
#skill_analysis_category-container [data-col-seq="6"], #skill_analysis_category-container [data-col-seq="7"] {
  padding-left: 1rem !important;
  width: 10% !important;
}

.manual-page-container {
  background: #1E025F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 8rem 0;
}
.manual-page-container h1 {
  display: inline-block !important;
}
.manual-page-container h1::after {
  content: " ";
}
.manual-page-content {
  width: 90%;
  max-width: 60rem;
}
.manual-page-content section {
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 0 0 3rem 0;
}
.manual-page-content p {
  color: #1E025F;
}
.manual-page-content p em {
  color: #F85C6A;
  font-weight: bold;
}
.manual-page-content ul {
  padding-left: 2rem;
}
.manual-page-content-background {
  background-color: #ddd;
  border-radius: 15px;
  padding: 0.5rem 2.5rem;
  width: 50%;
}
.manual-page-header {
  background-color: #40BC8A;
  padding: 1rem 2.5rem;
  border-radius: 0 3rem 0 0;
}
.manual-page-header h1 {
  color: #fff;
}
.manual-page-header-tip {
  background-color: #F85C6A;
}
.manual-frontpage-header {
  background-color: #40BC8A;
  padding: 1rem 2.5rem;
  border-radius: 0 0 3rem 0;
  position: relative;
  overflow: visible;
}
.manual-frontpage-header h1 {
  display: inline-block !important;
  color: #fff;
}
.manual-frontpage-bg {
  height: 18rem;
}

.manual-content-text,
.manual-frontpage-text {
  padding-left: 1rem;
  position: relative;
}

.manual-content-text::before {
  content: ">";
  position: absolute;
  height: 100%;
  width: 1rem;
  left: 0;
  top: 0;
}

.btn-manual {
  display: inline-block;
  width: 50%;
  height: 4rem;
  border: none;
}
.btn-manual:hover {
  background-color: #40BC8A;
  color: #fff;
}

.manual-stock-photo-container {
  display: inline-block;
  height: 100%;
  width: 8rem;
}

.manual-stock-photo {
  object-fit: cover;
}

.manual-frontpage-text {
  padding-left: 20rem;
}

.manual-frontpage section {
  display: flex;
}

.manual-screenshot {
  width: 100%;
  object-fit: contain;
  box-shadow: 7px 7px 24px 5px #ccc;
  margin: 0.5rem 0 2rem 0;
}
.manual-screenshot-icon {
  width: 2rem;
  border-radius: 1000px;
  border: 2px solid #F85C6A;
}

.manual-frontpage-bg {
  background-repeat: no-repeat;
  background-position: -18%;
  background-size: contain;
}

.manual-header-alt {
  color: hsl(258, 96%, 19%) !important;
}

.quickstart-modal-header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0 6px 6px -6px #000;
  top: -2px;
  left: 0;
}

#quickstartPopupModal .modal .modal-open {
  overflow: hidden;
}

@media all and (-ms-high-contrast: none) {
  .quickstart-modal-header {
    position: absolute !important;
  }
}
.manual-button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .manual-page-container {
    display: block;
    padding-top: 0;
  }
  .manual-page-content {
    width: 100%;
  }
  .manual-button-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .btn-manual {
    width: 50vw;
    margin: 0 !important;
    border-radius: 0 !important;
    font-size: 1.6rem;
  }
  .manual-frontpage-bg {
    background-image: none !important;
  }
  .manual-frontpage-text {
    padding-left: 0rem;
  }
}
.view-organisations input[type=checkbox].checkbox_large,
.view-vacancies input[type=checkbox].checkbox_large {
  appearance: au !important;
  height: 2rem;
  transform: scale(1.2);
}
.view-organisations input[type=checkbox].checkbox_large.checkbox_organisation, .view-organisations input[type=checkbox].checkbox_large.checkbox_vacancy,
.view-vacancies input[type=checkbox].checkbox_large.checkbox_organisation,
.view-vacancies input[type=checkbox].checkbox_large.checkbox_vacancy {
  margin-right: 0.75rem;
}
heightcheckbox_allheight .view-organisations input[type=checkbox]#checkbox_all,
heightcheckbox_allheight .view-vacancies input[type=checkbox]#checkbox_all {
  height: 2rem;
  position: relative;
}
.view-organisations input[type=checkbox].checkbox_organisation,
.view-vacancies input[type=checkbox].checkbox_organisation {
  appearance: checkbox !important;
}
.view-organisations .cabinet--rows .cabinet-title,
.view-vacancies .cabinet--rows .cabinet-title {
  margin-left: 1.5rem !important;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .view-organisations .cabinet--rows .checkbox_organisation,
    .view-vacancies .cabinet--rows .checkbox_organisation {
      position: absolute;
    }
    .view-organisations .cabinet--rows .cabinet-title,
    .view-vacancies .cabinet--rows .cabinet-title {
      margin-left: 2.5rem !important;
    }
  }
}

.portfolio_action_bar {
  position: relative;
  padding: 0 2px;
}
.portfolio_action_bar_container {
  display: flex;
  width: 100%;
  height: 3.6rem;
  padding-top: 4px;
  padding-right: 2rem;
  justify-content: space-between;
}
.portfolio_action_bar_buttons {
  margin-top: 0.7rem;
  margin-left: 8px;
  height: 2rem;
}
.portfolio_action_bar_checkbox {
  flex-grow: 1;
}
.portfolio_action_bar_grid-row {
  margin: 0.1rem 0 0 0.75rem;
}

#checkbox_organisation_all_label,
#checkbox_vacancy_all_label {
  position: relative;
  left: 6rem;
  bottom: 1rem;
}

.control {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: center;
  align-content: center;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  background: transparent;
  overflow: visible;
  text-align: center;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  border-radius: 0;
  cursor: pointer;
  touch-action: manipulation;
  text-transform: none;
  width: auto;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
.control:focus, .control--focus {
  outline-width: 0.1875rem;
}
.control__active, .control__init {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  flex-flow: row nowrap;
  align-items: center;
}
.control__label, .control__icon {
  flex: 0 0 auto;
}
.control__label {
  line-height: 1.25rem;
}
.control__icon .icon-container {
  margin: 0;
}
.control--size-sm {
  height: 2rem;
}
.control--size-md {
  height: 3rem;
}
.control--mode-block {
  flex: 1 0 100%;
  width: 100%;
  padding: 0 2rem;
}
.control--mode-block.control--align-space-between .control__active,
.control--mode-block.control--align-space-between .control__init {
  width: 100%;
  align-content: space-between;
}
.control--mode-block.control--icon-placement-suffix .control__label {
  order: 0;
}
.control--mode-block.control--icon-placement-suffix .control__icon {
  order: 10;
}
.control--mode-tab {
  flex: 1 0 auto;
  border-top: 0.1875rem solid transparent;
  height: 5rem;
  transition: border-color 150ms ease;
}
.control--mode-icon {
  padding: 0 1rem;
  border-radius: 1rem;
}
.control--mode-icon .control__label {
  visibility: hidden;
  position: absolute;
}
.control--mode-icon .control__icon .icon-container {
  margin: 0;
}
.control--mode-action {
  padding: 0 1rem;
  border-radius: 1rem;
}
.control--mode-action.control--outline {
  border: 0.1875rem solid transparent;
}

.team {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.team__name {
  flex: 1 0 100%;
  font-size: 0.875rem;
}
.team__member {
  flex: 0 0 auto;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  text-align: center;
  white-space: nowrap;
  line-height: 2rem;
  margin-right: 0.25rem;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.team__member:hover, .team__member:focus, .team__member:hover:focus {
  cursor: default;
}

.module-auth {
  text-align: center;
}
.module-auth .pane-v2--root .pane-section {
  height: calc(100vh - 8rem);
}
.module-auth .field-loginform-rememberme {
  padding-top: 2rem;
}
.module-auth .propose-signup,
.module-auth .propose-login,
.module-auth .propose-reset {
  text-align: center;
}
.module-auth .propose-signup a,
.module-auth .propose-login a,
.module-auth .propose-reset a {
  font-weight: 700;
  display: block;
}
@media (min-width: 768px) {
  .module-auth .propose-signup a,
  .module-auth .propose-login a,
  .module-auth .propose-reset a {
    display: inline;
  }
}
.module-auth .propose-login,
.module-auth .propose-reset {
  padding-top: 2rem;
}
.module-auth .void-title {
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 1rem;
}

.module-auth-title {
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin-bottom: 1rem;
}

.module-auth-form-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  height: 100%;
}

.module-auth-form {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .module-auth-form-wrap {
    align-items: center;
    justify-content: center;
  }
  .module-auth-form {
    flex: 0 0 66.6664%;
    max-width: 66.6664%;
    margin-top: -4rem;
  }
}
@media (min-width: 768px) {
  .module-auth-form {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .module-auth-form {
    flex: 0 0 33.3334%;
    max-width: 33.3334%;
  }
}
.module-career .career-switch,
.application-v2--module-career .career-switch {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 1rem;
}
.module-career .career-switch h2,
.application-v2--module-career .career-switch h2 {
  font-size: 1rem;
  line-height: 1.75rem;
  line-height: 1.5rem;
  flex: 0 0 auto;
  margin: 0;
  padding: 0 0.75rem 0 0;
  max-width: 60%;
}
.module-career .career-switch label,
.application-v2--module-career .career-switch label {
  flex: 0 0 auto;
  margin: 0.125rem 0 0 0;
}
.module-career .career-switch label input,
.application-v2--module-career .career-switch label input {
  position: absolute;
}
.module-career .career-slider,
.application-v2--module-career .career-slider {
  display: flex;
  flex-flow: row nowrap;
}
.module-career .career-slider .slider,
.application-v2--module-career .career-slider .slider {
  flex: 0 0 calc(60% - 4rem);
  margin-top: 0.5rem;
}
.module-career .career-slider .slider .tooltip-main,
.application-v2--module-career .career-slider .slider .tooltip-main {
  width: 4rem;
}
.module-career .career-slider .slider .tooltip-main:before,
.application-v2--module-career .career-slider .slider .tooltip-main:before {
  content: "" !important;
}
.module-career .career-slider .slider .tooltip-main:after,
.application-v2--module-career .career-slider .slider .tooltip-main:after {
  content: "%" !important;
}
.module-career .career-slider-title,
.application-v2--module-career .career-slider-title {
  flex: 0 0 40%;
  max-width: 40%;
}
.module-career .career-slider-title h3,
.application-v2--module-career .career-slider-title h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 1rem;
  line-height: 1.75rem;
}
.module-career.view-client,
.application-v2--module-career.view-client {
  /* index */
}
.module-career.view-client .layout-pane--primary,
.application-v2--module-career.view-client .layout-pane--primary {
  background: transparent;
}
.module-career.view-client .layout-pane--primary .pane-content,
.application-v2--module-career.view-client .layout-pane--primary .pane-content {
  overflow: hidden;
}
.module-career.view-client .career-dashboard-title,
.application-v2--module-career.view-client .career-dashboard-title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 0.86999rem;
  padding-top: 0.63001rem;
}
.module-career.view-client .lead,
.application-v2--module-career.view-client .lead {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.47374rem;
  padding-top: 0.52626rem;
}
.module-career.view-client .career-dashboard-intro,
.application-v2--module-career.view-client .career-dashboard-intro {
  margin-bottom: 3rem;
}
.module-career.view-client #career-dashboard-cards,
.application-v2--module-career.view-client #career-dashboard-cards {
  position: relative;
  margin-top: 2rem;
}
.module-career.view-client #career-dashboard-cards .card,
.application-v2--module-career.view-client #career-dashboard-cards .card {
  width: 100%;
}
.module-career.view-client #career-dashboard-cards .card .card-content,
.application-v2--module-career.view-client #career-dashboard-cards .card .card-content {
  height: 100%;
  position: relative;
}
.module-career.view-client #career-dashboard-cards .card h2,
.application-v2--module-career.view-client #career-dashboard-cards .card h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 1.71499rem;
  padding-top: 0.28501rem;
}
.module-career.view-client #career-dashboard-cards .card .indicator-radial,
.application-v2--module-career.view-client #career-dashboard-cards .card .indicator-radial {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.module-career.view-client #career-dashboard-cards .card .card-filter,
.application-v2--module-career.view-client #career-dashboard-cards .card .card-filter {
  margin-bottom: 0.25rem;
}
.module-career.view-client #career-dashboard-cards .card .btn,
.application-v2--module-career.view-client #career-dashboard-cards .card .btn {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  left: 2rem;
  width: auto;
}
.module-career.view-client #career-dashboard-cards .card .list,
.application-v2--module-career.view-client #career-dashboard-cards .card .list {
  margin-bottom: 3rem;
}
.module-career.view-tests .pane > .pane-content > .pane-section:first-child,
.application-v2--module-career.view-tests .pane > .pane-content > .pane-section:first-child {
  padding-top: 0rem;
}
.module-career.view-tests .card.card--test h2,
.application-v2--module-career.view-tests .card.card--test h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.46499rem;
  padding-top: 0.53501rem;
}
.module-career.view-professions .layout-pane--primary > .pane-filter,
.application-v2--module-career.view-professions .layout-pane--primary > .pane-filter {
  top: 0;
}
.module-career.view-professions .layout-pane--primary > .pane-filter .nav-tabs .nav-item,
.application-v2--module-career.view-professions .layout-pane--primary > .pane-filter .nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-filter,
.application-v2--module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-filter {
  top: 5rem;
}
.module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content,
.application-v2--module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content {
  top: 11rem;
}
.module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content > .pane-section:first-child,
.application-v2--module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content > .pane-section:first-child {
  padding-top: 0;
}
.module-career.view-professions .layout-pane--primary #pane-tab-2 > .pane-content,
.application-v2--module-career.view-professions .layout-pane--primary #pane-tab-2 > .pane-content {
  top: 5rem;
}
.module-career .layout-pane--tertiary .pane-content,
.application-v2--module-career .layout-pane--tertiary .pane-content {
  overflow: hidden;
}
.module-career .layout-pane--tertiary .focus-cover,
.module-career .layout-pane--tertiary .focus-meta,
.application-v2--module-career .layout-pane--tertiary .focus-cover,
.application-v2--module-career .layout-pane--tertiary .focus-meta {
  position: absolute;
}
.module-career .layout-pane--tertiary .focus-cover,
.application-v2--module-career .layout-pane--tertiary .focus-cover {
  top: -8rem;
  width: 100%;
  z-index: 1;
  transition: top 150ms ease;
}
.module-career .layout-pane--tertiary .focus-cover-actions,
.application-v2--module-career .layout-pane--tertiary .focus-cover-actions {
  bottom: 2rem;
  opacity: 0;
}
.module-career .layout-pane--tertiary .focus-map,
.application-v2--module-career .layout-pane--tertiary .focus-map {
  top: 0;
  bottom: 0;
}
.module-career .layout-pane--tertiary .focus-meta,
.application-v2--module-career .layout-pane--tertiary .focus-meta {
  height: 0;
  overflow: hidden;
}
.module-career .layout-pane--tertiary .focus-meta-title,
.application-v2--module-career .layout-pane--tertiary .focus-meta-title {
  display: none;
}
.module-career .layout-pane--tertiary .pane.pane--distance .slider .slider-handle,
.application-v2--module-career .layout-pane--tertiary .pane.pane--distance .slider .slider-handle {
  margin-top: -0.125rem;
}
.module-career .layout-pane--tertiary.focus--profession,
.application-v2--module-career .layout-pane--tertiary.focus--profession {
  /* When a single professions is shown */
}
.module-career .layout-pane--tertiary.focus--profession .focus-cover,
.application-v2--module-career .layout-pane--tertiary.focus--profession .focus-cover {
  top: 0;
}
.module-career .layout-pane--tertiary.focus--profession .focus-cover-actions,
.application-v2--module-career .layout-pane--tertiary.focus--profession .focus-cover-actions {
  bottom: -1rem;
  opacity: 1;
}
.module-career .layout-pane--tertiary.focus--profession .focus-map,
.application-v2--module-career .layout-pane--tertiary.focus--profession .focus-map {
  top: 8rem;
  bottom: 8.75rem;
}
.module-career .layout-pane--tertiary.focus--profession .focus-map-toggle,
.application-v2--module-career .layout-pane--tertiary.focus--profession .focus-map-toggle {
  bottom: 9.75rem;
}
.module-career .layout-pane--tertiary.focus--profession .pane.pane--distance,
.application-v2--module-career .layout-pane--tertiary.focus--profession .pane.pane--distance {
  bottom: 13.75rem;
}
.module-career .layout-pane--tertiary.focus--profession .focus-meta,
.application-v2--module-career .layout-pane--tertiary.focus--profession .focus-meta {
  bottom: 0;
  height: 8.75rem;
  padding-top: 2.5rem;
}
.module-career .layout-pane--tertiary.focus--profession .focus-meta-toggle,
.application-v2--module-career .layout-pane--tertiary.focus--profession .focus-meta-toggle {
  height: 8.75rem;
}
.module-career .layout-pane--tertiary.focus--profession-meta,
.application-v2--module-career .layout-pane--tertiary.focus--profession-meta {
  /* When a single professions meta info is shown */
}
.module-career .layout-pane--tertiary.focus--profession-meta .focus-map,
.application-v2--module-career .layout-pane--tertiary.focus--profession-meta .focus-map {
  top: 8rem;
  bottom: 5rem;
}
.module-career .layout-pane--tertiary.focus--profession-meta .pane.pane--distance,
.application-v2--module-career .layout-pane--tertiary.focus--profession-meta .pane.pane--distance {
  display: none;
}
.module-career .layout-pane--tertiary.focus--profession-meta .focus-meta,
.application-v2--module-career .layout-pane--tertiary.focus--profession-meta .focus-meta {
  bottom: 0;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 10rem);
}
.module-career .layout-pane--tertiary.focus--profession-meta .focus-meta-toggle,
.application-v2--module-career .layout-pane--tertiary.focus--profession-meta .focus-meta-toggle {
  height: 2.5rem;
}
.module-career .layout-pane--tertiary.focus--profession-meta .focus-meta-title,
.application-v2--module-career .layout-pane--tertiary.focus--profession-meta .focus-meta-title {
  display: block;
}
.module-career.view-tests .cards,
.application-v2--module-career.view-tests .cards {
  padding-bottom: 5rem;
}
.module-career.view-profile .cards,
.application-v2--module-career.view-profile .cards {
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 5rem;
}
.module-career .pane-filter--search .filter-reset,
.application-v2--module-career .pane-filter--search .filter-reset {
  position: absolute;
  top: 0;
  right: 1.75rem;
  height: 3rem;
  width: 3rem;
  z-index: 1;
  border-radius: 0 1rem 1rem 0;
  background: linear-gradient(to left, #f7f7f9 0%, rgba(247, 247, 249, 0) 100%);
  display: none;
}
.no-touch .module-career .pane-filter--search .filter-reset:hover > .tip,
.no-touch .module-career .pane-filter--search .filter-reset:hover > .action-label, .no-touch .module-career .pane-filter--search .filter-reset:focus > .tip,
.no-touch .module-career .pane-filter--search .filter-reset:focus > .action-label, .no-touch .module-career .pane-filter--search .filter-reset:hover:focus > .tip,
.no-touch .module-career .pane-filter--search .filter-reset:hover:focus > .action-label,
.no-touch .application-v2--module-career .pane-filter--search .filter-reset:hover > .tip,
.no-touch .application-v2--module-career .pane-filter--search .filter-reset:hover > .action-label,
.no-touch .application-v2--module-career .pane-filter--search .filter-reset:focus > .tip,
.no-touch .application-v2--module-career .pane-filter--search .filter-reset:focus > .action-label,
.no-touch .application-v2--module-career .pane-filter--search .filter-reset:hover:focus > .tip,
.no-touch .application-v2--module-career .pane-filter--search .filter-reset:hover:focus > .action-label {
  display: block;
}
.module-career .pane-filter--search .filter-reset:hover .icon--reset, .module-career .pane-filter--search .filter-reset:focus .icon--reset, .module-career .pane-filter--search .filter-reset:hover:focus .icon--reset,
.application-v2--module-career .pane-filter--search .filter-reset:hover .icon--reset,
.application-v2--module-career .pane-filter--search .filter-reset:focus .icon--reset,
.application-v2--module-career .pane-filter--search .filter-reset:hover:focus .icon--reset {
  opacity: 0.88;
}
@media (min-width: 768px) {
  .module-career.view-client #career-dashboard-cards,
  .application-v2--module-career.view-client #career-dashboard-cards {
    height: calc(100vh - 12rem);
  }
  .module-career.view-client #career-dashboard-cards:after,
  .application-v2--module-career.view-client #career-dashboard-cards:after {
    content: "flickity";
    display: none; /* hide :after */
  }
  .module-career.view-client #career-dashboard-cards .card,
  .application-v2--module-career.view-client #career-dashboard-cards .card {
    height: 100%;
    width: 40%;
  }
  .module-career.view-client #career-dashboard-cards .card .list,
  .application-v2--module-career.view-client #career-dashboard-cards .card .list {
    position: absolute;
    top: 7rem;
    bottom: 5rem;
    left: 2rem;
    right: 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 0;
  }
  .module-career.view-client #career-dashboard-cards .card-content,
  .application-v2--module-career.view-client #career-dashboard-cards .card-content {
    height: 100%;
  }
  .module-career.view-client .layout-pane--primary .pane-content > .pane-section:first-child, .module-career.view-coach .layout-pane--primary .pane-content > .pane-section:first-child,
  .application-v2--module-career.view-client .layout-pane--primary .pane-content > .pane-section:first-child,
  .application-v2--module-career.view-coach .layout-pane--primary .pane-content > .pane-section:first-child {
    padding-top: 9.75rem;
  }
  .module-career .navigation-toggle,
  .application-v2--module-career .navigation-toggle {
    display: none;
  }
  .module-career.view-tests .pane > .pane-content,
  .application-v2--module-career.view-tests .pane > .pane-content {
    top: 0;
  }
  .module-career.view-tests .pane > .pane-content > .pane-section:first-child,
  .application-v2--module-career.view-tests .pane > .pane-content > .pane-section:first-child {
    padding-top: 2rem;
  }
  .module-career.view-professions .pane.pane--popover-md,
  .application-v2--module-career.view-professions .pane.pane--popover-md {
    top: 11.25rem;
  }
  .module-career.view-professions .pane.pane--distance,
  .application-v2--module-career.view-professions .pane.pane--distance {
    top: auto;
    bottom: 5rem;
  }
  .module-career.view-professions .layout-pane--primary > .pane-filter .nav-tabs .nav-item,
  .application-v2--module-career.view-professions .layout-pane--primary > .pane-filter .nav-tabs .nav-item {
    width: 33% !important;
  }
  .module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content,
  .application-v2--module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content {
    top: 12rem;
  }
  .module-career.view-professions .layout-pane--primary #pane-tab-2 > .pane-content,
  .module-career.view-professions .layout-pane--primary #pane-tab-3 > .pane-content,
  .application-v2--module-career.view-professions .layout-pane--primary #pane-tab-2 > .pane-content,
  .application-v2--module-career.view-professions .layout-pane--primary #pane-tab-3 > .pane-content {
    top: 6rem;
  }
}
@media (min-width: 992px) {
  .module-career.view-professions .pane.pane--distance,
  .application-v2--module-career.view-professions .pane.pane--distance {
    left: 10rem;
  }
}
@media (min-width: 1200px) {
  .module-career.view-professions .pane.pane--distance,
  .application-v2--module-career.view-professions .pane.pane--distance {
    left: 15rem;
  }
}

.module-uwv .select2-container--krajee .select2-selection {
  padding: 0 !important;
}
.module-uwv .strip .flickity-viewport {
  height: 60px !important;
}
.module-uwv .uwv-dashboard__grid-container {
  display: grid;
  width: 100%;
  height: auto;
  overflow: hidden;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: auto auto auto;
}
.module-uwv #uwv-dashboard__vacancies-table {
  cursor: pointer;
  width: calc(100% - 8rem);
  left: -5rem;
  position: relative;
  grid-row: 1/3;
  grid-column: 1/3;
}
.module-uwv #uwv-dashboard__vacancies-table table {
  table-layout: fixed;
}
.module-uwv #uwv-dashboard__vacancies-table table td,
.module-uwv #uwv-dashboard__vacancies-table table h2, .module-uwv #uwv-dashboard__vacancies-table table p {
  font-size: 1rem !important;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="0"] {
  width: 25% !important;
  padding-right: 1rem;
  text-align: right;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="1"] {
  width: 33% !important;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="1"] .uwv-dashboard__vacancy-title {
  white-space: pre;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="2"] {
  width: 32% !important;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="2"] .uwv-dashboard__vacancy-title {
  white-space: pre;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="3"] {
  width: 10% !important;
  padding: 0 !important;
  overflow: visible;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="3"] .uwv-dashboard__probability-range {
  text-align: right;
}
.module-uwv .uwv-chart__top-right {
  grid-row: 1;
  grid-column: 3;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__top-right div {
  border: 0.5rem solid #fff;
}
.module-uwv .uwv-chart__mid-right {
  grid-row: 2;
  grid-column: 3;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__mid-right div {
  border: 0.5rem solid #fff;
}
.module-uwv .uwv-chart__bottom-right {
  grid-row: 3;
  grid-column: 2/4;
  margin: 1rem 0;
  padding: 0 1rem;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__bottom-right div {
  border: 0.5rem solid #fff;
}
.module-uwv .uwv-chart__bottom-left {
  grid-row: 3;
  grid-column: 1;
  margin: 1rem 0;
  padding: 0 1rem;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__bottom-left div {
  border: 0.5rem solid #fff;
}
.module-uwv .vacanciesList {
  cursor: pointer;
}
.module-uwv .actions {
  position: fixed;
}
.module-uwv [name="VacancyClassificationSearch[VacancyTitle]"],
.module-uwv .uwv__filter,
.module-uwv [aria-labelledby=select2-vacancyclassificationsearch-steekproefnummer-container],
.module-uwv .select2-container--krajee .select2-selection--single,
.module-uwv .sla-option {
  border-radius: 0 !important;
}
.module-uwv .sla-chart-container {
  position: relative;
  padding: 2rem 0;
}
.module-uwv .sla-chart-container .highcharts-title {
  position: absolute !important;
  top: -2rem !important;
  width: 100% !important;
  text-align: center !important;
}
.module-uwv .sla-option {
  width: 10rem !important;
  display: inline-block;
}
.module-uwv .sla-option-container {
  position: absolute;
  top: 0;
  left: 0;
}
.module-uwv .uwv-grid__text {
  margin: 0;
  white-space: pre;
  padding-right: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module-uwv .search_container-boc-isco4-layer5 {
  width: 25%;
  box-sizing: border-box;
  height: auto;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
}
.module-uwv .search-buttons__container {
  width: auto;
  position: absolute;
  top: 0;
  right: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.module-uwv .btn--uwv-option {
  display: inline-block;
  width: 4.2rem;
  height: 2rem;
  margin-left: 0.2rem !important;
  text-align: center;
  line-height: 0.4rem;
}
.module-uwv .uwv__filter-betrouwbaarheid-container {
  position: absolute;
  top: 0;
}
.module-uwv .uwv__filter-betrouwbaarheid-text {
  display: inline-block;
  width: 15%;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
.module-uwv .uwv__filter-betrouwbaarheid-input {
  display: inline-block;
  width: 35%;
  padding-left: 0.5rem;
  padding-right: 0;
  text-align: right;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container {
  line-height: 3rem;
}
.module-uwv .btn-close-small {
  position: absolute;
  top: 0.9rem !important;
  right: 1rem !important;
  color: #fff !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  border-radius: 1000px !important;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
}
.module-uwv [aria-labelledby=select2-vacancyclassificationsearch-steekproefnummer-container] .select2-selection__arrow {
  visibility: hidden;
}
.module-uwv #vacanciesList-container td {
  position: relative;
}
.module-uwv #vacanciesList-container th {
  text-align: left;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container .select2-search__field {
  background-color: rgba(0, 0, 0, 0) !important;
  height: 48px;
  width: 100%;
  padding: 16px;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container {
  position: absolute;
  top: -0.4rem;
  padding: 0;
  left: 1rem;
}
.module-uwv #vacanciesList-container table,
.module-uwv #vacanciesList-skeleton table {
  table-layout: fixed;
}
.module-uwv #vacanciesList-container [data-col-seq="0"],
.module-uwv #vacanciesList-skeleton [data-col-seq="0"] {
  width: 9% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="1"],
.module-uwv #vacanciesList-skeleton [data-col-seq="1"] {
  width: 30% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="2"],
.module-uwv #vacanciesList-skeleton [data-col-seq="2"] {
  width: 28% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="3"],
.module-uwv #vacanciesList-skeleton [data-col-seq="3"] {
  width: 12% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="4"],
.module-uwv #vacanciesList-skeleton [data-col-seq="4"] {
  width: 9% !important;
  text-align: center;
}
.module-uwv #vacanciesList-container [data-col-seq="5"],
.module-uwv #vacanciesList-skeleton [data-col-seq="5"] {
  width: 13% !important;
}
.module-uwv .vacanciesList .indicator-progress {
  width: 65%;
}
.module-uwv .random-vacancies-container {
  position: relative;
  height: auto;
}
.module-uwv .random-vacancies {
  width: 20rem;
  height: auto;
}
.module-uwv .random-vacancies__form {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  flex-direction: column;
}
.module-uwv .btn--random-vacancies {
  border: 2px solid #96989C;
  margin-top: 1rem;
}
.module-uwv .toggle-vacancy-menu {
  width: auto;
  position: absolute;
  left: 0;
  border: 2px solid #000;
}
.module-uwv .btn__reset-form,
.module-uwv .select2-selection__clear {
  position: absolute !important;
  top: 0.9rem !important;
  right: 2.5rem !important;
  background-color: #666 !important;
  color: #fff !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  border-radius: 1000px !important;
  line-height: 1rem !important;
  text-align: center !important;
  cursor: pointer !important;
}
.module-uwv .btn__reset-form::before,
.module-uwv .select2-selection__clear::before {
  content: "x";
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.module-uwv .btn__reset-form:hover,
.module-uwv .select2-selection__clear:hover {
  background-color: #000 !important;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container .select2-selection__clear {
  position: relative !important;
  right: 0rem !important;
}
.module-uwv .form-get-steekproeven {
  width: 25rem;
}
.module-uwv #vacancyclassificationsearch-manualclassificationjudgment ~ .select2-container--krajee .select2-selection__arrow {
  background-image: none !important;
}
.module-uwv .manual-judgment__correction::after {
  content: "!";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
}

.btn__next-small {
  text-align: center;
  border-radius: 1000px;
  box-shadow: none;
  padding: 0.5rem 1rem;
}

#vacancyPopupModal [type=checkbox] {
  -webkit-appearance: checkbox;
}
#vacancyPopupModal .modal-content {
  max-height: 90vh;
  overflow-y: scroll;
}
#vacancyPopupModal .modal-footer {
  text-align: left !important;
}
#vacancyPopupModal .btn-danger {
  border-color: #ff4444;
}
#vacancyPopupModal .vacancy-judgement .btn,
#vacancyPopupModal .vacancy-judgement form {
  display: inline-block;
}
#vacancyPopupModal .select2-selection__rendered {
  text-align: left;
}
#vacancyPopupModal .select2.select2-container.select2-container--krajee {
  width: 100% !important;
}
#vacancyPopupModal .focus-property-description-decision::first-letter {
  text-transform: uppercase;
}
#vacancyPopupModal .uwv-form form {
  width: 100%;
}
#vacancyPopupModal .uwv-form .focus-property-term {
  text-align: left !important;
}
#vacancyPopupModal .uwv-form--button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
#vacancyPopupModal .uwv-form--button-container .btn {
  margin-left: 0 !important;
  width: 100% !important;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment #oknokform-judgment {
  display: flex;
  justify-content: left;
  align-items: center;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio {
  text-align: left;
  margin-right: 1rem;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio label {
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio:nth-of-type(1) label {
  background-color: #C5D937;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio:nth-of-type(2) label, #vacancyPopupModal .uwv-form .field-oknokform-judgment .radio:nth-of-type(3) label {
  background-color: #D43636;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment input {
  appearance: auto;
  width: 1rem;
  height: 1rem;
}
#vacancyPopupModal .uwv-form .select2-selection__choice {
  max-width: calc(100% - 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
#vacancyPopupModal .uwv-form .select2-selection__choice .select2-selection__choice__remove {
  position: relative;
  bottom: 2px;
}

.advanced-search-container {
  position: relative;
  height: 4rem;
}
.advanced-search-container .form-advanced-search {
  position: absolute;
  left: 0;
  display: flex;
}
.advanced-search-container .form-advanced-search > .form-group {
  display: flex;
  position: relative;
}
.advanced-search-container .form-advanced-search > .form-group > .form-control {
  background-color: #F6F8FC;
  width: 30rem;
  border-color: #bbb;
  margin-right: 2rem;
}
.advanced-search-container .form-advanced-search > .form-group > .form-control:hover {
  border-color: #443469;
}
.advanced-search-container #advancedsearchfield {
  display: inline-block;
}
.advanced-search-container .form-advanced-search > .form-group > .btn-primary {
  width: 5rem;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
}
.advanced-search-container .reset_form {
  position: absolute;
  cursor: pointer;
  background-color: #5E529F !important;
  font-weight: bold;
  color: #fff !important;
  transition: all 0.3s;
  right: 1rem !important;
}
.advanced-search-container .reset_form:hover {
  background-color: #1E025F !important;
}

#select2-vacancyclassificationsearch-manualclassificationjudgment-results li {
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#select2-vacancyclassificationsearch-manualclassificationjudgment-results li::after, #select2-vacancyclassificationsearch-manualclassificationjudgment-results li::before {
  content: "";
  height: 90%;
  margin: 0.5rem 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999;
}

[aria-labelledby=select2-vacancyclassificationsearch-manualclassificationjudgment-container] {
  padding: 0 !important;
}

.review-ok-nok-btn-container,
#review-btn-container {
  display: flex;
}

.brand--uwv .select2-container--krajee .select2-selection__choice {
  border: 1px solid #666 !important;
}

input.sladatatype,
#show_eval {
  -webkit-appearance: checkbox;
}

#uwvSlaExport {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 1rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 8px -2px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#uwvSlaExport .icon-container.icon-container--download.icon-container--lg {
  margin-right: 0 !important;
  width: 2rem !important;
}

.uwv__chart-box {
  background: #fff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  padding: 2rem;
  margin-bottom: 2rem;
}

.layout-default.application-v2 {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.layout-default.application-v2 .logo,
.layout-default.application-v2 .user {
  z-index: 1;
}
.layout-default.application-v2 > .action--navigation {
  top: auto;
  right: auto;
  left: 1rem;
  bottom: 1rem;
  position: absolute;
  z-index: 910;
}
@media (min-width: 768px) {
  .layout-default .pane-close.action--shrink-md {
    top: 1.5rem;
  }
  .layout-default .pane-v2.pane-v2--root .pane-v2-banner .action--search {
    display: none;
  }
  .layout-default .pane-v2.pane-v2--search {
    display: block;
  }
}
@media (max-width: 991px) {
  .layout-default.layout-default--split .pane-v2.layout-default--split-secondary {
    top: 0 !important;
    height: 100% !important;
  }
}
@media (min-width: 992px) {
  .layout-default.layout-default--split .pane-v2.layout-default--split-secondary {
    width: 34%;
    margin-right: -34%;
    /* Exact height is managed through JS, these are default */
    top: 5rem;
    height: calc(100% - 5rem);
  }
  .layout-default.layout-default--split .pane-v2-backdrop.layout-default--split-secondary {
    display: none;
  }
  .layout-default.layout-default--split-open .pane-v2.layout-default--split-primary .pane-v2-content, .layout-default.layout-default--split-lg-open .pane-v2.layout-default--split-primary .pane-v2-content {
    width: 66%;
  }
  .layout-default.layout-default--split-open .pane-v2.layout-default--split-secondary, .layout-default.layout-default--split-lg-open .pane-v2.layout-default--split-secondary {
    margin-right: 0;
  }
  .layout-default.layout-default--split-open.view-instruments .pane-v2--root .action[data-pane-target=cart], .layout-default.layout-default--split-lg-open.view-instruments .pane-v2--root .action[data-pane-target=cart] {
    opacity: 0;
  }
  .layout-default.layout-default--split-close .pane-v2.layout-default--split-primary .pane-v2-content {
    width: 100%;
  }
  .layout-default.layout-default--split-close .pane-v2.layout-default--split-secondary {
    margin-right: -34%;
  }
  .layout-default.layout-default--split-close.view-instruments .pane-v2--root .action[data-pane-target=cart] {
    opacity: 1;
  }
}

.view-results > .action--navigation,
.view-results > .user {
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.view-results > .user {
  display: block;
  left: auto;
  right: 1rem;
}
.view-results .search {
  /* For mobile only */
  top: 4.5rem;
  z-index: 711;
}
.view-results .search .search-profile {
  top: -3.5rem;
  left: 0;
  right: 0;
  width: auto;
  text-align: center;
}
.view-results .search .search-query {
  margin-left: 0;
  margin-right: 0;
}
.view-results .search .search-profile {
  left: 4.5rem;
}
.view-results .pane-v2-backdrop--navigation {
  z-index: 99996;
}
.view-results .pane-v2--navigation {
  z-index: 99997;
}
.view-results .pane-v2-backdrop--navigation-portfolio,
.view-results .pane-v2-backdrop--navigation-queries {
  z-index: 99998;
}
.view-results .pane-v2--navigation-portfolio,
.view-results .pane-v2--navigation-queries {
  z-index: 99999;
}
.view-results .pane-results,
.view-results .pane-focus,
.view-results .pane-filters,
.view-results .pane-index {
  display: block;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
  will-change: top, width;
}
.safari11 .view-results .pane-results,
.safari11 .view-results .pane-focus,
.safari11 .view-results .pane-filters,
.safari11 .view-results .pane-index {
  transition-property: none;
  transition-duration: 0ms;
  transition-timing-function: none;
}
.view-results .pane-results {
  height: calc(100% - 8.5rem);
  top: 8.5rem;
  position: absolute;
  z-index: 710;
}
.windows.chrome .view-results .pane-results .pane-content {
  z-index: initial;
}
.view-results .pane-results .pane-content.results-map {
  overflow: hidden;
}
.view-results .pane-results .pane-content.results-map .map {
  height: 100%;
}
.view-results .pane-results.pane-results--modes .pane-content {
  padding-top: 4rem;
}
@media (min-width: 992px) {
  .view-results .pane-results.pane-results--modes .pane-content {
    padding-top: 5rem;
  }
}
.view-results .pane-filters {
  height: 100%;
  margin-left: -100vw;
  z-index: 720;
}
.view-results .pane-filters .pane-footer {
  padding: 1rem 1rem 0;
}
.view-results .pane-filters .btn.btn-secondary {
  padding-left: 0;
  padding-right: 0;
}
.view-results .pane-focus {
  display: flex;
  height: 100%;
  width: 100%;
  margin-left: 0;
  margin-right: -100%;
  z-index: 730;
}
.view-results .pane-focus .pane-back {
  display: block;
}
.view-results .pane-focus .focus-cover-actions {
  display: none;
}
.view-results .pane-focus .btn.focus-save {
  display: none;
}
.view-results .pane-focus .action--close .key {
  display: none;
}
.view-results .pane-v2--organisations_label {
  z-index: 731;
}
.view-results .pane-index {
  z-index: 711; /* Above results (for labels), Below the rest */
}
.view-results .pane-header .pane-header-dropdown-toggle:after {
  content: "";
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
}
.view-results.view-results--top .results-modes, .view-results.view-results--pinned .results-modes {
  opacity: 1;
  height: 4rem;
  pointer-events: all;
}
.view-results.view-results--not-top .results-modes, .view-results.view-results--unpinned .results-modes {
  opacity: 0;
  height: 0;
  padding: 0;
  pointer-events: none;
}
.view-results.view-results--not-top .pane-results .pane-content.results-map, .view-results.view-results--unpinned .pane-results .pane-content.results-map {
  padding-top: 0;
}
.view-results.view-results--not-top.view-results--pinned .results-modes, .view-results.view-results--unpinned.view-results--pinned .results-modes {
  opacity: 1;
  height: 4rem;
  pointer-events: all;
}
.view-results.view-results--filter .pane-filters {
  margin-left: 0;
  background: #fff;
}
.view-results.view-results--filter .pane-filters .pane-content::-webkit-scrollbar {
  background-color: #fff;
}
.view-results.view-results--filter .results-modes {
  opacity: 0 !important;
  height: 0;
  padding: 0;
  pointer-events: none;
}
.view-results.view-results--filter .pane-results .pane-content {
  padding-top: 0;
}
.view-results.view-results--focus .pane-filters {
  margin-left: -100vw;
}
.view-results.view-results--focus .pane-results {
  margin-left: 0;
}
.view-results.view-results--focus .pane-results .pane-content {
  padding-top: 0;
}
.view-results.view-results--focus .pane-focus {
  margin-right: 0;
}
.view-results.view-results--focus .results-modes {
  opacity: 0 !important;
  height: 0;
  padding: 0;
  pointer-events: none;
}
@media (min-width: 768px) {
  .view-results {
    /* Except .pane-focus */
  }
  .view-results .pane-results,
  .view-results .pane-filters,
  .view-results .pane-focus,
  .view-results .pane-index {
    height: calc(100% - 5rem);
    top: 5rem;
  }
  .view-results .search {
    top: 1rem;
  }
  .view-results .search .search-profile {
    top: 0;
    left: 0;
    right: auto;
    width: 4rem;
    text-align: left;
  }
  .view-results .search .search-query {
    margin-left: 4.5rem;
    margin-right: 0;
  }
  .view-results .search.search--profile .search-profile {
    width: 12rem;
  }
  .view-results .search.search--profile .search-query {
    margin-left: 12.5rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .view-results .search {
    left: 6rem;
    right: 12rem;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .view-results .search {
    left: 8rem;
    right: 14rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .view-results .search {
    left: 50%;
    right: auto;
    width: 50rem;
    margin-left: -25rem;
  }
}
@media (min-width: 768px) {
  .view-results .pane-focus {
    top: 0rem;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .view-results {
    /* Desktop */
  }
  .view-results .search {
    z-index: 732;
  }
  .view-results .pane-focus,
  .view-results .pane-filters {
    position: absolute;
  }
  .view-results .pane-focus {
    margin-left: 100vw;
    width: 66vw;
    /* Set .pane-focus height equal to other panes */
    top: 9rem;
    height: calc(100% - 9rem);
  }
  .view-results .pane-focus .pane-back {
    display: none;
  }
  .view-results .pane-focus .pane-close {
    display: block;
  }
  .view-results .pane-focus .actions-header {
    right: 5.5rem;
  }
  .view-results .pane-filters {
    margin-left: -25vw;
    width: 25vw;
  }
  .view-results.view-results--filter .pane-filters {
    margin-left: 0;
  }
  .view-results.view-results--filter .pane-results {
    width: 75vw;
    margin-left: 25vw;
  }
  .view-results.view-results--filter .results-filter {
    opacity: 0 !important;
    pointer-events: none;
  }
  .view-results.view-results--focus .pane-filters {
    margin-left: -25vw;
  }
  .view-results.view-results--focus .pane-results {
    width: 34vw;
    margin-left: 0;
  }
  .view-results.view-results--focus .pane-results .vacancy-heading {
    width: 100%;
  }
  .view-results.view-results--focus .pane-results .vacancy-meta {
    width: 0;
    opacity: 0;
  }
  .view-results.view-results--focus .pane-focus {
    margin-left: 34vw;
  }
}
.view-results.view-results--report .pane-results .pane-content {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 992px) {
  .view-results.view-results--report .pane-results .pane-content {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.view-results.view-results--report .pane-results .nav-tabs {
  text-align: center;
  margin-bottom: 2rem;
}

.view-dashboard #favourites {
  padding-bottom: 24rem; /* room for extra long dropdowns */
}

.view-grid .pane-back {
  display: block;
}
.view-grid .logo {
  display: none;
}
@media (min-width: 992px) {
  .view-grid .logo {
    display: block;
  }
}
.view-grid .pane-tabs {
  text-align: center;
}
.view-grid .pane-tabs + .pane-content {
  padding-top: 1rem;
}
.no-touch.ie11 .view-grid .content, .no-touch.chrome .view-grid .content {
  overflow: hidden;
}
@media (min-width: 992px) {
  .view-grid .pane-back,
  .view-grid .pane-header-title {
    display: none;
  }
  .view-grid .pane-header {
    height: 9rem;
  }
  .view-grid .pane-header + .pane-content {
    top: 9rem;
    padding-top: 3rem;
  }
  .view-grid .pane-header + .pane-tabs {
    top: 9rem;
    text-align: left;
  }
  .view-grid .pane-header + .pane-tabs + .pane-content {
    top: 13rem;
    padding-top: 3rem;
  }
}

.view-shop .header {
  display: none;
}
.view-shop .pane-close {
  display: block;
}
.view-shop .price-notes {
  padding-bottom: 6rem;
}

.view-results.view-results--report.view-results--report-doelgroependashboard .pane-results .pane-content {
  position: relative;
  overflow-y: auto;
  padding-bottom: 0;
}
@media (max-width: 991px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .pane-results .pane-content {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .pane-results {
    left: 6rem;
    width: calc(100% - 6rem);
  }
}
@media (min-width: 992px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard.view-results--filter .pane-results {
    left: 0rem;
    width: calc(100% + 25vw);
  }
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta {
  margin-bottom: 0;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta + .results-meta {
  margin-top: 0;
  margin-bottom: 2rem;
  height: 3rem;
  z-index: 0;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta + .results-meta .results-meta-item {
  display: inline-block;
  max-width: 50%;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta + .results-meta .results-meta-item + .results-meta-item {
  padding-left: 0.25rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta + .results-meta .results-meta-item .results-meta-label {
  max-width: 100%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 992px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .results-meta {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .results-meta .results-meta-dropdown--right {
    right: 2rem;
  }
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title {
  overflow: hidden;
  white-space: nowrap;
  max-width: 50%;
  text-overflow: ellipsis;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title .results-meta-label {
  font-size: 1rem;
  line-height: 1.75rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .pane-index {
  display: none !important;
}
@media (min-width: 992px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .pane-index {
    right: auto;
    width: 6rem;
    display: block !important;
  }
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-charts {
  padding: 0 1rem 3rem;
  margin: 0rem;
}
.touch.webkit .view-results.view-results--report.view-results--report-doelgroependashboard .report-charts {
  transform: translate3d(0, 0, 0);
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter {
  margin: 0 -1rem;
  padding: 0 0 1rem;
  overflow: hidden;
  clear: both;
  position: relative;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter:first-child .report-chapter-heading .report-chapter-icon {
  height: 4rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter:first-child .report-chapter-heading .report-chapter-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter:first-child .report-chapter-heading .report-chapter-icon .report-icon {
  top: 2rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-heading {
  margin-left: -1rem;
  margin-right: -1rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-icon {
  position: relative;
  height: 6rem;
  width: 100%;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-icon:before {
  content: "";
  display: block;
  height: 0.06125rem;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-icon .report-icon {
  top: 4rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company {
  padding: 1.75rem 2.5rem;
  margin: 0 0 0.5rem;
  min-height: 0 !important;
  z-index: 0;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary:hover, .view-results.view-results--report.view-results--report-doelgroependashboard .report-summary:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice:focus,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company:hover,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company:focus {
  z-index: 1;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: -0.37751rem;
  padding-top: 0.37751rem;
  text-align: left;
  position: relative;
  display: block;
  padding-right: 3.5rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .btn .icon--info,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .btn .icon--info,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .btn .icon--info,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .btn .icon--info,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .btn .icon--info,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .btn .icon--info,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .btn .icon--save,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .btn .icon--info {
  font-size: 1rem;
  font-family: "icons-report" !important;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .btn .icon--save:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .btn .icon--save:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .btn .icon--save:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .btn .icon--save:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .btn .icon--save:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .btn .icon--save:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .btn .icon--save:before {
  top: 0.125rem;
  content: "\e905";
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .btn .icon--info:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .btn .icon--info:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .btn .icon--info:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .btn .icon--info:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .btn .icon--info:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .btn .icon--info:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .btn .icon--info:before {
  content: "\e908";
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .report-header-info.btn,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .report-header-export-form {
  position: absolute;
  right: 0;
  top: 0.25rem;
  display: block;
  margin: 0;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .report-header-export,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .report-header-export,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .report-header-export,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .report-header-export,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .report-header-export,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .report-header-export,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .report-header-export {
  margin: 0;
  display: block;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-title .report-header-export-form,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-title .report-header-export-form {
  margin-right: 1.75rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-header-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-header-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-header-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-header-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-header-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-header-icon:before,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-header-icon:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary + .report-chapter .report-chapter-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart + .report-chapter .report-chapter-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list + .report-chapter .report-chapter-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags + .report-chapter .report-chapter-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments + .report-chapter .report-chapter-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice + .report-chapter .report-chapter-title,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company + .report-chapter .report-chapter-title {
  margin-top: 4rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-content,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-content,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-content,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-content,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-content,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-content,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-content {
  min-height: 20rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .report-segment-value,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .report-segment-value,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .report-segment-value,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .report-segment-value,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .report-segment-value,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .report-segment-value,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-segment-label,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .report-segment-value {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: -0.44001rem;
  padding-top: 0.44001rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary .dropdown-menu,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart .dropdown-menu,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list .dropdown-menu,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags .dropdown-menu,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments .dropdown-menu,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice .dropdown-menu,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company .dropdown-menu {
  z-index: 1;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary:nth-child(even),
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart:nth-child(even),
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list:nth-child(even),
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags:nth-child(even),
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments:nth-child(even),
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice:nth-child(even),
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company:nth-child(even) {
  clear: left;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-summary.report-chart--wide,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-chart.report-chart--wide,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-list.report-chart--wide,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tags.report-chart--wide,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-segments.report-chart--wide,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-advice.report-chart--wide,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-company.report-chart--wide {
  width: 100%;
}
@media (min-width: 768px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-summary.report-chart--wide .report-card,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chart.report-chart--wide .report-card,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-list.report-chart--wide .report-card,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-tags.report-chart--wide .report-card,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-segments.report-chart--wide .report-card,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-advice.report-chart--wide .report-card,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-company.report-chart--wide .report-card {
    min-height: 20rem !important;
  }
}
@media (min-width: 992px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-summary.report-chart--wide .report-summary-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chart.report-chart--wide .report-summary-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-list.report-chart--wide .report-summary-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-tags.report-chart--wide .report-summary-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-segments.report-chart--wide .report-summary-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-advice.report-chart--wide .report-summary-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-company.report-chart--wide .report-summary-list {
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 2rem;
  }
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-card.report-card--tip {
  padding-bottom: 5rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip {
  position: relative;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip.report-tip--active .report-tip-toggle {
  display: none;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip.report-tip--active .report-tip-content {
  display: block;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-toggle,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-close {
  border: 0;
  display: block;
  text-align: center;
  position: absolute;
  bottom: -1.75rem;
  left: 0;
  margin: 0;
  padding: 0;
  transition: background-color 150ms ease;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-close {
  border-radius: 0.625rem;
  padding: 0 0.625rem;
  margin: -0.75rem 0 0 -1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 2rem;
  min-width: 2rem;
  left: auto;
  right: 0.5rem;
  bottom: auto;
  top: 1.5rem;
  text-align: center;
  font-size: 0.75rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-icon,
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-label {
  display: block;
  padding: 0 0.6125rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-icon {
  font-size: 1.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-label {
  font-size: 0.875rem;
  padding-bottom: 0.375rem;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-content {
  display: none;
  margin: 2.25rem 0.5rem 0;
  padding: 1.5rem 2.5rem 1.5rem 2rem;
  position: relative;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-content p {
  margin: 0;
}
.view-results.view-results--report.view-results--report-doelgroependashboard .report-tip-content p + p {
  margin-top: 1rem;
}
@media (min-width: 576px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title .results-meta-label {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 768px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter > div:nth-child(even) .report-tip-toggle {
    left: -1rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter > div:nth-child(odd) .report-tip-toggle {
    left: auto;
    right: -1rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-summary,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chart,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-list,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-tags,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-segments,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-advice,
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-company {
    margin-bottom: 1rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-summary:nth-child(odd),
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chart:nth-child(odd),
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-list:nth-child(odd),
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-tags:nth-child(odd),
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-segments:nth-child(odd),
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-advice:nth-child(odd),
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-company:nth-child(odd) {
    clear: none;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-card {
    min-height: 35rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title .results-meta-label {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
}
@media (min-width: 992px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title {
    max-width: 33.3333%;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .results-meta-title .results-meta-label {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-charts {
    padding: 0 4rem 5rem;
    margin: 0 2rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter {
    margin: 0 -4rem;
    padding: 0 4rem 3rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter > div:nth-child(even) .report-tip-toggle {
    left: -4rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter > div:nth-child(odd) .report-tip-toggle {
    right: -4rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-heading {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-card {
    min-height: 30rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-card.report-card--tip {
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-charts {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter {
    padding: 0 8rem 3rem;
    margin: 0 -8rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter > div:nth-child(even) .report-tip-toggle {
    left: -8rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter > div:nth-child(odd) .report-tip-toggle {
    right: -8rem;
  }
  .view-results.view-results--report.view-results--report-doelgroependashboard .report-chapter-heading {
    margin-left: -8rem;
    margin-right: -8rem;
  }
}

.view-professions .layout-pane--primary {
  width: auto;
  display: block;
  z-index: 1000;
  top: 4rem;
}
.view-professions .layout-pane--tertiary {
  margin-left: 100%;
  display: block;
  z-index: 1100;
}
.view-professions.layout-split--aside .layout-pane--primary {
  opacity: 1;
  pointer-events: all;
  margin-left: 0;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: block;
}
.view-professions.layout-split--aside .layout-pane--secondary {
  display: block;
  left: -100%;
  z-index: 900;
}
.view-professions.layout-split--aside .layout-pane--secondary .pane-content {
  overflow: hidden;
}
.view-professions.layout-split--aside.application.state-navigation .layout-pane--secondary {
  left: 0;
}
.view-professions.layout-split--aside.application.state-navigation .main {
  margin-left: 100%;
}
@media (min-width: 768px) {
  .view-professions.layout-split--aside.application.state-navigation .main {
    margin-left: 0;
    opacity: 1;
  }
  .view-professions.layout-split--aside .layout-pane--primary {
    margin-left: 6.25rem;
    width: calc(100% - 6.25rem);
  }
  .view-professions.layout-split--aside .layout-pane--secondary {
    left: 0;
    width: 6.25rem;
    background: transparent;
  }
}
@media (min-width: 992px) {
  .view-professions.layout-split--aside .layout-pane--primary {
    margin-left: 6.25rem;
    width: calc(100% - 6.25rem);
  }
  .view-professions.layout-split--aside .layout-pane--secondary {
    width: 6.25rem;
  }
}
.view-professions.layout-split--open .layout-pane--tertiary {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .view-professions .layout-pane--tertiary {
    width: 50%;
  }
  .view-professions .layout-pane--tertiary .pane-header .pane-header-title {
    display: none;
  }
  .view-professions .layout-pane--tertiary .pane-filter {
    top: 0;
  }
  .view-professions.layout-split--md-open .layout-pane--primary {
    width: 50%;
  }
  .view-professions.layout-split--md-open .layout-pane--primary > .pane-footer {
    display: none;
  }
  .view-professions.layout-split--md-open .layout-pane--tertiary {
    margin-left: 50%;
  }
  .view-professions.layout-split--steps .layout-pane--primary > .pane-header {
    display: none;
  }
  .view-professions.layout-split--steps .layout-pane--primary > .pane-filter {
    top: 1rem;
  }
  .view-professions.layout-split--steps .layout-pane--primary > .pane-content-wrap > .pane-filter {
    top: 6rem;
  }
  .view-professions.layout-split--steps .layout-pane--primary,
  .view-professions.layout-split--steps .layout-pane--tertiary {
    top: 8rem;
    height: auto;
    bottom: 0;
  }
  .view-professions.layout-split--aside .logo {
    display: none !important;
  }
  .view-professions.layout-split--aside .sidemenu-link {
    text-align: center;
  }
  .view-professions.layout-split--aside .sidemenu-icon {
    display: inline;
  }
  .view-professions.layout-split--aside .sidemenu-label {
    display: none;
  }
  .view-professions.layout-split--aside .layout-pane--primary {
    overflow: visible;
  }
  .view-professions.layout-split--aside .layout-pane--primary > .pane-header .pane-back {
    left: -5.25rem;
  }
  .view-professions.layout-split--aside .layout-pane--primary > .pane-header .pane-header-title {
    font-size: 1.75rem;
    line-height: 3rem;
    margin-bottom: -0.95751rem;
    padding-top: 0.95751rem;
    text-align: left;
    margin-left: 0;
  }
  .view-professions.layout-split--aside .layout-pane--primary .pane-filter-tab .pane-header > .pane-close {
    display: none;
  }
  .view-professions.layout-split--aside.layout-split--open .layout-pane--primary {
    width: calc(50% - 6rem);
  }
}
@media (min-width: 992px) {
  .view-professions.layout-split--md-open .layout-pane--primary {
    width: 40%;
  }
  .view-professions.layout-split--md-open .layout-pane--tertiary {
    width: 60%;
    margin-left: 40%;
  }
  .view-professions.layout-split--aside .sidemenu-link {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.view-instrument .action--close {
  position: fixed;
  right: 0.5rem;
  top: 0.5rem;
}
.view-instrument #instrument {
  width: calc(100% - 4rem);
  margin: 2rem 2rem 0;
  border: 0;
}

.modal--inspiration .pane-v2 {
  max-width: 64.25rem;
}
.modal--inspiration .pane-v2 img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.view-inspiration {
  min-width: 320px;
}
.view-inspiration .signature {
  display: block;
}
.view-inspiration .search {
  z-index: 9999;
}
.view-inspiration .search + .view-inspiration-callout {
  margin-top: 5.5rem;
}
.view-inspiration .search-keys {
  right: 4rem;
}
.view-inspiration .pane--base .pane-content {
  display: flex;
  flex-flow: row nowrap;
}
.view-inspiration .pane--base .pane-content-inner {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}
.view-inspiration .pane--base .pane-header .search {
  top: 1rem;
}
.view-inspiration .vacancy-list {
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.view-inspiration .vacancy-title {
  padding-top: 1rem;
}
.view-inspiration .navigation,
.view-inspiration .navigation-toggle,
.view-inspiration .counter-limit {
  display: none;
}
.view-inspiration .view-inspiration-callout {
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 1rem;
  padding: 1.25rem 2rem;
  margin: 0 1rem;
  font-weight: 500;
  opacity: 0;
  transition: opacity 300ms ease-in;
  position: relative;
}
.view-inspiration .view-inspiration-callout span {
  padding-right: 0.5rem;
}
.view-inspiration .view-inspiration-callout:before {
  content: "";
  display: block;
  position: absolute;
  height: 1rem;
  width: 1rem;
  left: 4rem;
  top: -0.25rem;
  transform: rotate(45deg);
}
.view-inspiration .view-inspiration-callout--init {
  opacity: 1;
}
.view-inspiration .view-inspiration-title {
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
}
.view-inspiration .view-inspiration-title em {
  display: inline-block;
  position: relative;
}
.view-inspiration .view-inspiration-title em:after {
  content: "";
  display: block;
  height: 0.1875rem;
  width: 0%;
  position: absolute;
  left: 0;
  bottom: -0.375rem;
  transition: width 1s ease-in-out;
}
.view-inspiration .view-inspiration-title--init em:after {
  width: 100%;
}
.view-inspiration .view-inspiration--search .search {
  position: relative;
  top: 0;
  width: calc(100% - 2rem);
}
.view-inspiration .view-inspiration--search .search .search-query {
  margin-left: 0;
}
.view-inspiration .view-inspiration--search.pane--base .pane-content {
  justify-content: center;
  align-items: center;
}
.view-inspiration .view-inspiration--search.pane--base .pane-content-inner {
  top: -4.5rem;
}
.view-inspiration .view-inspiration--results .search {
  top: 1rem;
}
.view-inspiration .view-inspiration--results .view-inspiration-title {
  margin-top: 3rem;
}
.view-inspiration .view-inspiration--results .view-inspiration-callout {
  margin-bottom: 5rem;
}
.view-inspiration .view-inspiration--results .vacancy .indicator-radial {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}
.view-inspiration .view-inspiration--results .vacancy .btn {
  display: block;
  margin: 2rem 0 1rem;
}
@media (min-width: 576px) {
  .view-inspiration .view-inspiration--search .search {
    left: 1.5rem;
    right: 0;
    margin-left: 0;
    width: calc(100% - 3rem);
  }
  .view-inspiration .view-inspiration-title {
    font-size: 2.25rem;
    line-height: 2.75rem;
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }
  .view-inspiration .view-inspiration-title span {
    display: block;
  }
  .view-inspiration .view-inspiration-callout {
    margin: 0 1.5rem;
  }
}
@media (min-width: 768px) {
  .view-inspiration .view-inspiration--search .search {
    left: 0;
    right: 0;
    margin-left: 0;
    width: 100%;
  }
  .view-inspiration .view-inspiration--search.pane--base .pane-content-inner {
    flex: 0 0 66%;
  }
  .view-inspiration .view-inspiration--results .view-inspiration-title {
    padding-left: 3rem;
  }
  .view-inspiration .view-inspiration--results .vacancy .vacancy-link {
    padding-left: 10rem;
    min-height: 6rem;
  }
  .view-inspiration .view-inspiration--results .vacancy .indicator-radial {
    right: auto;
    left: 2.5rem;
  }
  .view-inspiration .view-inspiration--results .vacancy .btn {
    position: absolute;
    top: 50%;
    right: 3rem;
    margin-top: -1.125rem;
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .view-inspiration .view-inspiration--search .search {
    left: 0;
    right: 0;
    margin-left: 0;
    width: 100%;
  }
}

.tour__img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}
.tour__img--bottom {
  bottom: 0;
}

.layout-column .layout-pane--primary,
.layout-column .layout-pane--tertiary,
.layout-split .layout-pane--primary,
.layout-split .layout-pane--tertiary {
  position: absolute;
}
.layout-column .layout-pane--secondary,
.layout-split .layout-pane--secondary {
  display: block;
  z-index: 700;
}
.layout-column.state-navigation .main,
.layout-split.state-navigation .main {
  margin-left: 18em;
  opacity: 0.6;
}
@media (min-width: 768px) {
  .layout-column.state-navigation .main,
  .layout-split.state-navigation .main {
    margin-left: 32rem;
  }
}

html.state--actions .vacancy-title .indicator-state {
  display: inline-block;
}
html.state--actions .vacancy-title:hover .indicator-state-label, html.state--actions .vacancy-title:focus .indicator-state-label, html.state--actions .vacancy-title:hover:focus .indicator-state-label {
  opacity: 1;
}
html.state--actions .vacancy:hover .indicator-state .indicator-state-label, html.state--actions .vacancy:focus .indicator-state .indicator-state-label, html.state--actions .vacancy:hover:focus .indicator-state .indicator-state-label,
html.state--actions .vacanciesList:hover .indicator-state .indicator-state-label,
html.state--actions .vacanciesList:focus .indicator-state .indicator-state-label,
html.state--actions .vacanciesList:hover:focus .indicator-state .indicator-state-label {
  opacity: 1;
}

.support-toggle {
  display: none;
}

.state--support .support-toggle {
  display: block;
}
.state--support .view-results .support-toggle {
  bottom: 4.5rem;
}

html.state--notify .user .pulse {
  display: block;
}

html.state--mute {
  /* DO NOT DISPLAY NEWS aka MUTE */
}
html.state--mute .user .pulse {
  display: none !important;
}
html.state--mute .user-modal.modal .modal-dialog .timeline {
  display: none;
}
@media (min-width: 992px) {
  html.state--mute .user-modal.modal .modal-dialog {
    bottom: auto !important;
  }
  html.state--mute .user-modal.modal .modal-dialog .pane-header {
    height: 13rem;
  }
}

html.state--client .view-results .pane-v2-banner.pane-v2-banner--steps {
  min-height: 9.5rem;
}
html.state--client .view-results .pane-results .pane-content {
  padding-top: 0;
}
@media (min-width: 768px) {
  html.state--client .view-results .search {
    top: 5rem;
  }
  html.state--client .view-results .pane-results {
    height: calc(100% - 9.5rem);
    top: 9.5rem;
  }
  html.state--client .view-results .pane-index,
  html.state--client .view-results .pane-filters,
  html.state--client .view-results .pane-focus {
    height: calc(100% - 0rem);
    top: 0rem;
  }
}
@media (min-width: 992px) {
  html.state--client .view-results .pane-results,
  html.state--client .view-results .pane-filters,
  html.state--client .view-results .pane-focus,
  html.state--client .view-results .pane-index {
    height: calc(100% - 9.5rem);
    top: 9.5rem;
  }
}

.fixed {
  position: fixed;
}

.bottom {
  bottom: 20px;
}

.right {
  right: 20px;
}

.text--left {
  text-align: left;
}
.text--center {
  text-align: center;
}
.text--right {
  text-align: right;
}

.absolute {
  position: absolute;
}
.absolute-regular-right {
  right: 20px;
}
.absolute-regular-left {
  left: 20px;
}
.absolute-regular-top {
  top: 20px;
}
.absolute-regular-bottom {
  bottom: 20px;
}

.padding-extra-small {
  padding: 5px;
}
.padding-extra-small-top {
  padding-top: 5px;
}
.padding-extra-small-right {
  padding-right: 5px;
}
.padding-extra-small-bottom {
  padding-bottom: 5px;
}
.padding-extra-small-left {
  padding-left: 5px;
}
.padding-extra-small-x {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-extra-small-y {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-small {
  padding: 10px;
}
.padding-small-top {
  padding-top: 10px;
}
.padding-small-right {
  padding-right: 10px;
}
.padding-small-bottom {
  padding-bottom: 10px;
}
.padding-small-left {
  padding-left: 10px;
}
.padding-small-x {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-small-y {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-regular {
  padding: 20px;
}
.padding-regular-top {
  padding-top: 20px;
}
.padding-regular-right {
  padding-right: 20px;
}
.padding-regular-bottom {
  padding-bottom: 20px;
}
.padding-regular-left {
  padding-left: 20px;
}
.padding-regular-x {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-regular-y {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-large {
  padding: 35px;
}
.padding-large-top {
  padding-top: 35px;
}
.padding-large-right {
  padding-right: 35px;
}
.padding-large-bottom {
  padding-bottom: 35px;
}
.padding-large-left {
  padding-left: 35px;
}
.padding-large-x {
  padding-left: 35px;
  padding-right: 35px;
}
.padding-large-y {
  padding-top: 35px;
  padding-bottom: 35px;
}
.padding-extra-large {
  padding: 45px;
}
.padding-extra-large-top {
  padding-top: 45px;
}
.padding-extra-large-right {
  padding-right: 45px;
}
.padding-extra-large-bottom {
  padding-bottom: 45px;
}
.padding-extra-large-left {
  padding-left: 45px;
}
.padding-extra-large-x {
  padding-left: 45px;
  padding-right: 45px;
}
.padding-extra-large-y {
  padding-top: 45px;
  padding-bottom: 45px;
}

.margin-extra-small, .margin-xs {
  margin: 5px;
}
.margin-extra-small-top, .margin-xs-top {
  margin-top: 5px;
}
.margin-extra-small-right, .margin-xs-right {
  margin-right: 5px;
}
.margin-extra-small-bottom, .margin-xs-bottom {
  margin-bottom: 5px;
}
.margin-extra-small-left, .margin-xs-left {
  margin-left: 5px;
}
.margin-extra-small-x, .margin-xs-x {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-extra-small-y, .margin-xs-y {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-small, .margin-s, .margin-sm {
  margin: 10px;
}
.margin-small-top, .margin-s-top, .margin-sm-top {
  margin-top: 10px;
}
.margin-small-right, .margin-s-right, .margin-sm-right {
  margin-right: 10px;
}
.margin-small-bottom, .margin-s-bottom, .margin-sm-bottom {
  margin-bottom: 10px;
}
.margin-small-left, .margin-s-left, .margin-sm-left {
  margin-left: 10px;
}
.margin-small-x, .margin-s-x, .margin-sm-x {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-small-y, .margin-s-y, .margin-sm-y {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-regular {
  margin: 20px;
}
.margin-regular-top {
  margin-top: 20px;
}
.margin-regular-right {
  margin-right: 20px;
}
.margin-regular-bottom {
  margin-bottom: 20px;
}
.margin-regular-left {
  margin-left: 20px;
}
.margin-regular-x {
  margin-left: 20px;
  margin-right: 20px;
}
.margin-regular-y {
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-large, .margin-l {
  margin: 35px;
}
.margin-large-top, .margin-l-top {
  margin-top: 35px;
}
.margin-large-right, .margin-l-right {
  margin-right: 35px;
}
.margin-large-bottom, .margin-l-bottom {
  margin-bottom: 35px;
}
.margin-large-left, .margin-l-left {
  margin-left: 35px;
}
.margin-large-x, .margin-l-x {
  margin-left: 35px;
  margin-right: 35px;
}
.margin-large-y, .margin-l-y {
  margin-top: 35px;
  margin-bottom: 35px;
}
.margin-extra-large, .margin-xl {
  margin: 45px;
}
.margin-extra-large-top, .margin-xl-top {
  margin-top: 45px;
}
.margin-extra-large-right, .margin-xl-right {
  margin-right: 45px;
}
.margin-extra-large-bottom, .margin-xl-bottom {
  margin-bottom: 45px;
}
.margin-extra-large-left, .margin-xl-left {
  margin-left: 45px;
}
.margin-extra-large-x, .margin-xl-x {
  margin-left: 45px;
  margin-right: 45px;
}
.margin-extra-large-y, .margin-xl-y {
  margin-top: 45px;
  margin-bottom: 45px;
}

.border-bottom-regular {
  border-bottom: 1px solid #CAC6DC;
}

.border-regular-top {
  border-top: 1px solid #CAC6DC;
}

.border {
  border: 1px solid #CAC6DC !important;
  border-radius: 10px;
}
.border--hover {
  cursor: pointer;
}
.border--hover:hover {
  border-color: #1E025F !important;
}

.align-self-center {
  align-self: center;
}

.background-text-white {
  background: #ffffff;
  border-radius: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-horizontal-center {
  display: flex;
  justify-content: center;
}

.flex-vertical--center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.grid-bar {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
}

.grid {
  display: grid;
  grid-gap: 20px;
}
.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}
.grid-gap-small {
  grid-gap: 10px;
}

.flex-bar--center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}

#yii-debug-toolbar {
  display: none !important;
}

.blur {
  filter: blur(2.5px);
}

.height-100 {
  height: 100%;
}

.width-fit-content {
  width: fit-content;
}

.width-100 {
  width: 100% !important;
}

.width-75 {
  width: 75% !important;
}

.width-50 {
  width: 50% !important;
}

.width-25 {
  width: 25% !important;
}

.react-link {
  text-decoration: underline !important;
  color: #9F99B1 !important;
  cursor: pointer;
}
.react-link:hover {
  color: #443469 !important;
}

.overflow-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.animation-fade {
  animation: fadeIn 0.75s ease-in-out;
}
.animation-fade-fast {
  animation: fadeIn 0.15s ease-in-out;
}
.animation-pulse {
  animation: pulse 2s infinite;
}

.grid-container {
  display: grid;
  position: relative;
  width: 100%;
  height: auto;
  grid-gap: 0 2rem;
  padding: 0 1rem;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.icon-round {
  border-radius: 1000px;
  background-color: #9f99b1;
  width: 1.5rem !important;
}
.icon-round svg {
  fill: #fff;
  stroke: #fff;
  height: 0.85rem;
  width: 0.85rem;
  top: 0.35rem;
  left: 0.35rem;
}

.cabinet-v3__content .cabinet-drawer .cabinet-organisation .icon-container .icon-wrap svg {
  fill: #ffffff;
  stroke: #ffffff;
}

.main-menu-toggle-icon.action--hamburger .icon-container--hamburger {
  width: 24px;
}
.main-menu-toggle-icon.action--hamburger .icon-container--hamburger svg {
  fill: #443469;
}

.bulk-actions--bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #E4E4EC;
  box-shadow: 0 0 8px -2px #d2d2d2;
  border-radius: 5px 5px 0 0;
}
.bulk-actions--bar__organisaties, .bulk-actions--bar__vacancies {
  grid-column: 1/-1;
  width: 100%;
}
.bulk-actions--select-all {
  display: flex;
  align-items: center;
}
.bulk-actions--label {
  margin-left: 1rem;
  height: 2rem;
}
.bulk-actions--all-container, .bulk-actions--item-container {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid dimgrey;
  border-radius: 5px;
  position: relative;
}
.bulk-actions--all-container {
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
.bulk-actions--all-container.bulk-actions--all__semi {
  background-image: url("../icons/checkbox-semi.svg");
  background-size: 70%;
}
.bulk-actions--all-container.bulk-actions--all__checked {
  background-image: url("../icons/checkbox-checked.svg");
  background-size: 80%;
}
.bulk-actions--option-buttons {
  display: none;
}

.cabinet--grid .bulk-actions--bar {
  padding: 1rem 1.25rem;
}

.cabinet--grid .bulk-actions--all-container {
  margin-left: 4px;
}

.select-all-item {
  appearance: auto;
}

.action--rows,
.action--grid {
  height: 2rem !important;
  width: 2rem !important;
}

.action-bar__cabinet-grid-row .action__button:first-child {
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.action-bar__cabinet-grid-row .icon-wrap svg path {
  fill: #ffffff;
  stroke: #ffffff;
}

.pane-v2-header {
  min-height: 0;
  padding: 1rem 2.5rem;
  background-color: #ffffff;
  z-index: 3;
}
.pane-v2-header h2 {
  line-height: 2.3rem;
}
.pane-v2-header .pane-section {
  padding-left: 0;
}
.pane-v2-header--flex {
  display: flex;
  align-items: baseline;
}
.pane-v2-header__subtitle {
  margin: 0 10rem 0 1rem;
  color: #9F99B1;
  min-width: fit-content;
  white-space: nowrap;
}
.pane-v2-header .results-meta-period {
  position: absolute;
  right: 2rem;
}

@media (max-width: 767px) {
  .pane-v2-header-crumbs {
    top: 0.75rem;
  }
}
.cabinet-search__input {
  padding: 0.25rem 1rem;
  border: 1px solid #CAC6DC;
  border-radius: 10px;
  background-color: #ffffff;
  margin-right: 1rem;
  width: 15rem;
  position: relative;
}
.cabinet-sort__select-sortby {
  padding: 0 0.5rem;
  height: 2rem;
  border: 1px solid #CAC6DC;
  border-radius: 10px 0 0 10px;
  background-color: #ffffff;
  margin-right: 0;
  -webkit-appearance: none;
  outline: none;
}
.cabinet-sort .action__button {
  width: 2rem !important;
  height: 2rem !important;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9F99B1;
  padding: 0.45rem;
}
.cabinet-sort .action__button:hover {
  background-color: #766e90 !important;
}
.cabinet-sort .action__button:hover.action--accent-secondary {
  background-color: #1E025F;
}
.cabinet-sort .action__button.action--accent-secondary {
  background-color: #1E025F;
}
.cabinet-sort .action__button.action--accent-secondary:hover {
  background-color: #1E025F !important;
}
.cabinet-sort .action__button::before {
  content: "";
  background-color: transparent;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.cabinet-sort .action__button .icon-container--sort_order.icon-container--lg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem !important;
  height: 2rem !important;
  margin: 0 !important;
}
.cabinet-sort .action__button.action--sort_order-desc .icon-container--lg {
  transform: rotate(180deg);
}
.cabinet-sort .action__button .icon-container {
  position: relative;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
}

.action__button {
  width: 2rem !important;
  height: 2rem !important;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9F99B1;
  padding: 0.45rem;
}
.action__button:hover {
  background-color: #766e90 !important;
}
.action__button:hover.action--accent-secondary {
  background-color: #1E025F;
}
.action__button.action--accent-secondary {
  background-color: #1E025F;
}
.action__button.action--accent-secondary:hover {
  background-color: #1E025F !important;
}
.action__button::before {
  content: "";
  background-color: transparent;
  position: absolute;
  border-radius: 74639d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.action__button .icon-container--sort_order.icon-container--lg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem !important;
  height: 2rem !important;
  margin: 0 !important;
}
.action__button.action--sort_order-desc .icon-container--lg {
  transform: rotate(180deg);
}
.action__button .icon-container {
  position: relative;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
}

.cabinet-sort__switch-asc-desc {
  border-radius: 0 10px 10px 0;
  background-color: #9F99B1 !important;
}

.cabinet__input-container {
  position: relative;
}
.cabinet__input-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
}

.organisation--label {
  z-index: 999;
}

.organisation--label--list {
  display: none;
  list-style: none;
  position: absolute;
  z-index: 10;
  background: #1E025F;
  opacity: 90%;
  color: white;
  bottom: 2rem;
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0 0 8px -2px #d2d2d2;
}

.organisation--label:hover .organisation--label--list {
  display: block;
}

.btn--label {
  background: #9F99B1 !important;
  z-index: 999;
}

.focus-label--container {
  display: flex;
}

.btn--label.btn--label__colleague {
  background: none !important;
}

.btn--label.btn--label-team svg {
  fill: #fff;
  stroke: #fff;
}

.tooltip-box {
  padding: 15px;
  width: auto;
  box-shadow: 0 0 8px -2px #d2d2d2;
  background: #1E025F;
  opacity: 90%;
  position: absolute;
  z-index: 100000000;
  display: none;
  color: #FFF;
  border-radius: 0.75rem;
  white-space: nowrap;
}
.tooltip-box--narrow {
  white-space: normal;
  max-width: 25rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

.search-v3__info-block {
  position: absolute;
  right: 10rem;
  top: 0.75rem;
  z-index: 999;
}

.chart__settings-container {
  display: flex;
  margin-bottom: 1.5rem;
}
.chart__setting-container {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}
.chart__setting-container select {
  background-color: #ffffff;
  border: 1px solid #CAC6DC;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.chart__setting--daterange {
  width: auto;
  background-color: #ffffff;
  border: 1px solid #CAC6DC;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}
.chart__view {
  position: relative;
  display: flex;
}
.chart__counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.chart__counter h1 {
  font-size: 200px;
  line-height: 200px;
}
.chart__counter h2 {
  font-size: 80px;
  line-height: 80px;
}

.stack-chart__container {
  width: 100%;
  padding: 2rem 2rem 1rem 2rem;
}
.stack-chart__bar {
  width: 100%;
  height: 1.25rem;
  background-color: rgba(194, 187, 209, 0.1);
  position: relative;
  margin: 0.25rem 0;
}
.stack-chart__bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #C2BBD1;
}
.stack-chart__bar--0::after {
  clip-path: polygon(0 0, 9% 0, 11% 50%, 9% 100%, 0 100%);
}
.stack-chart__bar--1::after {
  clip-path: polygon(0 0, 18% 0, 20% 50%, 18% 100%, 0 100%);
}
.stack-chart__bar--2::after {
  clip-path: polygon(0 0, 27% 0, 29% 50%, 27% 100%, 0 100%);
}
.stack-chart__bar--3::after {
  clip-path: polygon(0 0, 36% 0, 38% 50%, 36% 100%, 0 100%);
}
.stack-chart__bar--4::after {
  clip-path: polygon(0 0, 45% 0, 47% 50%, 45% 100%, 0 100%);
}
.stack-chart__bar--5::after {
  clip-path: polygon(0 0, 54% 0, 56% 50%, 54% 100%, 0 100%);
}
.stack-chart__bar--6::after {
  clip-path: polygon(0 0, 63% 0, 65% 50%, 63% 100%, 0 100%);
}
.stack-chart__bar--7::after {
  clip-path: polygon(0 0, 72% 0, 74% 50%, 72% 100%, 0 100%);
}
.stack-chart__bar--8::after {
  clip-path: polygon(0 0, 81% 0, 83% 50%, 81% 100%, 0 100%);
}
.stack-chart__bar--9::after {
  clip-path: polygon(0 0, 90% 0, 92% 50%, 90% 100%, 0 100%);
}
.stack-chart__bar--active::after {
  background: #1E025F;
}
.stack-chart__bar--active {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 0.5rem;
}
.stack-chart__bar--active em {
  line-height: 1.25rem;
  font-size: 0.8rem;
  color: #1E025F;
}
.stack-chart__bar--active em:hover {
  color: #1E025F;
}

.donut-chart__container .highcharts-color-0 {
  fill: #40BC8A;
}
.donut-chart__container .highcharts-color-1 {
  fill: #1E025F;
}
.donut-chart__container .highcharts-color-2 {
  fill: #F85C6A;
}
.donut-chart__container .highcharts-color-3 {
  fill: #5a5e79;
}

.chart__view.chart__view--heatmap,
.chart__view.chart__view--distancetimemap {
  padding: 0;
  grid-row: 2/4;
}
.chart__view.chart__view--heatmap .heatmap-container,
.chart__view.chart__view--distancetimemap .heatmap-container {
  height: 100%;
  width: 100%;
}
.chart__view.chart__view--heatmap .heatmap-legend,
.chart__view.chart__view--distancetimemap .heatmap-legend {
  position: absolute;
  z-index: 1;
  left: 20px;
  align-self: center;
  width: 20px;
  height: 300px;
  background: #fff;
}
.chart__view.chart__view--heatmap .heatmap-legend__bar,
.chart__view.chart__view--distancetimemap .heatmap-legend__bar {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(82, 66, 185, 0.5), rgba(77, 62, 180, 0.55), rgba(72, 57, 175, 0.6), rgba(67, 53, 170, 0.65), rgba(62, 49, 165, 0.7), rgba(57, 44, 160, 0.75), rgba(52, 40, 155, 0.75), rgba(47, 36, 150, 0.8), rgba(42, 31, 145, 0.8), rgba(37, 27, 140, 0.9), rgba(32, 23, 135, 0.9), rgba(27, 18, 130, 0.95), rgb(22, 14, 125), rgb(30, 2, 95));
}
.chart__view.chart__view--heatmap .heatmap__textfield,
.chart__view.chart__view--distancetimemap .heatmap__textfield {
  position: absolute;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px;
}
.chart__view.chart__view--heatmap .map .gm-bundled-control.gm-bundled-control-on-bottom,
.chart__view.chart__view--distancetimemap .map .gm-bundled-control.gm-bundled-control-on-bottom {
  right: 50px !important;
  bottom: 9rem !important;
}
.chart__view.chart__view--heatmap .heatmap-loader,
.chart__view.chart__view--distancetimemap .heatmap-loader {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: #ffffff;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-style-iw {
  max-height: 17rem !important;
}
.gm-style-iw .list-header {
  padding: 10px 35px !important;
}
.gm-style-iw .list-header:hover .list-subtitle {
  color: #1E025F !important;
  cursor: pointer;
}
.gm-style-iw span {
  display: none !important;
}

.chart__container--heatmap .chart__footer {
  display: none;
}

.chart__container--distancetimemap .chart__footer {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  grid-row: 3;
  grid-column: 1;
}
.chart__container--distancetimemap .chart__footer footer {
  color: #1E025F;
}

.dropdown__container {
  display: none;
  position: absolute;
  background-color: white;
  top: 2.5rem;
  width: max-content;
  box-shadow: 0 0 8px -2px #d2d2d2;
  z-index: 5;
}
.dropdown__container--left {
  left: 0;
}
.dropdown__container--right {
  right: 0;
}
.dropdown__clickable {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  z-index: 4;
}
.dropdown__item {
  cursor: pointer;
  padding: 0.625rem 2rem;
}
.dropdown__item:hover {
  background: #F4F6Fa;
}
.dropdown__items {
  list-style: none;
  position: relative;
}

.cabinet-options.dropdown {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.cabinet-options.dropdown.open {
  z-index: 101;
}

.grid-view .accountsList .dropdown-menu {
  right: 5rem;
}

.select2-container--krajee-bs3 .select2-results__option.select2-results__option--selected, .select2-container--krajee-bs3 .select2-results__option[aria-selected=true] {
  background: #F4F6Fa !important;
  color: inherit !important;
}

.select2-container--krajee-bs3 .select2-results__option--highlighted[aria-selected] {
  background: #1E025F !important;
  color: #ffffff !important;
}

.s2-togall-button {
  color: #443469;
}

.kv-table-header {
  background: none !important;
}

.grid-view .form-control {
  border-color: #E4E4EC;
}

.kv-grid-table .select2-container--krajee-bs3 .select2-selection--single {
  height: 3.5rem !important;
  border-color: #E4E4EC !important;
  border-radius: 1rem !important;
  background: none;
}
.kv-grid-table .select2-container--krajee-bs3 .select2-selection--single .select2-selection__rendered {
  line-height: 2.75rem !important;
  padding-left: 0.5rem;
}
.kv-grid-table .select2-container--krajee-bs3 .select2-selection--single .select2-selection__arrow {
  height: 3.5rem !important;
  border: none !important;
}

.select2-container--krajee-bs3 .select2-selection--single .select2-selection__rendered {
  line-height: 2rem;
  font-size: 1rem;
}
.select2-container--krajee-bs3 .select2-selection--single .select2-selection__arrow {
  height: 100% !important;
  border: none !important;
}

.pane-v2--navigation {
  z-index: 9999999999 !important;
}

.view-results .pane-results, .view-results .pane-filters, .view-results .pane-focus, .view-results .pane-index {
  top: 9rem !important;
  height: calc(100% - 9rem) !important;
}

.search-keys.search-keys--dgd {
  width: 100%;
}

#id-pjax-search-v3 .search {
  right: auto;
  left: auto;
  height: auto;
  width: 100%;
  margin-left: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

#search-v3__draggable-items {
  cursor: default;
}

.search-v3.pane-v2-banner {
  min-height: 5rem;
}

.search-v3 {
  display: grid;
  width: 100%;
  grid-column: 2;
  grid-template-rows: auto auto;
  position: relative;
}
.search-v3 .icon-container--drag {
  display: none;
}
.search-v3__button {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid #CAC6DC !important;
  background: #E4E4EC !important;
  font-weight: normal !important;
  width: 100% !important;
  justify-content: space-between !important;
}
.search-v3__button:hover .icon-container.icon-container--selected svg path, .search-v3__button .icon-container.icon-container--selected svg path, .search-v3__button.btn-v3--white.btn-v3__icon-container .icon-container.icon-container--selected svg path {
  fill: none !important;
  stroke: #ffffff !important;
}
.search-v3__submit {
  grid-row: 1;
  grid-column: 3;
  justify-self: center;
  margin: 0 0 0 1.5rem !important;
  width: 4rem !important;
}
.search-v3__reset {
  grid-row: 1;
  grid-column: 4;
  justify-self: center;
  margin: 0 0 0 1rem !important;
}
.search-v3 .form-control-label {
  font-size: 1.2rem;
  font-weight: bold;
  padding-left: 0 !important;
}
.search-v3 .search-keys.search-keys--selectize .selectize-input {
  height: 45px !important;
  align-items: center;
}
.search-v3 .search-keys.search-keys--selectize .selectize-input .search-key {
  margin: 0 !important;
  top: -1px;
}
.search-v3 .search-keys.search-keys--selectize .selectize-input .search-key .search-key-label {
  border-radius: 8px;
  height: 1.75rem;
  line-height: 1.75rem;
}
.search-v3__tooltip--query {
  position: absolute;
  right: -1.5rem;
  top: 0.75rem;
}
.search-v3__content {
  position: relative;
  height: auto;
  width: 100%;
}
.search-v3__container--free-entry, .search-v3__items-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  height: 45px;
}
.search-v3__container-active-items {
  display: flex;
  height: 5rem;
  width: 100%;
  max-width: calc(100vw - 18.5rem);
}
.search-v3 #search-v3__button--settings {
  display: none !important;
}
.search-v3__submit {
  margin-right: 1rem;
  background-color: #1E025F;
  border: none;
}
.search-v3__submit .icon-container .icon-wrap svg {
  stroke: #ffffff;
  fill: #ffffff;
}
.search-v3__save svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.search-v3__save:hover svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.search-v3__settings-cancel svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.search-v3__settings-cancel:hover svg {
  fill: #1E025F;
  stroke: #1E025F;
}
.search-v3__text-input, .search-v3__select {
  position: relative;
}
.search-v3__text-input:not(:last-child), .search-v3__select:not(:last-child) {
  margin-bottom: 1rem;
}
.search-v3__text-input .btn-remark, .search-v3__select .btn-remark {
  cursor: help;
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: #9F99B1;
  color: #ffffff;
}
.search-v3__text-input .btn-remark:hover, .search-v3__select .btn-remark:hover {
  background-color: #766e90;
}
.search-v3__text-input input.form-control, .search-v3__text-input input.pane-filter-input, .search-v3__text-input .form-group .select2.select2-container .selection .select2-selection, .search-v3__select input.form-control, .search-v3__select input.pane-filter-input, .search-v3__select .form-group .select2.select2-container .selection .select2-selection {
  border-radius: 10px !important;
  padding: 0.5rem 1rem;
  background-color: #ffffff !important;
}
.search-v3__text-input input.form-control li.select2-search.select2-search--inline .select2-search__field, .search-v3__text-input input.pane-filter-input li.select2-search.select2-search--inline .select2-search__field, .search-v3__text-input .form-group .select2.select2-container .selection .select2-selection li.select2-search.select2-search--inline .select2-search__field, .search-v3__select input.form-control li.select2-search.select2-search--inline .select2-search__field, .search-v3__select input.pane-filter-input li.select2-search.select2-search--inline .select2-search__field, .search-v3__select .form-group .select2.select2-container .selection .select2-selection li.select2-search.select2-search--inline .select2-search__field {
  border: none !important;
}
.search-v3__text-input input.form-control .select2-selection__choice, .search-v3__text-input input.pane-filter-input .select2-selection__choice, .search-v3__text-input .form-group .select2.select2-container .selection .select2-selection .select2-selection__choice, .search-v3__select input.form-control .select2-selection__choice, .search-v3__select input.pane-filter-input .select2-selection__choice, .search-v3__select .form-group .select2.select2-container .selection .select2-selection .select2-selection__choice {
  background-color: #9F99B1 !important;
  color: white !important;
  margin: 0.5rem 0.5rem 0 0 !important;
  border: none;
  text-shadow: none;
  padding: 0.3rem 0.75rem;
}
.search-v3__text-input input.form-control .select2-selection__choice .select2-selection__choice__remove, .search-v3__text-input input.pane-filter-input .select2-selection__choice .select2-selection__choice__remove, .search-v3__text-input .form-group .select2.select2-container .selection .select2-selection .select2-selection__choice .select2-selection__choice__remove, .search-v3__select input.form-control .select2-selection__choice .select2-selection__choice__remove, .search-v3__select input.pane-filter-input .select2-selection__choice .select2-selection__choice__remove, .search-v3__select .form-group .select2.select2-container .selection .select2-selection .select2-selection__choice .select2-selection__choice__remove {
  position: relative;
  left: 0.25rem;
  top: -0.15rem;
  color: #F4F6Fa;
  cursor: pointer;
  text-shadow: none;
}
.search-v3__items-container--clone {
  height: fit-content;
  display: none;
  grid-column: 1;
  margin: 0.5rem;
}
.search-v3__items-container--clone--inactive {
  display: none;
}
.search-v3--settings-open i {
  line-height: 4rem;
}
.search-v3--settings-open #search-v3__button--more, .search-v3--settings-open .search-v3__submit, .search-v3--settings-open .search-v3__reset {
  display: none;
}
.search-v3--settings-open .search-v3__items-container-clone--active {
  position: relative;
  border: 2px dashed #E4E4EC;
}
.search-v3--settings-open .search-v3__items-container-clone--active #jd-draggable-search-dummy {
  width: 100%;
  max-width: 100%;
}
.search-v3--settings-open .search-v3__items-container-clone--inactive .search-v3__item--dummy button {
  width: 100%;
  height: 45px;
}
.search-v3--settings-open .search-v3__items-container-clone--inactive, .search-v3--settings-open .search-v3__items-container-clone--active {
  justify-self: start;
  padding: 1rem;
  width: 100%;
}
.search-v3--settings-open .search-v3__items-container--active, .search-v3--settings-open .search-v3__items-container--inactive {
  display: none;
}
.search-v3--settings-open .search-v3__items-container--clone--active, .search-v3--settings-open .search-v3__items-container--clone--inactive {
  display: flex;
}
.search-v3--settings-open .search-v3__items-container--clone {
  display: grid;
}
.search-v3--settings-open .search-v3__action-buttons {
  margin-right: 2rem;
}
.search-v3--settings-open .search-v3__button {
  cursor: grab;
}
.search-v3--settings-open .search-v3__button:active {
  cursor: grabbing;
}
.search-v3--settings-open .search-v3__button.btn-v3__icon-container .icon-container--drag {
  display: initial;
}
.search-v3--settings-open .search-v3__button.btn-v3__icon-container .icon-container--drop {
  display: none;
}
.search-v3__container--free-entry {
  margin-right: 1rem;
  grid-row: 1;
  width: 100%;
}
.search-v3__container--free-entry .search-keys.search-keys--selectize .selectize-input > input {
  margin-left: 0 !important;
  width: 100%;
}
.search-v3__container--free-entry .search-keys.search-keys--selectize .selectize-input > input::placeholder {
  font-weight: bold;
}
.search-v3__container--free-entry input, .search-v3__container--free-entry .search-keys.search-keys--selectize {
  background-color: #fff !important;
  border-radius: 10px;
  padding: 0.25rem 1.5rem;
  border: 1px solid #CAC6DC;
  width: 100%;
  height: 45px;
}
.search-v3__container--free-entry #search-input-selectized {
  height: 45px;
  width: 100% !important;
}
.search-v3__container--free-entry #search-input-selectized::placeholder {
  color: #1E025F;
}
.search-v3__container--free-entry .search-keys.search-keys--selectize.search-key {
  margin: 0 0 0.125rem 0 !important;
}
.search-v3__container--free-entry .has-items::before {
  height: 45px;
}
.search-v3__container--free-entry .search-keys.search-keys--selectize {
  position: relative;
  padding: 0 1rem;
}
.search-v3__container--free-entry .search-keys.search-keys--selectize .selectize-input {
  height: 45px !important;
}
.search-v3__container--free-entry .search-keys.search-keys--selectize .selectize-input .search-input-selectized {
  height: 45px !important;
}
.search-v3__container--location {
  height: 45px;
  align-self: center;
  grid-column: 2;
  grid-row: 1;
  position: relative;
  z-index: 10;
}
.search-v3__container--location.search-v3__location-container--open {
  z-index: 20;
}
.search-v3__container--location #location-autocomplete {
  line-height: 2.6rem;
  height: 45px;
  border: 1px solid #CAC6DC;
  border-radius: 10px;
  background: #ffffff;
  padding-left: 1rem !important;
}
.search-v3__container--location #location-autocomplete::placeholder {
  color: #1E025F;
}
.search-v3__container--location .icon-container.icon-container--location {
  background: #E4E4EC;
  padding: 0.5rem;
  border-radius: 10px;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 35px;
  cursor: pointer;
}
.search-v3__container--location .icon-container.icon-container--location svg {
  fill: #1E025F !important;
  stroke: #1E025F !important;
}
.search-v3__top-bar {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  z-index: 0;
}
.search-v3__top-bar.search-v3__top-bar.search-v3__free-entry--focus {
  z-index: 1000000000000000000;
}
.search-v3__items-container {
  grid-row: 1;
  display: grid;
  grid-template-rows: 4.1rem 3.5rem 0;
  margin: 0.5rem 0;
  grid-template-columns: 2fr 1fr 4.5rem 4rem;
}
.search-v3__items-container .search-v3__item--hidden {
  visibility: hidden;
}
.search-v3__items-container--active {
  display: flex;
  z-index: 10;
  grid-column: 1/3;
  grid-row: 2;
  height: 5rem;
}
.search-v3__items-container--active .search-v3__container-active-items .search-v3__item:last-child .search-v3__button {
  margin-right: 0;
}
.search-v3__items-container--inactive {
  display: flex;
  grid-column: 1/3;
  grid-row: 2;
  padding-right: 13rem;
}
.search-v3__items-container--advanced {
  position: relative;
  left: 0;
  height: fit-content;
  width: auto;
  grid-template-rows: 4.1rem 3.5rem 4rem;
}
.search-v3__items-container--advanced .search-v3__container--free-entry .search-keys {
  width: 100%;
}
.search-v3__items-container--advanced .search-v3__items-container--inactive {
  height: 4rem;
  grid-row: 3;
  position: relative;
}
.search-v3__items-container--advanced .search-v3__items-container--inactive.search-v3__items-container--clone--inactive {
  left: -0.5rem;
  width: calc(100% + 1rem);
}
.search-v3__items-container--advanced .search-v3__item--hidden {
  visibility: visible;
}
.search-v3__items-container--advanced .search-v3__action-buttons {
  grid-row: 1;
}
.search-v3__items-container--advanced .search-v3__action-buttons .search-v3__advanced {
  width: 4rem;
  background: none !important;
  border: 2px solid #1E025F;
}
.search-v3__items-container--advanced .search-v3__action-buttons .search-v3__advanced svg {
  transform: rotate(180deg);
}
.search-v3__items-container--advanced .search-v3__action-buttons .search-v3__advanced svg path {
  stroke: #1E025F !important;
}
.search-v3__items-container--advanced #search-v3__button--more .icon-container--double-arrow-down {
  transform: rotateX(180deg);
}
.search-v3__items-container--advanced #search-v3__button--settings {
  display: flex !important;
  background: #ffffff;
  border-radius: 10px;
  height: 45px;
  border: 1px solid #9F99B1;
  position: absolute;
  right: 0;
  padding: 0.25rem 1rem !important;
}
.search-v3__items-container--advanced #search-v3__button--settings .indicator {
  margin: 0.55rem -0.5rem 0 0.5rem !important;
}
.search-v3__items-container--clone {
  grid-row: 2;
  grid-column: 1/-1;
  display: none;
  grid-template-rows: 4rem 4rem 4rem;
  grid-template-columns: 2fr 1fr 4.5rem 4rem;
}
.search-v3__items-container--clone--active {
  display: none;
  grid-column: 1/3;
  grid-row: 2;
  left: -0.55rem;
  width: calc(100% + 1.1rem);
  border: 2px dotted #9F99B1;
  position: relative;
  height: 4rem;
}
.search-v3__items-container--clone--active i {
  position: absolute;
  bottom: -3rem;
}
.search-v3__items-container--clone--inactive {
  display: none;
  grid-column: 1/3;
  grid-row: 3;
}
.search-v3 .search-v3__header {
  grid-row: 1;
  grid-column: 1/-1;
  position: absolute;
  width: calc(100% + 4rem);
  top: -2rem;
  left: -2rem;
  padding: 1rem 2rem;
  border-bottom: 1px solid #CAC6DC;
  display: flex;
  justify-content: space-between;
}
.search-v3 .search-v3__header .search-v3__action-container {
  position: absolute;
  right: 1rem;
  top: 0;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  border-left: 1px solid #CAC6DC;
  height: 100%;
}
.search-v3__container--hidden {
  grid-row: 2;
  visibility: hidden;
  background-color: #F4F6Fa;
  border-bottom: 1px solid #CAC6DC;
  display: flex;
}
.search-v3 .search-v3__clone--active-items {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  height: 3.5rem;
  width: 100%;
  background: #fff;
  border: 2px dotted #ccc;
  transition: all 0.2s;
  visibility: hidden;
  display: flex;
}
.search-v3 .search-v3__clone--hidden-items {
  background: #ddd;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 7rem;
  z-index: 4;
  width: 100%;
  visibility: hidden;
}
.search-v3__query-container {
  visibility: hidden;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  top: 4.5rem;
  transition: opacity 0.15s;
  opacity: 0;
  z-index: 4;
  width: 30rem;
  height: auto;
  max-height: calc(100vh - 10rem);
  overflow-y: scroll;
}
.search-v3__query-container.search-v3__location {
  width: 50rem;
  top: 0.5rem !important;
  left: 0;
}
.search-v3__query-container.search-v3__location .search-v3__query-container__main {
  padding-bottom: 0;
  margin-top: -2.5rem;
}
.search-v3__query-container.search-v3__location .search-v3__query-container__main .btn-v3 {
  position: absolute;
  bottom: 1rem;
}
.search-v3__query-container.search-v3__location--open {
  visibility: visible;
  opacity: 1;
}
.search-v3__query-container.search-v3__branche, .search-v3__query-container.search-v3__profession_class {
  height: auto;
  max-height: calc(100vh - 10rem);
  width: 45rem !important;
}
.search-v3__query-container.search-v3__branche .btn-v3, .search-v3__query-container.search-v3__profession_class .btn-v3 {
  margin-top: 1rem;
}
.search-v3__query-container.search-v3__branche .search-v3__query-container__main, .search-v3__query-container.search-v3__profession_class .search-v3__query-container__main {
  height: 100%;
}
.search-v3__query-container.search-v3__branche .search-v3__query-container__main .tab-pane, .search-v3__query-container.search-v3__profession_class .search-v3__query-container__main .tab-pane {
  height: auto;
}
.search-v3__query-container.search-v3__branche .search-v3__query-container__main .tab-pane #sbi-tree, .search-v3__query-container.search-v3__branche .search-v3__query-container__main .tab-pane #isco-tree, .search-v3__query-container.search-v3__profession_class .search-v3__query-container__main .tab-pane #sbi-tree, .search-v3__query-container.search-v3__profession_class .search-v3__query-container__main .tab-pane #isco-tree {
  max-height: calc(100vh - 24rem);
  margin-top: 2rem;
  overflow: scroll;
}
.search-v3__query-container.search-v3__branche .search-v3__query-container__main .tab-pane #sbi-tree .ui-fancytree, .search-v3__query-container.search-v3__branche .search-v3__query-container__main .tab-pane #isco-tree .ui-fancytree, .search-v3__query-container.search-v3__profession_class .search-v3__query-container__main .tab-pane #sbi-tree .ui-fancytree, .search-v3__query-container.search-v3__profession_class .search-v3__query-container__main .tab-pane #isco-tree .ui-fancytree {
  padding: 0 1rem;
  height: 100%;
}
.search-v3__query-container .btn-v3 {
  margin-top: 2rem;
}
.search-v3__query-container__header {
  height: 3rem;
  border-bottom: 1px solid #CAC6DC;
}
.search-v3__query-container__title {
  padding-left: 2rem;
  display: flex;
  align-items: center;
  height: 100%;
}
.search-v3__query-container__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
}
.search-v3__query-container__close .search-v3__button--close {
  top: 0;
  right: 0;
}
.search-v3__query-container__main {
  padding: 2.75rem 2rem 2rem 2rem;
}
.search-v3__query-container select, .search-v3__query-container input, .search-v3__query-container .form-group .select2.select2-container .selection .select2-selection {
  border: 1px solid #9F99B1 !important;
  width: 100%;
  padding: 0.25rem 1rem !important;
  min-height: 45px !important;
  height: 45px;
  border-radius: 0.4rem !important;
}
.search-v3__query-container .form-group .select2.select2-container .selection .select2-selection {
  height: auto;
}
.search-v3 .search-v3__dropdown--province[style=""] {
  position: relative;
  top: 1rem;
  height: calc(100% + 2rem);
  z-index: 10;
}
.search-v3 .search-v3__dropdown--province[style=""] + .search-v3__location-distance {
  padding-top: 1.5rem;
}
.search-v3 .field-searchmodel-physicallocationprovince .select2-selection__choice {
  margin: 0.25rem !important;
}
.search-v3 .field-searchmodel-physicallocationprovince .select2-search.select2-search--inline {
  min-height: 2rem;
  height: 2rem;
  margin: 0.25rem !important;
}
.search-v3 .field-searchmodel-physicallocationprovince .select2-search.select2-search--inline .select2-search__field {
  min-height: 2rem;
}
.search-v3__location-distance {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  position: relative;
}
.search-v3__location-distance .pane-locations-slider--disabled::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 1rem;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
}
.search-v3__location-distance .form-group.field-distance-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 3rem;
}
.search-v3__location-distance .form-group.field-distance-slider #distance-slider-slider {
  width: 100% !important;
}
.search-v3__location-distance .pane-locations-mode {
  position: relative;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.search-v3__location-distance .pane-locations-mode .icon-container--lg {
  margin: 0 !important;
}
.search-v3__location-distance .pane-locations-mode#tab-area .search-v3--provinces-used {
  visibility: hidden;
  position: absolute;
  right: -0.25rem;
  top: 1.75rem;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1rem;
  background-color: #40BC8A;
}
.search-v3__location-distance .pane-locations-slider {
  width: 100%;
  left: 0 !important;
  position: relative !important;
  padding: 0 1rem 0 2rem;
  height: 1.5rem;
}
.search-v3__location-distance .pane-locations-slider .form-group.field-distance-slider {
  padding: 0;
}
.search-v3__location-distance #map-canvas.pane-map {
  width: 100%;
  visibility: visible !important;
}
.search-v3 .search-v3--map {
  position: relative;
  left: -2rem;
  width: calc(100% + 4rem);
}
.search-v3__item {
  grid-row: 1;
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: 20rem;
  min-width: 0;
  padding: 0 0.5rem;
}
.search-v3__item.search-v3__item--hidden:first-child {
  padding-left: 0;
}
.search-v3__item:last-child {
  padding-right: 0;
}
.search-v3__item:hover {
  min-width: auto;
}
.search-v3__item:hover .search-v3__button {
  min-width: fit-content;
}
.search-v3__item span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-v3__item--hidden {
  grid-row: 2;
}
.search-v3__item--dummy {
  width: 100%;
  max-width: 100% !important;
  padding-left: 0 !important;
}
.search-v3__item--dummy button {
  opacity: 0;
  padding: 0 !important;
  width: 100%;
  height: 45px;
  cursor: auto;
}
.search-v3-item--active {
  z-index: 20;
}
.search-v3-item--active .search-v3__button {
  background: #ffffff;
}
.search-v3-item--active .search-v3__query-container {
  visibility: visible;
  opacity: 1;
}
.search-v3__backdrop {
  width: 100vw;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #9F99B1;
  position: fixed;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s;
}
.search-v3__backdrop--active {
  visibility: visible;
  opacity: 0.8;
}
.search-v3__drag-container {
  display: flex;
  justify-content: flex-start;
  width: auto;
}
.search-v3 .search-v3__setting.btn-v3 .icon-container {
  margin: 0 !important;
}
.search-v3 #search-v3__button--more {
  grid-row: 2;
  grid-column: 3;
}
.search-v3 #search-v3__button--more .icon-container--sm {
  margin: 0 !important;
}
.search-v3 #search-v3__button--more {
  width: 4rem !important;
  height: 45px !important;
  margin: 0 0 0 1rem !important;
  border: 1px solid #CAC6DC;
}
.search-v3__action-buttons {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  z-index: 3;
  margin-left: 2rem;
}

#id-pjax-search-v3 {
  grid-column: 2;
  width: 100%;
  position: relative;
  top: -1rem;
}

.pane-v2.pane-v2--root {
  top: 9rem;
}
.pane-v2.pane-v2--root.pane-v2--login {
  top: 0rem;
}
.pane-v2.pane-v2--root .pane-section.pane-section--first {
  padding-top: 5rem;
}

.select2-container--krajee .select2-dropdown {
  margin-top: 8px !important;
}

.search-v3__item--ondragover:not(.search-v3__item--dummy)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
  z-index: 10;
}

.action--navigation-icon div, .action--navigation-icon::before, .action--navigation-icon::after {
  content: "";
  height: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  border-radius: 0 !important;
}

.form-group.field-searchmodel-physicallocationprovince .select2-container--krajee-bs3 .select2-selection--multiple .select2-search--inline .select2-search__field {
  border: none !important;
}

.pane-locations-mode .icon-container {
  margin: auto;
}

.pane-locations-mode.--active svg {
  fill: #1E025F;
  stroke: #1E025F;
}

.headroom--not-top.headroom--unpinned .navbar {
  top: -12rem;
  transition: 0.3s top;
}
.headroom--not-top.headroom--unpinned .view-results .pane-results, .headroom--not-top.headroom--unpinned .view-results .pane-filters, .headroom--not-top.headroom--unpinned .view-results .pane-focus, .headroom--not-top.headroom--unpinned .view-results .pane-index {
  top: 0 !important;
  height: 100% !important;
}

.pane-v2.pane-v2--dashboard.pane-v2--root {
  top: 9rem !important;
  height: calc(100% - 9rem);
}

.search-v3__mobile-filter-switch {
  display: none !important;
}

.search-v3__query-backdrop {
  visibility: hidden;
}

@media (min-width: 576px) {
  .search-v3__mobile-submit {
    visibility: hidden !important;
    display: none !important;
  }
  #search-v3__close {
    display: none;
  }
}
@media (max-width: 767px) {
  .selectize-input.items.not-full.has-options.has-items.focus.input-active::before {
    opacity: 0;
  }
  #search-v3__close {
    display: initial;
    position: fixed;
    right: 0;
    top: 1rem;
  }
  .search-v3__item:not(.search-v3__item--hidden):nth-child(2),
  .search-v3__item:last-child {
    padding: 0 0.5rem;
  }
  .pane-v2.pane-v2--portfolio.pane-v2--root {
    top: 5rem !important;
  }
  .search-v3--active::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #9F99B1;
    opacity: 0.65;
  }
  .search-v3__backdrop.search-v3__backdrop--active {
    background: #ffffff;
    opacity: 1;
    visibility: hidden !important;
  }
  .search-v3__submit {
    visibility: hidden;
  }
  #results-save {
    right: 0.5rem;
  }
  .navbar .search-v3__mobile-submit.btn-v3--action-icon {
    position: fixed;
    visibility: visible;
    width: 100vw;
    height: 5rem;
    bottom: 1rem;
    z-index: 10;
    width: calc(100vw - 2rem) !important;
  }
  .view-results #results.results-focus-open.pane-results--modes {
    top: 0 !important;
    height: 100% !important;
  }
  .search-keys.search-keys--selectize .search-key {
    margin: 0;
  }
  .search-v3__mobile-filter-switch {
    display: flex !important;
    position: absolute !important;
    top: -5rem;
    left: calc(100% - 2.5rem);
  }
  .search-v3__items-container--active {
    flex-direction: column;
  }
  .pane-v2.pane-v2--dashboard.pane-v2--root {
    top: -1rem !important;
    height: 100% !important;
  }
  .view-results .pane-results {
    top: 5rem !important;
  }
  .search-v3__location-container--open {
    z-index: 1000000 !important;
  }
  .view-results .search {
    top: 0;
  }
  #id-pjax-search-v3 {
    position: absolute;
    top: 5rem !important;
    left: -5rem;
    width: 100vw;
    height: 0;
    padding: 1rem;
  }
  #id-pjax-search-v3 .search {
    top: 0 !important;
  }
  .results-focus-open #id-pjax-search-v3 {
    padding: 0;
  }
  .navbar {
    height: 5rem !important;
  }
  .navbar__button-container {
    height: 5rem !important;
  }
  .navbar__mid-container {
    grid-column: 2/4;
    padding-right: 2rem;
  }
  .navbar__button-container--right {
    display: none !important;
  }
  .navbar .btn-v3--action-icon {
    height: 2.65rem !important;
  }
  .search-v3__items-container {
    grid-template-columns: 1fr 3rem;
    grid-template-rows: 4rem 4rem 1fr 1fr;
    margin: 0 !important;
    width: calc(100vw - 5rem);
    padding-right: 1rem;
    display: none;
  }
  .search-v3__items-container.search-v3--mobile-open {
    display: grid;
  }
  .search-v3__items-container.search-v3--mobile-open::before {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    top: 0;
    left: 0;
  }
  .search-v3__items-container.search-v3--mobile-open .search-v3__top-bar.search-v3__free-entry--focus {
    z-index: 100000000 !important;
  }
  .search-v3__items-container.search-v3--mobile-open .search-v3__top-bar.search-v3__free-entry--focus::before {
    content: "";
    background: #fff;
    opacity: 0.65;
    position: fixed;
    width: 100vw;
    height: calc(100vh - 7.25rem);
    top: 0rem;
    left: 0rem;
  }
  .search-v3__items-container.search-v3--mobile-open .search-v3__top-bar.search-v3__free-entry--focus .search-keys.search-keys--selectize .search-key {
    z-index: 100000000000;
  }
  .search-v3__items-container .search-v3__top-bar {
    grid-column: 1;
    grid-row: 1;
    padding-top: 0.25rem;
    width: calc(100vw - 9rem);
  }
  .search-v3__items-container .search-v3__top-bar .search-v3__container--free-entry {
    position: absolute;
    top: 0;
    left: 1.5rem;
    width: calc(100vw - 4.5rem);
    margin: 0 !important;
  }
  .search-v3__items-container .search-v3__top-bar .search-v3__container--free-entry .has-items::before {
    z-index: 99999999 !important;
    right: 0 !important;
  }
  .search-v3__items-container .search-v3__container--location {
    position: absolute;
    left: 1.5rem;
    top: 0;
    width: calc(100% + 0.5rem);
    z-index: 10;
    grid-column: 1;
    grid-row: 2;
    padding-bottom: 0.25rem;
  }
  .search-v3__items-container .search-v3__container--location .search-v3__item {
    position: absolute !important;
    top: -9.5rem;
    left: -0.5rem;
  }
  .search-v3__items-container .search-v3__container--location .search-v3__query-container.search-v3__location {
    top: 13.5rem !important;
  }
  .search-v3__items-container .search-v3__container--location .search-v3__location-distance {
    height: 8rem;
    position: relative;
  }
  .search-v3__items-container .search-v3__container--location .search-v3__location-distance .pane-locations-slider {
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 0 !important;
    margin: 0 !important;
  }
  .search-v3__items-container .search-v3__container--location .search-v3__location-distance .pane-locations-slider .tooltip.tooltip-main {
    display: initial !important;
  }
  .search-v3__items-container .search-v3__container--location .search-v3__location-distance .pane-locations-slider .form-group.field-distance-slider {
    width: calc(100% - 2rem) !important;
  }
  .search-v3__items-container .search-v3__submit {
    grid-column: 2;
    grid-row: 1;
  }
  .search-v3__items-container .search-v3__reset {
    display: none;
  }
  .search-v3__items-container .search-v3__items-container--active {
    position: relative;
    width: calc(100% + 6rem);
    top: 0;
    left: 1rem;
    grid-column: 1;
    grid-row: 3;
    visibility: hidden;
    z-index: 100000;
    overflow-y: scroll;
    height: calc(100vh - 24rem);
  }
  .search-v3__items-container .search-v3__items-container--inactive {
    grid-column: 1;
    grid-row: 4;
    padding-right: 0;
  }
  .search-v3__items-container .search-v3__items-container--inactive .search-v3__item {
    visibility: hidden;
  }
  .search-v3__items-container .react-searchprofilesdropdown {
    display: none;
  }
  .search-v3__items-container .search-v3__container-active-items, .search-v3__items-container .search-v3__items-container--inactive {
    flex-direction: column;
    height: 100%;
    max-width: calc(100vw - 3.5rem);
  }
  .search-v3__items-container #search-v3__button--more {
    display: none;
  }
  .search-v3__items-container .search-v3__item {
    margin: 0.5rem 0;
    position: initial !important;
    max-width: 100%;
  }
  .search-v3__items-container .search-v3__item .search-v3__query-container {
    position: fixed;
    top: 5rem;
    left: 0;
    transform: translate(0) !important;
    width: 100vw !important;
    max-height: calc(100vh - 10rem);
    overflow-y: scroll;
  }
  .search-v3__items-container .search-v3__item .search-v3__query-container .search-v3__query-container__main {
    padding-bottom: 6rem;
  }
  .search-v3__items-container .search-v3__item .icon-container--drop {
    transform: rotate(-90deg);
  }
  .search-v3__query-container__main .search-v3__submit-btn {
    display: none;
  }
  .search-v3-item--active .search-v3__query-container::after {
    content: "";
    position: absolute;
    visibility: visible;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: #ffffff;
  }
  .search-v3__top-bar,
  .search-v3__container--location,
  .search-v3__mobile-submit {
    visibility: hidden !important;
  }
  .search-v3--mobile-open .search-v3__items-container--active, .search-v3--mobile-open .search-v3__items-container--inactive, .search-v3--mobile-open .search-v3__top-bar, .search-v3--mobile-open .search-v3__container--location, .search-v3--mobile-open .search-v3__mobile-submit {
    visibility: visible !important;
  }
}
@media (max-width: 1199px) {
  .search-v3__query-container.search-v3__location {
    top: -3rem;
    height: calc(100vh - 3rem);
  }
}
.search-v3.search-v3--mini .search-v3__container--free-entry {
  margin-right: 0;
}
.search-v3.search-v3--mini .search-v3__experience {
  grid-column: 2;
  grid-row: 1;
  align-self: center;
  height: 45px;
  width: 8rem;
  border: 1px solid #CAC6DC;
  border-radius: 10px;
  color: #1E025F;
  margin: initial;
  background: #ffffff;
}
.search-v3.search-v3--mini .search-v3__experience .search-experience {
  right: 0;
  top: 4px;
  line-height: 1rem;
  height: 35px;
  border-radius: 0 0.5rem 0.5rem 0;
  background-position: 90%;
  color: #1E025F;
  font-size: 0.875rem;
}
.search-v3.search-v3--mini .search-v3__container--location {
  grid-column: 3;
}
.search-v3.search-v3--mini #search-v3__button--more {
  grid-column: 4;
  grid-row: 1;
  margin: 0 !important;
}
.search-v3.search-v3--mini .search-v3__submit {
  grid-column: 5;
  grid-row: 1;
  margin: 0 !important;
}
.search-v3.search-v3--mini .search-v3__reset {
  grid-column: 6;
  grid-row: 1;
  margin: 0 !important;
}
.search-v3.search-v3--mini .search-v3__items-container {
  grid-template-columns: 2fr auto 1fr auto auto auto;
  grid-column-gap: 20px;
}
.search-v3.search-v3--mini .search-v3__items-container .search-v3__items-container--active {
  grid-column: 1/-1;
  grid-row: 2/4;
  height: 7rem;
  display: none;
}
.search-v3.search-v3--mini .search-v3__items-container .search-v3__items-container--active .search-v3__container-active-items {
  height: 7rem;
  max-width: 100%;
  max-height: initial;
  flex-wrap: wrap;
  position: relative;
  left: -0.5rem;
}
.search-v3.search-v3--mini .search-v3__items-container .search-v3__items-container--active .search-v3__container-active-items .search-v3__item {
  width: initial;
}
.search-v3.search-v3--mini .search-v3__items-container.search-v3__items-container--advanced .search-v3__items-container--active {
  display: flex;
}
.search-v3.search-v3--mini #clone--inactive {
  display: none;
}

.pane-content--loading::after {
  animation: spin 1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left: 0.125rem solid #1E025F;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 12rem;
  margin: -6rem 0 0 -6rem;
  width: 12rem;
}
.pane-content--loading::after {
  display: block;
}

.download--pending {
  position: relative;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.download--pending::after {
  animation: spin 1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left: 0.125rem solid #1E025F;
  border-radius: 50%;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  content: "";
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateZ(0);
  z-index: auto;
  height: 1.85rem;
  margin: -0.925rem 0 0 -0.925rem;
  width: 1.85rem;
}
.download--pending::after {
  display: block;
}
.download--pending::before {
  content: "";
  position: absolute;
  width: 10rem;
  height: 5rem;
}
.download--pending svg {
  display: none;
}

.pane-content--loading--sm::after {
  position: relative;
}

.loading-spinner::after {
  content: "";
  display: block;
  z-index: auto;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateZ(0);
  margin: -6rem 0 0 -6rem;
  height: 12rem;
  width: 12rem;
  animation: spin 1.1s infinite linear;
  border-bottom: 0.125rem solid transparent;
  border-left: 0.125rem solid #40BC8A;
  border-right: 0.125rem solid transparent;
  border-top: 0.125rem solid transparent;
  border-radius: 50%;
}
.loading-spinner--sm::after {
  margin: 4rem 0 0 -3rem !important;
  width: 6rem !important;
  height: 6rem !important;
}

.profile-toolbar__container {
  position: relative;
  left: 0;
  width: calc(100% - 1rem);
  padding: 0.75rem 0 0.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #9F99B1;
  z-index: 1;
  grid-row: 2;
  grid-column: 1/-1;
  border-radius: 5px 5px 0 0;
}
.profile-toolbar__container .profile-toolbar__input {
  width: calc(100% - 12rem);
  max-width: 40rem;
}
.profile-toolbar__container .profile-toolbar__input select {
  height: 2rem;
  border: none;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  width: 100%;
}
.profile-toolbar__container .profile-toolbar__options {
  display: flex;
  position: absolute;
  right: 0;
  width: auto;
  align-items: center;
}
.profile-toolbar__container .profile-toolbar__options .react-dropdownprofileoptions {
  display: flex;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-left: 1px solid #CAC6DC;
  margin-left: 0.5rem;
}
.profile-toolbar__container .profile-toolbar__options .react-dropdownprofileoptions #dropdownbtn img {
  height: 1rem;
  width: 1rem;
}

#search-pane-profiles .grid-container {
  padding: 0;
}
#search-pane-profiles .grid-container #zoekprofielen {
  padding: 0;
}
#search-pane-profiles .grid-container #zoekprofielen .cabinet-card {
  grid-template-columns: [title] 5fr [options] 1fr;
  padding: 0.75rem 1rem !important;
}
#search-pane-profiles .grid-container #zoekprofielen .cabinet-card h3.cabinet-title {
  font-size: 1rem;
  line-height: 1.2rem;
  grid-column: 1/-1;
}
#search-pane-profiles .grid-container #zoekprofielen .cabinet-card .cabinet-properties, #search-pane-profiles .grid-container #zoekprofielen .cabinet-card .cabinet-options, #search-pane-profiles .grid-container #zoekprofielen .cabinet-card .cabinet-meta, #search-pane-profiles .grid-container #zoekprofielen .cabinet-card .cabinet__profile--alerts {
  display: none;
}

.pane-v2--noquery .void.void--boxed {
  box-shadow: 0 0 8px -2px #d2d2d2 !important;
  border: none !important;
}
.pane-v2--noquery .void.void--boxed .pane-section {
  padding: 2rem !important;
}
.pane-v2--noquery .void.void--boxed .pane-section .void-title {
  margin-top: 2rem;
}
.pane-v2--noquery .void.void--boxed .profile-toolbar__options {
  display: none;
}

#share-profile .select2-search.select2-search--inline {
  width: 100%;
}
#share-profile .select2-search.select2-search--inline .select2-search__field {
  width: 100%;
  min-width: 100%;
}

.btn-v3 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none;
  border-radius: 10px;
  padding: 0.25rem 1rem;
  width: fit-content;
  height: 35px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  /*THEMES*/
}
.btn-v3--dropdown {
  height: 35px;
  font-size: 0.7rem;
  line-height: 0.7rem;
}
.btn-v3--dropdown--legacy {
  height: 1.5rem;
  line-height: 1rem;
}
.btn-v3--cover::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.btn-v3 svg {
  stroke-width: 0;
}
.btn-v3--md {
  height: 20px;
  padding: 0.5rem 1rem;
  line-height: 0.5rem;
  font-size: 0.75rem;
}
.btn-v3--lg {
  height: 45px;
  border-radius: 2rem;
  padding: 0 2rem 0 2rem;
}
.btn-v3--wide {
  width: 100%;
}
.btn-v3--margin {
  margin: 0 0.5rem;
}
.btn-v3--margin-r, .btn-v3--margin-right {
  margin-right: 20px;
}
.btn-v3--margin-l, .btn-v3--margin-left {
  margin-left: 20px;
}
.btn-v3--margin-top-l {
  margin-top: 2rem;
}
.btn-v3--margin-r-sm {
  margin-right: 0.5rem;
}
.btn-v3--margin-l-sm {
  margin-left: 0.5rem;
}
.btn-v3--margin-lg {
  margin: 0 1rem;
}
.btn-v3:disabled::before, .btn-v3--muted::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(225, 225, 225, 0.65);
  z-index: 1;
  cursor: not-allowed;
}
@media (max-width: 991px) {
  .btn-v3--ok, .btn-v3--nok {
    width: 3rem;
  }
  .btn-v3--ok::after, .btn-v3--nok::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
  }
}
.btn-v3--ok {
  background-color: #40bc8a;
}
.btn-v3--ok:hover {
  background-color: #2d8360 !important;
}
@media (max-width: 991px) {
  .btn-v3--ok::after {
    content: "v";
    background-color: #40bc8a;
  }
  .btn-v3--ok::after:hover {
    background-color: #2d8360 !important;
  }
}
.btn-v3--nok {
  background-color: #F85C6A;
}
.btn-v3--nok:hover {
  background-color: #f51327 !important;
}
@media (max-width: 991px) {
  .btn-v3--nok::after {
    content: "x";
    background-color: #F85C6A;
  }
  .btn-v3--nok::after:hover {
    background-color: #f51327 !important;
  }
}
.btn-v3--wide {
  width: 100%;
}
.btn-v3__text--white {
  color: #fff;
}
.btn-v3--action-icon {
  cursor: pointer;
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 45px;
}
.btn-v3--action-icon .icon-container.icon-container--lg {
  margin: 0 !important;
}
.btn-v3--noclick {
  cursor: initial !important;
}
.btn-v3--outline-dash {
  background: #fff;
  border: 2px dashed #9F99B1;
  color: #9F99B1 !important;
}
.btn-v3--outline-dash:hover {
  border: 2px dashed #40BC8A;
  color: #40BC8A !important;
}
.btn-v3--side-left {
  margin: 0 0.5rem 0 0;
}
.btn-v3--side-right {
  margin: 0 0 0 0.5rem;
}
.btn-v3__icon-container {
  padding: 0 2.75rem 0 1rem;
}
.btn-v3__icon-container::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2.25rem;
  border-radius: 0 10px 10px 0;
}
.btn-v3__icon-container .icon-container {
  margin: 0 !important;
  position: absolute;
  right: 1rem;
  z-index: 1;
}
.btn-v3__icon-container .icon-container .icon-wrap {
  justify-content: initial !important;
}
.btn-v3__icon-container .icon-container svg {
  fill: #ffffff !important;
  stroke: #ffffff !important;
}
.btn-v3__icon-container--left {
  padding: 0 1rem 0 1.75rem;
}
.btn-v3__icon-container--left::after {
  left: 0;
  right: initial;
  border-radius: 10px 0 0 10px;
}
.btn-v3__icon-container--left .icon-container {
  right: initial;
  left: 0.7rem;
  width: 0.65rem;
}
.btn-v3__icon-container--both-sides {
  padding: 0 2.75rem;
}
.btn-v3__icon-container--both-sides::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2.25rem;
}

.results-focus-open .profile-toolbar {
  padding: 0.75rem 1rem;
}
.results-focus-open .profile-toolbar .btn-v3--focus {
  width: 2.75rem;
  display: flex;
  justify-content: center;
  padding: 0;
}
.results-focus-open .profile-toolbar .btn-v3--focus em {
  display: none;
}
.results-focus-open .profile-toolbar .btn-v3--focus .icon-container {
  right: initial;
}
.results-focus-open .profile-toolbar .btn-v3--focus .icon-container.icon-container--sm {
  width: 1rem;
}
.results-focus-open .btn-v3--focus {
  width: 3.25rem;
  display: flex;
  justify-content: center;
  padding: 0;
}
.results-focus-open .btn-v3--focus a {
  display: none;
}
.results-focus-open .btn-v3--focus .icon-container {
  right: initial;
}
.results-focus-open .btn-v3--focus .icon-container .icon-container--sm {
  width: 1rem;
}

.focus-admin--baguette {
  position: absolute;
  right: 12.5rem;
  top: 1.5rem;
}
.focus-admin--baguette #focus-admin {
  z-index: 99999;
}
.focus-admin--baguette #focus-admin::before {
  background-color: #40BC8A;
}
.focus-admin--baguette #focus-admin svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.focus-admin--baguette .dropdown-menu {
  left: initial;
  right: 0 !important;
}

.fixed.action-buttons {
  display: flex;
}

.tab-block {
  box-shadow: 0 0 8px -2px #d2d2d2;
  width: 100%;
  height: auto;
  background-color: #ffffff;
}
.tab-block__header {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
}
.tab-block__tabs {
  display: flex;
  background-color: #F4F6Fa;
  padding: 1rem 1rem 0 1rem;
  height: 3.5rem;
}
.tab-block__link {
  margin-right: 1rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  cursor: pointer;
  border-radius: 0.75rem 0.75rem 0 0;
  background-color: #ffffff;
  box-shadow: 0 -5px 8px -7px #d2d2d2;
  white-space: nowrap;
}
.tab-block__link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.tab-block__link-icon {
  height: 1.5rem;
}
.tab-block__link-cover {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.tab-block__link h3 {
  font-size: 1rem;
  display: inline-block;
}
.tab-block__link em {
  font-size: 0.75rem;
}
.tab-block--active {
  bottom: 0.5rem;
  height: 3.5rem;
}
.tab-block--active::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  border-bottom: 2px solid #9F99B1;
  top: -0.75rem;
  margin-left: 10%;
}
.tab-block__content {
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 -5px 8px -7px #d2d2d2;
  max-height: 35rem;
  padding: 20px;
}
.tab-block ul {
  list-style: none;
  margin: 0;
}

.radial-progress {
  width: 4.25rem;
  background: #fff;
  border-radius: 50%;
}
.radial-progress__container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.radial-progress__container em {
  position: absolute;
  font-size: 0.75rem;
}
.radial-progress circle {
  fill: none;
  stroke: #40BC8A;
  stroke-width: 5px;
  animation: rotate 1.5s ease-in;
}

.pdfViewer {
  background: #1E025F !important;
}

.pdfViewer .page {
  background-clip: initial !important;
  background: #1E025F !important;
}

.error__flex--center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.organisation-label {
  display: flex;
  cursor: pointer;
  border-radius: 1rem;
  background-color: #9F99B1;
  position: relative;
  height: 30px;
  padding: 0 5px;
}
.organisation-label__container {
  position: relative;
}
.organisation-label--colleague-only {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #40BC8A;
}
.organisation-label__inset--left {
  padding-left: calc(30px + 5px);
}
.organisation-label__inset--left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(30px + 5px);
  height: 30px;
  border-radius: 1rem 0 0 1rem;
  background-color: #40BC8A;
}
.organisation-label span {
  font-weight: 600;
  color: #ffffff;
  font-size: 10pt;
  line-height: 18pt;
  padding: 0 10px;
  line-height: 30px;
}
.organisation-label .react-svg-icon__label {
  width: 35px;
}
.organisation-label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.report-list-item::before {
  top: -2px;
}
.report-list-item .organisation-name {
  margin-top: 7px;
}

.react-button {
  position: relative;
  display: grid;
  align-content: center;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: center;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  z-index: 1;
}
.react-button em {
  align-self: center;
}
.react-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0);
  z-index: 2;
}
.react-button--small {
  height: 20px !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  color: #ffffff;
  font-size: 12pt;
  line-height: 18pt;
}
.react-button--regular {
  height: 35px;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
  color: #ffffff;
  font-size: 12pt;
  line-height: 18pt;
}
.react-button--large {
  height: 45px !important;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
  color: #ffffff;
  font-size: 12pt;
  line-height: 18pt;
}
.react-button--small-square {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 12pt;
  line-height: 18pt;
}
.react-button--wide {
  width: 100%;
}
.react-button--submit, .react-button--save {
  background: #1E025F;
  border-color: #1E025F;
}
.react-button--action {
  background: #5a5e79;
  border-color: #5a5e79;
}
.react-button--action-attention {
  background: #40BC8A;
  border-color: #40BC8A;
}
.react-button--action-warning {
  background: #9F99B1;
  border-color: #9F99B1;
}
.react-button--action-icon {
  background: none;
  border: none;
}
.react-button--dropdown {
  background: #9F99B1;
  border-color: #9F99B1;
}
.react-button--dropdown-icononly {
  background: none;
  border-color: rgba(255, 255, 255, 0);
}
.react-button--tab-nav {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1E025F;
}
.react-button--tab-nav:hover {
  border-color: #9F99B1;
  color: #1E025F;
}
.react-button--nav, .react-button--tab-nav-active {
  background-color: #ffffff;
  border-color: #1E025F !important;
  color: #1E025F;
}
.react-button--nav:hover, .react-button--tab-nav-active:hover {
  border-color: #1E025F;
  color: #1E025F;
  background-color: #F4F6Fa;
}
.react-button--footer-action {
  background: none;
  border: none;
}
.react-button--label {
  background: #9F99B1;
  border-color: #9F99B1;
}
.react-button--label-positive {
  background-color: #40BC8A;
  border-color: #40BC8A;
  opacity: 0.5;
}
.react-button--label-negative {
  background-color: #ea858A;
  border-color: #ea858A;
}
.react-button--disabled {
  opacity: 0.5;
  z-index: 1;
  cursor: not-allowed;
}
.react-button--disabled::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  z-index: 0;
}

.pane-v2-tabs .flickity-slider .strip-item .tab.react-button {
  height: auto;
}

.pane-v2-tabs .flickity-slider .strip-item .tab.react-button.active {
  background-color: #ffffff;
  border: 2px solid #1E025F;
  color: #1E025F;
}
.pane-v2-tabs .flickity-slider .strip-item .tab.react-button.active:hover {
  border: 2px solid #1E025F;
  color: #1E025F;
  background-color: #F4F6Fa;
}

.brand--jobdigger-loopbaan .flickity-viewport,
.brand--ig-jobdigger .flickity-viewport {
  height: 65px !important;
}
.brand--jobdigger-loopbaan .flickity-slider,
.brand--ig-jobdigger .flickity-slider {
  padding: 1rem !important;
  top: 10px;
}
.brand--jobdigger-loopbaan .react-button.tab--open::before,
.brand--ig-jobdigger .react-button.tab--open::before {
  background: none !important;
}

.button-container {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  gap: 20px;
  align-content: center;
}
.button-container--span {
  width: 100%;
  grid-auto-columns: 1fr;
}
.button-container--span .react-tooltip-wrapper, .button-container--span .react-button {
  width: 100%;
}
.button-container--fit {
  width: fit-content;
}
.button-container--small {
  gap: 10px;
}

.cabinet__ .heading-action {
  margin-left: 0 !important;
}

.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  align-self: center;
  border-radius: 1rem !important;
}
.btn__container {
  display: flex;
}
.btn--label, .btn--label[disabled].btn {
  opacity: 1;
  cursor: default;
  font-size: 0.875rem;
  padding: 0.75rem !important;
  height: 1.5rem !important;
  background-color: #9F99B1 !important;
  margin-right: 1rem;
  width: min-content;
}
.btn--label svg, .btn--label[disabled].btn svg {
  fill: #F4F6Fa !important;
  stroke: #F4F6Fa !important;
}
.btn--label__team, .btn--label[disabled].btn__team {
  color: #ffffff;
  padding-right: 2rem !important;
  z-index: 5;
}
.btn--label__team:after, .btn--label[disabled].btn__team:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 50% 50% 0;
  background-color: #40BC8A;
  z-index: 3;
  width: 1.5rem;
  height: 100%;
}
.btn--label__team:after .btn--label__icon, .btn--label[disabled].btn__team:after .btn--label__icon {
  width: 3.5rem;
}
.btn--label__team .icon-container, .btn--label[disabled].btn__team .icon-container {
  position: absolute;
  margin: 0 !important;
}
.btn--label__team .icon-container--star, .btn--label[disabled].btn__team .icon-container--star {
  width: 1.5rem;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--label__team .icon-container--star .icon-wrap, .btn--label[disabled].btn__team .icon-container--star .icon-wrap {
  width: 50%;
}
.btn--label__team:not(.btn--label__colleague) .btn--label-text, .btn--label[disabled].btn__team:not(.btn--label__colleague) .btn--label-text {
  padding-right: 1.3rem;
}
.btn--label:not(.btn--label__team) .icon-container, .btn--label[disabled].btn:not(.btn--label__team) .icon-container {
  position: absolute;
  margin: 0 !important;
  width: 0.75rem;
  padding: 0;
}
.btn--label__colleague, .btn--label[disabled].btn__colleague {
  padding-right: 0.75rem;
}
.btn--label__colleague.btn--label__team::after, .btn--label[disabled].btn__colleague.btn--label__team::after {
  border-radius: 1000px;
}
.btn--label span, .btn--label[disabled].btn span {
  font-size: 0.7rem;
  color: #ffffff;
  line-height: 0.25rem;
}
.btn--label.btn--label__primary {
  background-color: #40BC8A !important;
}
.btn--standard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem !important;
  border: none;
  border-radius: 1000px !important;
  align-self: center;
  width: min-content;
  height: 2rem !important;
}
.btn--standard .button-label {
  font-weight: normal;
}
.btn--standard-primary {
  background-color: #40BC8A;
}
.btn--standard-primary:hover {
  background-color: #2d8360 !important;
}
.btn--standard-primary .button-label {
  color: #ffffff;
}
.btn--standard-secondary {
  background-color: #1E025F;
}
.btn--standard-secondary:hover {
  background-color: #060014 !important;
}
.btn--standard-secondary .button-label {
  color: #ffffff;
}
.btn--standard-light {
  background-color: #9F99B1;
}
.btn--standard-light:hover {
  background-color: #766e90 !important;
}
.btn--standard-light .button-label {
  color: #ffffff;
}
.btn--standard-lightest {
  background-color: #ffffff;
}
.btn--standard-lightest:hover {
  background-color: #d9d9d9 !important;
}
.btn--standard-lightest .button-label {
  color: #9F99B1;
}
.btn--standard-lightest--background {
  color: #ffffff !important;
  background-color: #40BC8A;
  width: auto !important;
  padding: 1rem;
}
.btn--standard-lightest--background:hover {
  color: #ffffff !important;
  background-color: #2d8360 !important;
}
.btn--standard-lightest--top {
  bottom: auto;
  top: 1rem;
}
.btn--standard-lightest--bottom {
  top: auto;
  bottom: 1rem;
}
.btn--standard-lightest--left {
  right: auto;
  left: 1rem;
}
.btn--standard-lightest--right {
  left: auto;
  right: 1rem;
}
.btn--standard-lightest--center {
  left: auto;
  right: auto;
  margin: auto 50%;
  transform: translateX(-50%);
}
.btn--standard-danger {
  background-color: #F85C6A;
  color: #ffffff;
}
.btn--close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 99999;
}
.btn__meta-data {
  background-color: #F4F6Fa;
  color: #9F99B1;
  border-radius: 1000px;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
}
.btn.yes, .btn.no {
  display: inline-block !important;
  width: 40% !important;
  color: #ffffff;
}
.btn.yes {
  background-color: #40BC8A !important;
}
.btn.no {
  background-color: #1E025F !important;
}
.btn#ModerationAspectsDone {
  background-color: #40BC8A !important;
  color: #ffffff !important;
}

.pane-v2--login .btn {
  width: 100%;
}

.report-header-info.btn.btn-remark.btn-xxs {
  border: 2px solid #9F99B1;
  display: initial;
}

.report-header-export.btn.btn-quaternary.btn-xxs,
#search-settings-vacancies .btn {
  display: initial;
}

.cabinet-status.btn__label--team .icon-container {
  left: 0.025rem;
}

.react-button-nav {
  cursor: pointer;
  height: 45px;
}
.react-button-nav:hover, .react-button-nav--active .react-icon--label.react-icon__wrapper {
  background: #1E025F !important;
}
.react-button-nav:hover .react-button-nav__text, .react-button-nav--active .react-icon--label.react-icon__wrapper .react-button-nav__text {
  color: #ffffff !important;
}
.react-button-nav__text {
  margin-left: calc(45px + 10px);
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
}
.react-button-nav:nth-child(odd) {
  background: #F4F6Fa;
}
.react-button-nav__collaps {
  position: relative;
  height: 45px;
  cursor: pointer;
}

.react-nav-col--collapsed .react-button-nav, .react-nav-col--collapsed .react-button-nav__collaps {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-nav-col--collapsed .react-button-nav .react-button-nav__text, .react-nav-col--collapsed .react-button-nav__collaps .react-button-nav__text {
  display: none;
}
.react-nav-col--collapsed .react-button-nav .react-icon, .react-nav-col--collapsed .react-button-nav__collaps .react-icon {
  position: relative;
  top: initial;
  right: initial;
  left: initial;
  bottom: initial;
}
.react-nav-col--collapsed .react-button-nav__collaps .react-icon {
  transform: rotate(180deg);
}

.pdf-view {
  display: grid;
  grid-template-rows: 1fr 45px;
  grid-template-columns: 45px 1fr 45px;
  height: 100% !important;
  width: 100% !important;
  overflow-y: scroll;
}
.pdf-view .react-svg-icon__arrow-right {
  grid-column: 3;
  grid-row: 1;
}
.pdf-view .react-svg-icon__arrow-left {
  grid-column: 1;
  grid-row: 1;
}
.pdf-view .react-pdf__Document {
  grid-column: 2;
  grid-row: 1;
  height: 100% !important;
  width: 100% !important;
}
.pdf-view .react-pdf__page-number {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf-view .react-pdf__Page {
  margin-bottom: 35px;
  box-shadow: 4px 4px 12px 4px #E4E4EC;
}
.pdf-view .react-pdf__Page__canvas {
  height: auto !important;
  width: 100% !important;
}
.pdf-view .react-pdf__Page__textContent, .pdf-view .react-pdf__Page__annotations {
  display: none !important;
}

.wizard__button-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 35px;
}

.datepicker {
  position: relative;
}
.datepicker .react-svg-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 6px;
  background-color: #ffffff;
}

#react-root-loading-modal {
  position: absolute;
  z-index: 10000000000;
}

.loader__container {
  position: fixed;
  bottom: 0;
  right: 0;
}
.loader__container--bottom {
  width: auto;
}
.loader__container--center {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
}

.loader {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 8px -2px #d2d2d2;
  width: 20rem;
  padding: 30px;
}
.loader--center {
  z-index: 1;
}
.loader__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #9F99B1;
  opacity: 0.65;
}
.loader--bottom {
  right: 0;
  bottom: 2rem;
}
.loader--slidein {
  animation-name: slide-from-right;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
.loader__bar {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 10px;
  background: #E4E4EC;
  overflow: hidden;
}
.loader__bar::after {
  content: "";
  position: absolute;
  top: 0;
  /*left: calc(0 - 60px);*/
  width: 120px;
  height: 100%;
  background: linear-gradient(to left, #E4E4EC, #40BC8A 30px, #E4E4EC);
  animation-name: loading-bar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes loading-bar {
  0% {
    left: -120px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -120px;
  }
}
@keyframes slide-from-right {
  0% {
    right: 0;
  }
  100% {
    right: 22rem;
  }
}

.market-insight {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  min-height: 15rem;
}
.market-insight .react-loader__datapoint {
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.market-insight__days-open {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.market-insight__average-days-open {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.market-insight__intermediate {
  grid-column: 1/-1;
  grid-row: 2;
  border-top: 1px solid #CAC6DC;
  border-bottom: 1px solid #CAC6DC;
  padding: 10px 0;
}
.market-insight__sources {
  padding-top: 10px;
  grid-column: 1/-1;
  grid-row: 3;
}
.market-insight__sources ul {
  margin: 0;
}

.ratio-bar {
  padding: 10px 0;
}
.ratio-bar__label {
  display: flex;
  justify-content: space-between;
}
.ratio-bar__label p {
  margin: 0;
}
.ratio-bar__bar {
  height: 20px;
  display: flex;
}
.ratio-bar__bar--left {
  background-color: #1E025F;
}
.ratio-bar__bar--right {
  background-color: #40BC8A;
}

.pane-v2-banner .pane-v2-banner--navigation {
  display: grid;
  grid-template-columns: auto auto auto;
}

.pane-v2-banner.pane-v2-banner--navigation {
  display: none;
  grid-template-columns: 6rem auto 7rem;
  position: relative;
  border-bottom: 1px solid #CAC6DC;
  z-index: 999;
}

.main-navigation-grid-left {
  grid-column: 1;
}

.pane-v2-banner--navigation .main-menu--toggle-container {
  height: 100%;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid #CAC6DC;
}

.main-menu-toggle-icon {
  width: 2rem;
  height: 2rem;
}

.pane-v2-banner--navigation .main-menu--options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 7rem;
  top: 0;
  right: 0;
  padding: 0;
  border-left: 1px solid #CAC6DC;
}
.pane-v2-banner--navigation .main-menu--options-container button.user {
  transform: scale(0.8);
  margin: 0;
}

.main-menu--options-container .user {
  margin: 0 0.5rem;
}

@media (max-width: 991px) {
  .pane-v2-banner--navigation .main-menu--toggle-container,
  .pane-v2-banner--navigation .main-menu--options-container {
    border-right: none;
    border-left: none !important;
  }
}
.pane-v2--navigation .pane-v2-content {
  background-color: #ffffff !important;
}

@media (max-width: 767px) {
  .search-query {
    top: 0.75rem;
  }
}
.action-bar__cabinet-grid-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.action-bar__cabinet-grid-row .action__button {
  background: none !important;
  padding: 0;
  width: 20px !important;
}
.action-bar__cabinet-grid-row .action__button:first-child {
  margin-left: 45px;
}
.action-bar__cabinet-grid-row .action__button:hover {
  background: none !important;
}
.action-bar__cabinet-grid-row .action__button:hover .icon-wrap svg path {
  fill: #1E025F !important;
  stroke: #1E025F !important;
}
.action-bar__cabinet-grid-row .action__button.action--accent-secondary .icon-wrap svg path {
  fill: #1E025F;
  stroke: #1E025F;
}
.action-bar__cabinet-grid-row .action__button .icon-wrap svg path {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.action-bar__cabinet-grid-row .action__button .icon-container {
  height: 20px;
  width: 20px;
}

.react-nav-col__container {
  border: 1px solid #CAC6DC;
  border-width: 0 1px 0 0;
  padding: 35px;
}
.react-nav-col__container--left {
  align-items: flex-start !important;
}

.nav__button {
  display: flex;
}
.nav__button h4 {
  white-space: nowrap;
}

.pane-v2-tabs {
  border-bottom: 1px solid #CAC6DC;
}
.pane-v2-tabs .strip.strip--mask::before,
.pane-v2-tabs .strip.strip--mask::after {
  background: none !important;
}
.pane-v2-tabs .tab.tab--open {
  border: none;
  color: #ffffff;
  line-height: 4rem;
  text-align: center;
  padding: 0 1rem;
}
.pane-v2-tabs .tab--open::before {
  content: "";
  position: absolute;
  border-radius: 9999px;
  height: 2rem;
  margin-top: 1rem;
  width: 100%;
  z-index: -1;
  transform: translateX(-1rem);
}
.pane-v2-tabs .flickity-slider {
  width: auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}
.pane-v2-tabs .flickity-slider .strip-item {
  position: relative !important;
  left: 0 !important;
  padding: 0 !important;
  margin: 0 1rem !important;
  display: flex;
  justify-content: center;
}
.pane-v2-tabs .flickity-slider .strip-item .tab {
  height: inherit;
}

.tab-nav {
  position: relative;
  left: 2rem;
  list-style: none;
  padding: 1rem 0;
  z-index: 99;
  display: flex;
}
.tab-nav__container {
  width: 100%;
  padding-left: 0.5rem;
  height: 4rem;
  background-color: #F4F6Fa;
  position: relative;
  z-index: 2;
}
.tab-nav__item {
  display: inline-block;
  margin-right: 2rem;
}
.tab-nav__link {
  font-weight: 700;
  font-size: 0.875rem;
  color: #9F99B1;
}
.tab-nav__link:hover {
  color: #1E025F;
}
.tab-nav__link:active {
  color: #9F99B1 !important;
}
.tab-nav__link.active {
  color: #ffffff;
  content: "";
  border-radius: 9999px;
  background-color: #40BC8A;
  padding: 0.5rem 1rem;
  z-index: -1;
}
.tab-nav__link.active:hover {
  background-color: #2d8360 !important;
}

.results-modes + .pane-content .results-meta {
  margin-top: 0;
  height: 3rem;
}

#results #results-scroll-content {
  padding-top: 7rem !important;
}

@media (max-width: 767px) {
  .tab-nav {
    justify-content: space-around;
    left: 0;
  }
  .tab-nav__item {
    transform: scale(0.8);
    margin-right: 0.5rem;
  }
}
.react-pdfexport {
  background: #ccc;
}

.pdf-export__page {
  position: relative;
  width: 210mm;
  height: 297mm;
  margin: 0mm auto;
  background-color: white;
}
.pdf-export__page h1, .pdf-export__page h2, .pdf-export__page h3, .pdf-export__page h4 {
  color: #1E025F;
}
.pdf-export__page h2.pdf-export__header-text {
  height: 10mm;
  font-weight: 600;
  color: #40BC8A;
  margin-bottom: 4mm;
}
.pdf-export__page .chart__view--list .chart__list-item {
  height: 8mm;
}
.pdf-export__page .chart__view--list .chart__list-item .chart__list-item-header {
  height: 8mm;
}
.pdf-export__page .chart__view--list .chart__list-item .chart__list-item--name h3 {
  font-size: 1rem;
}
.pdf-export__page .chart__view--list .chart__list-item .chart__list-item--count {
  line-height: 2rem;
}
.pdf-export__front-page {
  padding: 0;
}
.pdf-export__front-page b, .pdf-export__front-page em, .pdf-export__front-page p {
  color: #ffffff;
}
.pdf-export__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35mm;
  padding: 12.5mm 20mm;
}
.pdf-export__header img {
  max-width: 80mm;
  max-height: 12.5mm;
}
.pdf-export__front-page-title {
  width: 90%;
  height: 242mm;
  background: radial-gradient(#4E32BF -20%, #1E025F 80%);
  border-radius: 0 0 100px 0;
  padding: 20mm;
}
.pdf-export__front-page-title h2.pdf-export__header-text {
  margin-bottom: 1rem;
}
.pdf-export__front-page-title h3.pdf-export__header-text.pdf-export__title {
  color: #fff;
  font-weight: 700;
  line-height: 27mm;
  margin: 0 !important;
  overflow-wrap: break-word;
  max-height: 200mm;
  overflow: clip;
}
.pdf-export__summary-page {
  width: 90%;
  height: 242mm;
  background: #40BC8A;
  border-radius: 0 0 100px 0;
  padding: 20mm;
}
.pdf-export__summary-page .pdf-export__header--summary {
  font-weight: 800;
  color: #443469;
  font-size: 30pt;
  line-height: 35pt;
}
.pdf-export__summary-page b, .pdf-export__summary-page em, .pdf-export__summary-page p {
  color: #fff;
}
.pdf-export__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20mm;
  display: flex;
  align-items: center;
  padding: 0 18mm;
}
.pdf-export__footer--logo {
  height: 6.25mm;
}
.pdf-export__footer--logo-left {
  margin-right: 10mm;
}
.pdf-export__footer--logo--white g {
  fill: #ffffff;
}
.pdf-export__footer--text {
  max-width: 52mm;
  overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #443469;
  font-size: 10pt;
  line-height: 18pt;
}
.pdf-export__copyright {
  position: absolute;
  bottom: 0;
  padding: 9mm 18mm;
  width: 100%;
}
.pdf-export__copyright span {
  color: #ffffff;
  font-size: 12pt;
  line-height: 18pt;
}
.pdf-export__datapoint-container {
  width: 100%;
  height: 133mm;
  padding: 6.25mm 12.5mm;
}
.pdf-export__chart-container {
  position: relative;
  display: flex;
  height: 92mm !important;
  width: 100%;
}
.pdf-export__chart-container .pdf-export__api-logo {
  position: absolute;
  height: 1.5rem;
  right: 0;
  bottom: 0;
}
.pdf-export__source {
  color: #9F99B1;
  font-size: 10pt;
  line-height: 18pt;
  position: absolute;
  left: 0;
  bottom: -4mm;
}
.pdf-export__info-text {
  width: 100%;
  height: 23mm;
  padding-top: 6mm;
  overflow: clip;
}
.pdf-export__back-page {
  background: radial-gradient(#4E32BF -20%, #1E025F 80%);
  padding: 0;
}
.pdf-export__back-page .pdf-export__contact {
  width: 80%;
  height: 107mm;
  margin: 0 auto;
  border-radius: 0 0 50px 50px;
  background: #fff;
  padding: 15mm 10mm;
}
.pdf-export__back-page .pdf-export__disclaimer {
  width: 60%;
}
.pdf-export__back-page .pdf-export__disclaimer h2 {
  text-decoration: underline #40BC8A;
  font-weight: 800;
}
.pdf-export__back-page .pdf-export__adress {
  margin: 10mm 0 0 10mm;
}
.pdf-export__back-page .pdf-export__adress h3 {
  font-weight: 700;
  margin: 0;
}
.pdf-export__back-page .pdf-export__web-contact {
  position: absolute;
  top: 82mm;
  right: 42mm;
  height: 53mm;
  width: 90mm;
  border-radius: 40px 0 40px 0;
  background: #40BC8A;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5mm 0;
}
.pdf-export__back-page .pdf-export__web-contact div {
  border-radius: 2rem;
  height: 12mm;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdf-export__back-page .pdf-export__web-contact div h4 {
  line-height: 1.75rem;
  margin: 0;
  font-weight: 700;
}

.chart__view--donut .amchart__container {
  width: 100%;
}

@media print {
  .pdf-export__page {
    page-break-after: always;
  }
  @page {
    size: A4;
  }
}
.cabinet-v3__container .heading {
  margin: 1rem 0 -1rem 0;
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
}
.cabinet-v3__container .heading .heading-action {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  align-items: flex-end;
  height: auto;
}
.cabinet-v3__container .heading .cabinet__options-container {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.cabinet-v3__container .heading .profile-toolbar {
  bottom: -1rem;
}
.cabinet-v3__container .heading .heading-subtitle {
  color: #1E025F;
  line-height: 1.75rem;
}
.cabinet-v3__container #id-pjax-zoekprofielen .cabinet-meta {
  justify-self: center;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-label {
  display: flex;
  justify-content: center;
  z-index: 3;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-focus-open .cabinet-link.vacancy--open {
  border-width: 2px;
  border-style: solid;
  border-color: #9F99B1;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 2;
}
.cabinet-v3__container #id-pjax-vacancies_extended .vacancy-date {
  text-align: right;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-matchbox-alert {
  background-color: #40BC8A;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1rem !important;
  border-radius: 0;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-matchbox {
  z-index: 1 !important;
}
.cabinet-v3__container #id-pjax-vacancies_extended .indicator-state {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 0.5rem;
  padding: 0;
  margin: 0;
}
.cabinet-v3__container #id-pjax-vacancies_extended .indicator-state::before {
  border-radius: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 2px;
}
.cabinet-v3__container #id-pjax-vacancies_extended .indicator-state-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0.5rem;
}
.cabinet-v3__container #id-pjax-vacancies_extended .vacancy-meta-item {
  padding: 0;
  margin: 0;
  max-width: 100% !important;
  z-index: 4;
  position: relative;
}
.cabinet-v3__container #id-pjax-vacancies_extended .vacancy-meta-item.vacancy-meta-item--clipped {
  z-index: 10;
}
.cabinet-v3__container #id-pjax-vacancies_extended .vacancy-meta-item .cabinet-sublink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.cabinet-v3__container #id-pjax-vacancies_extended .vacancy-meta-item .cabinet-sublink:hover, .cabinet-v3__container #id-pjax-vacancies_extended .vacancy-meta-item .cabinet-sublink .cabinet-sublink em {
  color: #9F99B1 !important;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-organisation {
  padding: 0 1rem 0 0;
  margin: 0;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-title {
  display: flex;
  align-items: center;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-title h3 {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1rem;
}
.cabinet-v3__container #id-pjax-vacancies_extended .cabinet-title h3 span {
  font-size: 1rem;
}
.cabinet-v3__container #id-pjax-vacancies_extended .vacancy-saved {
  position: relative;
  z-index: 3;
}
.cabinet-v3__content {
  padding: 2rem;
  padding-bottom: 0;
  position: relative;
}
.cabinet-v3__content .icon-container--team .icon-wrap {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
}
.cabinet-v3__content .icon-container--team .icon-wrap svg {
  width: 60%;
  height: 60%;
  fill: #ffffff;
  stroke: #ffffff;
  left: initial;
  top: initial;
}
.cabinet-v3__content .cabinet--shadow.cabinet--grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  grid-gap: 2rem;
  padding: 1rem 0 !important;
  position: relative;
}
.cabinet-v3__content .cabinet--shadow.cabinet--grid:not(.cabinet--empty) {
  border-bottom: 1px solid #CAC6DC;
  border-top: 1px solid #CAC6DC;
}
.cabinet-v3__content .cabinet--shadow.cabinet--rows, .cabinet-v3__content .cabinet--shadow.cabinet--grid .cabinet-drawer {
  box-shadow: 0 0 8px -2px #d2d2d2;
  background-color: #ffffff;
  border-radius: 5px;
}
.cabinet-v3__content .cabinet--shadow.cabinet--grid .cabinet-drawer:hover .cabinet-card, .cabinet-v3__content .cabinet--shadow.cabinet--rows .cabinet-drawer:hover .cabinet-card {
  cursor: pointer;
}
.cabinet-v3__content .cabinet--shadow.cabinet--grid .cabinet-drawer:hover .cabinet-card::before, .cabinet-v3__content .cabinet--shadow.cabinet--rows .cabinet-drawer:hover .cabinet-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #E4E4EC;
}
.cabinet-v3__content .cabinet-status {
  width: 0;
  position: absolute;
  left: -1rem;
}
.cabinet-v3__content .cabinet--rows {
  padding: 20px;
  border-radius: 5px;
}
.cabinet-v3__content .cabinet--rows .cabinet-drawer {
  border-bottom: 1px solid #CAC6DC !important;
}
.cabinet-v3__content .heading-pane-section {
  padding: 1rem 0;
}
.cabinet-v3__content .heading {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: auto;
}
.cabinet-v3__content .heading-action {
  max-width: 100%;
  position: relative;
}
.cabinet-v3__content .heading-action .heading-subtitle {
  position: absolute;
  right: 0;
  bottom: 0;
}
.cabinet-v3__content .heading-action .heading-subtitle:hover {
  color: inherit;
}
.cabinet-v3__content .void--boxed {
  grid-column: 1/-1;
  box-shadow: none;
  border: none;
}
.cabinet-v3__content .void--boxed .pane-section {
  box-shadow: none;
}
.cabinet-v3__content .cabinet-card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.cabinet-v3__content .cabinet-labels, .cabinet-v3__content .cabinet-meta-link {
  position: relative;
  z-index: 2;
}
.cabinet-v3__content .cabinet-meta-link, .cabinet-v3__content .cabinet-meta-location {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cabinet-v3__content .label--deactivated {
  display: none;
  z-index: 9;
}
.cabinet-v3__content .cabinet--deactivated .label--deactivated {
  display: flex;
  position: absolute;
  top: 1.25rem;
  right: 3rem;
}
.cabinet-v3__content .cabinet-link.cabinet--deactivated, .cabinet-v3__content .cabinet-card-link.cabinet--deactivated {
  background-color: white !important;
  opacity: 0.65;
  border-radius: 5px;
}
.cabinet-v3__content .cabinet-options {
  z-index: 5;
}
.cabinet-v3__content .cabinet-status, .cabinet-v3__content .cabinet-properties {
  z-index: 3;
}
.cabinet-v3__content .trend {
  display: flex;
  align-items: center;
}
.cabinet-v3__content--grid, .cabinet-v3__content--rows {
  margin: 0 !important;
}
.cabinet-v3__content__options-container {
  width: 100%;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cabinet-v3__content__options-container .action-bar__cabinet-grid-row {
  margin-left: 2rem;
}
.cabinet-v3__content .pagination {
  position: absolute;
  bottom: -5rem;
  width: 100%;
  margin: 0;
  padding: 1rem;
}
.cabinet-v3 .cabinet__header {
  display: flex;
}
.cabinet-v3 .cabinet-drawer--organisation .cabinet-properties-link {
  text-transform: lowercase;
}
.cabinet-v3 .cabinet-drawer--organisation .cabinet-properties-link span {
  display: inline-block;
}
.cabinet-v3 .cabinet-drawer--organisation .cabinet-properties-link span:first-letter {
  text-transform: uppercase;
}
.cabinet-v3 .cabinet-meta-location,
.cabinet-v3 .cabinet-organisation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0.5rem;
}
.cabinet-v3 .cabinet-meta-date {
  text-align: left;
}
.cabinet-v3 .action--rows,
.cabinet-v3 .action--grid {
  transform: none !important;
}
.cabinet-v3 .cabinet--empty {
  box-shadow: none !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
}
.cabinet-v3 .cabinet--empty .void-inner {
  padding: 2rem !important;
}
.cabinet-v3 .cabinet--empty .void--muted {
  background-color: #ffffff;
  box-shadow: 0 0 8px -2px #d2d2d2;
}
.cabinet-v3 .pane-section.cabinet--grid::after,
.cabinet-v3 .pane-section.cabinet--rows::after {
  display: none;
}
.cabinet-v3 .grid-container .cabinet-options.dropdown {
  position: relative !important;
}
.cabinet-v3 .grid-container .cabinet--rows .cabinet-properties, .cabinet-v3 .grid-container .cabinet--rows .cabinet-meta, .cabinet-v3 .grid-container .cabinet--rows .cabinet-title, .cabinet-v3 .grid-container .cabinet--rows .cabinet-labels, .cabinet-v3 .grid-container .cabinet--grid .cabinet-properties, .cabinet-v3 .grid-container .cabinet--grid .cabinet-meta, .cabinet-v3 .grid-container .cabinet--grid .cabinet-title, .cabinet-v3 .grid-container .cabinet--grid .cabinet-labels {
  margin: 0 !important;
  padding: 0 !important;
}
.cabinet-v3 .grid-container .cabinet--rows .cabinet-card, .cabinet-v3 .grid-container .cabinet--grid .cabinet-card {
  height: 100%;
}
.cabinet-v3 .grid-container .input__container {
  justify-self: flex-start;
  align-self: center;
}
.cabinet-v3 .grid-container .cabinet-title, .cabinet-v3 .grid-container .cabinet-properties, .cabinet-v3 .grid-container .cabinet-labels, .cabinet-v3 .grid-container .cabinet-meta {
  align-self: center;
  justify-self: left;
}
.cabinet-v3 .grid-container .cabinet-title .organisation-name, .cabinet-v3 .grid-container .cabinet-properties .organisation-name, .cabinet-v3 .grid-container .cabinet-labels .organisation-name, .cabinet-v3 .grid-container .cabinet-meta .organisation-name {
  max-width: 100%;
}
.cabinet-v3 .grid-container .cabinet-date {
  overflow: visible;
}
.cabinet-v3 .grid-container .cabinet-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.cabinet-v3 .grid-container .cabinet-options .cabinet-title-options, .cabinet-v3 .grid-container .cabinet-options .action {
  width: 0rem !important;
}
.cabinet-v3 #id-pjax-zoekprofielen .cabinet-meta {
  justify-self: center;
}

.cabinet__profile--alerts {
  z-index: 4;
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: fit-content;
}

.cabinet--heading {
  grid-row: 1;
  grid-column: 1;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767px) {
  #id-pjax-zoekprofielen,
  #id-pjax-prospects,
  #id-pjax-vacancies {
    width: 100% !important;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }
  .cabinet-v3__container,
  .cabinet__container {
    width: 100%;
    margin-bottom: 1rem !important;
  }
  .cabinet-v3__container .cabinet__options-container,
  .cabinet__container .cabinet__options-container {
    display: none !important;
  }
  .cabinet-v3__container .heading,
  .cabinet__container .heading {
    padding: 0 0.5rem !important;
  }
  .cabinet-v3__container .heading .cabinet--heading,
  .cabinet__container .heading .cabinet--heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cabinet-v3__container .heading .heading-action,
  .cabinet__container .heading .heading-action {
    grid-row: 2;
    grid-column: 1;
    margin-left: 0;
  }
  .dashboard-funnel-container {
    padding: 0 !important;
  }
  .cabinet-v3__content {
    top: 2rem;
    padding: 0rem;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-drawer .cabinet-card {
    padding: 0.75rem 1.5rem !important;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-drawer .cabinet-card h3 {
    font-size: 1rem;
  }
  .cabinet-v3__content .cabinet--rows, .cabinet-v3__content .cabinet--grid {
    padding: 0;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-card, .cabinet-v3__content .cabinet--grid .cabinet-card {
    grid-template-columns: [title] 65% [meta] 30% [options] 5% !important;
    grid-template-rows: 1fr !important;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-properties, .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-properties-link, .cabinet-v3__content .cabinet--rows .cabinet-card div.cabinet-meta-link, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-properties, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-properties-link, .cabinet-v3__content .cabinet--grid .cabinet-card div.cabinet-meta-link {
    grid-column: meta !important;
    grid-row: 1;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-properties a, .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-properties-link a, .cabinet-v3__content .cabinet--rows .cabinet-card div.cabinet-meta-link a, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-properties a, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-properties-link a, .cabinet-v3__content .cabinet--grid .cabinet-card div.cabinet-meta-link a {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.5rem !important;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-properties-link, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-properties-link {
    width: 100%;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-title, .cabinet-v3__content .cabinet--rows .cabinet-card .cabinet-title-link, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-title, .cabinet-v3__content .cabinet--grid .cabinet-card .cabinet-title-link {
    grid-column: title !important;
    grid-row: 1;
    padding-right: 0.5rem !important;
  }
  .cabinet-properties,
  .cabinet-properties-link span,
  .cabinet-meta-link {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cabinet-organisation.cabinet-meta-link,
  .cabinet-meta-date,
  .cabinet-matchbox,
  .cabinet__profile--alerts {
    display: none !important;
  }
  .cabinet-profiles-properties a {
    display: block !important;
  }
  .portfolio-funnel-container .pane-section,
  .dashboard-funnel-container .pane-section {
    height: 8rem !important;
  }
  .funnel {
    margin: 0;
    height: 8rem;
    padding: 0 !important;
    justify-content: space-around;
  }
  .funnel .trend span.trend-change {
    font-size: 3rem !important;
  }
  .funnel .funnel-title {
    font-size: 1rem;
  }
  .bulk-actions--bar,
  .cabinet-card--bulk .input__container {
    display: none;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-drawer.cabinet-drawer--organisation .cabinet-card {
    grid-template-columns: [title] 45% [label] 25% [meta] 25% [options] 5% !important;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-drawer.cabinet-drawer--organisation .cabinet-labels {
    grid-column: label;
  }
  .cabinet-v3__content .cabinet--rows .cabinet-drawer.cabinet-drawer--organisation .cabinet-meta {
    display: none;
  }
  .cabinet-trend {
    display: none;
  }
  #id-pjax-zoekprofielen .cabinet-meta {
    display: none;
  }
  #id-pjax-vacancies_extended {
    grid-template-columns: 3fr 1fr !important;
    grid-template-rows: 1fr 1fr !important;
  }
  #id-pjax-vacancies_extended .cabinet-title {
    grid-column: 1;
    grid-row: 1;
  }
  #id-pjax-vacancies_extended .cabinet-organisation {
    grid-column: 1;
    grid-row: 2;
  }
  #id-pjax-vacancies_extended .cabinet-label {
    grid-column: 2;
    grid-row: 1;
  }
  #id-pjax-vacancies_extended .vacancy-location {
    grid-row: 2;
    grid-column: 2;
  }
  #id-pjax-vacancies_extended .vacancy-level, #id-pjax-vacancies_extended .vacancy-date, #id-pjax-vacancies_extended .organisation-property, #id-pjax-vacancies_extended .vacancy-urihost {
    display: none !important;
  }
  .results-focus-open {
    grid-template-columns: 1fr !important;
  }
  .results-focus-open #results-scroll-content {
    display: none !important;
  }
  .results-focus-open .pane-focus {
    grid-column: 1 !important;
    padding: 0 !important;
  }
  .focus--container {
    grid-template-rows: 3rem auto auto auto auto auto !important;
    grid-template-columns: 100% !important;
  }
  .focus--container .focus--main-content {
    grid-row: 3 !important;
  }
  .focus--container .focus--aside {
    grid-row: 4 !important;
    grid-column: 1 !important;
  }
  #focus-audience .audience-link {
    grid-template-columns: auto !important;
    grid-template-rows: auto auto auto auto auto auto !important;
  }
  #focus-audience .audience-link .audience-item-orientation {
    grid-row: 3 !important;
    grid-column: 1 !important;
  }
  #focus-audience .audience-link .audience-item-travel_time {
    grid-row: 4 !important;
  }
  #focus-audience .audience-link .audience-item-age {
    grid-row: 5 !important;
    grid-column: 1 !important;
  }
  #focus-audience .audience-link .audience-credits {
    grid-row: 6 !important;
  }
  #focus .focus--scroll .focus--container .focus--header-container .focus--header {
    padding: 1rem 1rem 1rem 2rem !important;
    flex-direction: column;
    max-width: calc(100% - 5.6rem);
  }
  #focus .focus--scroll .focus--container .focus--header-container .focus--header .focus-title {
    font-size: 1rem !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
    margin-right: 0 !important;
  }
  #focus .focus--scroll .focus--container .focus--header-container .focus--header .focus-organisation {
    width: 100%;
  }
  #focus #focus-admin {
    display: none !important;
  }
  .profile-toolbar {
    padding: 0.75rem 0.5rem 0.75rem 1rem;
  }
  .profile-toolbar__container {
    width: 100%;
  }
  .profile-toolbar__options {
    width: 40% !important;
    justify-content: flex-end;
  }
  .profile-toolbar__input {
    width: 100% !important;
    max-width: calc(100% - 5rem) !important;
    padding-right: 0.5rem !important;
  }
  .profile-toolbar .btn-v3--focus {
    width: 2.75rem;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .profile-toolbar .btn-v3--focus a {
    display: none;
  }
  .profile-toolbar .btn-v3--focus .icon-container {
    right: initial;
  }
  .profile-toolbar .btn-v3--focus .icon-container.icon-container--sm {
    width: 1rem;
  }
  .btn-v3--focus {
    width: 3.25rem;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .btn-v3--focus a {
    display: none;
  }
  .btn-v3--focus .icon-container {
    right: initial;
  }
  .btn-v3--focus .icon-container .icon-container--sm {
    width: 1rem;
  }
  .btn-v3#shareProfile, .btn-v3#copyProfile {
    display: none;
  }
  #results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended #vacancies_extended #sectionvacancies_extended.pane-section {
    padding-right: 0 !important;
  }
  #results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended #vacancies_extended #sectionvacancies_extended .cabinet-drawer__scroll-container.vacancy-list {
    padding-right: 0rem !important;
  }
  .headroom--unpinned .pane-v2-banner.pane-v2-banner--navigation {
    top: -5rem;
  }
  .headroom--unpinned .view-results .search {
    top: -4.5rem;
  }
  .headroom--unpinned .view-results .pane-results {
    top: 0.5rem;
  }
  #results.results-focus-open.pane-results .pane-focus.focus.pane-v2.pane-v2--focus.pane-v2--aside-right {
    margin-left: 0 !important;
  }
  #focus-admin {
    display: none;
  }
}
#search-pane-profiles .cabinet__container {
  padding: 2rem !important;
}

#results-scroll-content {
  padding-bottom: 0 !important;
}
#results-scroll-content .grid-container__vacancies-extended .cabinet-v3__content {
  padding-bottom: 3rem !important;
}

#vacancies_extended.cabinet--last-result .cabinet-drawer--last-result {
  height: 6rem;
}

#results:not(.results-focus-open) .pane-focus {
  display: none !important;
}

.grid-container .cabinet--rows .cabinet-card {
  display: grid;
  grid-row: auto;
  padding: 0.65rem 2rem !important;
}
.grid-container .cabinet--grid .cabinet-card {
  display: grid;
}
.grid-container #id-pjax-zoekprofielen .cabinet--rows .cabinet-card {
  grid-template-columns: [title] 6fr [status] 1fr [location] 3fr [meta] 3fr [alerts] 13rem [options] 4rem;
}
.grid-container #id-pjax-zoekprofielen .cabinet--rows .cabinet-card.cabinet-card--bulk {
  grid-template-columns: [bulkcheckbox] 3rem [title] 2fr [location] 1fr [meta] 1fr [alerts] 1fr [options] 4rem;
}
.grid-container #id-pjax-zoekprofielen .cabinet--grid .cabinet-card {
  grid-template-columns: 3fr 2fr 2fr 1rem;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5rem;
  grid-template-areas: "title title title options" "location meta . .";
}
@media (min-width: 576px) {
  .grid-container #id-pjax-zoekprofielen .cabinet--grid .cabinet-card.cabinet-card--bulk {
    grid-template-columns: 3rem 3fr 2fr 2fr 2rem;
    grid-template-areas: "checkbox title title title options" "checkbox location location trend .";
  }
}
.grid-container #id-pjax-organisaties .cabinet--rows .cabinet-card, .grid-container #id-pjax-prospects .cabinet--rows .cabinet-card {
  grid-template-columns: [title] 2fr [location] 1fr [trend] 0.5fr [labels] 1fr [meta] 1fr [options] 4rem;
}
.grid-container #id-pjax-organisaties .cabinet--rows .cabinet-card.cabinet-card--bulk, .grid-container #id-pjax-prospects .cabinet--rows .cabinet-card.cabinet-card--bulk {
  grid-template-columns: [bulkcheckbox] 3rem [title] 2fr [location] 1fr [trend] 0.5fr [labels] 1fr [meta] 1fr [options] 4rem;
}
.grid-container #id-pjax-organisaties .cabinet--grid .cabinet-card, .grid-container #id-pjax-prospects .cabinet--grid .cabinet-card {
  grid-template-columns: 3fr 2fr 2fr 2rem;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "title title title options" "location meta meta ." "labels trend trend .";
  height: 100%;
}
@media (min-width: 576px) {
  .grid-container #id-pjax-organisaties .cabinet--grid .cabinet-card.cabinet-card--bulk, .grid-container #id-pjax-prospects .cabinet--grid .cabinet-card.cabinet-card--bulk {
    grid-template-columns: 3rem 3fr 2fr 2fr 2rem;
    grid-template-areas: "checkbox title title title options" "checkbox location meta meta ." "checkbox labels trend trend .";
  }
}
.grid-container #id-pjax-vacancies .cabinet--rows .cabinet-card {
  grid-template-columns: [title] 2fr [location] 1fr [labels] 1fr [meta] 1fr [organisation] 1fr [date] 7rem [options] 2rem;
}
.grid-container #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk {
  grid-template-columns: [bulkcheckbox] 3rem [title] 2fr [location] 1fr [labels] 1fr [meta] 1fr [organisation] 1fr [date] 7rem [options] 2rem;
}
.grid-container #id-pjax-vacancies .cabinet--grid .cabinet-card {
  grid-template-columns: 3fr 2fr 2fr 2rem;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "title title title options" "location location meta ." "organisation date . .";
}
@media (min-width: 576px) {
  .grid-container #id-pjax-vacancies .cabinet--grid .cabinet-card.cabinet-card--bulk {
    grid-template-columns: 3rem 3fr 2fr 2fr 2rem;
    grid-template-areas: "checkbox title title title options" "checkbox location location meta ." " checkbox organisation date . .";
  }
}
.grid-container #id-pjax-vacancies_extended {
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.grid-container #id-pjax-vacancies_extended .cabinet--rows .cabinet-card {
  grid-template-columns: [title] 20% [matches] 10% [organisation] 20% [label] 10% [location] 10% [urihost] 10% [education] 5% [date] 15%;
}
.grid-container #id-pjax-vacancies_extended .cabinet--rows .cabinet-card.cabinet-card--bulk {
  grid-template-columns: [bulkcheckbox] 3rem [title] 2fr [location] 1fr [labels] 1fr [meta] 1fr [meta-2] 0.35fr [options] 4rem;
}
.grid-container #id-pjax-vacancies_extended .cabinet-focus-open.cabinet--rows .cabinet-card {
  grid-template-columns: 65% 35%;
  grid-template-rows: 1fr 1fr 1fr;
}
.grid-container #id-pjax-vacancies_extended .cabinet--grid .cabinet-card {
  grid-template-columns: 3fr 2fr 2fr 2rem;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "title title title options" "location location meta .";
}
@media (min-width: 576px) {
  .grid-container #id-pjax-vacancies_extended .cabinet--grid .cabinet-card.cabinet-card--bulk {
    grid-template-columns: 3rem 3fr 2fr 2fr 2rem;
    grid-template-areas: "checkbox title title title options" "checkbox location location meta .";
  }
}
.grid-container #id-pjax-career-applications .cabinet--rows .cabinet-card {
  grid-template-columns: [title] 2fr [location] 1fr [labels] 1fr [meta] 1fr [organisation] 1fr [date] 7rem [options] 2rem;
}
.grid-container #id-pjax-career-applications .cabinet--rows .cabinet-card.cabinet-card--bulk {
  grid-template-columns: [bulkcheckbox] 3rem [title] 2fr [location] 1fr [labels] 1fr [meta] 1fr [organisation] 1fr [date] 7rem [options] 2rem;
}
.grid-container #id-pjax-career-applications .cabinet--grid .cabinet-card {
  grid-template-columns: 3fr 2fr 2fr 2rem;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "title title title options" "location location meta ." "organisation date . .";
}
@media (min-width: 576px) {
  .grid-container #id-pjax-career-applications .cabinet--grid .cabinet-card.cabinet-card--bulk {
    grid-template-columns: 3rem 3fr 2fr 2fr 2rem;
    grid-template-areas: "checkbox title title title options" "checkbox location location meta ." " checkbox organisation date . .";
  }
}
.grid-container .cabinet--rows .cabinet-card .vacancy-link {
  grid-column: 1/-1;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-title {
  grid-column: title;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-status {
  grid-column: status;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-properties {
  grid-column: location;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-labels {
  grid-column: labels;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-trend {
  grid-column: trend;
  grid-row: 1;
  z-index: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-meta {
  grid-column: meta;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-meta-link {
  grid-column: location;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-meta-location {
  grid-column: meta;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-meta-date {
  grid-column: date;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-options {
  grid-column: options;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-organisation {
  grid-column: organisation;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-education, .grid-container .cabinet--rows .cabinet-card .vacancy-level {
  grid-column: education;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-date, .grid-container .cabinet--rows .cabinet-card .vacancy-date {
  grid-column: date;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-domain {
  grid-column: domain;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-meta-date {
  text-align: right;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-card--checkbox {
  grid-column: bulkcheckbox;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-location, .grid-container .cabinet--rows .cabinet-card .vacancy-location {
  grid-column: location;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-label {
  grid-column: label;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet-matchbox {
  grid-column: matches;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .vacancy-urihost {
  grid-column: urihost;
  grid-row: 1;
}
.grid-container .cabinet--rows .cabinet-card .cabinet__profile--alerts {
  grid-column: alerts;
  grid-row: 1;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk {
  grid-template-columns: 3rem 6rem 25% 20% 15% 20% 2rem !important;
}
@media (max-width: 767px) {
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk {
    grid-template-columns: 1fr 1fr 3rem !important;
    grid-template-rows: 1fr 1fr !important;
  }
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk .cabinet-title {
    grid-column: 1 !important;
    grid-row: 1 !important;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 0 !important;
  }
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk .cabinet-title-link {
    grid-column: 1 !important;
    grid-row: 1;
  }
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk .cabinet-application-status {
    grid-column: 2 !important;
    grid-row: 1 !important;
  }
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk .dropdown__clickable {
    grid-column: 3 !important;
    grid-row: 1 !important;
  }
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk .cabinet-organisation.cabinet-meta-link {
    grid-column: 1 !important;
    grid-row: 2 !important;
    display: initial !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    padding-right: 0.25rem;
  }
  .grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card.cabinet-card--bulk .cabinet-meta-location {
    grid-column: 2 !important;
    grid-row: 2 !important;
    white-space: nowrap;
    overflow: hidden;
  }
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .cabinet-meta-date {
  grid-column: 2;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .cabinet-title {
  grid-column: 3;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .cabinet-application-status {
  grid-column: 6;
  grid-row: 1;
  justify-self: start;
  display: flex;
  z-index: 3;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .cabinet-organisation {
  grid-column: 4;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .cabinet-meta-location {
  grid-column: 5;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .dropdown__clickable {
  grid-column: 7;
  grid-row: 1;
  justify-content: center;
}
.grid-container.grid-container__career-vacancies #id-pjax-vacancies .cabinet--rows .cabinet-card .dropdown__clickable .icon-container {
  margin: 0 !important;
}
.grid-container .cabinet--rows.cabinet-focus-open .cabinet-title {
  grid-row: 1;
  grid-column: 1;
}
.grid-container .cabinet--rows.cabinet-focus-open .cabinet-organisation {
  grid-row: 2;
  grid-column: 1;
}
.grid-container .cabinet--rows.cabinet-focus-open .cabinet-label {
  grid-row: 2;
  grid-column: 2;
}
.grid-container .cabinet--rows.cabinet-focus-open .cabinet-matchbox {
  grid-row: 3;
  grid-column: 2;
}
.grid-container .cabinet--rows.cabinet-focus-open .vacancy-location {
  grid-row: 3;
  grid-column: 1;
}
.grid-container .cabinet--rows.cabinet-focus-open .vacancy-urihost {
  grid-row: 1;
  grid-column: 2;
}
.grid-container .cabinet--rows.cabinet-focus-open .vacancy-level, .grid-container .cabinet--rows.cabinet-focus-open .vacancy-date {
  display: none !important;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-title {
  grid-area: title;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-properties {
  grid-area: location;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-labels {
  grid-area: labels;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-meta {
  grid-area: meta;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-meta-link {
  grid-area: location;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-meta-date {
  grid-area: date;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-organisation {
  grid-area: organisation;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-trend {
  grid-area: trend;
  z-index: 1;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-options, .grid-container .cabinet--grid .cabinet-card .cabinet-status {
  grid-area: options;
}
.grid-container .cabinet--grid .cabinet-card .cabinet-card--checkbox {
  grid-area: checkbox;
}

.results-focus-open #id-pjax-vacancies_extended {
  margin-top: -1.25rem !important;
}
.results-focus-open .heading {
  grid-template-columns: 1fr auto;
}
.results-focus-open .heading .cabinet--heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.results-focus-open .heading .heading-action {
  grid-column: 2;
  grid-row: 1;
  margin-left: 0;
}
.results-focus-open .profile-toolbar__container .profile-toolbar__input {
  width: calc(100% - 5.5rem);
  max-width: unset !important;
}
.results-focus-open .profile-toolbar__container .profile-toolbar__options {
  margin-left: 0.5rem;
  margin-right: 0;
}

#results.headroom--unpinned {
  grid-template-rows: 0 1fr !important;
}
#results.headroom--unpinned .tab-nav__container {
  opacity: 0;
  z-index: 0 !important;
}
#results.headroom--unpinned .pane-v2-header--flex {
  opacity: 0;
  z-index: 0 !important;
}
#results.headroom--unpinned #results-scroll-content.pane-content {
  padding-top: 0 !important;
}
#results.headroom--unpinned .profile-toolbar.profile-toolbar__container {
  top: -4rem;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
}

.profile-toolbar.profile-toolbar__container,
.tab-nav__container,
#results-scroll-content.pane-content,
#results {
  transition: all 0.3s;
}

#results.results-focus-open.pane-results--modes.headroom--pinned {
  grid-template-rows: 3rem 1fr;
}

@media (max-width: 767px) {
  .headroom--unpinned #results.results-vacancies #results-scroll-content.pane-content {
    top: 0 !important;
  }
}
.dashboard-funnel-container,
.portfolio-funnel-container {
  padding: 1.5rem;
  padding-top: 1rem;
}
.dashboard-funnel-container .pane-section,
.portfolio-funnel-container .pane-section {
  padding: 0;
  box-shadow: 0 0 8px -2px #d2d2d2;
  background-color: #ffffff;
  border-radius: 5px;
}
.dashboard-funnel-container .pane-section--inset,
.portfolio-funnel-container .pane-section--inset {
  height: 10rem;
}
.dashboard-funnel-container .pane-section--inset .funnel,
.portfolio-funnel-container .pane-section--inset .funnel {
  padding-top: 1rem;
}
.dashboard-funnel-container .pane-section--inset .funnel .funnel-trend .trend-change,
.portfolio-funnel-container .pane-section--inset .funnel .funnel-trend .trend-change {
  font-weight: bold;
}
.dashboard-funnel-container .pane-section--inset .funnel .funnel-title,
.portfolio-funnel-container .pane-section--inset .funnel .funnel-title {
  font-weight: bold;
}
.dashboard-funnel-container .pane-section--inset .funnel-title,
.portfolio-funnel-container .pane-section--inset .funnel-title {
  height: 2rem;
}
.dashboard-funnel-container .pane-section--inset .funnel-step:hover,
.portfolio-funnel-container .pane-section--inset .funnel-step:hover {
  transform: scale(1.1);
}
.dashboard-funnel-container .pane-section--inset .funnel-step:hover .funnel-title,
.portfolio-funnel-container .pane-section--inset .funnel-step:hover .funnel-title {
  color: #1E025F;
}

.navbar_menu--icon__container {
  height: 3rem;
  width: 3rem;
  border-radius: 1000px;
  border: 1px solid;
  border-color: #9F99B1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  z-index: 9;
  position: relative;
  margin: 0 0.5rem;
}
.navbar_menu--icon__container h1, .navbar_menu--icon__container .navbar--icon__svg {
  color: #9F99B1;
  fill: #9F99B1;
  stroke: #9F99B1;
}
.navbar_menu--icon__container:hover {
  border-color: #1E025F;
}
.navbar_menu--icon__container:hover h1, .navbar_menu--icon__container:hover .navbar--icon__svg {
  color: #1E025F;
  fill: #1E025F;
  stroke: #1E025F;
}
.navbar_menu--backdrop {
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #1E025F;
  opacity: 0%;
  top: 0;
  left: 0;
  z-index: 10;
}
.navbar_menu--backdrop__active {
  opacity: 20%;
}
.navbar_menu--items-modal {
  position: fixed;
  height: 100%;
  width: 30rem;
  right: -31rem;
  top: 0;
  background-color: #ffffff;
  z-index: 11;
  box-shadow: 0 0 8px 4px #d2d2d2;
}
.navbar_menu--items-modal__active {
  right: 0;
}
.navbar_menu--items-modal--header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pane-v2 nav.navigation {
  height: 100%;
}

.menu-option--margin-top {
  top: 3rem;
}

.menu-option--logout {
  position: absolute;
  bottom: 8rem;
  background: #F85C6A;
}
.menu-option--logout span {
  color: #ffffff;
  text-align: center;
}
.menu-option--logout:hover {
  background-color: #f51327 !important;
}

.pane-v2--navigation .pane-v2-banner--close {
  border-bottom: 1px solid #CAC6DC;
}

@media (max-width: 991px) {
  .navbar_menu--items-modal {
    width: 100%;
    right: -105%;
  }
  .navbar_menu--items-modal__active {
    right: 0;
  }
}
.menu__career {
  position: absolute;
  top: 3.25rem;
}

.intro-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10rem;
}
.intro-modal__text-container {
  height: 100%;
  width: 20%;
  background: white;
  box-shadow: 0 0 8px -2px #d2d2d2;
  z-index: 10;
  padding: 2rem;
  margin-right: 1rem;
  position: relative;
}
.intro-modal__text-container .icon-container svg {
  stroke: #fff;
  fill: #fff;
}
.intro-modal__video-container {
  position: relative;
  height: 100%;
  width: 80%;
  background: white;
  box-shadow: 0 0 8px -2px #d2d2d2;
  z-index: 10;
}
.intro-modal__video-container .btn--close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro-modal__video-container .btn--close .icon-container {
  margin: 0 !important;
  position: relative;
  left: 0;
  top: 0;
}
.intro-modal__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 4rem;
}
.intro-modal__footer .btn--close {
  right: auto;
}

@media (max-width: 767px) {
  .intro-modal__backdrop {
    padding: 1rem;
    flex-direction: column-reverse;
  }
  .intro-modal__text-container {
    height: auto;
    width: 100%;
    margin-right: 0;
    margin-top: 1rem;
  }
  .intro-modal__text-container p {
    margin-bottom: 3rem;
  }
  .intro-modal__video-container {
    height: auto;
    width: 100%;
  }
  .intro-modal__video-container .btn--close {
    display: none;
  }
}
.career-dashboard__grid {
  display: grid;
  grid-template-columns: 20% 40% 40%;
  grid-template-rows: 50% 50%;
  height: calc(100vh - 9rem);
  width: 100%;
  background-color: #F4F6Fa;
}
.career-dashboard__container {
  padding: 2rem;
}
.career-dashboard__container--profile {
  grid-row: 1/-1;
  grid-column: 1;
}
.career-dashboard__container--vacancies {
  grid-row: 1;
  grid-column: 3;
}
.career-dashboard__container--tests {
  grid-row: 2;
  grid-column: 3;
}
.career-dashboard__container--applications {
  grid-row: 2;
  grid-column: 2;
}
.career-dashboard__container--professions {
  grid-row: 1;
  grid-column: 2;
}
.career-dashboard__card {
  box-shadow: 0 0 8px -2px #d2d2d2;
  height: 100%;
  width: 100%;
  padding: 2rem;
  background-color: white;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr auto;
}
.career-dashboard__header {
  grid-row: 1;
  grid-column: 1;
}
.career-dashboard__meta {
  grid-row: 1;
  grid-column: 2;
}
.career-dashboard__content {
  grid-row: 2;
  grid-column: 1/-1;
  overflow-y: scroll;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}
.career-dashboard__content .list-item {
  padding: 0 !important;
}
.career-dashboard__content .list-item .list-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.career-dashboard__content .list-item .list-link {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.career-dashboard__content .list-header {
  padding-top: 0.35rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 0.35rem !important;
}
.career-dashboard__footer {
  grid-row: 3;
  grid-column: 1/-1;
}
.career-dashboard__vacancy--muted::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.career-dashboard__vacancy--muted .career-dashboard--expired__container {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  padding-bottom: 0 !important;
  z-index: 1;
}
.career-dashboard__vacancy--muted .career-dashboard--expired {
  position: absolute;
  display: block;
  right: 0;
}
.career-dashboard__vacancy .list-header .list-action {
  display: none;
}
.career-dashboard__vacancy .list-header .list-link {
  display: flex;
}
.career-dashboard__vacancy .list-header .list-link .list-subtitle {
  margin-left: 1rem;
}
.career-dashboard--expired {
  display: none;
}
.career-dashboard--expired__container {
  display: none;
}

.view-tests .pane-v2.pane-v2--root .pane-section.pane-section--first {
  padding-top: 1rem !important;
}

@media (max-width: 991px) {
  .career-dashboard__grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    height: 100%;
    width: 100%;
    background-color: #F4F6Fa;
  }
  .career-dashboard__card {
    max-height: 25rem;
  }
  .career-dashboard__info-text {
    white-space: initial !important;
  }
  .career-dashboard__container {
    padding: 2rem;
  }
  .career-dashboard__container--profile {
    grid-row: 1;
    grid-column: 1;
  }
  .career-dashboard__container--vacancies {
    grid-row: 4;
    grid-column: 1;
  }
  .career-dashboard__container--tests {
    grid-row: 2;
    grid-column: 1;
  }
  .career-dashboard__container--applications {
    grid-row: 5;
    grid-column: 1;
  }
  .career-dashboard__container--professions {
    grid-row: 3;
    grid-column: 1;
  }
  .career-dashboard__container .btn-v3 {
    width: 100%;
  }
}
.module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content, .application-v2--module-career.view-professions .layout-pane--primary > .pane-content-wrap > .pane-content#results-scroll-content {
  height: calc(100vh - 17rem) !important;
}

#profession-list .list-item .list-action {
  height: 100%;
  top: 0;
  display: flex;
  justify-content: space-evenly;
}

.list-subtitle {
  display: flex;
}

#results #results-scroll-content.career__vacancies {
  padding-top: 0 !important;
}

.organisation-follow--following .btn-v3 {
  background-color: #40BC8A !important;
}

.btn-competences {
  display: flex;
  padding: 0.5rem;
}
.btn-competences .btn.btn-xxs {
  width: 2.5rem;
  margin-right: 0.5rem;
}

.career_search ~ .pane-v2--search.pane-v2 {
  top: 1rem;
}

.career_search.pane-v2.pane-v2--client.pane-v2--root {
  top: 9rem !important;
}

@media (min-width: 768px) {
  .view-professions.layout-split--md-open .pane.pane--back.pane--back-md--hide.layout-pane--primary {
    width: 50%;
  }
  .view-professions.layout-split--md-open .layout-pane--tertiary {
    width: 50% !important;
    margin-left: 50% !important;
  }
}
.pane-v2-banner .logo {
  display: none;
}

@media only screen and (min-width: 1400px) {
  .pane-v2-banner .logo {
    display: block;
  }
}
.career__focus .focus--container .focus--actions {
  padding: 4rem 0 !important;
}
.career__focus .focus--container .focus--actions .focus-save path {
  fill: #9F99B1 !important;
}
.career__focus .focus--container .focus--actions .focus-save:hover path {
  fill: #1E025F !important;
}
.career__focus .focus--container .focus--actions .focus-save .action-label-wrap {
  width: 9rem;
}
.career__focus .focus--container .focus--moderate.pane-section.pane-section--inset.pane-section--expand {
  position: absolute;
  left: 0;
  top: 6.5rem;
  width: 100%;
  padding: 0 2rem !important;
}
.career__focus .focus--container .focus--moderate.pane-section.pane-section--inset.pane-section--expand #save-vacancy {
  width: 100%;
}

#focus_modal .modal-dialog {
  max-width: 65rem;
}
#focus_modal .modal-dialog #focus > .pane-back.action {
  display: none;
}
#focus_modal .modal-dialog #focus-content, #focus_modal .modal-dialog .focus--scroll {
  height: 100%;
}

.isIframe .account-type--jobdigger_moderator .focus--container .focus--actions {
  display: none;
}

.account-type--jobdigger_moderator .organisation-dropdown {
  display: none !important;
}
.account-type--jobdigger_moderator .focus-admin--baguette {
  right: 7.5rem !important;
}
.account-type--jobdigger_moderator .organisation-label {
  display: none;
}

.candidate-matches__item {
  position: relative;
  display: grid;
  grid-template-columns: auto 4.25rem;
  grid-template-rows: auto auto;
  height: 4.5rem;
  border-bottom: 1px solid #E4E4EC;
}
.candidate-matches__item::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  opacity: 0;
  cursor: pointer;
}
.candidate-matches__title {
  grid-column: 1;
  grid-row: 1;
  align-self: end;
  line-height: 1.5rem;
}
.candidate-matches__score {
  grid-column: 2;
  grid-row: 1/-1;
}
.candidate-matches__location {
  grid-column: 1;
  grid-row: 2;
  align-self: baseline;
  line-height: 1.5rem;
}
.candidate-matches__more {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar {
  display: grid;
  grid-template-columns: auto 1fr auto;
  position: fixed;
  top: 0;
  z-index: 10000000;
  width: 100vw;
  border-bottom: 1px solid #CAC6DC;
  height: auto;
  background-color: #F4F6Fa;
}
.navbar__button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 5rem;
  height: 9rem;
}
.navbar__button-container .navbar__icon {
  line-height: 0.75rem;
}
.navbar__button-container .navbar__icon .icon-container.icon-container--lg {
  margin: 0 !important;
}
.navbar__mid-container {
  width: 100%;
}

.pane-v2.pane-v2--navigation-portfolio,
.pane-v2.pane-v2--navigation-queries {
  z-index: 99999100000 !important;
}

.pane-v2-backdrop.pane-v2-backdrop--navigation-portfolio,
.pane-v2-backdrop.pane-v2-backdrop--navigation-queries,
.pane-v2-backdrop.pane-v2-backdrop--navigation {
  z-index: 1000000000000;
}

.navbar__new-window-button {
  display: none;
}

.isIframe .navbar__new-window-button {
  display: block;
}
.isIframe .navbar__new-window-button .icon-wrap svg {
  fill: #9F99B1;
  width: 32px !important;
  height: 32px !important;
}

.dashboard-only .pane-header, .dashboard-only .pane-v2-banner--navigation, .dashboard-only #search-form, .dashboard-only .view-results .pane-results.pane-results--modes .pane-content, .dashboard-only .results-modes, .dashboard-only .pane-header, .dashboard-only #results-save, .dashboard-only #filters-open {
  display: none;
}
.dashboard-only .pane-focus {
  top: 0px !important;
}
.dashboard-only #results-scroll-content {
  padding-top: 0px !important;
}
.dashboard-only.view-results .pane-results {
  top: 0 !important;
  height: 100% !important;
}

.report-list-items .report-list-item:nth-of-type(1n+11) {
  display: none;
}

.inner-pane {
  overflow-y: scroll;
  overflow-x: auto;
  padding: 35px;
  margin-bottom: 45px;
}

.page-pane {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 8px -2px #d2d2d2;
  border-radius: 10px;
  background-color: #ffffff;
}

.grid-view {
  background-color: #ffffff;
  height: 100%;
}
.grid-view__dummy-scroll {
  overflow-y: hidden;
  scrollbar-gutter: stable;
}
.grid-view--border {
  border: 1px solid #CAC6DC;
  border-radius: 10px;
  padding: 20px 35px;
}
.grid-view__scroll-container {
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-gutter: stable;
}
.grid-view__column-action {
  width: 20px;
  height: 20px;
}
.grid-view__column-sort {
  position: absolute;
  right: 10px;
}
.grid-view__index {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E4E4EC;
}
.grid-view__col {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
}
.grid-view__col-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid-view__col-date {
  width: 100%;
  text-align: center;
}
.grid-view__col--center {
  justify-content: center;
}
.grid-view__col--clickable div {
  cursor: pointer;
}
.grid-view__row-options {
  overflow: visible;
  cursor: pointer;
}
.grid-view__row-options-pane {
  z-index: 1;
}
.grid-view__dropdown-pane {
  position: absolute;
  z-index: 1;
  top: 45px;
  right: -20px;
  padding: 35px;
  background-color: #ffffff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  border-radius: 5px;
}
.grid-view__scoll-container {
  overflow: scroll;
  height: 100%;
}
.grid-view .no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.grid-view-filter__container .react-tooltip-wrapper {
  grid-column: 1/-1;
}
.grid-view-filter__container .react-tooltip-wrapper .react-button {
  width: 100%;
}
.grid-view-filter__select-container {
  display: flex;
  align-items: center;
  height: 45px;
  padding: 10px;
  border: 2px solid #E4E4EC;
  border-radius: 10px;
}
.grid-view-filter__select-container--active {
  border: 2px solid #9F99B1;
}
.grid-view-filter__select-container input {
  margin-right: 10px;
}
.grid-view-filter__select-container em {
  white-space: nowrap;
}

.grid-row {
  height: 45px;
}
.grid-row:nth-child(odd) {
  background-color: #F4F6Fa;
}
.grid-row:nth-child(even) {
  background-color: #ffffff;
}
.grid-row__header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: left;
  padding: 0 20px;
}
.grid-row__header:not(:last-child):not(:nth-last-child(2)) {
  border-right: 2px solid #E4E4EC;
}
.grid-row__header input {
  margin-right: 10px;
  width: fit-content;
  border-radius: 10px;
}
.grid-row__header .react-button {
  margin-right: 10px;
}
.grid-row--active {
  background-color: #1E025F !important;
}
.grid-row--active .grid-view__col-text {
  color: #ffffff !important;
  font-weight: bold;
}
.grid-row--active .grid-view__row-options .react-svg-icon, .grid-row--active .grid-view__col-icon .react-svg-icon, .grid-row--active .grid-view__col-email .react-svg-icon {
  fill: #ffffff !important;
}
.grid-row--active .grid-view__index {
  background: #1E025F !important;
}
.grid-row--active .grid-view__index h3 {
  color: #ffffff !important;
}
.grid-row .grid-view__col:not(:last-child) {
  border-right: 2px solid #E4E4EC;
}

.meter {
  display: flex;
}
.meter-bar {
  height: 20px;
  width: 5px;
  margin-right: 5px;
  background-color: #E4E4EC;
}
.meter-bar--active {
  background-color: #443469;
}

.layout-fullscreen .navbar {
  height: 5rem;
  width: 6rem;
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0);
}
.layout-fullscreen .navbar__button-container {
  height: 5rem;
  width: 6rem;
}
.layout-fullscreen .navbar__mid-container, .layout-fullscreen .navbar__button-container--right {
  display: none;
}
.layout-fullscreen .react-page {
  height: 100vh;
  top: 0 !important;
}
.layout-fullscreen .react-page .react-content__header {
  position: absolute;
  height: 5rem;
  left: 6rem;
  top: 0;
  width: calc(100% - 8rem);
  display: flex;
  align-items: center;
  padding: 0;
}
.layout-fullscreen .react-page .react-page__container-headspace {
  position: relative;
  top: 4rem;
}
.layout-fullscreen .pane-v2.pane-v2--dashboard.pane-v2--root {
  top: 5rem !important;
  height: calc(100% - 4rem);
}
.layout-fullscreen__header {
  position: fixed;
  left: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
}

.layout-fullscreen .navbar {
  height: 5rem;
  width: 6rem;
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0);
}
.layout-fullscreen .navbar__button-container {
  height: 5rem;
  width: 6rem;
}
.layout-fullscreen .navbar__mid-container, .layout-fullscreen .navbar__button-container--right {
  display: none;
}
.layout-fullscreen .react-page {
  height: 100vh;
  top: 0 !important;
}
.layout-fullscreen .react-page .react-content__header {
  position: absolute;
  height: 5rem;
  left: 6rem;
  top: 0;
  width: calc(100% - 8rem);
  display: flex;
  align-items: center;
  padding: 0;
}
.layout-fullscreen .react-page .react-page__container-headspace {
  position: relative;
  top: 4rem;
}
.layout-fullscreen .pane-v2.pane-v2--dashboard.pane-v2--root {
  top: 5rem !important;
  height: calc(100% - 4rem);
}
.layout-fullscreen__header {
  position: fixed;
  left: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
}

.block-pane {
  box-shadow: 0 0 8px -2px #d2d2d2;
  padding: 20px;
}

.view-dashboard .pane-v2-cover {
  min-height: 6rem;
  display: flex;
  align-items: center;
}
.view-dashboard .pane-v2-cover .logo {
  top: initial;
  position: relative;
}

.grid-container__dashboard {
  grid-template-columns: 1fr 1fr 0.45fr;
  grid-template-rows: [profiles] auto [funnel] auto [prospects] auto [vacancies] auto;
}

.view-doelgroependashboard .grid-container__dashboard {
  grid-template-rows: [profiles] auto [vacancies] auto;
}

#id-pjax-zoekprofielen {
  height: 100%;
}
@media (max-width: 991px) {
  #id-pjax-zoekprofielen {
    width: auto;
  }
}

.dashboard-info-block {
  padding: 2rem;
  padding-left: 0;
  padding-top: 2.5rem;
  grid-column: 4;
  grid-row: 1/5;
}
.dashboard-info-block article {
  border-bottom: 1px solid #CAC6DC;
  padding: 1rem 0;
  max-width: 16rem;
}
.dashboard-info-block-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-width: 2px;
  border-style: solid;
}
.dashboard-info-block-icon {
  display: inline-block;
  position: relative;
  top: 0.4rem;
  margin-right: 0.5rem;
  width: 4%;
}
.dashboard-info-block-icon-text {
  display: inline-block;
  width: 85%;
  line-height: 3rem;
}
.dashboard-info-block-list {
  padding: 1rem;
  list-style: none;
}
.dashboard-info-block p {
  margin: 0;
  padding-bottom: 0.5rem;
}

.cabinet-v3__container--zoekprofielen {
  grid-column: 1/4;
  grid-row: profiles;
}
.cabinet-v3__container--prospects {
  grid-column: 1/4;
  grid-row: prospects;
}
.cabinet-v3__container--vacancies {
  grid-column: 1/4;
  grid-row: vacancies;
}

.dashboard-funnel-container {
  grid-column: 1/4;
}

.dashboard-funnel-container {
  grid-row: funnel;
}

@media (max-width: 991px) {
  .dashboard-info-block {
    display: none;
  }
}
#sectionvacancies .cabinet-card-link {
  z-index: 3;
}

.grid-container__portfolio {
  grid-template-columns: [col] 100%;
  grid-template-rows: [funnel] auto [prospects] auto [vacancies] auto;
}
.grid-container__portfolio .portfolio-funnel-container {
  grid-column: col;
  grid-row: funnel;
}
.grid-container__portfolio .cabinet-v3__container--prospects {
  grid-column: 1;
  grid-row: prospects;
  height: 100%;
}
.grid-container__portfolio .cabinet-v3__container--vacancies {
  grid-column: 1;
  grid-right: vacancies;
}

@media (min-width: 576px) {
  .application-v2--state-portfolio .cabinet-v3__container.cabinet-v3__container--vacancies {
    height: calc(100vh - 16rem);
  }
}
.view-results.view-results--focus .pane-results {
  width: 100% !important;
}

.pane-results:not(.results-focus-open) #focus-content {
  display: none;
}

#results.results-focus-open.pane-results--modes {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: 5rem 1fr;
  top: 7rem !important;
}
#results.results-focus-open.pane-results--modes.headroom--unpinned {
  top: 0 !important;
}

.pane-focus.focus.pane-v2.pane-v2--focus.pane-v2--aside-right {
  top: 0 !important;
}

#results.results-focus-open .pane-v2-header--flex {
  display: none;
}

#results #results-scroll-content.pane-content {
  padding-top: 3rem !important;
  overflow: auto;
}

#results .pane-v2-header {
  background: #F4F6Fa;
  position: relative;
}
#results .pane-v2-header .tab-nav__container {
  width: fit-content;
  position: absolute;
  right: 5rem;
  height: 3rem;
}
#results .pane-v2-header .tab-nav__container .tab-nav {
  padding: 0.5rem 0 !important;
}
#results .pane-v2-header .tab-nav__container .tab-nav__item {
  margin-right: 0 !important;
}

#results .results-meta-period.results-meta-dropdown {
  position: absolute;
  top: 1rem;
}

#results.results-focus-open.pane-results--modes .actions.actions-footer,
#focus .actions.actions-footer {
  z-index: 999;
}
#results.results-focus-open.pane-results--modes .tab-nav__container,
#focus .tab-nav__container {
  grid-row: 1;
  grid-column: 1/-1;
}
#results.results-focus-open.pane-results--modes #results-scroll-content,
#focus #results-scroll-content {
  grid-row: 2;
  grid-column: 1;
  position: relative;
  padding-top: 0rem !important;
}
#results.results-focus-open.pane-results--modes #results-scroll-content .pane-content--loading::before,
#focus #results-scroll-content .pane-content--loading::before {
  content: "";
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  background-color: #fff;
  opacity: 70%;
}
#results.results-focus-open.pane-results--modes #results-scroll-content .cabinet-v3__content,
#focus #results-scroll-content .cabinet-v3__content {
  padding-bottom: 0 !important;
}
#results.results-focus-open.pane-results--modes #results-map-content,
#focus #results-map-content {
  grid-row: 2;
  grid-column: 1;
  position: relative;
  padding: 4rem 2rem 4rem 3rem;
}
#results.results-focus-open.pane-results--modes #results-map-content #results-map,
#focus #results-map-content #results-map {
  box-shadow: 0 0 8px -2px #d2d2d2;
}
#results.results-focus-open.pane-results--modes .pane-focus, #results.results-focus-open.pane-results--modes .pane-v2--focus,
#focus .pane-focus,
#focus .pane-v2--focus {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
  height: 100% !important;
  margin-left: 0 !important;
  position: relative;
  top: -0.25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem 3rem 1rem 0;
  background-color: #F4F6Fa;
}

body .focus--scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
body .focus--container {
  display: grid;
  grid-template-columns: 1fr 24rem;
  grid-template-rows: 2.6rem auto auto auto auto;
  grid-gap: 1rem;
  padding: 2rem 2rem 5rem 2rem;
  background: #ffffff;
  position: relative;
}
body .focus--container .focus--header-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 5.6rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CAC6DC;
}
body .focus--container .focus--header-container .focus--header {
  height: 5.6rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
body .focus--container .focus--header-container .focus--header .focus-title {
  margin: 0;
  margin-right: 1rem;
  padding: 0;
  font-size: 24px !important;
  line-height: 24px;
  padding-bottom: 0.5rem;
}
body .focus--container .focus--header-container .focus--header .focus-organisation {
  flex-wrap: wrap;
  position: inherit;
}
body .focus--container .focus--header-container .focus--header .focus-organisation .organisation-dropdown {
  position: absolute;
  right: 5.6rem;
  top: 0;
  width: 5.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #CAC6DC;
}
body .focus--container .focus--header-container .focus--header .focus-organisation .organisation-dropdown .icon-container {
  transform: scale(1.2);
}
body .focus--container .focus--header-container .focus--header .organisation-name {
  font-size: 16px;
  line-height: 18px;
}
body .focus--container .focus--options {
  display: flex;
  justify-content: flex-end;
}
body .focus--container .focus--options .pane-v2-actions {
  padding: 0 !important;
  align-items: center;
  height: 5.6rem;
  width: 5.6rem;
  align-self: end;
  border-left: 1px solid #CAC6DC;
}
body .focus--container .focus--options .pane-v2-actions .action--close {
  position: initial;
  margin: 0;
}
body .focus--container .focus-contact {
  position: absolute;
  top: 4rem;
  padding: 0 !important;
  left: 2rem;
}
body .focus--container .focus-contact .pane-v2-footer-notice {
  padding: 0 1rem;
  border-radius: 1rem;
}
body .focus--container .focus--actions {
  grid-row: 2;
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0;
}
body .focus--container .focus--actions .action {
  width: inherit;
  transform: scale(1.2);
}
body .focus--container .focus--actions .action .icon-container {
  transform: scale(1.3);
}
body .focus--container .focus--actions .action .action-label-wrap {
  color: #9F99B1;
}
body .focus--container .focus--actions .action.action--accent svg {
  fill: #40BC8A;
  stroke: #40BC8A;
}
body .focus--container .focus--actions .action.action--accent .action-label-wrap {
  color: #40BC8A;
}
body .focus--container .focus--actions .action:hover .icon-container svg {
  fill: #1E025F;
  stroke: #1E025F;
}
body .focus--container .focus--actions .action:hover .action-label-wrap {
  color: #1E025F;
}
body .focus--container .focus--main-content {
  grid-row: 3/5;
  grid-column: 1;
}
body .focus--container .focus--main-content .focus-description {
  word-break: break-word;
}
body .focus--container .focus--aside {
  grid-row: 3/5;
  grid-column: 2;
}
body .focus--container .focus--aside #focus-audience, body .focus--container .focus--aside #focus-thematchbox {
  box-shadow: 0 0 8px -2px #d2d2d2;
}
body .focus--container .focus--aside #focus-audience:hover, body .focus--container .focus--aside #focus-thematchbox:hover {
  box-shadow: 0 0 8px -2px #d2d2d2;
}
body .focus--container .focus--aside #focus-audience a:hover, body .focus--container .focus--aside #focus-thematchbox a:hover {
  color: #1E025F;
}
body .focus--container .focus--aside #focus-audience .audience-title, body .focus--container .focus--aside #focus-thematchbox .audience-title {
  color: #1E025F;
}
body .focus--container .focus--aside #focus-audience .audience-title:hover, body .focus--container .focus--aside #focus-thematchbox .audience-title:hover {
  color: #1E025F;
}
body .focus--container .focus--aside #fold_focus_audience {
  padding: 20px 35px;
  cursor: pointer;
}
body .focus--container .focus--aside #fold_focus_audience .audience-link-icon .icon-wrap svg {
  fill: #1E025F;
  stroke: #1E025F;
}
body .focus--container .focus--aside .focus-audience--closed {
  display: none !important;
}
body .focus--container .focus--aside #focus-audience .audience-link {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: auto auto;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-header {
  grid-row: 1;
  grid-column: 1/-1;
  color: #1E025F;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item {
  padding: 0 !important;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-recruitment_feasibility {
  grid-row: 2;
  grid-column: 1/-1;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-recruitment_feasibility h4 {
  margin-bottom: 1rem !important;
  line-height: 1rem;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-distribution_gender {
  grid-row: 3;
  grid-column: 1;
  justify-content: center;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-orientation {
  grid-row: 3;
  grid-column: 2;
  justify-content: center;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time_age {
  grid-row: 4;
  grid-column: 1/-1;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age {
  padding-bottom: 1rem !important;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta {
  padding: 0;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta .audience-meta-items, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta .audience-meta-items {
  justify-content: center;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta .audience-meta-items .audience-meta-item, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta .audience-meta-items .audience-meta-item {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: auto auto;
  flex: none;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta .audience-meta-items .audience-meta-item .audience-meta-desc, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta .audience-meta-items .audience-meta-item .audience-meta-desc {
  grid-row: 1;
  grid-column: 1/3;
  color: #1E025F;
  font-size: 1rem;
  font-weight: 700;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta .audience-meta-items .audience-meta-item .audience-meta-value, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta .audience-meta-items .audience-meta-item .audience-meta-value {
  grid-row: 2;
  grid-column: 2;
  color: #9F99B1;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta .audience-meta-items .audience-meta-item .audience-meta-icon, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta .audience-meta-items .audience-meta-item .audience-meta-icon {
  position: initial;
  grid-row: 2;
  grid-column: 1;
  justify-self: center;
  align-self: center;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time .audience-meta .audience-meta-items .audience-meta-item .audience-meta-icon svg, body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age .audience-meta .audience-meta-items .audience-meta-item .audience-meta-icon svg {
  fill: #D3D9E3 !important;
  stroke: #D3D9E3 !important;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-travel_time {
  grid-row: 4;
  grid-column: 1;
  justify-content: center;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-item-age {
  grid-row: 4;
  grid-column: 2;
  justify-content: center;
}
body .focus--container .focus--aside #focus-audience .audience-link .audience-credits {
  grid-row: 5;
  grid-column: 1/-1;
}
body #focus-content {
  box-shadow: 0 0 8px -2px #d2d2d2;
  position: relative;
}
body .focus--location {
  grid-row: 5;
  grid-column: 1/-1;
}
body .focus--location .pane-section--inset {
  padding: 0 !important;
}
body .focus--location .focus-location {
  background-color: #ffffff;
}
body .focus--contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  border-top: 1px solid #CAC6DC;
  padding: 0 2rem 1rem 2rem;
  background: #ffffff;
}
body .focus--contact #focus-admin {
  margin-top: 1rem;
}

#results-scroll-content {
  height: 100%;
  padding-bottom: 4rem;
}
#results-scroll-content .grid-container {
  height: 100%;
}
#results-scroll-content .grid-container .cabinet-v3__container {
  height: 100%;
  overflow: hidden;
}
#results-scroll-content .grid-container .cabinet-v3__container.cabinet-v3__container--vacancies_extended .heading {
  display: none;
}
#results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended {
  height: 100%;
}
#results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended #vacancies_extended {
  height: 100%;
}
#results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended #vacancies_extended #sectionvacancies_extended {
  height: 100%;
  overflow: hidden;
  position: relative;
}
#results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended #vacancies_extended #sectionvacancies_extended .cabinet-drawer__scroll-container {
  padding-right: 0rem;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

#focus.in {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
#focus.in.modal {
  z-index: 100000 !important;
}
#focus.in .pane-v2--focus {
  padding: 0 !important;
  left: auto;
  right: auto;
  height: 90% !important;
  width: 75rem !important;
}
#focus.in .focus-description {
  max-width: 46rem;
  overflow: scroll;
}
#focus.in.modal--pane-v2.modal.modal--lg .pane-v2 {
  margin-top: 0 !important;
}

@media (min-width: 768px) {
  .modal--pane-v2.modal.modal--lg .pane-v2 {
    margin-top: 0 !important;
  }
}
.brand--jobdigger-loopbaan .focus--container .focus--actions {
  padding: 4rem 0 !important;
}
.brand--jobdigger-loopbaan .focus--container .focus--actions .focus-save path {
  fill: #9F99B1 !important;
}
.brand--jobdigger-loopbaan .focus--container .focus--actions .focus-save:hover path {
  fill: #1E025F !important;
}
.brand--jobdigger-loopbaan .focus--container .focus--actions .focus-save .action-label-wrap {
  width: 9rem;
}
.brand--jobdigger-loopbaan .focus--container .focus--moderate.pane-section.pane-section--inset.pane-section--expand {
  position: absolute;
  left: 0;
  top: 6.5rem;
  width: 100%;
  padding: 0 2rem !important;
}
.brand--jobdigger-loopbaan .focus--container .focus--moderate.pane-section.pane-section--inset.pane-section--expand #save-vacancy {
  width: 100%;
}
.brand--jobdigger-loopbaan #results .tab-nav .tab-nav__item:nth-child(1) {
  display: initial !important;
}

@media (max-width: 767px) {
  #results .pane-v2-header .tab-nav__container {
    right: 0 !important;
  }
  .tab-nav__item {
    margin-right: 0.25rem !important;
  }
  .pane.pane-results.pane-results--modes > .pane-v2-header {
    display: none !important;
  }
  #results #results-scroll-content.pane-content {
    position: relative;
    top: -8rem;
  }
  #results.results-vacancies #results-scroll-content.pane-content {
    top: -4rem !important;
    height: calc(100vh - 5rem);
  }
  .view-results--focus .focus--container {
    grid-template-rows: auto auto auto auto auto auto !important;
    grid-template-columns: 100% !important;
  }
  .view-results--focus .focus--container .focus--aside {
    grid-column: 1 !important;
  }
  .view-results--focus .focus--header-container {
    height: 8rem !important;
    position: initial !important;
    grid-row: 1;
  }
  .view-results--focus .focus--header-container .focus--options {
    width: 1.4rem !important;
    height: 3.7rem !important;
    position: relative;
    right: 1rem;
  }
  .view-results--focus .focus--header-container .focus--options .action {
    width: 1rem !important;
  }
  .view-results--focus .focus--header-container .focus--options .pane-v2-actions {
    border: none !important;
  }
  .view-results--focus .focus--header-container .focus--header {
    display: initial !important;
    height: 100% !important;
    padding: 0 !important;
  }
  .view-results--focus .focus--header-container .focus--header .focus-title {
    margin-right: 2.5rem;
  }
  .view-results--focus .focus--header-container .focus--header .organisation {
    display: initial !important;
  }
  .view-results--focus .focus--header-container .focus--header .organisation-name {
    padding: 0.5rem 0 !important;
  }
  .view-results--focus .focus--header-container .focus--header .organisation-dropdown {
    top: 2.45rem !important;
    right: 5.5rem !important;
    height: 1rem !important;
    width: auto !important;
    border: none !important;
  }
  .view-results--focus .focus--header-container .focus--header .organisation-property {
    margin-bottom: 0.5rem !important;
  }
  .view-results--focus .focus--actions .action {
    transform: scale(0.9) !important;
  }
  .view-results--focus .focus--actions .action .action-label-wrap {
    width: 100%;
  }
  .view-results--focus .focus--main-content {
    grid-row: 3 !important;
  }
  .view-results--focus .focus--main-content .focus-original {
    display: none;
  }
  .view-results--focus .focus--aside {
    grid-row: 4 !important;
  }
  .view-results--focus .focus-thematchbox {
    grid-row: 5 !important;
  }
  .view-results--focus .focus-location {
    grid-row: 6 !important;
  }
  .action--download {
    display: none;
  }
  .results-focus-open .actions-footer {
    display: none;
  }
}
@media (max-width: 1199px) {
  #results.results-focus-open #results-scroll-content {
    display: none;
  }
  #results.results-focus-open .pane-focus.focus.pane-v2.pane-v2--focus.pane-v2--aside-right {
    grid-column: 1/-1 !important;
    margin-left: 1.5rem !important;
  }
}
.dashboard-only .navbar {
  display: none !important;
}
.dashboard-only #focus-content {
  position: absolute;
  top: 0;
}
.dashboard-only .cabinet-v3__content {
  top: 10rem !important;
}
.dashboard-only .results-meta {
  height: 0 !important;
}
.dashboard-only #results #results-scroll-content.pane-content {
  top: 2rem !important;
  position: relative;
  padding-top: 2rem !important;
  overflow: scroll;
  height: 100% !important;
  display: block !important;
}
.dashboard-only #results.results-vacancies.pane.pane-results {
  top: 0rem !important;
  height: 100% !important;
}
.dashboard-only #results.results-vacancies.pane.pane-results .cabinet-v3__content {
  top: 0rem !important;
}
.dashboard-only #results.results-vacancies.pane.pane-results #results-scroll-content {
  top: 0 !important;
}
.dashboard-only #results.pane.pane-results.pane-results--modes {
  height: 100% !important;
}
.dashboard-only #results-scroll-content {
  display: initial !important;
  top: 4rem;
}
.dashboard-only #results {
  position: absolute !important;
  top: 0rem !important;
}
.dashboard-only #results header.pane-v2-header {
  display: initial !important;
  position: absolute !important;
  width: 100%;
}
.dashboard-only #results header.pane-v2-header .heading-subtitle.pane-v2-header__subtitle {
  margin-left: 0 !important;
}
.dashboard-only #results header.pane-v2-header .tab-nav__container {
  top: 1rem;
  right: 1rem;
  position: fixed;
}
.dashboard-only #results header.pane-v2-header .tab-nav__container .tab-nav {
  left: 0 !important;
}

html.isIframe body #results header.pane-v2-header {
  padding: 0 2.5rem !important;
}
html.isIframe body #results header.pane-v2-header .cabinet--heading {
  font-size: 1.2rem;
  max-width: 50%;
}
html.isIframe body #results header.pane-v2-header .pane-v2-header__subtitle {
  height: 3rem;
  line-height: 3rem;
}
html.isIframe body .career__focus .focus--container .focus--actions {
  padding: 4rem 0 !important;
}
html.isIframe body #results-scroll-content .grid-container__vacancies-extended {
  padding-bottom: 0 !important;
}
html.isIframe body #results-scroll-content .grid-container__vacancies-extended .cabinet--heading {
  line-height: 1.25rem;
  font-size: 1.25rem;
}
html.isIframe body #results-scroll-content .grid-container__vacancies-extended .cabinet-v3__content .cabinet--rows {
  padding: 1rem 1.5rem !important;
}
html.isIframe body #results-scroll-content .grid-container__vacancies-extended .cabinet--rows .cabinet-card {
  padding: 0.25rem 2rem !important;
}
html.isIframe body #results-scroll-content .grid-container__vacancies-extended .cabinet-title h3 {
  font-size: 1.15rem;
}
html.isIframe body #results .tab-nav {
  margin: 0 !important;
  padding: 0 !important;
}
html.isIframe body #results .tab-nav__container {
  height: 4rem !important;
  padding: 0.25rem 0.5rem !important;
}
html.isIframe body #results .tab-nav__container .btn-v3 {
  padding: 0 1rem !important;
  height: 1.5rem;
}
html.isIframe body #results .tab-nav__container .tab-nav__link.active {
  padding: 0.25rem 1rem !important;
}
html.isIframe body #results #results-scroll-content {
  padding-top: 1rem !important;
}
html.isIframe body #results-scroll-content .grid-container__vacancies-extended .cabinet-v3__content {
  padding-bottom: 2.5rem !important;
}
html.isIframe body #results.results-focus-open.pane-results--modes {
  grid-template-rows: 3rem 1fr !important;
}
html.isIframe body #results footer .focus--contact {
  padding-bottom: 0.5rem !important;
}
html.isIframe body#results.results-focus-open.pane-results--modes .pane-focus, html.isIframe body #results.results-focus-open.pane-results--modes .pane-v2--focus, html.isIframe body #focus .pane-focus, html.isIframe body #focus .pane-v2--focus {
  grid-row: 1/3;
  padding: 4rem 1rem 1rem 0 !important;
}
html.isIframe body .focus--contact {
  position: fixed;
  width: calc(100% - 1rem);
}
html.isIframe body .focus--container .focus--actions {
  padding: 0.2rem 0 !important;
}
html.isIframe body .focus--container .focus--actions .action {
  transform: scale(1) !important;
}
html.isIframe body .focus--container {
  grid-template-columns: 1fr 21rem !important;
}
html.isIframe body .focus--container .focus--aside {
  grid-row: 3/5 !important;
  grid-column: 2 !important;
  padding-left: 1rem;
}
html.isIframe body .headroom--not-top, html.isIframe body .results-focus-open {
  top: -3rem !important;
  z-index: 999998 !important;
  height: auto !important;
}
html.isIframe body .pane-v2--organisations_label {
  z-index: 999999 !important;
}
html.isIframe body .actions-footer {
  display: initial !important;
}
html.isIframe body .actions-footer #export-open {
  display: initial !important;
}
html.isIframe body #results.results-focus-open.pane-results--modes.headroom--unpinned {
  top: -2.5rem !important;
}

#results .results-meta {
  z-index: 99999;
}

.view-results--report #results-scroll-content .results-meta {
  margin-top: 1rem;
}

.pane-v2--clients .pane-v2-actions,
.pane-v2--admin .pane-v2-actions {
  position: fixed;
  right: 0;
  bottom: 0;
}

.view-doelgroependashboard.view-results #results.pane-results.pane-results--modes, .view-doelgroependashboard .pane-v2.pane-v2--root,
.account-type--doelgroepdashboard.view-results #results.pane-results.pane-results--modes,
.account-type--doelgroepdashboard .pane-v2.pane-v2--root,
.account-type--carerix_doelgroepdashboard.view-results #results.pane-results.pane-results--modes,
.account-type--carerix_doelgroepdashboard .pane-v2.pane-v2--root,
.account-type--doelgroepdashboard_admin.view-results #results.pane-results.pane-results--modes,
.account-type--doelgroepdashboard_admin .pane-v2.pane-v2--root {
  top: 5rem !important;
  height: calc(100% - 5rem) !important;
}
.view-doelgroependashboard.view-results #results.pane-results.pane-results--modes.headroom--unpinned, .view-doelgroependashboard .pane-v2.pane-v2--root.headroom--unpinned,
.account-type--doelgroepdashboard.view-results #results.pane-results.pane-results--modes.headroom--unpinned,
.account-type--doelgroepdashboard .pane-v2.pane-v2--root.headroom--unpinned,
.account-type--carerix_doelgroepdashboard.view-results #results.pane-results.pane-results--modes.headroom--unpinned,
.account-type--carerix_doelgroepdashboard .pane-v2.pane-v2--root.headroom--unpinned,
.account-type--doelgroepdashboard_admin.view-results #results.pane-results.pane-results--modes.headroom--unpinned,
.account-type--doelgroepdashboard_admin .pane-v2.pane-v2--root.headroom--unpinned {
  top: 0 !important;
  height: 100% !important;
}
.view-doelgroependashboard.view-results #results.pane-results.pane-results--modes.results-focus-open, .view-doelgroependashboard .pane-v2.pane-v2--root.results-focus-open,
.account-type--doelgroepdashboard.view-results #results.pane-results.pane-results--modes.results-focus-open,
.account-type--doelgroepdashboard .pane-v2.pane-v2--root.results-focus-open,
.account-type--carerix_doelgroepdashboard.view-results #results.pane-results.pane-results--modes.results-focus-open,
.account-type--carerix_doelgroepdashboard .pane-v2.pane-v2--root.results-focus-open,
.account-type--doelgroepdashboard_admin.view-results #results.pane-results.pane-results--modes.results-focus-open,
.account-type--doelgroepdashboard_admin .pane-v2.pane-v2--root.results-focus-open {
  top: 2rem !important;
  height: calc(100% - 2rem) !important;
}
.view-doelgroependashboard .navbar__button-container,
.account-type--doelgroepdashboard .navbar__button-container,
.account-type--carerix_doelgroepdashboard .navbar__button-container,
.account-type--doelgroepdashboard_admin .navbar__button-container {
  height: 5rem;
}
.view-doelgroependashboard .react-page,
.account-type--doelgroepdashboard .react-page,
.account-type--carerix_doelgroepdashboard .react-page,
.account-type--doelgroepdashboard_admin .react-page {
  top: 4rem;
  height: calc(100vh - 4rem);
}
.view-doelgroependashboard .view-results .pane-results, .view-doelgroependashboard .view-results .pane-filters, .view-doelgroependashboard .view-results .pane-focus, .view-doelgroependashboard .view-results .pane-index,
.account-type--doelgroepdashboard .view-results .pane-results,
.account-type--doelgroepdashboard .view-results .pane-filters,
.account-type--doelgroepdashboard .view-results .pane-focus,
.account-type--doelgroepdashboard .view-results .pane-index,
.account-type--carerix_doelgroepdashboard .view-results .pane-results,
.account-type--carerix_doelgroepdashboard .view-results .pane-filters,
.account-type--carerix_doelgroepdashboard .view-results .pane-focus,
.account-type--carerix_doelgroepdashboard .view-results .pane-index,
.account-type--doelgroepdashboard_admin .view-results .pane-results,
.account-type--doelgroepdashboard_admin .view-results .pane-filters,
.account-type--doelgroepdashboard_admin .view-results .pane-focus,
.account-type--doelgroepdashboard_admin .view-results .pane-index {
  height: calc(100% - 4rem) !important;
}
.view-doelgroependashboard #quarter_range label,
.account-type--doelgroepdashboard #quarter_range label,
.account-type--carerix_doelgroepdashboard #quarter_range label,
.account-type--doelgroepdashboard_admin #quarter_range label {
  color: #9F99B1;
}
.view-doelgroependashboard .react-page__container,
.account-type--doelgroepdashboard .react-page__container,
.account-type--carerix_doelgroepdashboard .react-page__container,
.account-type--doelgroepdashboard_admin .react-page__container {
  border-radius: 10px;
  padding: 0 !important;
  height: calc(100% - 4rem);
}
.view-doelgroependashboard .react-page__container .react-tooltip,
.account-type--doelgroepdashboard .react-page__container .react-tooltip,
.account-type--carerix_doelgroepdashboard .react-page__container .react-tooltip,
.account-type--doelgroepdashboard_admin .react-page__container .react-tooltip {
  height: initial !important;
}
.view-doelgroependashboard .react-page__container .react-tooltip__text,
.account-type--doelgroepdashboard .react-page__container .react-tooltip__text,
.account-type--carerix_doelgroepdashboard .react-page__container .react-tooltip__text,
.account-type--doelgroepdashboard_admin .react-page__container .react-tooltip__text {
  left: 100%;
}
.view-doelgroependashboard .react-page__container .react-tooltip-tip,
.account-type--doelgroepdashboard .react-page__container .react-tooltip-tip,
.account-type--carerix_doelgroepdashboard .react-page__container .react-tooltip-tip,
.account-type--doelgroepdashboard_admin .react-page__container .react-tooltip-tip {
  z-index: 0;
}
.view-doelgroependashboard .react-page__container.react-grid > div,
.account-type--doelgroepdashboard .react-page__container.react-grid > div,
.account-type--carerix_doelgroepdashboard .react-page__container.react-grid > div,
.account-type--doelgroepdashboard_admin .react-page__container.react-grid > div {
  padding: 35px;
}
.view-doelgroependashboard .react-page__container .react-nav-col__container,
.account-type--doelgroepdashboard .react-page__container .react-nav-col__container,
.account-type--carerix_doelgroepdashboard .react-page__container .react-nav-col__container,
.account-type--doelgroepdashboard_admin .react-page__container .react-nav-col__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #CAC6DC;
  margin: 0 !important;
  overflow-x: clip;
  overflow-y: auto;
  padding: 35px;
  grid-column: 1;
}
.view-doelgroependashboard .react-page__container .react-nav-col__container .nav__button,
.account-type--doelgroepdashboard .react-page__container .react-nav-col__container .nav__button,
.account-type--carerix_doelgroepdashboard .react-page__container .react-nav-col__container .nav__button,
.account-type--doelgroepdashboard_admin .react-page__container .react-nav-col__container .nav__button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-doelgroependashboard .react-page__container .react-button-nav,
.account-type--doelgroepdashboard .react-page__container .react-button-nav,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav {
  background: rgba(255, 255, 255, 0) !important;
}
.view-doelgroependashboard .react-page__container .react-button-nav .react-button-nav__text,
.account-type--doelgroepdashboard .react-page__container .react-button-nav .react-button-nav__text,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav .react-button-nav__text,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav .react-button-nav__text {
  font-weight: 400;
}
.view-doelgroependashboard .react-page__container .react-button-nav:hover,
.account-type--doelgroepdashboard .react-page__container .react-button-nav:hover,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav:hover,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav:hover {
  background: #ffffff !important;
}
.view-doelgroependashboard .react-page__container .react-button-nav:hover .react-icon--label.react-icon__wrapper svg path,
.account-type--doelgroepdashboard .react-page__container .react-button-nav:hover .react-icon--label.react-icon__wrapper svg path,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav:hover .react-icon--label.react-icon__wrapper svg path,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav:hover .react-icon--label.react-icon__wrapper svg path {
  fill: #1E025F;
  stroke: #1E025F;
}
.view-doelgroependashboard .react-page__container .react-button-nav:hover .react-button-nav__text,
.account-type--doelgroepdashboard .react-page__container .react-button-nav:hover .react-button-nav__text,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav:hover .react-button-nav__text,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav:hover .react-button-nav__text {
  color: inherit !important;
  font-weight: 700;
}
.view-doelgroependashboard .react-page__container .react-button-nav.react-button-nav,
.account-type--doelgroepdashboard .react-page__container .react-button-nav.react-button-nav,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav.react-button-nav,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav.react-button-nav {
  height: 2.5rem;
}
.view-doelgroependashboard .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper,
.account-type--doelgroepdashboard .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper {
  background: #ffffff !important;
}
.view-doelgroependashboard .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper svg path,
.account-type--doelgroepdashboard .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper svg path,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper svg path,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav.react-button-nav--active .react-icon--label.react-icon__wrapper svg path {
  fill: #1E025F;
  stroke: #1E025F;
}
.view-doelgroependashboard .react-page__container .react-button-nav.react-button-nav--active .react-button-nav__text,
.account-type--doelgroepdashboard .react-page__container .react-button-nav.react-button-nav--active .react-button-nav__text,
.account-type--carerix_doelgroepdashboard .react-page__container .react-button-nav.react-button-nav--active .react-button-nav__text,
.account-type--doelgroepdashboard_admin .react-page__container .react-button-nav.react-button-nav--active .react-button-nav__text {
  color: inherit !important;
  font-weight: 700;
}
.view-doelgroependashboard .react-page__container .react-icon--label.react-icon__wrapper,
.account-type--doelgroepdashboard .react-page__container .react-icon--label.react-icon__wrapper,
.account-type--carerix_doelgroepdashboard .react-page__container .react-icon--label.react-icon__wrapper,
.account-type--doelgroepdashboard_admin .react-page__container .react-icon--label.react-icon__wrapper {
  background: #ffffff;
}
.view-doelgroependashboard .react-page__container .react-icon--label.react-icon__wrapper svg path,
.account-type--doelgroepdashboard .react-page__container .react-icon--label.react-icon__wrapper svg path,
.account-type--carerix_doelgroepdashboard .react-page__container .react-icon--label.react-icon__wrapper svg path,
.account-type--doelgroepdashboard_admin .react-page__container .react-icon--label.react-icon__wrapper svg path {
  fill: #9F99B1;
  stroke: #9F99B1;
}
.view-doelgroependashboard.view-results--filter .view-results,
.account-type--doelgroepdashboard.view-results--filter .view-results,
.account-type--carerix_doelgroepdashboard.view-results--filter .view-results,
.account-type--doelgroepdashboard_admin.view-results--filter .view-results {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 25vw;
  z-index: 3000000000;
}
.view-doelgroependashboard.view-results--filter .view-results .pane.pane-filters,
.account-type--doelgroepdashboard.view-results--filter .view-results .pane.pane-filters,
.account-type--carerix_doelgroepdashboard.view-results--filter .view-results .pane.pane-filters,
.account-type--doelgroepdashboard_admin.view-results--filter .view-results .pane.pane-filters {
  margin-left: 0;
  top: 0 !important;
  height: 100% !important;
  box-shadow: 0 0 8px -2px #d2d2d2;
  background: #ffffff;
}
.view-doelgroependashboard.view-results--filter .view-results .pane.pane-filters .checkbox .indicator,
.account-type--doelgroepdashboard.view-results--filter .view-results .pane.pane-filters .checkbox .indicator,
.account-type--carerix_doelgroepdashboard.view-results--filter .view-results .pane.pane-filters .checkbox .indicator,
.account-type--doelgroepdashboard_admin.view-results--filter .view-results .pane.pane-filters .checkbox .indicator {
  border: 1px solid #9F99B1;
  border-radius: 5px;
  height: 20px;
  width: 20px;
  background: #ffffff;
  top: 16px;
}
.view-doelgroependashboard.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator,
.account-type--doelgroepdashboard.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator,
.account-type--carerix_doelgroepdashboard.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator,
.account-type--doelgroepdashboard_admin.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator {
  background: white !important;
}
.view-doelgroependashboard.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator::before,
.account-type--doelgroepdashboard.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator::before,
.account-type--carerix_doelgroepdashboard.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator::before,
.account-type--doelgroepdashboard_admin.view-results--filter .view-results .pane.pane-filters .checkbox input:checked ~ .indicator::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 8px;
  border: 1px solid #9F99B1;
  top: 8px;
  left: 9.5px;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  transform: rotateY(180deg) rotateZ(45deg);
}
.view-doelgroependashboard #saveProfileModal,
.account-type--doelgroepdashboard #saveProfileModal,
.account-type--carerix_doelgroepdashboard #saveProfileModal,
.account-type--doelgroepdashboard_admin #saveProfileModal {
  justify-content: center;
  align-items: center;
  background: rgba(30, 2, 95, 0.7333333333);
  z-index: 100000000009;
}
.view-doelgroependashboard #saveProfileModal .modal-dialog,
.account-type--doelgroepdashboard #saveProfileModal .modal-dialog,
.account-type--carerix_doelgroepdashboard #saveProfileModal .modal-dialog,
.account-type--doelgroepdashboard_admin #saveProfileModal .modal-dialog {
  top: initial;
}

.chart__container--samenvatting .chart__view--text {
  grid-column: 1/-1;
  background: #fff;
}

.isIframe .focus--moderator .focus--aside {
  display: none;
}
.isIframe .focus--moderator .focus--main-content {
  grid-column: 1/3 !important;
}

.react-modal--full {
  background-color: #ffffff;
  border-radius: 10px;
  width: calc(100vw - 45px);
  max-width: 100rem;
  height: calc(100vh - 45px);
  overflow: scroll;
  padding: 45px;
}
.react-modal__datapoints-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px;
}
.react-modal__datapoints-container .chart__container {
  margin: 0;
}

.Toastify__toast-container {
  --toastify-z-index: 99999999999;
  --toastify-icon-color-success: #40BC8A;
  --toastify-icon-color-error: #F85C6AFF;
  --toastify-font-family: neuzeit-grotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --toastify-color-progress-success: #40BC8A;
  --toastify-color-progress-error: #F85C6AFF;
}

.usage-monitor__team-selector .react-form-select__control {
  min-width: 15rem;
}
.usage-monitor__layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  height: 100%;
  padding: 10px;
}
.usage-monitor__layout.usage-monitor__dashboard .usage-monitor__dashboard-datapoints {
  grid-column: 3;
  grid-row: 2/4;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
}
.usage-monitor .flex-col {
  display: flex;
  flex-direction: column;
}
.usage-monitor___add-team-form {
  grid-column: 1;
  grid-row: 1;
}
.usage-monitor__add-team-members {
  grid-column: 2;
  grid-row: 2;
}
.usage-monitor__add-team-header, .usage-monitor__manage-team-header {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
}
.usage-monitor__organisation-name {
  grid-column: 1;
  grid-row: 1;
  padding: 20px;
}
.usage-monitor__header {
  grid-column: 1/-1;
  grid-row: 1;
  padding: 20px;
}
.usage-monitor__filters {
  grid-column: 1/-1;
  grid-row: 2;
  background: #F4F6Fa;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.usage-monitor__filters div {
  display: flex;
  align-items: center;
}
.usage-monitor__filters div h4 {
  margin-right: 20px;
}
.usage-monitor__layout .grid-view {
  grid-column: 1/3;
  grid-row: 2/-1;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.usage-monitor__datapoints {
  grid-column: 2/-1;
  grid-row: 3/-1;
  padding: 0 20px 0 20px;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}
.usage-monitor__manage-team-header {
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  justify-content: space-between;
}
.usage-monitor__manage-team {
  display: flex;
  flex-direction: column;
}
.usage-monitor__manage-team .__manage-team-layout {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr 1fr auto;
  height: calc(100vh - 200px);
}
.usage-monitor__manage-team .__manage-team-layout .usage-monitor__manage-team-actions {
  grid-column: 1;
  grid-row: 4;
}
.usage-monitor__manage-team .__manage-team-layout .grid-view__cross-over {
  grid-column: 2;
  grid-row: 2/4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.usage-monitor__manage-team .__manage-team-layout .grid-view__cross-over div.react-svg-icon {
  margin: 20px;
}
.usage-monitor__manage-team-info-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 35px;
  height: auto;
  width: auto;
}
.usage-monitor__manage-team-nonmembers {
  grid-column: 1;
  grid-row: 2/4;
}
.usage-monitor__manage-team-nonmembers .grid-view {
  height: 100%;
}
.usage-monitor__manage-team-members {
  grid-column: 3;
  grid-row: 2/4;
}
.usage-monitor__manage-team-members .grid-view {
  height: 100%;
}
.usage-monitor__manage-team-selector {
  grid-column: 3;
  grid-row: 2;
  position: relative;
  top: 20px;
  width: 55%;
  left: calc(45% - 50px);
  height: fit-content;
}
.usage-monitor__log-layout {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(auto-fill, 30rem);
  height: 100%;
  grid-gap: 10px;
}
.usage-monitor__log-layout .chart__container--stand-alone {
  height: 30rem;
  margin: 0;
}
.usage-monitor__user-log-layout {
  display: grid;
  grid-template-columns: 45% 50%;
  grid-template-rows: repeat(3, 30rem);
  overflow-y: auto;
  overflow-x: auto;
  grid-gap: 20px;
}
.usage-monitor__user-log-layout .chart__counter {
  font-size: 5px;
}
.usage-monitor__no-team {
  grid-column: 1/-1;
  grid-row: 3/-1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.usage-monitor__select-users-modal {
  height: calc(90vh - 100px);
}
.usage-monitor__select-users-modal h1 {
  margin: 10px 0;
}
.usage-monitor__select-users-modal .gridview_container {
  height: calc(100% - 105px);
}
.usage-monitor__select-users-modal .gridview_container .grid-view {
  margin: 0;
}
.usage-monitor__select-users-modal .usage-monitor__manage-team-header .react-modal__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.usage-monitor__tips-and-tricks-modal {
  padding: 35px;
  background: #ffffff;
  border-radius: 10px;
  height: calc(100vh - 100px);
  width: calc(70vw - 100px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 120px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.usage-monitor__tips-and-tricks-modal .btn {
  width: 13rem;
  color: #1E025F;
}
.usage-monitor__tips-and-tricks-modal .btn-next {
  color: #fff;
  width: 13rem;
  border: none;
  border-radius: 10px;
  background-color: #1E025F;
}
.usage-monitor__tips-and-tricks-modal .btn-next.disabled {
  background-color: #9F99B1;
  cursor: not-allowed;
}
@media (max-width: 1024px) {
  .usage-monitor__tips-and-tricks-modal {
    width: calc(100vw - 100px);
  }
}
.usage-monitor__tips-and-tricks-modal ::-webkit-scrollbar {
  display: none;
}
.usage-monitor__tips-and-tricks-modal .my-auto {
  margin: auto 0;
}
.usage-monitor__tips-and-tricks-modal .mx-auto {
  margin: 0 auto;
}
.usage-monitor__tips-and-tricks-modal .actions {
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 35px;
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: space-between;
}
.usage-monitor__tips-and-tricks-modal .content {
  border: 1px solid #9F99B1;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-around;
}
.usage-monitor__tips-and-tricks-modal .content.checked {
  border: 1px solid #1E025F;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.usage-monitor__tips-and-tricks-modal .content ul {
  list-style-type: disc;
  margin-left: 20px;
}
.usage-monitor__tips-and-tricks-modal .content ol {
  list-style-type: decimal;
  margin-left: 20px;
}
.usage-monitor__tips-and-tricks-modal .content input[type=checkbox] {
  border: 1px solid #1E025F;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  appearance: auto;
  accent-color: #1E025F;
  margin-top: auto;
}
.usage-monitor__tips-and-tricks-modal .content input[type=checkbox] input[type=checkbox]:checked {
  background: #1E025F;
}
.usage-monitor__tips-and-tricks-modal .tip-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  padding: 20px 0;
  overflow-y: auto;
}
.usage-monitor__tips-and-tricks-modal .bullet-list {
  appearance: auto;
  list-style-type: disc;
  list-style-position: inside;
}
.usage-monitor__share-tips-and-tricks-modal {
  padding: 35px;
  background: #ffffff;
  border-radius: 10px;
  height: calc(100vh - 100px);
  width: calc(70vw - 100px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 120px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@media (max-width: 1024px) {
  .usage-monitor__share-tips-and-tricks-modal {
    width: calc(100vw - 100px);
  }
}
.usage-monitor__share-tips-and-tricks-modal ::-webkit-scrollbar {
  display: none;
}
.usage-monitor__share-tips-and-tricks-modal .my-auto {
  margin: auto 0;
}
.usage-monitor__share-tips-and-tricks-modal .mx-auto {
  margin: 0 auto;
}
.usage-monitor__share-tips-and-tricks-modal .actions {
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 35px;
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: space-between;
}
.usage-monitor__share-tips-and-tricks-modal .actions .back-btn {
  color: #1E025F;
  width: 13rem;
  border-radius: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .actions .send-mails-btn {
  color: #ffffff;
  background: #40bc8a;
  width: 13rem;
  border-radius: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .actions .send-mails-btn svg {
  fill: #ffffff;
  margin-left: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs {
  overflow-y: auto;
  overflow-x: hidden;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-around;
  margin-bottom: 10px;
  border: 1px solid #1E025F;
  border-radius: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .content ul {
  list-style-type: disc;
  margin-left: 20px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .content ol {
  list-style-type: decimal;
  margin-left: 20px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .bullet-list {
  appearance: auto;
  list-style-type: disc;
  list-style-position: inside;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-input-label {
  width: 20%;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mailto-list {
  display: flex;
  padding: 5px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mailto-list .mailto-receivers {
  width: 100%;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  max-height: 6rem;
  overflow-y: auto;
  min-height: 3.2rem;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mailto-list .mailto-item {
  padding: 2px 10px;
  border-radius: 20px;
  background-color: #443469;
  color: white;
  display: flex;
  height: 2rem;
  margin-left: 1rem;
  margin-top: 5px;
  margin-bottom: 5px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mailto-list .mailto-item .mailto-item-icon {
  display: flex;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mailto-list .mailto-item .mailto-item-name {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  margin: auto 0;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-subject {
  display: flex;
  align-content: center;
  padding: 5px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-input {
  width: 100%;
  margin-left: 1rem;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 10px;
  margin-top: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-content h3 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-content p {
  margin-bottom: 0;
  width: 100%;
  margin-left: 1rem;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-content .mail-greeting {
  display: flex;
  align-content: center;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-content .mail-greeting textarea {
  border: 1px solid #9F99B1;
  padding: 10px;
  min-height: 14rem;
  resize: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 1rem;
  overflow: auto;
  width: 100%;
}
.usage-monitor__share-tips-and-tricks-modal .mail-inputs .mail-sender {
  display: flex;
  align-content: center;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.support--layout {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
}
.support--layout h1 {
  margin: 20px;
}
.support--layout .tip-selector {
  width: auto;
  display: flex;
  margin: 0 20px 20px;
}
.support--layout .tip-selector .tips {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #1E025F;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
}
.support--layout .tip-selector .tips .tip-item {
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
}
.support--layout .tip-selector .tips .tip-item p {
  margin: 0;
}
.support--layout .tip-selector .tips .tip-item:hover {
  background: #E4E4EC;
}
.support--layout .tip-selector .tips .selected {
  background: #1E025F;
  color: white;
}
.support--layout .tip-selector .tips .selected:hover {
  background: #1E025F;
}
.support--layout .tip-selector .selected-tip-view {
  width: 50%;
  background: #F4F6Fa;
  border-radius: 10px;
}
.support--layout .tip-selector .content {
  padding: 20px;
  height: 100%;
}
.support--layout .help-and-support-container {
  margin: 0 20px 20px;
  height: fit-content;
}
.support--layout .help-and-support-container .chart__container--stand-alone {
  width: auto;
  height: fit-content;
}
.support--layout .help-and-support-container .quickstart-item .colored {
  background: #E4E4EC;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .mobile--invisible {
    display: none !important;
  }
}
.module-uwv .select2-container--krajee .select2-selection {
  padding: 0 !important;
}
.module-uwv .strip .flickity-viewport {
  height: 60px !important;
}
.module-uwv .uwv-dashboard__grid-container {
  display: grid;
  width: 100%;
  height: auto;
  overflow: hidden;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: auto auto auto;
}
.module-uwv #uwv-dashboard__vacancies-table {
  cursor: pointer;
  width: calc(100% - 8rem);
  left: -5rem;
  position: relative;
  grid-row: 1/3;
  grid-column: 1/3;
}
.module-uwv #uwv-dashboard__vacancies-table table {
  table-layout: fixed;
}
.module-uwv #uwv-dashboard__vacancies-table table td,
.module-uwv #uwv-dashboard__vacancies-table table h2, .module-uwv #uwv-dashboard__vacancies-table table p {
  font-size: 1rem !important;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="0"] {
  width: 25% !important;
  padding-right: 1rem;
  text-align: right;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="1"] {
  width: 33% !important;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="1"] .uwv-dashboard__vacancy-title {
  white-space: pre;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="2"] {
  width: 32% !important;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="2"] .uwv-dashboard__vacancy-title {
  white-space: pre;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="3"] {
  width: 10% !important;
  padding: 0 !important;
  overflow: visible;
}
.module-uwv #uwv-dashboard__vacancies-table--content [data-col-seq="3"] .uwv-dashboard__probability-range {
  text-align: right;
}
.module-uwv .uwv-chart__top-right {
  grid-row: 1;
  grid-column: 3;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__top-right div {
  border: 0.5rem solid #fff;
}
.module-uwv .uwv-chart__mid-right {
  grid-row: 2;
  grid-column: 3;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__mid-right div {
  border: 0.5rem solid #fff;
}
.module-uwv .uwv-chart__bottom-right {
  grid-row: 3;
  grid-column: 2/4;
  margin: 1rem 0;
  padding: 0 1rem;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__bottom-right div {
  border: 0.5rem solid #fff;
}
.module-uwv .uwv-chart__bottom-left {
  grid-row: 3;
  grid-column: 1;
  margin: 1rem 0;
  padding: 0 1rem;
  padding: 0.5rem;
}
.module-uwv .uwv-chart__bottom-left div {
  border: 0.5rem solid #fff;
}
.module-uwv .vacanciesList {
  cursor: pointer;
}
.module-uwv .actions {
  position: fixed;
}
.module-uwv [name="VacancyClassificationSearch[VacancyTitle]"],
.module-uwv .uwv__filter,
.module-uwv [aria-labelledby=select2-vacancyclassificationsearch-steekproefnummer-container],
.module-uwv .select2-container--krajee .select2-selection--single,
.module-uwv .sla-option {
  border-radius: 0 !important;
}
.module-uwv .sla-chart-container {
  position: relative;
  padding: 2rem 0;
}
.module-uwv .sla-chart-container .highcharts-title {
  position: absolute !important;
  top: -2rem !important;
  width: 100% !important;
  text-align: center !important;
}
.module-uwv .sla-option {
  width: 10rem !important;
  display: inline-block;
}
.module-uwv .sla-option-container {
  position: absolute;
  top: 0;
  left: 0;
}
.module-uwv .uwv-grid__text {
  margin: 0;
  white-space: pre;
  padding-right: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.module-uwv .search_container-boc-isco4-layer5 {
  width: 25%;
  box-sizing: border-box;
  height: auto;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
}
.module-uwv .search-buttons__container {
  width: auto;
  position: absolute;
  top: 0;
  right: 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}
.module-uwv .btn--uwv-option {
  display: inline-block;
  width: 4.2rem;
  height: 2rem;
  margin-left: 0.2rem !important;
  text-align: center;
  line-height: 0.4rem;
}
.module-uwv .uwv__filter-betrouwbaarheid-container {
  position: absolute;
  top: 0;
}
.module-uwv .uwv__filter-betrouwbaarheid-text {
  display: inline-block;
  width: 15%;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
.module-uwv .uwv__filter-betrouwbaarheid-input {
  display: inline-block;
  width: 35%;
  padding-left: 0.5rem;
  padding-right: 0;
  text-align: right;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container {
  line-height: 3rem;
}
.module-uwv .btn-close-small {
  position: absolute;
  top: 0.9rem !important;
  right: 1rem !important;
  color: #fff !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  border-radius: 1000px !important;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
}
.module-uwv [aria-labelledby=select2-vacancyclassificationsearch-steekproefnummer-container] .select2-selection__arrow {
  visibility: hidden;
}
.module-uwv #vacanciesList-container td {
  position: relative;
}
.module-uwv #vacanciesList-container th {
  text-align: left;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container .select2-search__field {
  background-color: rgba(0, 0, 0, 0) !important;
  height: 48px;
  width: 100%;
  padding: 16px;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container {
  position: absolute;
  top: -0.4rem;
  padding: 0;
  left: 1rem;
}
.module-uwv #vacanciesList-container table,
.module-uwv #vacanciesList-skeleton table {
  table-layout: fixed;
}
.module-uwv #vacanciesList-container [data-col-seq="0"],
.module-uwv #vacanciesList-skeleton [data-col-seq="0"] {
  width: 9% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="1"],
.module-uwv #vacanciesList-skeleton [data-col-seq="1"] {
  width: 30% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="2"],
.module-uwv #vacanciesList-skeleton [data-col-seq="2"] {
  width: 28% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="3"],
.module-uwv #vacanciesList-skeleton [data-col-seq="3"] {
  width: 12% !important;
}
.module-uwv #vacanciesList-container [data-col-seq="4"],
.module-uwv #vacanciesList-skeleton [data-col-seq="4"] {
  width: 9% !important;
  text-align: center;
}
.module-uwv #vacanciesList-container [data-col-seq="5"],
.module-uwv #vacanciesList-skeleton [data-col-seq="5"] {
  width: 13% !important;
}
.module-uwv .vacanciesList .indicator-progress {
  width: 65%;
}
.module-uwv .random-vacancies-container {
  position: relative;
  height: auto;
}
.module-uwv .random-vacancies {
  width: 20rem;
  height: auto;
}
.module-uwv .random-vacancies__form {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  flex-direction: column;
}
.module-uwv .btn--random-vacancies {
  border: 2px solid #96989C;
  margin-top: 1rem;
}
.module-uwv .toggle-vacancy-menu {
  width: auto;
  position: absolute;
  left: 0;
  border: 2px solid #000;
}
.module-uwv .btn__reset-form,
.module-uwv .select2-selection__clear {
  position: absolute !important;
  top: 0.9rem !important;
  right: 2.5rem !important;
  background-color: #666 !important;
  color: #fff !important;
  width: 1.2rem !important;
  height: 1.2rem !important;
  border-radius: 1000px !important;
  line-height: 1rem !important;
  text-align: center !important;
  cursor: pointer !important;
}
.module-uwv .btn__reset-form::before,
.module-uwv .select2-selection__clear::before {
  content: "x";
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.module-uwv .btn__reset-form:hover,
.module-uwv .select2-selection__clear:hover {
  background-color: #000 !important;
}
.module-uwv #select2-vacancyclassificationsearch-bocclassificationcode-container .select2-selection__clear {
  position: relative !important;
  right: 0rem !important;
}
.module-uwv .form-get-steekproeven {
  width: 25rem;
}
.module-uwv #vacancyclassificationsearch-manualclassificationjudgment ~ .select2-container--krajee .select2-selection__arrow {
  background-image: none !important;
}
.module-uwv .manual-judgment__correction::after {
  content: "!";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
}

.btn__next-small {
  text-align: center;
  border-radius: 1000px;
  box-shadow: none;
  padding: 0.5rem 1rem;
}

#vacancyPopupModal [type=checkbox] {
  -webkit-appearance: checkbox;
}
#vacancyPopupModal .modal-content {
  max-height: 90vh;
  overflow-y: scroll;
}
#vacancyPopupModal .modal-footer {
  text-align: left !important;
}
#vacancyPopupModal .btn-danger {
  border-color: #ff4444;
}
#vacancyPopupModal .vacancy-judgement .btn,
#vacancyPopupModal .vacancy-judgement form {
  display: inline-block;
}
#vacancyPopupModal .select2-selection__rendered {
  text-align: left;
}
#vacancyPopupModal .select2.select2-container.select2-container--krajee {
  width: 100% !important;
}
#vacancyPopupModal .focus-property-description-decision::first-letter {
  text-transform: uppercase;
}
#vacancyPopupModal .uwv-form form {
  width: 100%;
}
#vacancyPopupModal .uwv-form .focus-property-term {
  text-align: left !important;
}
#vacancyPopupModal .uwv-form--button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
#vacancyPopupModal .uwv-form--button-container .btn {
  margin-left: 0 !important;
  width: 100% !important;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment #oknokform-judgment {
  display: flex;
  justify-content: left;
  align-items: center;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio {
  text-align: left;
  margin-right: 1rem;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio label {
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio:nth-of-type(1) label {
  background-color: #C5D937;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment .radio:nth-of-type(2) label, #vacancyPopupModal .uwv-form .field-oknokform-judgment .radio:nth-of-type(3) label {
  background-color: #D43636;
}
#vacancyPopupModal .uwv-form .field-oknokform-judgment input {
  appearance: auto;
  width: 1rem;
  height: 1rem;
}
#vacancyPopupModal .uwv-form .select2-selection__choice {
  max-width: calc(100% - 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
#vacancyPopupModal .uwv-form .select2-selection__choice .select2-selection__choice__remove {
  position: relative;
  bottom: 2px;
}

.advanced-search-container {
  position: relative;
  height: 4rem;
}
.advanced-search-container .form-advanced-search {
  position: absolute;
  left: 0;
  display: flex;
}
.advanced-search-container .form-advanced-search > .form-group {
  display: flex;
  position: relative;
}
.advanced-search-container .form-advanced-search > .form-group > .form-control {
  background-color: #F6F8FC;
  width: 30rem;
  border-color: #bbb;
  margin-right: 2rem;
}
.advanced-search-container .form-advanced-search > .form-group > .form-control:hover {
  border-color: #443469;
}
.advanced-search-container #advancedsearchfield {
  display: inline-block;
}
.advanced-search-container .form-advanced-search > .form-group > .btn-primary {
  width: 5rem;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
}
.advanced-search-container .reset_form {
  position: absolute;
  cursor: pointer;
  background-color: #5E529F !important;
  font-weight: bold;
  color: #fff !important;
  transition: all 0.3s;
  right: 1rem !important;
}
.advanced-search-container .reset_form:hover {
  background-color: #1E025F !important;
}

#select2-vacancyclassificationsearch-manualclassificationjudgment-results li {
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#select2-vacancyclassificationsearch-manualclassificationjudgment-results li::after, #select2-vacancyclassificationsearch-manualclassificationjudgment-results li::before {
  content: "";
  height: 90%;
  margin: 0.5rem 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999;
}

[aria-labelledby=select2-vacancyclassificationsearch-manualclassificationjudgment-container] {
  padding: 0 !important;
}

.review-ok-nok-btn-container,
#review-btn-container {
  display: flex;
}

.brand--uwv .select2-container--krajee .select2-selection__choice {
  border: 1px solid #666 !important;
}

input.sladatatype,
#show_eval {
  -webkit-appearance: checkbox;
}

#uwvSlaExport {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 1rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 8px -2px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#uwvSlaExport .icon-container.icon-container--download.icon-container--lg {
  margin-right: 0 !important;
  width: 2rem !important;
}

.uwv__chart-box {
  background: #fff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  padding: 2rem;
  margin-bottom: 2rem;
}

.brand--carerix .search-v3__container--free-entry .search-keys,
.brand--carerix .search-v3__container--location #location-autocomplete,
.brand--carerix .btn-v3,
.brand--carerix .react-button,
.brand--carerix .search-v3 .search-keys.search-keys--selectize .selectize-input .search-key .search-key-label,
.brand--carerix .search-v3__text-input input.form-control, .brand--carerix .search-v3__text-input input.pane-filter-input, .brand--carerix .search-v3__text-input .form-group .select2.select2-container .selection .select2-selection, .brand--carerix .search-v3__select input.form-control, .brand--carerix .search-v3__select input.pane-filter-input, .brand--carerix .search-v3__select .form-group .select2.select2-container .selection .select2-selection,
.brand--carerix .search-v3__query-container select, .brand--carerix .search-v3__query-container input, .brand--carerix .search-v3__query-container .form-group .select2.select2-container .selection .select2-selection,
.brand--carerix .form.form--mute .form-control,
.brand--carerix .profile-toolbar__container .profile-toolbar__input select,
.brand--carerix .search-v3__container--location .icon-container.icon-container--location,
.brand--carerix .page-pane,
.brand--carerix .react-form__input, .brand--carerix .react-form-select__control, .brand--carerix .react-form .react-form-select__control,
.brand--carerix .chart__container {
  border-radius: 4px !important;
}
.brand--carerix p {
  color: rgb(66, 82, 110);
}
.brand--carerix .search-v3__button {
  background: rgb(235, 236, 240) !important;
}
.brand--carerix .btn-v3--secondary {
  background-color: rgb(0, 107, 199) !important;
}
.brand--carerix .react-button--tab-nav,
.brand--carerix .search-v3__item span {
  color: rgb(0, 107, 199) !important;
}
.brand--carerix .react-button.react-button--tab-nav-active,
.brand--carerix .react-button--nav {
  color: rgb(0, 107, 199) !important;
  border-color: rgb(0, 107, 199) !important;
}
.brand--carerix .cabinet-v3__content .cabinet--rows {
  padding: 0 !important;
  border-radius: 4px !important;
}
.brand--carerix #results-scroll-content .grid-container .cabinet-v3__container #id-pjax-vacancies_extended #vacancies_extended #sectionvacancies_extended .cabinet-drawer__scroll-container {
  margin-right: 0rem !important;
}
.brand--carerix .profile-toolbar.profile-toolbar__container {
  background: rgb(223, 225, 229) !important;
}
.brand--carerix #select_profile {
  background: #fff !important;
}
.brand--carerix .react-dropdown .react-svg-icon-theme--light svg circle {
  fill: rgb(107, 119, 140) !important;
}
.brand--carerix .action-bar__cabinet-grid-row .action__button.action--accent-secondary .icon-wrap svg path,
.brand--carerix .action-bar__cabinet-grid-row .action__button:hover .icon-wrap svg path {
  fill: rgb(66, 82, 110) !important;
}
.brand--carerix .search-v3__container--location .icon-container.icon-container--location svg path {
  fill: rgb(0, 107, 199) !important;
  stroke: rgb(0, 107, 199) !important;
}
.brand--carerix .react-svg-icon-theme--tab-nav-active {
  fill: rgb(0, 107, 199) !important;
}
.brand--carerix .profile-toolbar.profile-toolbar__container {
  width: 100% !important;
}
.brand--carerix .cabinet-matchbox.btn.btn--label,
.brand--carerix .organisation-property.btn--label__icon.cabinet-link,
.brand--carerix .organisation-label-modal-show.react-button,
.brand--carerix .organisation-label {
  background: rgb(137, 147, 164) !important;
}
.brand--carerix .cabinet-v3__container #id-pjax-vacancies_extended .cabinet-matchbox-alert,
.brand--carerix .organisation-label--colleague-only,
.brand--carerix .organisation-label.organisation-label__inset--left::before {
  background: rgb(41, 158, 0) !important;
}
.brand--carerix .search-v3__container--free-entry #search-input-selectized::placeholder {
  color: rgb(66, 82, 110) !important;
}

.react-content__header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.react-actions__container {
  display: flex;
}
.react-actions__container > * {
  margin-left: 10px;
  position: relative;
  z-index: 1;
}

.react-grid {
  display: grid;
  grid-template-columns: repeat(16, 6.25%);
}

.react-col-1 {
  grid-column: span 1;
}
.react-col-2 {
  grid-column: span 2;
}
.react-col-3 {
  grid-column: span 3;
}
.react-col-4 {
  grid-column: span 4;
}
.react-col-5 {
  grid-column: span 5;
}
.react-col-6 {
  grid-column: span 6;
}
.react-col-7 {
  grid-column: span 7;
}
.react-col-8 {
  grid-column: span 8;
}
.react-col-9 {
  grid-column: span 9;
}
.react-col-10 {
  grid-column: span 10;
}
.react-col-11 {
  grid-column: span 11;
}
.react-col-12 {
  grid-column: span 12;
}
.react-col-13 {
  grid-column: span 13;
}
.react-col-14 {
  grid-column: span 14;
}
.react-col-15 {
  grid-column: span 15;
}

.flex {
  display: flex;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-center {
  justify-content: center;
}
.flex-align-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}

.align-text-center {
  text-align: center;
}

.border {
  border-color: #9F99B1;
  border-style: solid;
  border-width: 0;
}
.border-thin {
  border-width: 1px;
}
.border-thin-top {
  border-top-width: 1px;
}
.border-regular {
  border-width: 2px;
}
.border-regular-top {
  border-top-width: 2px;
}
.border-thick {
  border-width: 4px;
}
.border-light {
  border-color: #E4E4EC;
}

.react-sort {
  display: flex;
}
.react-sort--header {
  margin-right: 10px;
}
.react-sort__icon {
  position: absolute;
  right: -1px;
  top: 0.5px;
  width: 35px;
  height: 35px;
  background: #9F99B1;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
}
.react-sort .btn-v3--icon {
  width: 10rem;
  text-align: left;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.charts__container {
  display: grid;
  position: relative;
  left: -20px;
  width: calc(100% + 20px * 2);
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
}
.charts__container .LazyLoad {
  max-height: 40rem;
  min-height: 25rem;
}
.charts__container .LazyLoad.chart__view--wide {
  max-height: 40rem;
  min-height: auto;
  grid-column: 1/-1;
}

@media only screen and (max-width: 1100px) {
  .charts__container {
    grid-template-columns: 1fr;
  }
}
.charts__page {
  height: 100%;
}

.chart__container {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 4.3125rem 22.5rem 2rem;
  height: 29rem;
  border: 1px solid #CAC6DC;
  margin: 20px;
  border-radius: 10px;
}
.chart__container--list5, .chart__container--list10 {
  grid-template-columns: 100%;
}
.chart__container--samenvatting {
  padding: 20px;
  margin: 20px 0;
  height: fit-content;
  grid-template-columns: auto;
  grid-template-rows: auto;
}
.chart__container--samenvatting .chart__samenvatting--plain-text {
  column-count: 2;
  grid-column: 1/-1;
}
.chart__container--samenvatting ul {
  margin: 0;
}
.chart__container--samenvatting .chart__header {
  grid-column: 1/-1;
}
.chart__container--samenvatting .chart__view {
  display: initial;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: auto;
  grid-column: 1/-1;
  min-height: fit-content;
}
.chart__container--samenvatting .chart__view--left {
  grid-column: 1;
  height: fit-content;
}
.chart__container--samenvatting .chart__view--right {
  grid-column: 2;
  height: fit-content;
}
.chart__container--samenvatting .chart__view--wide {
  grid-column: 1/3;
  height: fit-content;
}
.chart__header {
  position: relative;
  display: grid;
  grid-template-columns: auto max-content;
  padding: 20px;
  border-bottom: 1px solid #CAC6DC;
  align-content: center;
}
.chart__header h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chart__modal {
  width: calc(100vw - 45px * 2);
  height: calc(100vh - 45px * 2);
  background: #ffffff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  position: fixed;
  z-index: 3000000000;
  top: 0;
  left: 0;
  margin: 45px !important;
  grid-template-rows: 4.3125rem 1fr 6rem;
}
.chart__actions {
  height: 100%;
  display: flex;
  align-items: center;
}
.chart__actions > div {
  cursor: pointer;
}
.chart__tooltip {
  position: absolute;
  width: 24rem;
  top: 3rem;
  right: 0;
  padding: 20px;
  border-radius: 10px;
  background: rgba(30, 2, 95, 0.7333333333);
  z-index: 1;
  color: #ffffff;
  backdrop-filter: blur(3px);
}
.chart__view {
  padding: 10px;
}
.chart__view--fade-in {
  animation: fadeIn 0.75s;
}
.chart__view--kpi {
  padding: 0;
}
.chart__view--text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 95%;
  width: 100%;
  padding: 45px;
  background-color: #F4F6Fa;
}
.chart__view--text h1 {
  margin-bottom: 0;
  font-weight: 600;
  color: #443469;
  font-size: 30pt;
  line-height: 35pt;
}
.chart__view--text h3 {
  color: #1E025F;
  font-weight: 700;
}
.chart__view--barspreadhorizontal {
  height: 100%;
  display: flex;
  align-items: center;
}
.chart__view--double {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.chart__view--double .chart__view--small-container {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 2rem;
  grid-template-columns: 1fr;
  width: 40%;
  height: 10rem;
  margin: 0.5rem;
  background: #F4F6Fa;
  padding: 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.chart__view--double .chart__view--small-container .react-svg-icon__candidate, .chart__view--double .chart__view--small-container .chart__view--mini-waffle {
  grid-row: 1;
}
.chart__view--double .chart__view--small-container h3 {
  grid-row: 2;
  justify-self: center;
}
.chart__view--double.chart__view--export {
  justify-content: space-between;
}
.chart__view--double.chart__view--export .chart__view--small-container {
  width: 50%;
  height: 12rem;
  margin-bottom: 15mm;
}
.chart__view--list {
  width: 100%;
  padding: 10px 20px;
}
.chart__view--list .chart__list-item {
  display: grid;
  grid-template-columns: 35px minmax(0, 1fr) auto auto auto;
  background: #F4F6Fa;
  align-items: center;
  height: 1.8rem;
  width: 100%;
  margin-bottom: 2px;
  padding-right: 20px;
}
.chart__view--list .chart__list-item--checkered {
  height: 45px !important;
}
.chart__view--list .chart__list-item--checkered:nth-child(odd) {
  background-color: #F4F6Fa;
}
.chart__view--list .chart__list-item--checkered:nth-child(even) {
  background-color: #ffffff;
}
.chart__view--list .chart__list-item--media {
  grid-template-columns: minmax(0, 1fr) auto auto auto;
}
.chart__view--list .chart__list-item--count {
  white-space: nowrap;
  padding-right: 20px;
  text-decoration: underline;
  cursor: pointer;
}
.chart__view--list .chart__list-item.chart__list--10 {
  height: 20px;
}
.chart__view--list .chart__list-item h2, .chart__view--list .chart__list-item h3 {
  margin: 0;
}
.chart__view--list .chart__list-item-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  background: #E4E4EC;
}
.chart__view--list .chart__list-item-header.chart__list--10 {
  height: 20px;
  width: 20px;
}
.chart__view--list .chart__list-item-header.chart__list--10 h4 {
  line-height: 20px;
}
.chart__view--list .chart__list-item-header h2, .chart__view--list .chart__list-item-header h3 {
  width: 35px;
  text-align: center;
  margin: 0;
  color: #1E025F;
  font-weight: 400;
}
.chart__view--list .chart__list-item-text {
  padding: 0 20px;
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chart__view--list .chart__list-item-text .chart__list-item--count {
  position: absolute;
  right: 1rem;
  top: 0;
}
.chart__view--list .chart__list-item-text h3, .chart__view--list .chart__list-item-text h2, .chart__view--list .chart__list-item-text h4, .chart__view--list .chart__list-item-text em {
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
}
.chart__view--list .chart__list-item-text h2, .chart__view--list .chart__list-item-text h3 {
  font-weight: 400;
}
.chart__view--list .chart__list-item-text.chart__list--10 h4 {
  line-height: 20px;
  font-weight: 400;
}
.chart__view--list.chart__view--export {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  top: -1mm;
  padding: 10px 20px;
}
.chart__view--list.chart__view--export .chart__list-item h2 {
  font-size: 1.5rem;
}
.chart__view--list.chart__view--export .chart__list-item h3 {
  font-size: 1.25rem;
}
.chart__view--linechart {
  flex-direction: column;
}
.chart__view--linechart.pdf-export__chart-container {
  height: 65mm;
}
.chart__view--linechart .amchart__container {
  height: 100%;
  padding-top: 5px;
}
.chart__view--linechart .amchart__container.amcharts__container--has-legend {
  height: 82%;
}
.chart__view--linechart .amchart__container--export {
  width: 100%;
}
.chart__view--linechart .amchart__legend-toggle {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}
.chart__view--linechart .linechart__legend {
  position: relative;
  width: 100%;
  height: 4rem;
}
.chart__view--linechart .linechart__legend--open {
  height: 10rem;
  background: #ffffff;
  border: 1px solid #CAC6DC;
  border-radius: 5px;
}
.chart__view--waffle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.chart__view--mini-waffle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 10px;
  width: 100%;
}
.chart__view--mini-waffle .react-icon {
  display: flex;
  justify-content: center;
}
.chart__view--barhorizontal {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}
.chart__view--barhorizontal .amchart__container {
  position: relative;
  top: 10px;
  height: 107%;
  width: 100%;
  min-height: 20rem;
  max-height: 30rem;
}
.chart__view--barhorizontal .chart__footer {
  padding-top: 0rem;
}
.chart__view--barspreadhorizontal .amchart__container {
  width: 100%;
  height: 80%;
}
.chart__view--barspreadhorizontal .amchart__container--export {
  width: 100%;
  height: 65mm;
}
.chart__view--barvertical {
  display: flex;
  justify-content: center;
}
.chart__view--candidatevacancies.pdf-export__chart-container {
  height: 65mm;
}
.chart__view--candidatevacancies.pdf-export__chart-container h3 {
  color: #1E025F;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}
.chart__view--candidatevacancies.pdf-export__chart-container .react-svg-icon {
  position: relative !important;
}
.chart__view--candidatevacancies.pdf-export__chart-container .react-svg-icon--size-xl {
  width: 45px !important;
  height: 45px !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  grid-column: 1;
  justify-self: center;
}
.chart__view--map {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 20px;
}
.chart__footer {
  padding: 5px 20px 0 20px;
  grid-row: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  overflow: clip;
}
.chart__footer p {
  margin-bottom: 10px;
}
.chart__footer footer.audience-credits {
  padding: 0;
  margin-bottom: 10px;
  border: none;
}
.chart__dummy-item {
  opacity: 0.5;
}

.chart__container--stand-alone {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 45px calc(100% - 45px);
  border: 1px solid #CAC6DC;
  margin: 10px;
  border-radius: 10px;
}
.chart__container--stand-alone .chart__header--stand-alone {
  grid-row: 1;
  padding: 10px 20px;
  border-bottom: 1px solid #CAC6DC;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chart__container--stand-alone .chart__view--stand-alone {
  width: 100%;
  height: 100%;
  grid-row: 2;
  padding: 10px;
}
.chart__container--stand-alone .chart__view--stand-alone .chart__list-item {
  height: 35px;
}
.chart__container--stand-alone .chart__view--stand-alone .chart__view--list {
  height: 100%;
  overflow-y: scroll;
}
.chart__container--stand-alone .chart__view--stand-alone .chart__view--list-with-footer {
  height: calc(100% - 4rem);
}

.amchart__container {
  height: 100%;
}
.amchart__container--square {
  width: 500px;
}

.react-loader__datapoint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chart__container--samenvatting .react-loader__datapoint {
  grid-row: 1/-1;
  grid-column: 1/-1;
}

.chart__view--export {
  width: 100%;
}
.chart__view--export.chart__view--small-container {
  width: 50%;
}
.chart__view--export.chart__view--small-container [class*=icon--] {
  width: initial;
  height: initial;
  position: relative;
}

.react-dropdownmenulabel {
  position: relative;
}

.dropdown-v3 {
  position: absolute;
  z-index: 100000;
  top: 0.5rem;
  padding: 1rem 0;
  background-color: #fff;
  border: 1px solid #E4E4EC;
}

.react-close {
  width: 3rem;
  text-align: center;
  height: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
}

ul {
  list-style: none;
}

.react-dropdown {
  position: relative;
  display: inline-block;
}
.react-dropdown-button {
  display: flex;
}
.react-dropdown--position-left {
  right: 0;
}
.react-dropdown__container {
  position: absolute;
  width: fit-content;
  background-color: #fff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  z-index: 999999999;
  top: calc(100% + 0.5rem);
}
.react-dropdown__container--padding {
  padding: 20px;
}
.react-dropdown__header {
  display: flex;
  justify-content: space-between;
}
.react-dropdown__title {
  padding: 0.5rem 2rem;
}
.react-dropdown__items {
  list-style: none;
  padding: 5px 0;
  margin-bottom: 0 !important;
}
.react-dropdown__tab-header {
  display: flex;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #9F99B1;
}
.react-dropdown__tab-header h4 {
  font-weight: normal;
  margin-right: 1rem;
  cursor: pointer;
}
.react-dropdown__tab--active {
  font-weight: bold !important;
}
.react-dropdown__input-container {
  padding: 20px 20px 5px 20px;
  min-width: 15rem;
}
.react-dropdown__input-container .react-form__input {
  height: 35px;
}
.react-dropdown__item {
  display: flex;
  position: relative;
  padding: 5px 20px;
  justify-content: flex-start;
  align-items: center;
}
.react-dropdown__item:hover {
  background-color: #F4F6Fa;
  cursor: pointer;
}
.react-dropdown__item--active em {
  font-weight: bold;
}
.react-dropdown__item--active .react-checkbox__container + em {
  font-weight: normal;
}
.react-dropdown__item em {
  white-space: nowrap;
}
.react-dropdown__item em .react-dropdown__item--active {
  font-weight: bold;
}
.react-dropdown__item--date {
  white-space: nowrap;
}
.react-dropdown__item::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0);
}

.organisations-filter .react-dropdown__container {
  max-height: calc(100vh - 15rem);
}
.organisations-filter .react-dropdown__container .react-dropdown__items-container {
  max-height: calc(100vh - 19rem);
  overflow: scroll;
}

.search-profiles__dropdown-pane {
  height: 30rem;
  width: 35rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.search-profiles__dropdown-pane .react-dropdown__item em {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-profiles__dropdown-pane--position-right {
  right: 0;
}

.custom-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
}

.react-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #E4E4EC;
  opacity: 0.65;
  z-index: 100000;
}

#react-root-modal {
  position: absolute;
  z-index: 1100000000;
}

.modal {
  padding: 1rem;
}

.react-modal {
  position: relative;
  z-index: 99999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}
.react-modal__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.react-modal__footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.react-modal--full .react-modal__body--full {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.react-modal--flex > div {
  padding: 35px;
  background-color: #ffffff;
  border-radius: 10px;
}
.react-modal--not-scrollable {
  overflow: visible;
}

.react-modal__backdrop,
.react-backdrop {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.react-modal__backdrop::before,
.react-backdrop::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #E4E4EC;
  opacity: 0.65;
  z-index: 100000;
}

.react-modal__backdrop::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #E4E4EC;
  opacity: 0.65;
  z-index: 10000000000;
}

.react-organisationanalysisfromportfolio.react-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.react-organisationanalysisfromportfolio.react-component .blind-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.react-modal__content {
  position: relative;
  z-index: 100000000000;
  background: #ffffff;
  border-radius: 10px;
  padding: 45px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 0 8px -2px #d2d2d2;
}

.confirm-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-modal__content {
  z-index: 999999999;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.pane-block {
  padding: 2rem;
  box-shadow: 0 0 18px -4px #bbb;
  width: 40rem;
  height: 20rem;
  margin: 1rem;
}

.react-page {
  padding: 20px 35px;
  position: relative;
  top: 9rem;
  width: 100%;
  height: calc(100vh - 9rem);
}
.react-page--flex {
  display: flex;
  flex-wrap: wrap;
}
.react-page__container {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px -2px #d2d2d2;
  background-color: #ffffff;
}
.react-page__container-datapoints-content {
  display: grid;
  grid-template-columns: 100px 1fr;
}
.react-page__container-settings-content {
  display: grid;
  grid-template-columns: min-content 1fr;
}
.react-page__container-headspace {
  height: calc(100% - 4rem);
}

.react-menu__option {
  width: 100%;
  height: 3rem;
  padding: 0.5rem 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.react-menu__option:hover {
  cursor: pointer;
  background-color: #F4F6Fa;
}

.react-component {
  position: relative;
}

.react-select__pane {
  position: absolute;
  background: #fff;
  top: 2.75rem;
  box-shadow: 0 0 8px -2px #d2d2d2;
}
.react-select__pane ul {
  list-style: none;
  margin: 1rem 0;
}
.react-select__container {
  width: fit-content;
}
.react-select__selector {
  background: #fff;
  border: 2px solid #888;
  padding: 0.25rem 3rem 0.25rem 1rem;
  position: relative;
  height: 2.5rem;
  border-radius: 0.35rem;
}
.react-select__selector::after {
  content: "V";
  right: 0;
  top: 0;
  height: 100%;
  position: absolute;
  width: 2rem;
  border-left: 1px solid #CAC6DC;
  display: flex;
  justify-content: center;
  align-items: center;
}

#react-root-slide-menu {
  position: relative;
  z-index: 1000000;
}
#react-root-slide-menu .react-backdrop {
  z-index: 0;
}

.react-slide-menu__container {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100vh;
  width: fit-content;
  z-index: 10000;
}
.react-slide-menu__pane {
  position: relative;
  width: fit-content;
  min-width: 20rem;
  padding: 2rem;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 0 8px -2px #d2d2d2;
  transition: all 0.3s;
}
.react-slide-menu__pane--right {
  right: calc(0% - 1rem);
}
.react-slide-menu__pane--left {
  left: calc(0% - 1rem);
}
.react-slide-menu__pane--open.react-slide-menu__pane--right {
  right: 100%;
}
.react-slide-menu__pane--open.react-slide-menu__pane--left {
  left: 100%;
}
.react-slide-menu__close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.react-slide-menu__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0);
  z-index: 1;
}

.pane-v2--navigation.pane-v2 nav.navigation {
  height: calc(100% - 6rem);
}
.pane-v2--navigation.pane-v2 nav.navigation .navmenu__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.react-form {
  height: 100%;
  margin: 35px 0;
}
.react-form--horizontal {
  height: 35px;
  margin: 0 !important;
  display: flex;
  align-items: center;
}
.react-form--horizontal .form-group {
  margin: 0 20px 0 0;
}
.react-form:not(:first-child) {
  margin-top: 45px;
}
.react-form .form-group {
  position: relative;
}
.react-form__fields {
  position: relative;
  z-index: 2;
}
.react-form__field {
  display: grid;
  grid-template-columns: 20% minmax(auto, 40rem);
  grid-template-rows: 45px 45px;
  position: relative;
}
.react-form__field--radio {
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
.react-form__field--labelless {
  grid-template-columns: 1fr;
}
.react-form__field--hidden {
  display: none;
}
.react-form__block-field {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 35px 45px;
  grid-gap: 0 10px;
  margin-bottom: 20px;
}
.react-form__block-field label {
  grid-column: 1;
  grid-row: 1;
}
.react-form__block-field input {
  grid-column: 1;
  grid-row: 2;
}
.react-form__block-field .react-tooltip-wrapper {
  grid-column: 2;
  grid-row: 2;
}
.react-form__block-field--single {
  grid-template-rows: 35px;
}
.react-form__block-field--single .react-tooltip-wrapper {
  grid-row: 1;
}
.react-form__footer {
  grid-column: 2;
}
.react-form__label {
  position: relative;
  grid-column: 1;
  align-self: center;
}
.react-form__input-container, .react-form__submit-container {
  grid-column: 2;
  grid-row: 1/3;
  display: grid;
  grid-template-columns: 1fr 45px 45px;
  grid-template-rows: 45px 45px;
  grid-gap: 0 10px;
  width: 100%;
  height: 45px;
  position: relative;
}
.react-form__switch {
  display: flex;
  align-items: center;
  height: 100%;
}
.react-form__radio input {
  appearance: auto;
  margin: 0 10px;
}
.react-form__input, .react-form-select__control, .react-form .react-form-select__control {
  padding: 5px 10px;
  height: 45px;
  position: relative;
  width: 100%;
  border-radius: 10px !important;
  border: 1px solid #9F99B1;
  border-color: #9F99B1 !important;
  background: #ffffff;
}
.react-form__input--disabled, .react-form-select__control--disabled, .react-form .react-form-select__control--disabled {
  border: none;
}
.react-form__input .react-form-select__value-container, .react-form-select__control .react-form-select__value-container, .react-form .react-form-select__control .react-form-select__value-container {
  padding: 0 !important;
  height: 35px;
  margin-top: -5px;
}
.react-form__input .react-form-select__value-container .react-form-select__multi-value, .react-form-select__control .react-form-select__value-container .react-form-select__multi-value, .react-form .react-form-select__control .react-form-select__value-container .react-form-select__multi-value {
  height: 30px;
  margin-top: -7px;
}
.react-form__input .react-form-select__value-container .react-form-select__multi-value__label, .react-form-select__control .react-form-select__value-container .react-form-select__multi-value__label, .react-form .react-form-select__control .react-form-select__value-container .react-form-select__multi-value__label {
  height: 2rem;
  line-height: 25px;
}
.react-form__input .react-form-select__value-container .react-form-select__multi-value__remove, .react-form-select__control .react-form-select__value-container .react-form-select__multi-value__remove, .react-form .react-form-select__control .react-form-select__value-container .react-form-select__multi-value__remove {
  height: 1.5rem;
}
.react-form__input--small, .react-form-select__control--small, .react-form .react-form-select__control--small {
  height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.react-form__input--regular, .react-form-select__control--regular, .react-form .react-form-select__control--regular {
  height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.react-form__text-field {
  align-self: center;
  width: fit-content;
}
.react-form__flex-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-form select.inset--left,
.react-form input.inset--left {
  padding-left: 3rem;
}
.react-form select.inset--right,
.react-form input.inset--right {
  padding-right: 3rem;
}
.react-form select.inset--grey::after,
.react-form input.inset--grey::after {
  content: "";
  position: absolute;
  background: #E4E4EC;
  height: 35px;
  width: 35px;
  top: 0;
  right: 0;
  z-index: 1000;
}
.react-form label.inset--left, .react-form label.inset--right {
  position: absolute;
  line-height: 35px;
  width: 70px;
  text-align: center;
  z-index: 1;
}
.react-form label.inset--left {
  left: 0;
  padding-right: 20px;
}
.react-form label.inset--right {
  right: 0;
}
.react-form .react-form-select__single-value {
  align-self: baseline;
}
.react-form .react-form-select__indicator {
  padding: 0 0 0 20px !important;
}
.react-form .react-form-select__indicator.react-form-select__clear-indicator {
  padding-right: 20px !important;
}
.react-form__validation-message {
  grid-column: 1;
  grid-row: 2;
  padding-left: 20px;
  color: #F85C6A;
  font-size: 0.75rem;
  font-weight: bold;
}
.react-form__help {
  position: absolute;
  grid-column: 2;
  grid-row: 1;
}
.react-form__help-text {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem 0 1rem 0;
}
.react-form__button-container {
  display: flex;
}
.react-form__button-container button {
  margin-right: 1rem;
}
.react-form .rdrDateRangePickerWrapper {
  position: absolute;
  box-shadow: 0 0 8px -2px #d2d2d2;
  z-index: 1;
}
.react-form__date-value {
  border: 1px solid #9F99B1;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.react-form__daterange--value {
  display: flex;
  position: relative;
  width: 100%;
}
.react-form__daterange--value .react-form__date-picker {
  position: absolute;
  z-index: 1;
  top: 45px;
  right: 0;
  box-shadow: 0 0 8px -2px #d2d2d2;
}
.react-form__daterange--value em {
  margin: 0 1rem;
}
.react-form__field-icon {
  position: absolute;
  top: 0;
  right: calc(-3rem - 20px);
}

.react-form--invalid {
  border: 1px solid #F85C6A;
  background: rgba(255, 0, 0, 0.1254901961);
  color: #ff0000;
}

.react-form__action-icons,
.react-form__helptext {
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-self: center;
}

.formfield__action-icon {
  cursor: pointer;
  margin-right: 5px;
}

.react-form__fields-container > .react-form__addable ~ .react-form__addable {
  display: none;
}

.react-form__addable .react-tooltip-wrapper {
  width: 100%;
}
.react-form__addable .react-tooltip-wrapper .react-button {
  width: 100%;
  height: 45px;
}
.react-form__addable .react-form__action-icons {
  display: none;
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdrDateDisplay {
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem {
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: flex;
  flex-direction: row;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: flex;
}

.rdrWeekDay {
  flex-basis: 14.2857142857%;
  box-sizing: inherit;
  text-align: center;
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
  outline: none;
}

.rdrDateInput .rdrWarning {
  position: absolute;
  font-size: 1.6em;
  line-height: 1.6em;
  top: 0;
  right: 0.25em;
  color: #FF0000;
}

.rdrDay {
  box-sizing: inherit;
  width: 14.2857142857%;
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}
.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  pointer-events: none;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdrStaticRanges {
  display: flex;
  flex-direction: column;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: flex;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper {
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay {
  margin: 0.833em;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input {
  cursor: pointer;
  height: 2.5em;
  line-height: 2.5em;
  border: 0px;
  background: transparent;
  width: 100%;
  color: #849095;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
}

.rdrDateDisplayItemActive input {
  color: #7d888d;
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  font-weight: 600;
}

.rdrMonthAndYearPickers select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  padding: 10px 30px 10px 10px;
  border-radius: 4px;
  outline: 0;
  color: #3e484f;
  background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
  background-position: right 8px center;
  cursor: pointer;
  text-align: center;
}

.rdrMonthAndYearPickers select:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7;
}

.rdrNextPrevButton:hover {
  background: #E1E7F0;
}

.rdrNextPrevButton i {
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  margin: auto;
  transform: translate(-3px, 0px);
}

.rdrPprevButton i {
  border-width: 4px 6px 4px 4px;
  border-color: transparent rgb(52, 73, 94) transparent transparent;
  transform: translate(-3px, 0px);
}

.rdrNextButton i {
  margin: 0 0 0 7px;
  border-width: 4px 4px 4px 6px;
  border-color: transparent transparent transparent rgb(52, 73, 94);
  transform: translate(3px, 0px);
}

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth {
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  color: #1d2429;
}

.rdrDay:focus {
  outline: 0;
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
}

.rdrDayToday .rdrDayNumber span:after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #3d91ff;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background: #fff;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: rgba(255, 255, 255, 0.85);
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected {
  left: 2px;
  right: 2px;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected {
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0px;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0px;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper {
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600;
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff;
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #eff2f7;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges {
  padding: 10px 0;
}

.rdrInputRange {
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122);
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover {
  border-color: rgb(180, 191, 196);
  outline: 0;
  color: #333;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview {
  display: none;
}

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf;
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  filter: grayscale(100%) opacity(60%);
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

.react-switch-bg {
  height: 20px !important;
  width: 59px !important;
}
.react-switch-handle {
  height: 12px !important;
  width: 22px !important;
  border-radius: 1rem !important;
  margin-top: 0.2rem !important;
  margin-left: 0.2rem !important;
}

#quarter_range .react-svg-icon__calendar {
  position: absolute;
  top: 5px;
  right: 20px;
}

.react-form--forward-vacancy {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  width: 500px;
}
.react-form--forward-vacancy .react-form-select-creatable .react-form-select__value-container {
  height: auto !important;
}
.react-form--forward-vacancy .react-form-select-creatable .react-form-select__multi-value {
  margin-top: 5px;
}
.react-form--forward-vacancy .react-form-select-creatable .react-form-select__multi-value .react-form-select__multi-value__remove {
  height: auto !important;
}

.react-svg-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.react-svg-icon--size-xs svg, .react-svg-icon--size-extra-small svg {
  height: 5px;
  width: 5px;
}
.react-svg-icon--size-sm svg, .react-svg-icon--size-small svg {
  height: 10px;
  width: 10px;
}
.react-svg-icon--size-regular svg {
  height: 20px;
  width: 20px;
}
.react-svg-icon--size-lg svg, .react-svg-icon--size-large svg {
  height: 35px;
  width: 35px;
}
.react-svg-icon--size-xl svg, .react-svg-icon--size-extra-large svg {
  height: 45px;
  width: 45px;
}
.react-svg-icon--size-wrapper-xs, .react-svg-icon--size-wrapper-extra-small {
  height: 5px;
  width: 5px;
}
.react-svg-icon--size-wrapper-sm, .react-svg-icon--size-wrapper-small, .react-svg-icon--size-wrapper-small-square {
  height: 10px;
  width: 10px;
}
.react-svg-icon--size-wrapper-regular {
  height: 20px;
  width: 20px;
}
.react-svg-icon--size-wrapper-lg, .react-svg-icon--size-wrapper-large {
  height: 35px;
  width: 35px;
}
.react-svg-icon--size-wrapper-xl, .react-svg-icon--size-wrapper-extra-large {
  height: 45px;
  width: 45px;
}
.react-svg-icon-theme--regular, .react-svg-icon-theme--dropdown-icononly {
  fill: #9F99B1;
}
.react-svg-icon-theme--regular--hover:hover, .react-svg-icon-theme--regular--active, .react-svg-icon-theme--dropdown-icononly--hover:hover, .react-svg-icon-theme--dropdown-icononly--active {
  fill: #1E025F;
  cursor: pointer;
}
.react-svg-icon-theme--secondary {
  fill: #1E025F;
}
.react-svg-icon-theme--dark {
  fill: #1E025F;
}
.react-svg-icon-theme--light, .react-svg-icon-theme--submit, .react-svg-icon-theme--dropdown {
  fill: #ffffff;
}
.react-svg-icon-theme--light--hover:hover, .react-svg-icon-theme--light--active, .react-svg-icon-theme--submit--hover:hover, .react-svg-icon-theme--submit--active, .react-svg-icon-theme--dropdown--hover:hover, .react-svg-icon-theme--dropdown--active {
  fill: #1E025F;
  cursor: pointer;
}
.react-svg-icon-theme--tab-nav-active {
  fill: #1E025F;
}
.react-svg-icon-theme--action, .react-svg-icon-theme--action-attention {
  fill: #ffffff;
}
.react-svg-icon--active {
  fill: #1E025F;
}
.react-svg-icon-pointer {
  cursor: pointer;
}
.react-svg-icon__inset--left-extra-small {
  position: absolute;
  height: 100%;
  left: 5px;
}
.react-svg-icon__inset--left-small {
  position: absolute;
  height: 100%;
  left: 10px;
}
.react-svg-icon__inset--right-small {
  position: absolute;
  height: 100%;
  right: 10px;
}
.react-svg-icon--absolute {
  position: absolute;
}
.react-svg-icon--left-extra-small {
  left: 5px;
}
.react-svg-icon--left-small {
  left: 10px;
}
.react-svg-icon--left-regular {
  left: 20px;
}
.react-svg-icon--left-large {
  left: 35px;
}
.react-svg-icon--left-extra-large {
  left: 45px;
}
.react-svg-icon--top-extra-small {
  top: 5px;
}
.react-svg-icon--top-small {
  top: 10px;
}
.react-svg-icon--top-regular {
  top: 20px;
}
.react-svg-icon--top-large {
  top: 35px;
}
.react-svg-icon--top-extra-large {
  top: 45px;
}
.react-svg-icon--right-extra-small {
  right: 5px;
}
.react-svg-icon--right-small {
  right: 10px;
}
.react-svg-icon--right-regular {
  right: 20px;
}
.react-svg-icon--right-large {
  right: 35px;
}
.react-svg-icon--right-extra-large {
  right: 45px;
}
.react-svg-icon--bottom-extra-small {
  bottom: 5px;
}
.react-svg-icon--bottom-small {
  bottom: 10px;
}
.react-svg-icon--bottom-regular {
  bottom: 20px;
}
.react-svg-icon--bottom-large {
  bottom: 35px;
}
.react-svg-icon--bottom-extra-large {
  bottom: 45px;
}

.react-icon {
  position: absolute;
  top: 0.5rem;
  right: 20px;
}
.react-icon--active {
  background: #1E025F !important;
}
.react-icon--left {
  right: initial;
  left: 20px;
}
.react-icon--center {
  right: initial;
  top: initial;
}
.react-icon--flex {
  position: relative;
  top: initial;
  right: initial;
}
.react-icon svg {
  fill: #1E025F;
  stroke: none;
  height: 35px;
  width: 35px;
}
.react-icon--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.react-icon--flex-center::after {
  content: "";
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  background: rgba(255, 255, 255, 0);
}
.react-icon--small svg {
  height: 20px;
  width: 20px;
}
.react-icon--large svg {
  height: 35px;
  width: 35px;
}
.react-icon--xxl svg {
  height: 45px;
  width: 45px;
}
.react-icon--margin-left-s, .react-icon--margin-left-small {
  margin-left: 10px;
}
.react-icon--margin-left-xs {
  margin-left: 5px;
}
.react-icon--dark svg path {
  fill: #1E025F;
  stroke: #1E025F;
}
.react-icon__wrapper.react-icon--inactive svg {
  fill: #9F99B1;
  line-height: inherit !important;
}
.react-icon__wrapper.react-icon--warning svg {
  fill: #F85C6A !important;
  stroke: #F85C6A !important;
}
.react-icon--bg-white {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  box-shadow: 0 0 8px -2px #d2d2d2;
}
.react-icon--label.react-icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9F99B1;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.react-icon--label.react-icon__wrapper svg {
  height: 20px;
  width: 20px;
}
.react-icon--label.react-icon__wrapper svg path {
  fill: #ffffff;
  stroke: #ffffff;
}
.react-icon--form-inset-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E4E4EC;
  border-radius: 0 10px 10px 0;
  border: 1px solid #9F99B1;
  border-style: solid solid solid none;
}

.icon-wrap svg path {
  fill: inherit;
}

.react-checkbox {
  height: 20px;
  width: 20px;
  border: 2px solid #E4E4EC;
  border-radius: 5px;
}
.react-checkbox__container {
  position: relative;
  height: 20px;
  margin-right: 10px;
}
.react-checkbox__container .react-svg-icon.react-svg-icon__check {
  position: absolute;
  top: 0;
}

.react-tooltip {
  border-radius: 5px !important;
  background-color: #ffffff !important; /* Witte achtergrond */
  color: #443469 !important; /* Donkere tekstkleur voor contrast */
  box-shadow: 0 0 8px -2px #d2d2d2 !important; /* Licht schaduw effect */
  z-index: 10000000000000000000;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  max-width: 25rem;
  font-size: 20px;
}

.react-tooltip {
  line-height: 1.4 !important; /* Pas de regelafstand aan */
}

.react-tooltip div {
  margin: 0px 0 !important; /* Verklein de marge tussen regels */
}